import Login from '../Login';

export const DEFAULT_PUBLIC_PATH = '/';
export const PUBLIC_PATHS = {
  login: '/',
};
export const PUBLIC_PATHS_CONFIG = [
  {
    path: PUBLIC_PATHS.login,
    component: Login,
  },
];

import React from 'react';
import { connect } from 'react-redux';
import AddIndustriesForm from '../../../../Components/Forms/Admin/Industries/AddIndustries';
import ModalsWrappedForm from '../../../../Components/Forms/ModalsWrappedForm';
import trans from '../../../../Translations/translation.service';
import { actions as industriesActions, types } from '../../../../Redux/Api/Industries';
import { PRIVATE_PATHS } from '../../../config';
import { FEMALE_CODE } from '../../../../Constants';
import { ADD_ACTIONS } from '../../../../Components/Forms/Admin/Industries/config';
import { catchApiArrayErrors } from '../../../../Helpers';

class AddIndustries extends React.Component {
  createIndustries = data => {
    return this.props.createManyIndustries(data.industries).catch(
      catchApiArrayErrors('industries'),
    );
  };

  render() {
    const { history, success } = this.props;
    const subject = trans('resources.plural.industries');

    return (
      <ModalsWrappedForm
        gender={FEMALE_CODE}
        history={history}
        loading={this.props.isCreatingIndustries}
        open={success}
        path={PRIVATE_PATHS.adminIndustries.path}
        subject={subject}
        successMessage="successOpPlurF"
        type="add"
      >
        <AddIndustriesForm
          actions={ADD_ACTIONS}
          history={history}
          onSubmit={this.createIndustries}
          path={PRIVATE_PATHS.adminIndustries.path}
        />
      </ModalsWrappedForm>
    );
  }
}

const mapStateToProps = ({
  industries: { isCreatingMany },
  reduxRestResponse: { body, success, type },
}) => ({
  body,
  isCreatingIndustries: isCreatingMany,
  success: type === types.CREATEMANY_INDUSTRIES && success,
});

export default connect(
  mapStateToProps,
  {
    ...industriesActions,
  }
)(AddIndustries);

import trans from '../../../../Translations/translation.service';
import { API_DATE_FORMAT } from '../../../../Constants';

export const ADD_ACTIONS = [
  {
    color: 'secondary',
    handler: 'backToList',
    label: trans('modals.actions.backToList'),
    type: 'button',
  },
  {
    color: 'primary',
    label: trans('forms.actions.save'),
    type: 'submit',
  },
];
export const EDIT_ACTIONS = [
  {
    color: 'secondary',
    handler: 'backToList',
    label: trans('modals.actions.backToList'),
    type: 'button',
  },
  {
    color: 'primary',
    label: trans('forms.actions.save'),
    type: 'submit',
  },
];

export const DEFAULT_VALUES = {
  status: null,
  username: '',
  password: '',
  password_confirm: '',
  person: {
    first_name: '',
    id_number: '',
    last_name: '',
  },
  permissions: {
    _ids: [],
  },
};

export const ADD_INPUTS = [
  {
    fieldType: 'tab',
    name: 'user',
    tabs: [
      {
        code: 'general',
        label: trans('forms.tabs.client.users.general'),
        inputs: [
          {
            id: 'username',
            inputProps: {
              maxLength: 255,
            },
            label: trans('forms.fields.client.users.username'),
            name: 'username',
            required: true,
            sizes: {
              xs: 12,
              sm: 6,
              md: 4,
            },
          },
          {
            id: 'password',
            inputProps: {
              maxLength: 255,
            },
            label: trans('forms.fields.client.users.password'),
            name: 'password',
            required: true,
            sizes: {
              xs: 12,
              sm: 6,
              md: 4,
            },
            type: 'password',
          },
          {
            id: 'password-confirm',
            inputProps: {
              maxLength: 255,
            },
            label: trans('forms.fields.client.users.passwordConfirm'),
            name: 'password_confirm',
            required: true,
            sizes: {
              xs: 12,
              sm: 6,
              md: 4,
            },
            type: 'password',
          },
          {
            id: 'status',
            label: trans('forms.fields.client.users.status'),
            name: 'status',
            required: true,
            sizes: {
              xs: 12,
              sm: 6,
              md: 4,
            },
            type: 'select',
          },
        ],
      },
      {
        code: 'personal',
        label: trans('forms.tabs.client.users.personal'),
        inputs: [
          {
            id: 'person-first-name',
            inputProps: {
              maxLength: 255,
            },
            label: trans('forms.fields.client.users.personFirstName'),
            name: 'person.first_name',
            sizes: {
              xs: 12,
              sm: 6,
              md: 4,
            },
          },
          {
            id: 'person-last-name',
            inputProps: {
              maxLength: 255,
            },
            label: trans('forms.fields.client.users.personLastName'),
            name: 'person.last_name',
            sizes: {
              xs: 12,
              sm: 6,
              md: 4,
            },
          },
          {
            id: 'person-id-number',
            inputProps: {
              maxLength: 255,
            },
            label: trans('forms.fields.client.users.personIdNumber'),
            name: 'person.id_number',
            sizes: {
              xs: 12,
              sm: 6,
              md: 4,
            },
          },
          {
            id: 'person-alt-id-number',
            inputProps: {
              maxLength: 255,
            },
            label: trans('forms.fields.client.users.personAltIdNumber'),
            name: 'person.alt_id_number',
            sizes: {
              xs: 12,
              sm: 6,
              md: 4,
            },
          },
          {
            id: 'person-gender',
            label: trans('forms.fields.client.users.personGender'),
            name: 'person.gender',
            sizes: {
              xs: 12,
              sm: 6,
              md: 4,
            },
            type: 'select',
          },
          {
            id: 'person-birth-date',
            inputFormat: API_DATE_FORMAT,
            label: trans('forms.fields.client.users.personBirthDate'),
            name: 'person.birth_date',
            sizes: {
              xs: 12,
              sm: 6,
              md: 4,
            },
            type: 'datepicker',
          },
        ],
      },
      {
        code: 'permissions',
        label: trans('forms.tabs.client.users.permissions'),
        inputs: [
          {
            id: 'permissions',
            label: trans('forms.fields.client.users.permissions'),
            name: 'permissions._ids',
            sizes: {
              xs: 12,
            },
            type: 'multipleCheckbox',
          },
        ],
      },
    ],
  }
];

export const EDIT_INPUTS = [
  {
    fieldType: 'tab',
    name: 'user',
    tabs: [
      {
        code: 'general',
        inputs: [
          {
            id: 'username',
            inputProps: {
              maxLength: 255,
            },
            label: trans('forms.fields.client.users.username'),
            name: 'username',
            required: true,
            sizes: {
              xs: 12,
              sm: 6,
              md: 4,
            },
          },
          {
            id: 'status',
            label: trans('forms.fields.client.users.status'),
            name: 'status',
            required: true,
            sizes: {
              xs: 12,
              sm: 6,
              md: 4,
            },
            type: 'select',
          },
        ],
        label: trans('forms.tabs.client.users.general'),
      },
      {
        code: 'personal',
        inputs: [
          {
            id: 'person-first-name',
            inputProps: {
              maxLength: 255,
            },
            label: trans('forms.fields.client.users.personFirstName'),
            name: 'person.first_name',
            sizes: {
              xs: 12,
              sm: 6,
              md: 4,
            },
          },
          {
            id: 'person-last-name',
            inputProps: {
              maxLength: 255,
            },
            label: trans('forms.fields.client.users.personLastName'),
            name: 'person.last_name',
            sizes: {
              xs: 12,
              sm: 6,
              md: 4,
            },
          },
          {
            id: 'person-id-number',
            inputProps: {
              maxLength: 255,
            },
            label: trans('forms.fields.client.users.personIdNumber'),
            name: 'person.id_number',
            sizes: {
              xs: 12,
              sm: 6,
              md: 4,
            },
          },
          {
            id: 'person-alt-id-number',
            inputProps: {
              maxLength: 255,
            },
            label: trans('forms.fields.client.users.personAltIdNumber'),
            name: 'person.alt_id_number',
            sizes: {
              xs: 12,
              sm: 6,
              md: 4,
            },
          },
          {
            id: 'person-gender',
            label: trans('forms.fields.client.users.personGender'),
            name: 'person.gender',
            sizes: {
              xs: 12,
              sm: 6,
              md: 4,
            },
            type: 'select',
          },
          {
            id: 'person-birt-date',
            inputFormat: API_DATE_FORMAT,
            label: trans('forms.fields.client.users.personBirthDate'),
            name: 'person.birth_date',
            sizes: {
              xs: 12,
              sm: 6,
              md: 4,
            },
            type: 'datepicker',
          },
        ],
        label: trans('forms.tabs.client.users.personal'),
      },
      {
        code: 'permissions',
        inputs: [
          {
            id: 'permissions',
            label: trans('forms.fields.client.users.permissions'),
            name: 'permissions._ids',
            sizes: {
              xs: 12,
            },
            type: 'multipleCheckbox',
          },
        ],
        label: trans('forms.tabs.client.users.permissions'),
      },
      {
        code: 'change_password',
        inputs: [
          {
            id: 'password',
            inputProps: {
              maxLength: 255,
            },
            label: trans('forms.fields.client.users.password'),
            name: 'password',
            sizes: {
              xs: 12,
              sm: 6,
              md: 4,
            },
            type: 'password',
          },
          {
            id: 'password-confirm',
            inputProps: {
              maxLength: 255,
            },
            label: trans('forms.fields.client.users.passwordConfirm'),
            name: 'password_confirm',
            sizes: {
              xs: 12,
              sm: 6,
              md: 4,
            },
            type: 'password',
          },
        ],
        label: trans('forms.tabs.client.users.changePassword'),
      },
    ],
  }
];

export const USER_FORM = 'user';

export const USER_VALIDATIONS = [
  {
    field: 'status',
    message: ['default.forms.validations.isRequired'],
    method: 'isNull',
    validWhen: false,
  },
  {
    field: 'username',
    message: ['default.forms.validations.isRequired'],
    method: 'isEmpty',
    validWhen: false,
  },
  {
    field: 'password',
    message: ['default.forms.validations.isRequired'],
    method: 'isEmpty',
    validWhen: false,
  },
  {
    field: 'password_confirm',
    message: ['default.forms.validations.isRequired'],
    method: 'isEmpty',
    validWhen: false,
  },
  {
    args: ['password'],
    field: 'password_confirm',
    message: [
      'default.forms.validations.matchesField',
      {
        field: trans('forms.fields.client.users.password'),
      },
    ],
    method: 'matchesField',
    validWhen: true,
  },
];

export const USER_PARSE_CONFIG = [
  {
    field: 'person.birth_date',
    parser: 'date',
  },
];

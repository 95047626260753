import React from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import AccountStatus from '../../Components/Profile/Styled/AccountStatus';
import CompanyName from '../../Components/Profile/Styled/CompanyName';
import Info from '../../Components/Profile/Info';
import MyAccountForm from '../Forms/Client/Accounts/MyAccount';
import ProfileGrid from '../../Components/Profile/Styled/ProfileGrid';
import ProfileLeftPane from '../../Components/Profile/Styled/ProfileLeftPane';
import ProfileLeftPaneWrapper from '../../Components/Profile/Styled/ProfileLeftPaneWrapper';
import ProfileRightPane from '../../Components/Profile/Styled/ProfileRightPane';
import Separator from '../../Components/Profile/Styled/Separator';
import UserFullName from '../../Components/Profile/Styled/UserFullName';
import { actions as usersActions, types } from '../../Redux/Api/Users';
import trans from '../../Translations/translation.service';
import { ACCOUNTS_STATUSES } from '../../Constants';

class Profile extends React.Component {
  componentDidMount() {
    const { userId } = this.props;

    this.props.getUser(userId);
  }

  render() {
    const { account, user } = this.props;
    const companyName = account.company_name;
    const fullName = user.person ? `${user.person.first_name} ${user.person.last_name}` : '';
    const statusText = account.status !== undefined ?
      trans(`types.statusTexts.accounts.${ACCOUNTS_STATUSES[account.status]}`) : '';

    return (
      <ProfileGrid container>
        <ProfileLeftPane item xs={4}>
          <ProfileLeftPaneWrapper>
            <UserFullName>{fullName}</UserFullName>
            <CompanyName>{companyName}</CompanyName>
            <AccountStatus>{statusText}</AccountStatus>
            <Separator />
            {
              account.company &&
              <>
                <Info
                  label={trans('forms.fields.client.myAccount.companyEmail')}
                  content={account.company.email}
                />
                <Info
                  label={trans('forms.fields.client.myAccount.companyPhone')}
                  content={account.company.phone}
                />
                <Info
                  label={trans('forms.fields.client.myAccount.companyAddress')}
                  content={account.company.address}
                />
              </>
            }
          </ProfileLeftPaneWrapper>
        </ProfileLeftPane>
        <ProfileRightPane item xs={8}>
          <MyAccountForm />
        </ProfileRightPane>
      </ProfileGrid>
    );
  }
}

const mapStateToProps = ({
  accounts: {
    item: account,
  },
  auth: { user: { id } },
  users: {
    item: user,
  },
}) => ({
  account: account ? account.data : {},
  userId: id,
  user: user ? user.data : {},
});

export default connect(
  mapStateToProps,
  {
    ...usersActions,
  },
)(Profile);

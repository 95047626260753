import React from 'react';
import NewStory from './NewStory';
import Wrapper from './Styled/StyledWrapper';

class News extends React.Component {
    render() {
        const { news } = this.props;

        return (
            <Wrapper>
                <div>
                    <div>
                        {
                            Array.isArray(news) &&
                            news.map((newStory, key) => (
                                <NewStory key={key} {...newStory} />
                            ))
                        }
                    </div>
                </div>
            </Wrapper>
        );
    }
}

export default News;

import * as types from './types';

// Action Creators
export const setHeaderTitle = headerTitle => ({
  headerTitle,
  type: types.SET_HEADER_TITLE,
});

export const setHeaderMenu = headerMenu => ({
  headerMenu,
  type: types.SET_HEADER_MENU,
});

export const setHeaderMenuAction = (option, callback) => ({
  option,
  callback,
  type: types.SET_HEADER_MENU_ACTION,
});

export const setLocale = locale => ({
  locale,
  type: types.SET_LOCALE,
});

export const toggleOpen = open => ({
  open,
  type: types.SET_OPEN,
});

export const setOnSearch = onSearch => ({
  onSearch,
  type: types.SET_ON_SEARCH,
});

export const setRouteType = routeType => ({
  routeType,
  type: types.SET_ROUTE_TYPE,
});

export const setSearch = search => ({
  search,
  type: types.SET_SEARCH,
});

export const setSearchPlaceholder = searchPlaceholder => ({
  searchPlaceholder,
  type: types.SET_SEARCH_PLACEHOLDER,
});

export const setSelectedOption = selectedOption => ({
  selectedOption,
  type: types.SET_SELECTED_OPTION,
});

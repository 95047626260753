import React from 'react';
import Grid from '@material-ui/core/Grid';
import Filters from './Filters';

export default ({ filtersProps }) => {
  return (
    <Grid item xs={12}>
      <Filters filtersProps={filtersProps} />
    </Grid>
  );
};

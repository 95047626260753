import React from 'react';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';
import ResponsiveForm from '../../Form';
import { parseAccountSubscriptionData, validate } from '../../../../Helpers';
import {
  DEFAULT_VALUES,
  ADD_INPUTS,
  ACCOUNT_FORM as form,
  ACCOUNT_VALIDATIONS,
} from './config';

let AddAccountForm = ({ handleSubmit, subscriptions, loading = false, ...props }) => (
  <ResponsiveForm
    {...props}
    inputs={ADD_INPUTS}
    loading={loading}
    onSubmit={handleSubmit}
    values={{ subscriptions }}
  />
);

AddAccountForm = reduxForm({
  form,
  validate: validate(ACCOUNT_VALIDATIONS),
})(AddAccountForm);

const selector = formValueSelector(form);

const mapStateToProps = ({
  allIntegrators: {
    items: integrators,
  },
  allServices: {
    items: services,
  },
  ...state
}) => ({
  initialValues: parseAccountSubscriptionData(
    { ...DEFAULT_VALUES },
    integrators[1] ? integrators[1] : [],
    services[1] ? services[1] : [],
  ),
  subscriptions: selector(state, 'subscriptions'),
});

export default connect(
  mapStateToProps
)(AddAccountForm);

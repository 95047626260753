import React from 'react';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import IconGrid from './Styled/IconGrid';
import IconInputGrid from './Styled/IconInputGrid';
import { ICON_ALIGNMENTS } from './config';

export default ({
  clickableIcon,
  field,
  iconAlign,
  iconHandleClick,
  icon: Icon,
  error = false,
  removeLabel = '',
}) => {
  const icon = (
    <Tooltip key="icon" title={removeLabel}>
      <IconGrid item clickable={clickableIcon}>
        <Icon onClick={iconHandleClick} />
      </IconGrid>
    </Tooltip>
  );
  const input = (
    <IconInputGrid item key="input">
      {field}
    </IconInputGrid>
  );

  return (
    <Grid
      container
      alignItems={error ? 'center' : 'flex-end'}
      spacing={1}
    >
      {
        iconAlign === ICON_ALIGNMENTS.left
          ? [icon, input]
          : [input, icon]
      }
    </Grid>
  );
};

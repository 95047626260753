import { createResource } from 'redux-rest-resource';
import {
  HEADERS as headers,
  PAYMENTS_URL,
} from '../../Constants';
import { transformFetchResponse as transformResponse } from '../../Helpers';

export const { types, actions, reducers } = createResource({
  name: 'payment',
  url: `${PAYMENTS_URL}/:id`,
  headers,
  actions: {
    fetch: {
      transformResponse,
    },
  },
});

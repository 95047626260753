import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { DEFAULT_PRIVATE_PATHS_ARRAY } from './config';

const PublicRoute = ({
  authenticated,
  user,
  component: Component,
  ...props
}) => (
  <Route
    {...props}
    render={props =>
      !authenticated ? (
        <Component {...props} />
      ) : (
        <Redirect to={DEFAULT_PRIVATE_PATHS_ARRAY[user.role]} />
      )
    }
  />
);

const mapStateToProps = ({
  auth: { authenticated, user },
}) => {
  return {
    authenticated,
    user,
  };
};

export default connect(mapStateToProps)(PublicRoute);

import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import { parse } from 'query-string';
import Modal from '../../../Components/Modals/Modal';
import ExportFiltersForm from '../../../Components/Forms/Admin/ShortCodes/ExportFilters';
import Table from '../../../Components/Table';
import trans from '../../../Translations/translation.service';
import { actions as allAccountsActions } from '../../../Redux/Api/AllAccounts';
import { actions as allKeywordsActions } from '../../../Redux/Api/AllKeywords';
import { actions as allShortCodesActions } from '../../../Redux/Api/AllShortCodes';
import { actions as fileDownloadActions } from '../../../Redux/Ducks/FileDownload';
import { actions as messagesActions } from '../../../Redux/Api/Messages';
import { actions as navBarActions } from '../../../Redux/Ducks/Navbar';
import {
  ADMIN_SHORT_CODES_STATS_FILTERS_INPUTS,
} from '../../Dashboards/config';
import { EXPORT_FILTERS_ACTIONS } from '../../../Components/Forms/Admin/ShortCodes/config';
import { FILTERS_FORM as form } from '../../../Components/Filters/config';
import {
  ALL_SELECT_OPTION_F,
  ALL_SELECT_OPTION_M,
  ALL_SELECT_OPTION_VALUE as ALL,
  API_DATE_FORMAT,
  API_DATE_FORMAT_FIRST,
  HEADERS as headers,
  MESSAGES_URL,
  RECEIVED_MESSAGES_TABLE_TITLES,
} from '../../../Constants';
import { getPage, parseApiDate, parseApiTime } from '../../../Helpers';

class AdminReceivedMessages extends React.Component {
  state = {
    account: ALL,
    exportFilters: {},
    filters: {
      from: moment().format(API_DATE_FORMAT_FIRST),
    },
    shortCode: ALL,
    openExportFiltersModal: false,
  };

  fetchAllKeywords = () => {
    const { account, shortCode } = this.state;

    if (account === ALL && shortCode === ALL) {
      this.props.fetchAllKeywords();
    } else if (account === ALL) {
      this.props.fetchAllKeywords({}, { query: { short_code: shortCode } });
    } else if (shortCode === ALL) {
      this.props.fetchAllKeywords({}, { query: { account } });
    } else {
      this.props.fetchAllKeywords({}, { query: { account, short_code: shortCode } });
    }
  };

  fetchAllShortCodes = () => {
    const { account } = this.state;

    if (account === ALL) {
      this.props.fetchAllShortCodes();
    } else {
      this.props.fetchAllShortCodes({}, { query: { account } });
    }
  };

  fetchAllKeywordsFromAccount = account => {
    const { filters } = this.state;

    if (account === ALL) {
      delete filters.account;
    } else {
      filters.account =  account;
    }

    delete filters.keyword_id;
    delete filters.short_code;
    this.props.change(form, 'keyword_id', ALL);
    this.props.change(form, 'short_code_id', ALL);

    this.setState({ account, filters },  () => {
      this.fetchAllKeywords();
      this.fetchAllShortCodes();
      this.fetchMessages();
    });
  };

  fetchAllKeywordsFromShortCode = shortCode => {
    const { filters } = this.state;

    if (shortCode === ALL) {
      delete filters.short_code;
    } else {
      filters.short_code =  shortCode;
    }

    delete filters.keyword_id;
    this.props.change(form, 'keyword_id', ALL);

    this.setState({ filters, shortCode }, () => {
      this.fetchAllKeywords();
      this.fetchMessages();
    });
  };

  fetchMessages = newPage => {
    const { filters } = this.state;
    const { location } = this.props;
    const page = getPage(newPage, location);

    this.props.fetchMessages({}, {
      query: { page, keyword: true, outgoing: false, ...filters },
    });
  };

  fetchMessagesFromCarrier = carrier => {
    const { filters } = this.state;

    if (carrier === ALL) {
      delete filters.carrier;
    } else {
      filters.carrier =  carrier;
    }

    this.setState({ filters }, () => {
      this.fetchMessages();
    });
  };

  fetchMessagesFromDateFrom = date => {
    const { filters } = this.state;

    const momentDate = moment(date, API_DATE_FORMAT);

    if (momentDate._isValid) {
      filters.from =  date;
    } else {
      delete filters.from;
    }

    this.setState({ filters }, () => {
      this.fetchMessages();
    });
  };

  fetchMessagesFromDateTo = date => {
    const { filters } = this.state;

    const momentDate = moment(date, API_DATE_FORMAT);

    if (momentDate._isValid) {
      filters.to =  date;
    } else {
      delete filters.to;
    }

    this.setState({ filters }, () => {
      this.fetchMessages();
    });
  };

  fetchMessagesFromKeyword = keyword => {
    const { filters } = this.state;

    if (keyword === ALL) {
      delete filters.keyword_id;
    } else {
      filters.keyword_id =  keyword;
    }

    this.setState({ filters }, () => {
      this.fetchMessages();
    });
  };

  getActions = () => {
    const actions = [ ...EXPORT_FILTERS_ACTIONS ];
    actions[0].onClick = this.toggleOpenExportFiltersForm;
    return actions;
  };

  getDefaultCarrier = () => {
    const { filters } = this.state;
    const { location } = this.props;
    const query = location && parse(location.search);

    if (query && query.carrier) {
        filters.carrier = query.carrier;
        this.setState({ filters }, this.fetchMessages);

        return query.carrier;
    } else {
        this.fetchMessages();
    }

    return ALL;
  };

  handleSearch = (search)  => {
    const { filters } = this.state;

    filters.sender = search;

    this.setState({ filters }, () => {
      this.fetchMessages();
    });
  };

  initFilters = () => {
    const today = moment();
    this.props.reset(form);
    this.props.change(form, 'from_date', today.format(API_DATE_FORMAT_FIRST));
    this.props.change(form, 'to_date', today.format(API_DATE_FORMAT));
    this.props.change(form, 'account_id', ALL);
    this.props.change(form, 'keyword_id', ALL);
    this.props.change(form, 'short_code_id', ALL);
    this.props.change(form, 'carrier', this.getDefaultCarrier());
  };

  loading = () => {
    const {
      isDownloadingFile,
      isFetchingAccount,
      isFetchingKeywords,
      isFetchingMessages,
      isFetchingShortCodes,
      onDashboard
    } = this.props;
    return !onDashboard && (isDownloadingFile
      || isFetchingAccount
      || isFetchingKeywords
      || isFetchingMessages
      || isFetchingShortCodes);
  };

  parseMessageData = ({
    sender,
    content: message,
    keyword,
    send_date,
    send_time,
  }) => {
    if (typeof keyword !== 'object' || !keyword) {
      return [];
    }

    const {
      short_code,
      word,
    } = keyword;

    return [
      {
        text: sender,
      },
      {
        text: message,
      },
      {
        text: word,
      },
      {
        text: short_code && short_code.code,
      },
      {
        noWrap: true,
        text: parseApiDate(send_date),
      },
      {
        noWrap: true,
        text: parseApiTime(send_time),
      },
    ];
  };

  export = () => {
    const { exportFilters } = this.state;

    this.props.downloadFile(
      `${MESSAGES_URL}/export`,
      { headers, params: exportFilters },
      'received-messages.xlsx'
    ).then(() => {
      this.toggleOpenExportFiltersForm();
    });
  };

  setMenuActions = () => {
    this.props.setHeaderMenuAction('export', this.toggleOpenExportFiltersForm);
  };

  toggleOpenExportFiltersForm = () => {
    const { openExportFiltersModal } = this.state;
    let { exportFilters } = this.state;

    if (!openExportFiltersModal) {
      exportFilters = {};
    }

    this.setState({ exportFilters, openExportFiltersModal: !openExportFiltersModal });
  };

  componentDidMount() {
    this.props.fetchAllAccounts();
    this.fetchAllKeywords();
    this.fetchAllShortCodes();
    this.initFilters();
    this.props.setOnSearch(this.handleSearch);
    this.setMenuActions();
  }

  parseAccounts = () => {
    const accounts = this.props.accounts.map(({ id: value, company_name: text }) => ({
      text, value,
    }));

    accounts.unshift(ALL_SELECT_OPTION_F);

    return accounts;
  };

  parseKeywords = () => {
    const keywords = this.props.keywords.map(({ id: value, word: text }) => ({
      text, value,
    }));

    keywords.unshift(ALL_SELECT_OPTION_F);

    return keywords;
  };

  parseShortCodes = () => {
    const shortCodes = this.props.shortCodes.map(({ id: value, code: text }) => ({
      text, value,
    }));

    shortCodes.unshift(ALL_SELECT_OPTION_M);

    return shortCodes;
  };

  setExportFromFilter = date => {
    const { exportFilters } = this.state;

    exportFilters.from = date;

    this.setState({ exportFilters });
  };

  setExportToFilter = date => {
    const { exportFilters } = this.state;

    exportFilters.to = date;

    this.setState({ exportFilters });
  };

  render() {
    const accounts = this.parseAccounts();
    const keywords = this.parseKeywords();
    const shortCodes = this.parseShortCodes();
    const {
      messages,
      history,
      isFetchingMessages,
      pagination,
      showFilters,
      showPagination,
    } = this.props;

    return (
      <>
        <Modal
          noActions={true}
          open={this.state.openExportFiltersModal}
          title={trans('modals.titles.exportReceivedMessages')}
        >
          <ExportFiltersForm
            actions={this.getActions()}
            afterChange={{
              from_date: this.setExportFromFilter,
              to_date: this.setExportToFilter,
            }}
            onSubmit={this.export}
          />
        </Modal>
        <Table
          data={isFetchingMessages ? [] : messages}
          dataParser={this.parseMessageData}
          filtersProps={showFilters && {
            afterChange: {
              account_id: this.fetchAllKeywordsFromAccount,
              from_date: this.fetchMessagesFromDateFrom,
              to_date: this.fetchMessagesFromDateTo,
              short_code_id: this.fetchAllKeywordsFromShortCode,
              keyword_id: this.fetchMessagesFromKeyword,
              carrier: this.fetchMessagesFromCarrier,
            },
            data: {
              account_id: accounts,
              keyword_id: keywords,
              short_code_id: shortCodes,
            },
            inputs: ADMIN_SHORT_CODES_STATS_FILTERS_INPUTS,
            loading: this.loading(),
          }}
          handleChangePage={this.fetchMessages}
          history={history}
          loading={this.loading()}
          pagination={pagination}
          showPagination={showPagination}
          titles={RECEIVED_MESSAGES_TABLE_TITLES}
        />
      </>
    );
  }
}

AdminReceivedMessages.defaultProps = {
  showFilters: true,
  showPagination: true,
};

const mapStateToProps = ({
  allAccounts: {
    isFetching: isFetchingAccounts,
    items: accounts,
  },
  allKeywords: {
    isFetching: isFetchingKeywords,
    items: keywords,
  },
  allShortCodes: {
    isFetching: isFetchingShortCodes,
    items: shortCodes,
  },
  fileDownload: { pending },
  messages: {
    isFetching,
    items: [success, data, pagination],
  },
}) => ({
  isFetchingAccounts,
  isFetchingKeywords,
  isFetchingShortCodes,
  pagination,
  success,
  accounts: accounts[1] ? accounts[1] : [],
  isDownloadingFile: pending,
  isFetchingMessages: isFetching,
  keywords: keywords[1] ? keywords[1] : [],
  messages: success ? data : [],
  shortCodes: shortCodes[1] ? shortCodes[1] : [],
});

export default connect(
  mapStateToProps,
  {
    change,
    reset,
    ...allAccountsActions,
    ...allKeywordsActions,
    ...allShortCodesActions,
    ...fileDownloadActions,
    ...messagesActions,
    ...navBarActions,
  }
)(AdminReceivedMessages);

import AddFormSuccessModal from '../Modals/AddFormSuccess';
import EditFormSuccessModal from '../Modals/EditFormSuccess';
import ScheduleFormSuccessModal from '../Modals/ScheduleFormSuccess';
import SendFormSuccessModal from '../Modals/SendFormSuccess';
import ArrayFields from './ArrayFields';
import TabFields from './TabFields';
import Field from './Field';

export const FORMS_SUCCESS_MODALS = {
  add: AddFormSuccessModal,
  edit: EditFormSuccessModal,
  send: SendFormSuccessModal,
  schedule: ScheduleFormSuccessModal,
};

export const FORM_FIELD_COMPONENTS = {
  array: ArrayFields,
  defaultField: Field,
  tab: TabFields,
};

import AdminAccounts from '../Tables/Admin/Accounts';
import AdminActivities from '../Tables/Admin/Activities';
import AdminAddAccount from '../Forms/Admin/Accounts/AddAccount';
import AdminAddBanks from '../Forms/Admin/Banks/AddBanks';
import AdminAddBusinessTypes from '../Forms/Admin/BusinessTypes/AddBusinessTypes';
import AdminAddCredits from '../Forms/Admin/Accounts/AddCredits';
import AdminAddIndustries from '../Forms/Admin/Industries/AddIndustries';
import AdminAddKeyword from '../Forms/Admin/Keywords/AddKeyword';
import AdminAddNew from '../Forms/Admin/News/AddNew';
import AdminAddPayment from '../Forms/Admin/Payments/AddPayment';
import AdminAddShortCodes from '../Forms/Admin/ShortCodes/AddShortCodes';
import AdminBanks from '../Tables/Admin/Banks';
import AdminBusinessTypes from '../Tables/Admin/BusinessTypes';
import AdminDashboard from '../Dashboards/Admin';
import AdminEditAccount from '../Forms/Admin/Accounts/EditAccount';
import AdminEditBank from '../Forms/Admin/Banks/EditBank';
import AdminEditBusinessType from '../Forms/Admin/BusinessTypes/EditBusinessType';
import AdminEditIndustry from '../Forms/Admin/Industries/EditIndustry';
import AdminEditIntegrator from '../Forms/Admin/Integrators/EditIntegrator';
import AdminEditKeyword from '../Forms/Admin/Keywords/EditKeyword';
import AdminEditNew from '../Forms/Admin/News/EditNew';
import AdminEditPayment from '../Forms/Admin/Payments/EditPayment';
import AdminEditShortCode from '../Forms/Admin/ShortCodes/EditShortCode';
import AdminIndustries from '../Tables/Admin/Industries';
import AdminIntegrators from '../Tables/Admin/Integrators';
import AdminKeywords from '../Tables/Admin/Keywords';
import AdminNews from '../Tables/Admin/News';
import AdminReceivedMessages from '../Tables/Admin/ReceivedMessages';
import AdminReportsSMS from '../Tables/Admin/ReportsSMS';
import AdminReportsShortCodes from '../Tables/Admin/ReportsShortCodes';
import AdminPayments from '../Tables/Admin/Payments';
import AdminScheduledCampaigns from '../Tables/Admin/ScheduledCampaigns';
import AdminSentCampaigns from '../Tables/Admin/SentCampaigns';
import AdminSentMessages from '../Tables/Admin/SentMessages';
import AdminSentReplies from '../Tables/Admin/SentReplies';
import AdminShortCodes from '../Tables/Admin/ShortCodes';
import AdminShortCodesDashboard from '../Dashboards/AdminShortCodes';
import trans from '../../Translations/translation.service';
import {
  ADMIN_ACCOUNTS_LIST_HEADER_MENU,
  ADMIN_ADD_CREDITS_HEADER_MENU,
  ADMIN_BANKS_LIST_HEADER_MENU,
  ADMIN_KEYWORDS_HEADER_MENU,
  ADMIN_PAYMENTS_LIST_HEADER_MENU,
  ADMIN_RECEIVED_MESSAGES_HEADER_MENU,
  ADMIN_ROUTE_TYPE,
  ADMIN_SHORT_CODES_DASHBOARD_HEADER_MENU,
} from '../../Constants';

export const ADMIN_PRIVATE_PATHS = {
  adminAccounts: {
    headerMenu: ADMIN_ACCOUNTS_LIST_HEADER_MENU,
    path: '/admin-accounts',
    routeType: ADMIN_ROUTE_TYPE,
    selectedOption: 'adminAccounts',
    title: trans('default.titles.admin.accounts'),
  },
  adminActivities: {
    path: '/admin-activities',
    routeType: ADMIN_ROUTE_TYPE,
    selectedOption: 'adminActivities',
    title: trans('default.titles.activities'),
  },
  adminAddAccount: {
    path: '/admin-add-account',
    routeType: ADMIN_ROUTE_TYPE,
    selectedOption: 'adminAccounts',
    title: trans('default.titles.admin.addAccount'),
  },
  adminAddBanks: {
    path: '/admin-add-banks',
    routeType: ADMIN_ROUTE_TYPE,
    selectedOption: 'adminBanks',
    title: trans('default.titles.admin.addBanks'),
  },
  adminAddBusinessTypes: {
    path: '/admin-add-business-types',
    routeType: ADMIN_ROUTE_TYPE,
    selectedOption: 'adminCompanies',
    title: trans('default.titles.admin.addBusinessTypes'),
  },
  adminAddCredits: {
    headerMenu: ADMIN_ADD_CREDITS_HEADER_MENU,
    path: '/admin-add-credits',
    routeType: ADMIN_ROUTE_TYPE,
    selectedOption: 'adminAccounts',
    title: trans('default.titles.admin.addCredits'),
  },
  adminAddIndustries: {
    path: '/admin-add-industries',
    routeType: ADMIN_ROUTE_TYPE,
    selectedOption: 'adminCompanies',
    title: trans('default.titles.admin.addIndustries'),
  },
  adminAddKeyword: {
    path: '/admin-add-keyword',
    routeType: ADMIN_ROUTE_TYPE,
    selectedOption: 'adminShortCodes',
    title: trans('default.titles.admin.addKeyword'),
  },
  adminAddNew: {
    path: '/admin-add-new',
    routeType: ADMIN_ROUTE_TYPE,
    selectedOption: 'adminNews',
    title: trans('default.titles.admin.addNew'),
  },
  adminAddPayment: {
    path: '/admin-add-payment',
    routeType: ADMIN_ROUTE_TYPE,
    selectedOption: 'adminPayments',
    title: trans('default.titles.addPayment'),
  },
  adminAddShortCodes: {
    path: '/admin-add-short-codes',
    routeType: ADMIN_ROUTE_TYPE,
    selectedOption: 'adminShortCodes',
    title: trans('default.titles.admin.addShortCodes'),
  },
  adminBanks: {
    headerMenu: ADMIN_BANKS_LIST_HEADER_MENU,
    path: '/admin-banks',
    routeType: ADMIN_ROUTE_TYPE,
    selectedOption: 'adminBanks',
    title: trans('default.titles.admin.banks'),
  },
  adminBusinessTypes: {
    path: '/admin-business-types',
    routeType: ADMIN_ROUTE_TYPE,
    selectedOption: 'adminCompanies',
    title: trans('default.titles.admin.businessTypes'),
  },
  adminDashboard: {
    path: '/admin-dashboard',
    routeType: ADMIN_ROUTE_TYPE,
    search: true,
    searchPlaceholder: trans('default.filters.searchByNumber'),
    title: trans('default.titles.smsDashboard'),
  },
  adminEditAccount: {
    noParamsPath: '/admin-edit-account',
    path: '/admin-edit-account/:id',
    routeType: ADMIN_ROUTE_TYPE,
    selectedOption: 'adminAccounts',
    title: trans('default.titles.admin.editAccount'),
  },
  adminEditBank: {
    noParamsPath: '/admin-edit-bank',
    path: '/admin-edit-bank/:id',
    routeType: ADMIN_ROUTE_TYPE,
    selectedOption: 'adminBanks',
    title: trans('default.titles.admin.editBank'),
  },
  adminEditBusinessType: {
    noParamsPath: '/admin-edit-business-type',
    path: '/admin-edit-business-type/:id',
    routeType: ADMIN_ROUTE_TYPE,
    selectedOption: 'adminCompanies',
    title: trans('default.titles.admin.editBusinessType'),
  },
  adminEditIndustry: {
    noParamsPath: '/admin-edit-industry',
    path: '/admin-edit-industry/:id',
    routeType: ADMIN_ROUTE_TYPE,
    selectedOption: 'adminCompanies',
    title: trans('default.titles.admin.editIndustry'),
  },
  adminEditIntegrator: {
    noParamsPath: '/admin-edit-integrator',
    path: '/admin-edit-integrator/:id',
    routeType: ADMIN_ROUTE_TYPE,
    selectedOption: 'adminIntegrators',
    title: trans('default.titles.admin.editIntegrator'),
  },
  adminEditKeyword: {
    noParamsPath: '/admin-edit-keyword',
    path: '/admin-edit-keyword/:id',
    routeType: ADMIN_ROUTE_TYPE,
    selectedOption: 'adminShortCodes',
    title: trans('default.titles.editKeyword'),
  },
  adminEditNew: {
    noParamsPath: '/admin-edit-new',
    path: '/admin-edit-new/:id',
    routeType: ADMIN_ROUTE_TYPE,
    selectedOption: 'adminNews',
    title: trans('default.titles.admin.editNew'),
  },
  adminEditPayment: {
    noParamsPath: '/admin-edit-payment',
    path: '/admin-edit-payment/:id',
    routeType: ADMIN_ROUTE_TYPE,
    selectedOption: 'adminPayments',
    title: trans('default.titles.editPayment'),
  },
  adminEditShortCode: {
    noParamsPath: '/admin-edit-short-code',
    path: '/admin-edit-short-code/:id',
    routeType: ADMIN_ROUTE_TYPE,
    selectedOption: 'adminShortCodes',
    title: trans('default.titles.admin.editShortCode'),
  },
  adminIndustries: {
    path: '/admin-industries',
    routeType: ADMIN_ROUTE_TYPE,
    selectedOption: 'adminCompanies',
    title: trans('default.titles.admin.industries'),
  },
  adminIntegrators: {
    path: '/admin-integrators',
    routeType: ADMIN_ROUTE_TYPE,
    selectedOption: 'adminIntegrators',
    title: trans('default.titles.admin.integrators'),
  },
  adminKeywords: {
    headerMenu: ADMIN_KEYWORDS_HEADER_MENU,
    path: '/admin-keywords',
    routeType: ADMIN_ROUTE_TYPE,
    search: true,
    searchPlaceholder: trans('default.filters.searchByWord'),
    selectedOption: 'adminShortCodes',
    title: trans('default.titles.keywords'),
  },
  adminNews: {
    path: '/admin-news',
    routeType: ADMIN_ROUTE_TYPE,
    selectedOption: 'adminNews',
    title: trans('default.titles.admin.news'),
  },
  adminPayments: {
    headerMenu: ADMIN_PAYMENTS_LIST_HEADER_MENU,
    path: '/admin-payments',
    routeType: ADMIN_ROUTE_TYPE,
    selectedOption: 'adminPayments',
    title: trans('default.titles.admin.payments'),
  },
  adminReceivedMessages: {
    headerMenu: ADMIN_RECEIVED_MESSAGES_HEADER_MENU,
    path: '/admin-received-messages',
    routeType: ADMIN_ROUTE_TYPE,
    search: true,
    searchPlaceholder: trans('default.filters.searchByNumber'),
    selectedOption: 'adminShortCodes',
    title: trans('default.titles.receivedMessages'),
  },
  adminReportsSMS: {
    path: '/admin-reports-sms',
    routeType: ADMIN_ROUTE_TYPE,
    selectedOption: 'adminAccounts',
    title: trans('default.titles.admin.reportsSMS'),
  },
  adminReportsShortCodes: {
    path: '/admin-reports-short-codes',
    routeType: ADMIN_ROUTE_TYPE,
    selectedOption: 'adminAccounts',
    title: trans('default.titles.admin.reportsShortCodes'),
  },
  adminScheduledCampaigns: {
    path: '/admin-scheduled-campaigns',
    routeType: ADMIN_ROUTE_TYPE,
    selectedOption: 'adminCampaigns',
    title: trans('default.titles.scheduledCampaigns'),
  },
  adminSentCampaigns: {
    path: '/admin-sent-campaigns',
    routeType: ADMIN_ROUTE_TYPE,
    selectedOption: 'adminCampaigns',
    title: trans('default.titles.sentCampaigns'),
  },
  adminSentMessages: {
    path: '/admin-sent-messages',
    routeType: ADMIN_ROUTE_TYPE,
    search: true,
    searchPlaceholder: trans('default.filters.searchByNumber'),
    selectedOption: 'adminMessages',
    title: trans('default.titles.sentMessages'),
  },
  adminSentReplies: {
    path: '/admin-sent-replies',
    routeType: ADMIN_ROUTE_TYPE,
    search: true,
    searchPlaceholder: trans('default.filters.searchByNumber'),
    selectedOption: 'adminShortCodes',
    title: trans('default.titles.sentReplies'),
  },
  adminShortCodes: {
    path: '/admin-short-codes',
    routeType: ADMIN_ROUTE_TYPE,
    selectedOption: 'adminShortCodes',
    title: trans('default.titles.admin.shortCodes'),
  },
  adminShortCodesDashboard: {
    headerMenu: ADMIN_SHORT_CODES_DASHBOARD_HEADER_MENU,
    path: '/admin-short-codes-dashboard',
    routeType: ADMIN_ROUTE_TYPE,
    search: true,
    searchPlaceholder: trans('default.filters.searchByNumber'),
    selectedOption: 'adminShortCodes',
    title: trans('default.titles.shortCodesDashboard'),
  },
};
export const ADMIN_PRIVATE_PATHS_CONFIG = [
  {
    component: AdminAccounts,
    ...ADMIN_PRIVATE_PATHS.adminAccounts,
  },
  {
    component: AdminActivities,
    ...ADMIN_PRIVATE_PATHS.adminActivities,
  },
  {
    component: AdminAddAccount,
    ...ADMIN_PRIVATE_PATHS.adminAddAccount,
  },
  {
    component: AdminAddBanks,
    ...ADMIN_PRIVATE_PATHS.adminAddBanks,
  },
  {
    component: AdminAddBusinessTypes,
    ...ADMIN_PRIVATE_PATHS.adminAddBusinessTypes,
  },
  {
    component: AdminAddCredits,
    ...ADMIN_PRIVATE_PATHS.adminAddCredits,
  },
  {
    component: AdminAddIndustries,
    ...ADMIN_PRIVATE_PATHS.adminAddIndustries,
  },
  {
    component: AdminAddKeyword,
    ...ADMIN_PRIVATE_PATHS.adminAddKeyword,
  },
  {
    component: AdminAddNew,
    ...ADMIN_PRIVATE_PATHS.adminAddNew,
  },
  {
    component: AdminAddPayment,
    ...ADMIN_PRIVATE_PATHS.adminAddPayment,
  },
  {
    component: AdminAddShortCodes,
    ...ADMIN_PRIVATE_PATHS.adminAddShortCodes,
  },
  {
    component: AdminBanks,
    ...ADMIN_PRIVATE_PATHS.adminBanks,
  },
  {
    component: AdminBusinessTypes,
    ...ADMIN_PRIVATE_PATHS.adminBusinessTypes,
  },
  {
    component: AdminDashboard,
    ...ADMIN_PRIVATE_PATHS.adminDashboard,
  },
  {
    component: AdminEditAccount,
    ...ADMIN_PRIVATE_PATHS.adminEditAccount,
  },
  {
    component: AdminEditBank,
    ...ADMIN_PRIVATE_PATHS.adminEditBank,
  },
  {
    component: AdminEditBusinessType,
    ...ADMIN_PRIVATE_PATHS.adminEditBusinessType,
  },
  {
    component: AdminEditIndustry,
    ...ADMIN_PRIVATE_PATHS.adminEditIndustry,
  },
  {
    component: AdminEditIntegrator,
    ...ADMIN_PRIVATE_PATHS.adminEditIntegrator,
  },
  {
    component: AdminEditKeyword,
    ...ADMIN_PRIVATE_PATHS.adminEditKeyword,
  },
  {
    component: AdminEditNew,
    ...ADMIN_PRIVATE_PATHS.adminEditNew,
  },
  {
    component: AdminEditPayment,
    ...ADMIN_PRIVATE_PATHS.adminEditPayment,
  },
  {
    component: AdminEditShortCode,
    ...ADMIN_PRIVATE_PATHS.adminEditShortCode,
  },
  {
    component: AdminIndustries,
    ...ADMIN_PRIVATE_PATHS.adminIndustries,
  },
  {
    component: AdminIntegrators,
    ...ADMIN_PRIVATE_PATHS.adminIntegrators,
  },
  {
    component: AdminKeywords,
    ...ADMIN_PRIVATE_PATHS.adminKeywords,
  },
  {
    component: AdminNews,
    ...ADMIN_PRIVATE_PATHS.adminNews,
  },
  {
    component: AdminPayments,
    ...ADMIN_PRIVATE_PATHS.adminPayments,
  },
  {
    component: AdminReceivedMessages,
    ...ADMIN_PRIVATE_PATHS.adminReceivedMessages,
  },
  {
    component: AdminReportsSMS,
    ...ADMIN_PRIVATE_PATHS.adminReportsSMS,
  },
  {
    component: AdminReportsShortCodes,
    ...ADMIN_PRIVATE_PATHS.adminReportsShortCodes,
  },
  {
    component: AdminScheduledCampaigns,
    ...ADMIN_PRIVATE_PATHS.adminScheduledCampaigns,
  },
  {
    component: AdminSentCampaigns,
    ...ADMIN_PRIVATE_PATHS.adminSentCampaigns,
  },
  {
    component: AdminSentMessages,
    ...ADMIN_PRIVATE_PATHS.adminSentMessages,
  },
  {
    component: AdminSentReplies,
    ...ADMIN_PRIVATE_PATHS.adminSentReplies,
  },
  {
    component: AdminShortCodes,
    ...ADMIN_PRIVATE_PATHS.adminShortCodes,
  },
  {
    component: AdminShortCodesDashboard,
    ...ADMIN_PRIVATE_PATHS.adminShortCodesDashboard,
  },
];

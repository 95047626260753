import styled from 'styled-components';
import { COLORS } from '../../../../../Constants';

export default styled.div`
  background-color: ${COLORS.mediumBlue};
  color: white;
  height: 64px;
  margin-top: ${({ hasNews }) => hasNews ? 30 : 0}px;
  padding: 0 15px;
`;

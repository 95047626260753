/** Login component messages (ES)  */
export default {
  form: {
    actions: {
      signIn: 'Iniciar Sesión',
    },
    fields: {
      username: 'Nombre de Usuario',
      password: 'Contraseña',
    },
    title: 'Ingresa para iniciar tu sesión',
  },
};

import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Hidden from '@material-ui/core/Hidden';
import ExpansionPanel from '../Layouts/Default/Navbar/Styled/ExpansionPanel';
import ExpansionPanelDetails from '../Layouts/Default/Navbar/Styled/ExpansionPanelDetails';
import ExpansionPanelSummary from './Styled/ExpansionPanelSummary';
import FiltersForm from './FiltersForm';
import Option from '../Layouts/Default/Navbar/Styled/Option';
import OptionTitle from '../Layouts/Default/Navbar/Styled/OptionTitle';
import Wrapper from './Styled/StyledWrapper';
import trans from '../../Translations/translation.service';

class Filters extends React.Component {
  state = {
    expanded: false,
  };

  handleChange = (_, expanded) => {
    this.setState({ expanded });
  };

  render() {
    const { expanded } = this.state;
    const { filtersProps } = this.props;

    return (
      <Wrapper>
        <Hidden smDown>
          <FiltersForm className="filters-form" filtersProps={filtersProps} />
        </Hidden>
        <Hidden mdUp>
          <ExpansionPanel
            expanded={expanded}
            onChange={this.handleChange}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Option
                className="navbar-option"
              >
                <OptionTitle className="navbar-option-title">
                  {trans('default.filters.filters')}
                </OptionTitle>
              </Option>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className="expansion-panel-details">
              <FiltersForm className="filters-form" filtersProps={filtersProps} />
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Hidden>
      </Wrapper>
    );
  }
}

export default Filters;

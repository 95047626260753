import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import { reducers as auth } from '../Ducks/Auth';
import { reducers as fileDownload } from '../Ducks/FileDownload';
import { reducers as fileUpload } from '../Ducks/FileUpload';
import { reducers as navbar } from '../Ducks/Navbar';
import { reducers as reduxRestResponse } from '../Ducks/ReduxRestResponse';
import { reducers as windowResize } from '../Ducks/WindowResize';
// API Reducers
import { reducers as accounts } from '../Api/Accounts';
import { reducers as activities } from '../Api/Activities';
import { reducers as allAccounts } from '../Api/AllAccounts';
import { reducers as allBanks } from '../Api/AllBanks';
import { reducers as allBusinessTypes } from '../Api/AllBusinessTypes';
import { reducers as allCampaigns } from '../Api/AllCampaigns';
import { reducers as allChannels } from '../Api/AllChannels';
import { reducers as allContacts } from '../Api/AllContacts';
import { reducers as allGroups } from '../Api/AllGroups';
import { reducers as allIndustries } from '../Api/AllIndustries';
import { reducers as allIntegrators } from '../Api/AllIntegrators';
import { reducers as allKeywords } from '../Api/AllKeywords';
import { reducers as allNews } from '../Api/AllNews';
import { reducers as allPermissions } from '../Api/AllPermissions';
import { reducers as allServices } from '../Api/AllServices';
import { reducers as allShortCodes } from '../Api/AllShortCodes';
import { reducers as allUsers } from '../Api/AllUsers';
import { reducers as banks } from '../Api/Banks';
import { reducers as businessTypes } from '../Api/BusinessTypes';
import { reducers as campaigns } from '../Api/Campaigns';
import { reducers as campaignMessages } from '../Api/CampaignMessages';
import { reducers as channels } from '../Api/Channels';
import { reducers as contacts } from '../Api/Contacts';
import { reducers as groups } from '../Api/Groups';
import { reducers as industries } from '../Api/Industries';
import { reducers as integrators } from '../Api/Integrators';
import { reducers as keywords } from '../Api/Keywords';
import { reducers as messages } from '../Api/Messages';
import { reducers as messagesStats } from '../Api/MessagesStats';
import { reducers as messagesShortCodeStats } from '../Api/MessagesShortCodeStats';
import { reducers as news } from '../Api/News';
import { reducers as payments } from '../Api/Payments';
import { reducers as permissions } from '../Api/Permissions';
import { reducers as schedules } from '../Api/Schedules';
import { reducers as services } from '../Api/Services';
import { reducers as shortCodes } from '../Api/ShortCodes';
import { reducers as subscriptions } from '../Api/Subscriptions';
import { reducers as users } from '../Api/Users';

const rootReducer = combineReducers({
  auth,
  fileDownload,
  fileUpload,
  form,
  navbar,
  reduxRestResponse,
  windowResize,
  // API Reducers
  accounts,
  activities,
  allAccounts,
  allBanks,
  allBusinessTypes,
  allCampaigns,
  allChannels,
  allContacts,
  allGroups,
  allIndustries,
  allIntegrators,
  allKeywords,
  allNews,
  allPermissions,
  allServices,
  allShortCodes,
  allUsers,
  banks,
  businessTypes,
  campaigns,
  campaignMessages,
  channels,
  contacts,
  groups,
  industries,
  integrators,
  keywords,
  messages,
  messagesStats,
  messagesShortCodeStats,
  news,
  payments,
  permissions,
  schedules,
  services,
  shortCodes,
  subscriptions,
  users,
});

export default rootReducer;

import Close from '@material-ui/icons/Close';
import trans from '../../../../Translations/translation.service';

export const ADD_ACTIONS = [
  {
    color: 'secondary',
    handler: 'backToList',
    label: trans('modals.actions.backToList'),
    type: 'button',
  },
  {
    color: 'primary',
    label: trans('forms.actions.save'),
    type: 'submit',
  },
];
export const ADD_MODAL_ACTIONS = [
  {
    color: 'secondary',
    label: trans('modals.actions.cancel'),
    type: 'button',
  },
  {
    color: 'primary',
    label: trans('forms.actions.save'),
    type: 'submit',
  },
];
export const EDIT_ACTIONS = [
  {
    color: 'secondary',
    handler: 'backToList',
    label: trans('modals.actions.backToList'),
    type: 'button',
  },
  {
    color: 'primary',
    label: trans('forms.actions.save'),
    type: 'submit',
  },
];

export const DEFAULT_ADD_VALUES = {
  groups: [],
};
export const DEFAULT_VALUES = {
  name: '',
};

export const INPUTS = [
  {
    fieldType: 'array',
    name: 'groups',
    inputs: [
      {
        clickableIcon: true,
        id: 'name',
        icon: Close,
        iconAlign: 'right',
        inputProps: {
          maxLength: 255,
        },
        label: trans('forms.fields.client.groups.name'),
        name: 'name',
        remove: true,
        required: true,
        sizes: {
          xs: 12,
        },
      },
    ],
    size: {
      xs: 12,
      sm: 3,
    },
  },
];

export const ADD_INPUTS = [
  {
    id: 'name',
    inputProps: {
      maxLength: 255,
    },
    label: trans('forms.fields.client.groups.name'),
    name: 'name',
    required: true,
    sizes: {
      xs: 12,
    },
  },
];

export const EDIT_INPUTS = [
  {
    id: 'name',
    inputProps: {
      maxLength: 255,
    },
    label: trans('forms.fields.client.groups.name'),
    name: 'name',
    required: true,
    sizes: {
      xs: 12,
      sm: 6,
      md: 4,
    },
  },
];

export const GROUP_FORM = 'group';
export const GROUPS_FORM = 'groups';

export const GROUP_VALIDATIONS = [
  {
    field: 'name',
    message: ['default.forms.validations.isRequired'],
    method: 'isEmpty',
    validWhen: false,
  },
];

import styled from 'styled-components';
import { COLORS } from '../../../Constants';

export default styled.h1`
  color: ${COLORS.grey5};
  font-size: 16px;
  font-weight: normal;
  margin: 0 0 15px;
  text-align: center;
`;

import trans from '../../Translations/translation.service';
import { ALL_SELECT_OPTION_F, API_DATE_FORMAT } from '../../Constants';

export const CARRIERS_SELECT_OPTIONS = [
    ALL_SELECT_OPTION_F,
    {
        text: trans('default.carriers.movistar'),
        value: 0,
    },
    {
        text: trans('default.carriers.digitel'),
        value: 1,
    },
    {
        text: trans('default.carriers.movilnet'),
        value: 2,
    },
];

export const ADMIN_SMS_STATS_FILTERS_INPUTS = [
    {
      id: 'from',
      inputFormat: API_DATE_FORMAT,
      InputLabelProps: {
        shrink: true,
      },
      label: trans('forms.fields.admin.dashboardFilters.from'),
      name: 'from_date',
      sizes: {
        xs: 12,
        sm: 6,
        md: 2,
      },
      type: 'datepicker',
    },
    {
      id: 'to',
      inputFormat: API_DATE_FORMAT,
      InputLabelProps: {
        shrink: true,
      },
      label: trans('forms.fields.admin.dashboardFilters.to'),
      name: 'to_date',
      sizes: {
        xs: 12,
        sm: 6,
        md: 2,
      },
      type: 'datepicker',
    },
    {
      id: 'account',
      label: trans('forms.fields.admin.dashboardFilters.account'),
      name: 'account_id',
      sizes: {
        xs: 12,
        sm: 6,
        md: 2,
      },
      type: 'select',
    },
    {
      id: 'campaign',
      label: trans('forms.fields.admin.dashboardFilters.campaign'),
      name: 'campaign_id',
      sizes: {
        xs: 12,
        sm: 6,
        md: 2,
      },
      type: 'select',
    },
    {
      data: CARRIERS_SELECT_OPTIONS,
      id: 'carrier',
      label: trans('forms.fields.admin.dashboardFilters.carrier'),
      name: 'carrier',
      sizes: {
        xs: 12,
        sm: 6,
        md: 1,
      },
      type: 'select',
    },
    {
      id: 'integrator',
      label: trans('forms.fields.admin.dashboardFilters.integrator'),
      name: 'integrator_id',
      sizes: {
        xs: 12,
        sm: 6,
        md: 2,
      },
      type: 'select',
    },
    {
      id: 'api',
      name: 'api',
      label: trans('forms.fields.admin.dashboardFilters.api'),
      sizes: {
        xs: 12,
        sm: 6,
        md: 1,
      },
      type: 'switch',
    },
];

export const ADMIN_SHORT_CODES_STATS_FILTERS_INPUTS = [
    {
      id: 'from',
      inputFormat: API_DATE_FORMAT,
      InputLabelProps: {
        shrink: true,
      },
      label: trans('forms.fields.admin.dashboardFilters.from'),
      name: 'from_date',
      sizes: {
        xs: 12,
        sm: 6,
        md: 2,
      },
      type: 'datepicker',
    },
    {
      id: 'to',
      inputFormat: API_DATE_FORMAT,
      InputLabelProps: {
        shrink: true,
      },
      label: trans('forms.fields.admin.dashboardFilters.to'),
      name: 'to_date',
      sizes: {
        xs: 12,
        sm: 6,
        md: 2,
      },
      type: 'datepicker',
    },
    {
      id: 'account',
      label: trans('forms.fields.admin.dashboardFilters.account'),
      name: 'account_id',
      sizes: {
        xs: 12,
        sm: 6,
        md: 2,
      },
      type: 'select',
    },
    {
      id: 'short-code',
      label: trans('forms.fields.admin.dashboardFilters.shortCode'),
      name: 'short_code_id',
      sizes: {
        xs: 12,
        sm: 6,
        md: 2,
      },
      type: 'select',
    },
    {
      id: 'keyword',
      label: trans('forms.fields.admin.dashboardFilters.keyword'),
      name: 'keyword_id',
      sizes: {
        xs: 12,
        sm: 6,
        md: 2,
      },
      type: 'select',
    },
    {
      data: CARRIERS_SELECT_OPTIONS,
      id: 'carrier',
      label: trans('forms.fields.admin.dashboardFilters.carrier'),
      name: 'carrier',
      sizes: {
        xs: 12,
        sm: 6,
        md: 2,
      },
      type: 'select',
    },
];

export const CLIENT_SMS_STATS_FILTERS_INPUTS = [
    {
      id: 'from',
      inputFormat: API_DATE_FORMAT,
      InputLabelProps: {
        shrink: true,
      },
      label: trans('forms.fields.admin.dashboardFilters.from'),
      name: 'from_date',
      sizes: {
        xs: 12,
        sm: 6,
        md: 2,
      },
      type: 'datepicker',
    },
    {
      id: 'to',
      inputFormat: API_DATE_FORMAT,
      InputLabelProps: {
        shrink: true,
      },
      label: trans('forms.fields.admin.dashboardFilters.to'),
      name: 'to_date',
      sizes: {
        xs: 12,
        sm: 6,
        md: 2,
      },
      type: 'datepicker',
    },
    {
      id: 'campaign',
      label: trans('forms.fields.admin.dashboardFilters.campaign'),
      name: 'campaign_id',
      sizes: {
        xs: 12,
        sm: 6,
        md: 2,
      },
      type: 'select',
    },
    {
      data: CARRIERS_SELECT_OPTIONS,
      id: 'carrier',
      label: trans('forms.fields.admin.dashboardFilters.carrier'),
      name: 'carrier',
      sizes: {
        xs: 12,
        sm: 6,
        md: 2,
      },
      type: 'select',
    },
    {
      id: 'integrator',
      label: trans('forms.fields.admin.dashboardFilters.integrator'),
      name: 'integrator_id',
      sizes: {
        xs: 12,
        sm: 6,
        md: 2,
      },
      type: 'select',
    },
    {
      id: 'api',
      name: 'api',
      label: trans('forms.fields.admin.dashboardFilters.api'),
      sizes: {
        xs: 12,
        sm: 6,
        md: 2,
      },
      type: 'switch',
    },
];

export const CLIENT_SHORT_CODES_STATS_FILTERS_INPUTS = [
    {
      id: 'from',
      inputFormat: API_DATE_FORMAT,
      InputLabelProps: {
        shrink: true,
      },
      label: trans('forms.fields.admin.dashboardFilters.from'),
      name: 'from_date',
      sizes: {
        xs: 12,
        sm: 6,
        md: 2,
      },
      type: 'datepicker',
    },
    {
      id: 'to',
      inputFormat: API_DATE_FORMAT,
      InputLabelProps: {
        shrink: true,
      },
      label: trans('forms.fields.admin.dashboardFilters.to'),
      name: 'to_date',
      sizes: {
        xs: 12,
        sm: 6,
        md: 2,
      },
      type: 'datepicker',
    },
    {
      id: 'short-code',
      label: trans('forms.fields.admin.dashboardFilters.shortCode'),
      name: 'short_code_id',
      sizes: {
        xs: 12,
        sm: 6,
        md: 2,
      },
      type: 'select',
    },
    {
      id: 'keyword',
      label: trans('forms.fields.admin.dashboardFilters.keyword'),
      name: 'keyword_id',
      sizes: {
        xs: 12,
        sm: 6,
        md: 2,
      },
      type: 'select',
    },
    {
      data: CARRIERS_SELECT_OPTIONS,
      id: 'carrier',
      label: trans('forms.fields.admin.dashboardFilters.carrier'),
      name: 'carrier',
      sizes: {
        xs: 12,
        sm: 6,
        md: 2,
      },
      type: 'select',
    },
];

import styled from 'styled-components';

export default styled.div`
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  height: 64px;
  line-height: 64px;
  max-width: calc(100% - 170px);
  overflow: hidden;
  vertical-align: middle;
`;

import trans from '../Translations/translation.service';

export const ACCOUNT_TYPES = {
  corporate: 2,
  demo: 0,
  individual: 1,
};

export const ACCOUNTS_STATUSES = [
  'active',
  'inactive',
  'suspended',
];

export const ACCOUNTS_TYPES_LIST = [
  {
    value: 0,
    text: trans('types.accounts.demo'),
  },
  {
    value: 1,
    text: trans('types.accounts.corporate'),
  },
  {
    value: 2,
    text: trans('types.accounts.individual'),
  },
];
export const ACCOUNTS_STATUSES_LIST = [
  {
    value: 0,
    text: trans('types.statuses.accounts.active'),
  },
  {
    value: 1,
    text: trans('types.statuses.accounts.inactive'),
  },
  {
    value: 2,
    text: trans('types.statuses.accounts.suspended'),
  },
];
export const GENDERS_LIST = [
  {
    value: 0,
    text: trans('types.genders.female'),
  },
  {
    value: 1,
    text: trans('types.genders.male'),
  },
];
export const USERS_STATUSES_LIST = [
  {
    value: 0,
    text: trans('types.statuses.users.active'),
  },
  {
    value: 1,
    text: trans('types.statuses.users.inactive'),
  },
];

export const ACTIVITIES_TYPES_ARRAY = [
  'campaignSent',
  'apiMessageSent',
  'testMessageSent',
  'campaignScheduled',
  'campaignDeleted',
  'creditsAdded',
  'logIn',
  'logOut',
  'scheduledCampaignDeleted',
  'userCreated',
  'userDeleted',
  'accountCreated',
  'accountDeleted',
];

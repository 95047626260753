import trans from '../../../../Translations/translation.service';
import { API_DATE_FORMAT } from '../../../../Constants';

export const ADD_ACTIONS = [
  {
    color: 'secondary',
    handler: 'backToList',
    label: trans('modals.actions.backToList'),
    type: 'button',
  },
  {
    color: 'primary',
    label: trans('forms.actions.save'),
    type: 'submit',
  },
];
export const EDIT_ACTIONS = [
  {
    color: 'secondary',
    handler: 'backToList',
    label: trans('modals.actions.backToList'),
    type: 'button',
  },
  {
    color: 'primary',
    label: trans('forms.actions.save'),
    type: 'submit',
  },
];

export const DEFAULT_VALUES = {
  title: '',
  from_date: null,
  to_date: null,
  content: '',
};

export const INPUTS = [
  {
    id: 'title',
    inputProps: {
      maxLength: 255,
    },
    label: trans('forms.fields.admin.news.title'),
    name: 'title',
    required: true,
    sizes: {
      xs: 12,
      sm: 6,
      md: 4,
    },
  },
  {
    id: 'from',
    inputFormat: API_DATE_FORMAT,
    label: trans('forms.fields.admin.news.from'),
    name: 'from_date',
    sizes: {
      xs: 12,
      sm: 6,
      md: 4,
    },
    type: 'datepicker',
  },
  {
    id: 'to',
    inputFormat: API_DATE_FORMAT,
    label: trans('forms.fields.admin.news.to'),
    name: 'to_date',
    sizes: {
      xs: 12,
      sm: 6,
      md: 4,
    },
    type: 'datepicker',
  },
  {
    id: 'content',
    label: trans('forms.fields.admin.news.content'),
    multiline: true,
    name: 'content',
    required: true,
    rows: 4,
    sizes: {
      xs: 12,
    },
  },
];

export const NEW_FORM = 'new';

export const NEW_VALIDATIONS = [
  {
    field: 'title',
    message: ['default.forms.validations.isRequired'],
    method: 'isEmpty',
    validWhen: false,
  },
  {
    args: ['to_date'],
    field: 'from_date',
    message: [
      'default.forms.validations.dateIsBefore',
      {
        field: trans('forms.fields.admin.news.to'),
      },
    ],
    method: 'dateIsBefore',
    validWhen: true,
  },
  {
    field: 'content',
    message: ['default.forms.validations.isRequired'],
    method: 'isEmpty',
    validWhen: false,
  },
];

export const NEW_PARSE_CONFIG = [
  {
    field: 'from_date',
    parser: 'date',
    params: [API_DATE_FORMAT],
  },
  {
    field: 'to_date',
    parser: 'date',
    params: [API_DATE_FORMAT],
  },
];

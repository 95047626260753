import React from 'react';
import { connect } from 'react-redux';
import EditPaymentForm from '../../../../Components/Forms/Client/Payments/EditPayment';
import ModalsWrappedForm from '../../../../Components/Forms/ModalsWrappedForm';
import trans from '../../../../Translations/translation.service';
import { actions as allBanksActions } from '../../../../Redux/Api/AllBanks';
import { actions as paymentsActions, types } from '../../../../Redux/Api/Payments';
import { actions as fileUploadActions } from '../../../../Redux/Ducks/FileUpload';
import { PRIVATE_PATHS } from '../../../config';
import { PAYMENTS_URL, MALE_CODE, JSON_HEADERS as headers } from '../../../../Constants';
import {
  EDIT_ACTIONS,
  PAYMENT_PARSE_CONFIG,
} from '../../../../Components/Forms/Client/Payments/config';
import { catchApiItemErrors, parseDataForApi } from '../../../../Helpers';

class EditPayment extends React.Component {
  getPayment = () => {
    const { id } = this.props.match.params;

    this.props.getPayment(id);
  };

  loading = () => {
    const {
      isFetchingPayment,
      isFetchingBanks,
      isUpdatingPayment,
      isUploadingFile,
    } = this.props;
    return isFetchingPayment
      || isFetchingBanks
      || isUpdatingPayment
      || isUploadingFile;
  };

  openSuccessModal = () => {
    const { fileUploadSuccess, success } = this.props;

    return success || fileUploadSuccess;
  };

  parseBanks = () => (
    this.props.banks.map(({ id: value, name: text }) => ({
      text, value,
    }))
  );

  updatePayment = ({ transaction_src_file, contacts, ...data }) => {
    const { id } = data;

    return this.props.updatePayment(
        parseDataForApi(data, PAYMENT_PARSE_CONFIG)
      )
      .then(async res => {
        if (transaction_src_file) {
          await this.uploadContactsFile(id, transaction_src_file);
        }
      })
      .catch(
        catchApiItemErrors,
      );
  };

  uploadContactsFile = (id, transaction_src_file) => (
    this.props.uploadFile(
      `${PAYMENTS_URL}/${id}`,
      {
        transaction_src_file,
        transaction_src_file_name: transaction_src_file.name,
      },
      {
        headers,
      },
    )
  );

  componentDidMount() {
    this.getPayment();
    this.props.fetchAllBanks();
    this.props.uploadFileReset();
  }

  render() {
    const { payment, history } = this.props;
    const banks = this.parseBanks();
    const subject = trans('resources.singular.payment');

    return (
      <ModalsWrappedForm
        gender={MALE_CODE}
        history={history}
        loading={this.loading()}
        open={this.openSuccessModal()}
        path={PRIVATE_PATHS.clientPayments.path}
        subject={subject}
        successMessage="successOpSingM"
        type="edit"
      >
        {
          payment &&
          <EditPaymentForm
            actions={EDIT_ACTIONS}
            data={{
              bank_id: banks,
            }}
            history={history}
            onSubmit={this.updatePayment}
            path={PRIVATE_PATHS.clientPayments.path}
          />
        }
      </ModalsWrappedForm>
    );
  }
}

const mapStateToProps = ({
  allBanks: {
    items,
    isFetching: isFetchingBanks,
  },
  payments: { isFetchingItem, isUpdating, item },
  fileUpload: { pending, response, success: fileUploadSuccess },
  reduxRestResponse: { body, success, type },
  ...state
}) => ({
  body,
  isFetchingBanks,
  response,
  fileUploadSuccess,
  payment: item,
  banks: items[1] ? items[1] : [],
  isFetchingPayment: isFetchingItem,
  isUpdatingPayment: isUpdating,
  isUploadingFile: pending,
  success: type === types.UPDATE_PAYMENT && success,
});
export default connect(
  mapStateToProps,
  {
    ...allBanksActions,
    ...paymentsActions,
    ...fileUploadActions,
  }
)(EditPayment);

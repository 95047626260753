/** Global messages (ES)  */
export default {
  actions: {
    addedM: 'agregado',
    addedF: 'agregada',
    editedM: 'editado',
    editedF: 'editada',
    deletedM: 'eliminado',
    deletedF: 'eliminada',
    scheduledM: 'programado',
    scheduledF: 'programada',
    sentM: 'enviado',
    sentF: 'enviada',
  },
  activities: {
    description: {
      campaign: 'Campaña',
      companyName: 'Nombre',
      contacts: 'Contactos',
      credits: 'Créditos',
      integrator: 'Integradora',
      ip: 'Dirección IP',
      keyword: 'Palabra Clave',
      number: 'Número',
      sms: 'SMS Usado(s)',
      scheduledFor: 'Programada para',
      username: 'Nombre de Usuario',
    },
    types: {
      campaignScheduled: 'Campaña Programada',
      campaignSent: 'Campaña Enviada',
      apiMessageSent: 'Mensaje de API Enviado',
      testMessageSent: 'Mesaje de Prueba Enviado',
      campaignDeleted: 'Campaña Eiminada',
      creditsAdded: 'Créditos Agregados',
      logIn: 'Inicio de Sesión',
      logOut: 'Cierre de Sesión',
      scheduledCampaignDeleted: 'Campaña Programada Eliminada',
      userCreated: 'Usuario Creado',
      userDeleted: 'Usuario Eliminado',
      accountCreated: 'Cuenta Creada',
      accountDeleted: 'Cuenta Eliminada',
    },
  },
  filters: {
    filters: 'Filtros',
    search: 'Buscar',
    searchByNumber: 'Buscar por número',
    searchByWord: 'Buscar por palabra',
    allF: 'Todas',
    allM: 'Todos',
  },
  forms: {
    labels: {
      browse: 'Buscar',
    },
    messages: {
      fileAdded: 'El archivo {{ fileName }} fue agregado exitosamente.',
      fileRejected: 'El archivo {{ fileName }} fue rechazado. ',
      fileRemoved: 'El archivo {{ fileName }} fue eliminado.',
    },
    validations: {
      dateIsBefore: 'Debe ser una fecha antes de "{{ field }}"',
      fileSizeTooBig: 'El archivo es muy grande. El límite de tamaño es ',
      fileTypeNotSupported: 'Tipo de archivo no permitido. ',
      isCellPhoneNumber: 'Debe ser un número de celular válido',
      isEmail: 'Debe ser un email válido',
      isHigherThan: 'Debe ser mayor a {{ min }}',
      isRequired: 'Este campo no puede estar vacío',
      isUrl: 'Debe ser una URL válida',
      matchesField: 'Este campo debe ser igual al campo "{{ field }}"',
      maxFilesLength: 'Máximo número de archivos exceido. Sólo se permite(n) {{ filesLimit }}',
      notEnoughCredits: 'No tienes suficientes créditos. Selecciona otro integrador',
    },
  },
  loading : {
    dontRefresh: "No refresque la aplicación",
    pleaseWait: 'Por favor espere! Esto puede tardar.',
  },
  messageTypes: {
    api: 'API',
    test: 'Prueba',
  },
  months: {
    january: 'Enero',
    february: 'Febrero',
    march: 'Marzo',
    april: 'Abril',
    may: 'Mayo',
    june: 'Junio',
    july: 'Julio',
    august: 'Agosto',
    september: 'Septiembre',
    october: 'Octubre',
    november: 'Noviembre',
    december: 'Diciembre',
  },
  newCampaign: 'Campaña Nueva',
  newGroup: 'Grupo Nueva',
  paymentTypes: {
    sameBankTransfer: 'Transferencia Del Mismo Banco',
    otherBankTransfer: 'Transferencia De Otro Banco',
    cash: 'Efectivo',
    deposit: 'Depósito',
  },
  titles: {
    activities: 'Actividades',
    addPayment: 'Agregar Pago',
    addUser: 'Agregar Usuario',
    editKeyword: 'Editar Palabra Clave',
    editPayment: 'Editar Pago',
    editUser: 'Editar Usuario',
    keywords: 'Palabras Clave',
    lastReceivedMessages: 'Últimos Mensajes Recibidos',
    lastSentMessages: 'Últimos Mensajes Enviados',
    receivedMessages: 'Mensajes Recibidos',
    scheduledCampaigns: 'Campañas Programadas',
    sentCampaigns: 'Campañas Enviadas',
    sentMessages: 'Mensajes Enviados',
    sentReplies: 'Respuestas Enviadas',
    shortCodesDashboard: 'Dashboard - Números Cortos',
    smsDashboard: 'Dashboard - SMS',
    users: 'Usuarios',
    admin: {
      accounts: 'Cuentas',
      addAccount: 'Agregar Cuenta',
      addBanks: 'Agregar Bancos',
      addBusinessTypes: 'Agregar Tipos de Negocio',
      addCredits: 'Agregar Créditos',
      addIndustries: 'Agregar Industria',
      addKeyword: 'Agregar Palabra Clave',
      addNew: 'Agregar Noticia',
      addShortCodes: 'Agregar Números Cortos',
      banks: 'Bancos',
      businessTypes: 'Tipos de Negocio',
      editAccount: 'Editar Cuenta',
      editBank: 'Editar Banco',
      editBusinessType: 'Editar Tipo de Negocio',
      editIndustry: 'Editar Industria',
      editIntegrator: 'Editar Integradora',
      editNew: 'Editar Noticia',
      editShortCode: 'Editar Número Corto',
      industries: 'Industrias',
      integrators: 'Integradoras',
      news: 'Noticias',
      payments: 'Pagos',
      reportsSMS: 'Reportes SMS',
      reportsShortCodes: 'Reportes Números Cortos',
      shortCodes: 'Números Cortos',
    },
    client: {
      addCampaign: 'Agregar Campaña',
      addGroups: 'Agregar Grupos',
      campaigns: 'Campañas',
      contacts: 'Contactos',
      editCampaign: 'Editar Campaña',
      editGroup: 'Editar Grupo',
      groups: 'Grupos',
      myAccount: 'Mi Cuenta',
      myPayments: 'Mis Pagos',
      newMessage: 'Mensaje Nuevo',
    },
  },
  carriers: {
    movistar: 'Movistar',
    digitel: 'Digitel',
    movilnet: 'Movilnet',
  },
  langs: {
    en: 'Inglés',
    es: 'Español',
  },
  logout: 'Cerrar Sesión',
  stats: {
    messages: {
      available_credits: 'Créditos Disponibles',
      received_messages: 'Mensajes Recibidos',
      scheduled_campaigns: 'Campañas Programadas',
      sent_messages: 'Mensajes Enviados',
      sent_replies: 'Respuestas Enviadas',
    },
    contacts: {
      accepted: 'Contactos Aceptados',
      rejected: 'Contactos Rechazados',
    },
  },
  statuses: {
    active: 'Activo',
    inactive: 'Inactivo',
  },
};

import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import ResponsiveForm from '../../Form';
import { validate } from '../../../../Helpers';
import {
  DEFAULT_VALUES,
  INPUTS,
  INDUSTRIES_FORM as form,
  INDUSTRY_VALIDATIONS,
} from './config';

let AddIndustriesForm = ({ handleSubmit, loading = false, ...props }) => (
  <ResponsiveForm
    {...props}
    inputs={INPUTS}
    loading={loading}
    onSubmit={handleSubmit}
  />
);

AddIndustriesForm = reduxForm({
  form,
  validate: validate(INDUSTRY_VALIDATIONS),
})(AddIndustriesForm);

const mapStateToProps = () => ({
  initialValues: { ...DEFAULT_VALUES },
});

export default connect(
  mapStateToProps
)(AddIndustriesForm);

import React from 'react';
import { withStyles } from '@material-ui/styles';
import SearchIcon from '@material-ui/icons/Search';
import Input from './Styled/StyledSearchBarInput';
import StyledComponentsSearchBar from './Styled/StyledSearchBar';
import Button from './Styled/StyledSearchButton';
import InputContainer from './Styled/StyledSearchInputContainer';
import trans from '../../Translations/translation.service';

class SearchBar extends React.Component {
  state = {
    searchChanged: false,
    searchTerm: '',
  };

  handleInputChange = (ev)  => {
    const { value: searchTerm } = ev.target;

    this.setState({ searchTerm, searchChanged: true });
  };

  handleSearch = () => {
    const { searchChanged, searchTerm } = this.state;
    const { onSearch } = this.props;

    if (searchChanged) {
      this.setState({ searchChanged: false }, () => {
        onSearch(searchTerm);
      });
    }
  };

  render() {
    const { searchTerm } = this.state;
    const { classes, placeholder } = this.props;

    return (
      <StyledComponentsSearchBar className={classes.root}>
        <InputContainer>
          <Input
            fullWidth
            placeholder={placeholder || trans('default.filters.search')}
            onChange={this.handleInputChange}
            value={searchTerm}
          />
        </InputContainer>
        <Button onClick={this.handleSearch}>
          <SearchIcon />
        </Button>
      </StyledComponentsSearchBar>
    );
  }
}

const styles = theme => ({
  root: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
});

const StyledSearchBar = withStyles(styles)(SearchBar);

export default StyledSearchBar;

import React from 'react';
import { connect } from 'react-redux';
import EditShortCodeForm from '../../../../Components/Forms/Admin/ShortCodes/EditShortCode';
import ModalsWrappedForm from '../../../../Components/Forms/ModalsWrappedForm';
import trans from '../../../../Translations/translation.service';
import { actions as shortCodesActions, types } from '../../../../Redux/Api/ShortCodes';
import { PRIVATE_PATHS } from '../../../config';
import { MALE_CODE } from '../../../../Constants';
import { EDIT_ACTIONS } from '../../../../Components/Forms/Admin/ShortCodes/config';
import { catchApiItemErrors } from '../../../../Helpers';

class EditShortCode extends React.Component {
  getShortCode = () => {
    const { id } = this.props.match.params;

    this.props.getShortCode(id);
  };

  loading = () => {
    const { isFetchingShortCode, isUpdatingShortCode } = this.props;
    return isFetchingShortCode || isUpdatingShortCode;
  };

  updateShortCode = data => {
    return this.props.updateShortCode(data).catch(
      catchApiItemErrors,
    );
  };

  componentDidMount() {
    this.getShortCode();
  }

  render() {
    const { shortCode, history, success } = this.props;
    const loading = this.loading();
    const subject = trans('resources.singular.shortCode');

    return (
      <ModalsWrappedForm
        gender={MALE_CODE}
        history={history}
        loading={loading}
        open={success}
        path={PRIVATE_PATHS.adminShortCodes.path}
        subject={subject}
        successMessage="successOpSingM"
        type="edit"
      >
        {
          shortCode &&
          <EditShortCodeForm
            actions={EDIT_ACTIONS}
            history={history}
            onSubmit={this.updateShortCode}
            path={PRIVATE_PATHS.adminShortCodes.path}
          />
        }
      </ModalsWrappedForm>
    );
  }
}

const mapStateToProps = ({
  shortCodes: { isFetchingItem, isUpdating, item },
  reduxRestResponse: { body, success, type },
}) => ({
  body,
  shortCode: item,
  isFetchingShortCode: isFetchingItem,
  isUpdatingShortCode: isUpdating,
  success: type === types.UPDATE_SHORT_CODE && success,
});

export default connect(
  mapStateToProps,
  {
    ...shortCodesActions,
  }
)(EditShortCode);

import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import ResponsiveForm from '../../Form';
import { validate } from '../../../../Helpers';
import {
  DEFAULT_VALUES,
  ADD_INPUTS,
  USER_FORM as form,
  USER_VALIDATIONS,
} from './config';

let AddUserForm = ({ handleSubmit, loading = false, ...props }) => (
  <ResponsiveForm
    {...props}
    inputs={ADD_INPUTS}
    loading={loading}
    onSubmit={handleSubmit}
  />
);

AddUserForm = reduxForm({
  form,
  validate: validate(USER_VALIDATIONS),
})(AddUserForm);

const mapStateToProps = ({
  ...state
}) => ({
  initialValues: { ...DEFAULT_VALUES },
});

export default connect(
  mapStateToProps
)(AddUserForm);

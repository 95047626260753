import React from 'react';
import Card from './Styled/Card';
import CardContent from './Styled/CardContent';

export default ({ children }) => (
  <Card>
    <CardContent>
      {children}
    </CardContent>
  </Card>
);

import styled from 'styled-components';

export default styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 26px;
  width: 26px;

  .navbar-option-image {
    max-height: 100%;
    max-width: 100%;
  }
`;

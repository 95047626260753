import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import DefaultLayout from './Layouts/Default';
import { UNAUTHORIZED } from '../Constants';
import { DEFAULT_PUBLIC_PATH } from './config';
import { actions as authActions } from '../Redux/Ducks/Auth';
import { actions as navbarActions } from '../Redux/Ducks/Navbar';
import { types as usersTypes } from '../Redux/Api/Users';

class PrivateRoute extends React.Component {
  isNotAuthorized = ({ code, type }) => (
    code === UNAUTHORIZED && type !== usersTypes.LOGIN_USER
  );

  setMenuOptions = () => {
    const {
      headerMenu,
      routeType,
      search,
      searchPlaceholder,
      selectedOption,
      title,
    } = this.props;

    this.props.setHeaderTitle(title);
    this.props.setHeaderMenu(headerMenu);
    this.props.setRouteType(routeType);
    this.props.setSearch(search);
    this.props.setSearchPlaceholder(searchPlaceholder);
    this.props.setSelectedOption(selectedOption);
  };

  componentWillReceiveProps(nextProps) {
    if (this.isNotAuthorized(nextProps)) {
      this.props.logout(false);
    }
  }

  componentDidMount() {
    this.setMenuOptions();
  }

  render() {
    const { authenticated, user: { role }, path, component: Component, routeType } = this.props;
    return (
      <Route
        exact
        path={path}
        render={props =>
          authenticated ?
            routeType === undefined ||
            ((role === 0 && routeType === 'admin') || 
            ((role === 1 || role === 2) && routeType === 'client')) ?
              (
                <DefaultLayout>
                  <Component {...props} />
                </DefaultLayout>
              ) : ( <Redirect to="/" /> )
          : (
            <Redirect to={DEFAULT_PUBLIC_PATH} />
          )
        }
      />
    );
  }
}

const mapStateToProps = ({
  auth: { authenticated, user },
  reduxRestResponse: { code, type },
}) => ({
  authenticated,
  user,
  code,
  type,
});

export default connect(
  mapStateToProps,
  {
    ...authActions,
    ...navbarActions,
  }
)(PrivateRoute);

import DatePicker from './DatePicker';
import FilePicker from './FilePicker';
import Label from './Label';
import MultipleCheckbox from './MultipleCheckbox';
import Select from './Select';
import Switch from './Switch';
import TextField from './TextField';
import TimePicker from './TimePicker';
import TwoWaySwitch from './TwoWaySwitch';
export const DEFAULT_INPUT_TYPE = 'text';

export const INPUT_TYPES_COMPONENTS = {
  datepicker: DatePicker,
  file: FilePicker,
  label: Label,
  multipleCheckbox: MultipleCheckbox,
  number: TextField,
  password: TextField,
  select: Select,
  switch: Switch,
  text: TextField,
  timepicker: TimePicker,
  twoWaySwitch: TwoWaySwitch,
};

export const ICON_ALIGNMENTS = {
  left: 'left',
  right: 'right',
};

export const DEFAULT_ICON_ALIGNMENT = ICON_ALIGNMENTS.left;

import React from 'react';
import { TABLE_ACTIONS_CONFIG } from '../config';

export default ({ type, ...props }) => {
  const { label, component: Action } = TABLE_ACTIONS_CONFIG[type];

  return (
    <Action label={label} size="small" {...props} />
  );
};

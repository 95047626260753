import styled from 'styled-components';

export default styled.div`
    padding: 10px;

    .navbar-option-title {
        display: block;
        margin-left: 0;
    }

    .expansion-panel-details {
        padding-left: 0;
    }
`;
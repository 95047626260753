import trans from '../../Translations/translation.service';

export const FILTERS_FORM = 'filters';

export const FILTERS_ACTIONS = [
    {
      color: 'secondary',
      label: trans('forms.actions.clear'),
      type: 'button',
    },
    {
      color: 'primary',
      label: trans('forms.actions.apply'),
      type: 'submit',
    },
  ];
import React from 'react';
import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import moment from 'moment';
import FileDownloadLink from '../../../Components/FileDownloadLink';
import Table from '../../../Components/Table';
import trans from '../../../Translations/translation.service';
import { actions as allAccountsActions } from '../../../Redux/Api/AllAccounts';
import { actions as paymentsActions } from '../../../Redux/Api/Payments';
import {
  ADMIN_PAYMENTS_FILTERS_INPUTS,
} from './config';
import { FILTERS_FORM as form } from '../../../Components/Filters/config';
import {
  ADMIN_PAYMENTS_TABLE_TITLES,
  ALL_SELECT_OPTION_F,
  ALL_SELECT_OPTION_M,
  ALL_SELECT_OPTION_VALUE as ALL,
  API_DATE_FORMAT,
  DEFAULT_TABLE_ACTIONS,
  HEADERS as headers,
  MONTHS_LIST,
  MONTHS_SELECT_OPTIONS,
  PAYMENT_TYPES_LIST,
  SUCCESS_CODE,
} from '../../../Constants';
import { getPage, parseApiDate } from '../../../Helpers';
import { PRIVATE_PATHS } from '../../config';

class AdminPayments extends React.Component {
  state = {
    filters: {},
  };

  deletePayment = id => (
    this.props.deletePayment(id)
      .then(({ code }) => code === SUCCESS_CODE && this.fetchPayments())
  );

  fetchPayments = newPage => {
    const { filters } = this.state;
    const { location } = this.props;
    const page = getPage(newPage, location);

    this.props.fetchPayments({}, { query: { page, ...filters } });
  };

  fetchPaymentsFromDateFrom = date => {
    const { filters } = this.state;

    const momentDate = moment(date, API_DATE_FORMAT);

    if (momentDate._isValid) {
      filters.from =  date;
    } else {
      delete filters.from;
    }

    this.setState({ filters }, () => {
      this.fetchPayments();
    });
  };

  fetchPaymentsFromDateTo = date => {
    const { filters } = this.state;

    const momentDate = moment(date, API_DATE_FORMAT);

    if (momentDate._isValid) {
      filters.to =  date;
    } else {
      delete filters.to;
    }

    this.setState({ filters }, () => {
      this.fetchPayments();
    });
  };

  fetchPaymentsFromAccount = account => {
    const { filters } = this.state;

    if (account === ALL) {
      delete filters.account;
    } else {
      filters.account =  account;
    }

    this.setState({ filters }, () => {
      this.fetchPayments();
    });
  };

  fetchPaymentsFromMonth = month => {
    const { filters } = this.state;

    if (month === ALL) {
      delete filters.month;
    } else {
      filters.month =  month;
    }

    this.setState({ filters }, () => {
      this.fetchPayments();
    });
  };

  fetchPaymentsFromYear = year => {
    const { filters } = this.state;

    if (year === ALL) {
      delete filters.year;
    } else {
      filters.year =  year;
    }

    this.setState({ filters }, () => {
      this.fetchPayments();
    });
  };

  initFilters = () => {
    this.props.reset(form);
    this.props.change(form, 'from_date', undefined);
    this.props.change(form, 'to_date', undefined);
    this.props.change(form, 'account_id', ALL);
    this.props.change(form, 'month', ALL);
    this.props.change(form, 'year', ALL);
  };

  loading = () => {
    const {
      isDeletingPayment,
      isDownloadingFile,
      isFetchingAccounts,
      isFetchingPayments,
    } = this.props;

    return isDeletingPayment
      || isDownloadingFile
      || isFetchingAccounts
      || isFetchingPayments;
  };

  parsePaymentData = ({
    month,
    year,
    transaction_date,
    transaction_type,
    bank_name,
    transaction_id,
    transaction_src_download_path,
    transaction_src_file_name,
    account,
  }) => [
    {
      text: `${trans(`default.months.${MONTHS_LIST[month]}`)} - ${year}`,
    },
    {
      noWrap: true,
      text: parseApiDate(transaction_date),
    },
    {
      text: trans(`default.paymentTypes.${PAYMENT_TYPES_LIST[transaction_type]}`),
    },
    {
      text: bank_name,
    },
    {
      text: transaction_id,
    },
    {
      text: transaction_src_file_name
        ? <FileDownloadLink
          fileName={transaction_src_file_name}
          fileRequestOptions={{ headers }}
          fileUrl={transaction_src_download_path}
        />
        : ''
    },
    {
      text: account && account.company_name,
    },
  ];

  parseAccounts = () => {
    const accounts = this.props.accounts.map(({ id: value, company_name: text }) => ({
      text, value,
    }));

    accounts.unshift(ALL_SELECT_OPTION_F);

    return accounts;
  };

  parseYears = () => {
    const years = this.props.years.map(({ year }) => ({
      text: year, value: year,
    }));

    years.unshift(ALL_SELECT_OPTION_M);

    return years;
  };

  addAllOptionsToMonths = () => {
    const monthsList = [...MONTHS_SELECT_OPTIONS];

    monthsList.unshift(ALL_SELECT_OPTION_M);

    return monthsList;
  };

  componentDidMount() {
    this.fetchPayments();
    this.props.fetchAllAccounts();
    this.initFilters();
  }

  render() {
    const {
      payments,
      history,
      pagination,
    } = this.props;
    const accounts = this.parseAccounts();
    const months = this.addAllOptionsToMonths();
    const years = this.parseYears();
    const type = trans('resources.singular.payment');

    return (
      <Table
        actions={DEFAULT_TABLE_ACTIONS}
        actionButtonNames={{
          delete: ({ month, year }) => `${trans(`default.months.${MONTHS_LIST[month]}`)} - ${year}`,
        }}
        data={payments}
        dataParser={this.parsePaymentData}
        deleteWarningMessage="deleteOpSingM"
        editPath={PRIVATE_PATHS.adminEditPayment.noParamsPath}
        filtersProps={{
          afterChange: {
            from_date: this.fetchPaymentsFromDateFrom,
            to_date: this.fetchPaymentsFromDateTo,
            account_id: this.fetchPaymentsFromAccount,
            month: this.fetchPaymentsFromMonth,
            year: this.fetchPaymentsFromYear,
          },
          data: {
            account_id: accounts,
            month: months,
            year: years,
          },
          inputs: ADMIN_PAYMENTS_FILTERS_INPUTS,
          loading: this.loading(),
        }}
        handleChangePage={this.fetchPayments}
        history={history}
        loading={this.loading()}
        onDeleteAcceptClick={this.deletePayment}
        pagination={pagination}
        titles={ADMIN_PAYMENTS_TABLE_TITLES}
        type={type}
      />
    );
  }
}

const mapStateToProps = ({
  allAccounts: {
    isFetching: isFetchingAccounts,
    items: accounts,
  },
  payments: {
    isDeleting,
    isFetching,
    items: [success, data, years, pagination],
  },
  fileDownload: { pending },
}) => ({
  isFetchingAccounts,
  pagination,
  success,
  accounts: accounts[1] ? accounts[1] : [],
  payments: success ? data : [],
  isDeletingPayment: isDeleting,
  isDownloadingFile: pending,
  isFetchingPayments: isFetching,
  years: success ? years : [],
});

export default connect(
  mapStateToProps,
  {
    change,
    reset,
    ...allAccountsActions,
    ...paymentsActions,
  }
)(AdminPayments);

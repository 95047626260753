import Close from '@material-ui/icons/Close';
import trans from '../../../../Translations/translation.service';
import { API_DATE_FORMAT, MAX_REPLY_SMS, SMS_CHAR_SIZE } from '../../../../Constants';

export const EDIT_ACTIONS = [
  {
    color: 'secondary',
    handler: 'backToList',
    label: trans('modals.actions.backToList'),
    type: 'button',
  },
  {
    color: 'primary',
    label: trans('forms.actions.save'),
    type: 'submit',
  },
];

export const DEFAULT_VALUES = {
  word: '',
  short_code_id: null,
};

export const EDITABLE_INPUTS = [
  {
    id: 'word',
    inputProps: {
      maxLength: 255,
    },
    label: trans('forms.fields.admin.keywords.word'),
    name: 'word',
    normalize: value => value.toUpperCase(),
    required: true,
    sizes: {
      xs: 12,
      sm: 6,
      md: 4,
    },
  },
  {
    id: 'short-code',
    label: trans('forms.fields.admin.keywords.shortCode'),
    name: 'short_code_id',
    required: true,
    sizes: {
      xs: 12,
      sm: 6,
      md: 4,
    },
    type: 'select',
  },
  {
    addLabel: trans('forms.fields.admin.keywords.addReply'),
    defaultItem: {
      url: false,
    },
    fieldType: 'array',
    name: 'replies',
    inputs: [
      {
        id: 'from',
        inputFormat: API_DATE_FORMAT,
        label: trans('forms.fields.admin.keywords.replyFrom'),
        name: 'from_date',
        sizes: {
          xs: 12,
          sm: 6,
          md: 4,
        },
        type: 'datepicker',
      },
      {
        id: 'to',
        inputFormat: API_DATE_FORMAT,
        label: trans('forms.fields.admin.keywords.replyTo'),
        name: 'to_date',
        sizes: {
          xs: 12,
          sm: 6,
          md: 4,
        },
        type: 'datepicker',
      },
      {
        clickableIcon: true,
        icon: Close,
        iconAlign: 'right',
        id: 'url',
        label: trans('forms.fields.admin.keywords.replyUrl'),
        labelOff: trans('forms.fields.admin.keywords.replyUrlOff'),
        labelOn: trans('forms.fields.admin.keywords.replyUrlOn'),
        name: 'url',
        remove: true,
        removeLabel: trans('forms.fields.admin.keywords.removeReply'),
        sizes: {
          xs: 12,
          md: 4,
        },
        type: 'twoWaySwitch',
      },
      {
        id: 'content',
        label: trans('forms.fields.admin.keywords.replyContent'),
        name: 'content',
        propsByValues: values => (
          values && !values.url
            ? {
              charCount: true,
              inputProps: {
                maxLength: MAX_REPLY_SMS*SMS_CHAR_SIZE,
              },
              label: trans('forms.fields.admin.keywords.replyContent'),
              multiline: true,
              rows: 4,
            }
            : {
              label: trans('forms.fields.admin.keywords.replyContentUrl'),
            }
        ),
        required: true,
        sizes: {
          xs: 12,
        },
      },
    ],
    size: {
      xs: 12,
    },
    title: trans('forms.fields.admin.keywords.replies'),
  },
];

export const INPUTS = [
  {
    disabled: true,
    id: 'word',
    label: trans('forms.fields.admin.keywords.word'),
    name: 'word',
    normalize: value => value.toUpperCase(),
    required: true,
    sizes: {
      xs: 12,
      sm: 6,
      md: 4,
    },
  },
  {
    disabled: true,
    id: 'short-code-code',
    label: trans('forms.fields.admin.keywords.shortCode'),
    name: 'short_code.code',
    required: true,
    sizes: {
      xs: 12,
      sm: 6,
      md: 4,
    },
  },
  {
    addLabel: trans('forms.fields.admin.keywords.addReply'),
    defaultItem: {
      url: false,
    },
    fieldType: 'array',
    name: 'replies',
    inputs: [
      {
        id: 'from',
        inputFormat: API_DATE_FORMAT,
        label: trans('forms.fields.admin.keywords.replyFrom'),
        name: 'from_date',
        sizes: {
          xs: 12,
          sm: 6,
          md: 4,
        },
        type: 'datepicker',
      },
      {
        id: 'to',
        inputFormat: API_DATE_FORMAT,
        label: trans('forms.fields.admin.keywords.replyTo'),
        name: 'to_date',
        sizes: {
          xs: 12,
          sm: 6,
          md: 4,
        },
        type: 'datepicker',
      },
      {
        clickableIcon: true,
        icon: Close,
        iconAlign: 'right',
        id: 'url',
        label: trans('forms.fields.admin.keywords.replyUrl'),
        labelOff: trans('forms.fields.admin.keywords.replyUrlOff'),
        labelOn: trans('forms.fields.admin.keywords.replyUrlOn'),
        name: 'url',
        remove: true,
        removeLabel: trans('forms.fields.admin.keywords.removeReply'),
        sizes: {
          xs: 12,
          md: 4,
        },
        type: 'twoWaySwitch',
      },
      {
        id: 'content',
        label: trans('forms.fields.admin.keywords.replyContent'),
        name: 'content',
        propsByValues: values => (
          values && !values.url
            ? {
              charCount: true,
              inputProps: {
                maxLength: MAX_REPLY_SMS*SMS_CHAR_SIZE,
              },
              label: trans('forms.fields.admin.keywords.replyContent'),
              multiline: true,
              rows: 4,
            }
            : {
              label: trans('forms.fields.admin.keywords.replyContentUrl'),
            }
        ),
        required: true,
        sizes: {
          xs: 12,
        },
      },
    ],
    size: {
      xs: 12,
    },
    title: trans('forms.fields.admin.keywords.replies'),
  },
];

export const KEYWORD_FORM = 'keyword';

export const KEYWORD_VALIDATIONS = [
  {
    array: true,
    field: 'replies',
    validations: [
      {
        args: ['url', true, { protocols: ['http','https'], require_protocol: true }],
        field: 'content',
        message: ['default.forms.validations.isUrl'],
        method: 'isURLIf',
        validWhen: true,
      },
    ],
  },
];

export const REPLY_PARSE_CONFIG = [
  {
    field: 'from_date',
    parser: 'date',
    params: [API_DATE_FORMAT],
  },
  {
    field: 'to_date',
    parser: 'date',
    params: [API_DATE_FORMAT],
  },
];

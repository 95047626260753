import React from 'react';
import { connect } from 'react-redux';
import Table from '../../../Components/Table';
import trans from '../../../Translations/translation.service';
import { actions as integratorsActions } from '../../../Redux/Api/Integrators';
import { INTEGRATORS_TABLE_TITLES } from '../../../Constants';
import { PRIVATE_PATHS } from '../../config';
import { getPage } from '../../../Helpers';

class AdminIntegrators extends React.Component {
  fetchIntegrators = newPage => {
    const { location } = this.props;
    const page = getPage(newPage, location);

    this.props.fetchIntegrators({}, { query: { page } });
  };

  parseIntegratorData = ({
    code,
    credits,
    name,
  }) => [
    {
      text: name,
    },
    {
      text: code,
    },
    {
      text: credits,
    },
  ];

  componentDidMount() {
    this.fetchIntegrators();
  }

  render() {
    const { history, integrators, isFetchingIntegrators, pagination } = this.props;
    const type = trans('resources.singular.integrator');

    return (
      <Table
        actions={['edit']}
        data={integrators}
        dataParser={this.parseIntegratorData}
        editPath={PRIVATE_PATHS.adminEditIntegrator.noParamsPath}
        handleChangePage={this.fetchIntegrators}
        history={history}
        loading={isFetchingIntegrators}
        pagination={pagination}
        titles={INTEGRATORS_TABLE_TITLES}
        type={type}
      />
    );
  }
}

const mapStateToProps = ({
  integrators: {
    isFetching,
    items: [success, data, pagination],
  },
}) => ({
  pagination,
  success,
  integrators: success ? data : [],
  isFetchingIntegrators: isFetching,
});

export default connect(
  mapStateToProps,
  {
    ...integratorsActions,
  }
)(AdminIntegrators);

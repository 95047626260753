import * as types from './types';
import { DIMENSIONS } from './config';

const getWindowSize = () => {
  const width = window.innerWidth;
  const sizeConfig = DIMENSIONS.find(size => (
    (!size.max || width <= size.max) && (!size.min || width > size.min)
  ));

  return sizeConfig.size;
};

const defaultState = {
  size: getWindowSize(),
};

const reducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    case types.SET_WINDOW_SIZE:
      return { ...state, size: getWindowSize() };

    default: return state;
  }
};

// Reducer
export default reducer;

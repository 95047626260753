import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import ResponsiveForm from '../../Form';
import {
  EXPORT_FILTERS_FORM as form,
  ADMIN_EXPORT_RECEIVED_MESSAGES_FILTERS_INPUTS,
} from './config';

let ExportFiltersForm = ({ handleSubmit, loading = false, ...props }) => (
  <ResponsiveForm
    {...props}
    inputs={ADMIN_EXPORT_RECEIVED_MESSAGES_FILTERS_INPUTS}
    loading={loading}
    onSubmit={handleSubmit}
  />
);

ExportFiltersForm = reduxForm({
  form,
})(ExportFiltersForm);

const mapStateToProps = () => ({
});

export default connect(
  mapStateToProps
)(ExportFiltersForm);

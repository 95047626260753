import Close from '@material-ui/icons/Close';
import trans from '../../../../Translations/translation.service';

export const EDIT_ACTIONS = [
  {
    color: 'secondary',
    handler: 'backToList',
    label: trans('modals.actions.backToList'),
    type: 'button',
  },
  {
    color: 'primary',
    label: trans('forms.actions.save'),
    type: 'submit',
  },
];

export const DEFAULT_VALUES = {
  name: '',
};

export const EDIT_INPUTS = [
  {
    id: 'name',
    inputProps: {
      maxLength: 255,
    },
    label: trans('forms.fields.admin.integrators.name'),
    name: 'name',
    required: true,
    sizes: {
      xs: 12,
      sm: 6,
      md: 4,
    },
  },
  {
    disabled: true,
    id: 'code',
    inputProps: {
      maxLength: 255,
    },
    label: trans('forms.fields.admin.integrators.code'),
    name: 'code',
    required: true,
    sizes: {
      xs: 12,
      sm: 6,
      md: 4,
    },
  },
];

export const INTEGRATOR_FORM = 'integrator';

export const INTEGRATOR_VALIDATIONS = [
  {
    field: 'name',
    message: ['default.forms.validations.isRequired'],
    method: 'isEmpty',
    validWhen: false,
  },
];

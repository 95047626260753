import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import ResponsiveForm from '../../Form';
import { validate } from '../../../../Helpers';
import {
  INDUSTRY_FORM as form,
  INDUSTRY_VALIDATIONS,
  EDIT_INPUTS,
} from './config';

let EditIndustryForm = ({ handleSubmit, loading = false, ...props }) => (
  <ResponsiveForm
    {...props}
    inputs={EDIT_INPUTS}
    loading={loading}
    onSubmit={handleSubmit}
  />
);

EditIndustryForm = reduxForm({
  form,
  validate: validate(INDUSTRY_VALIDATIONS),
})(EditIndustryForm);

const mapStateToProps = ({
  industries: {
    item,
  },
}) => ({
  initialValues: item ? item.data : {},
});

export default connect(
  mapStateToProps
)(EditIndustryForm);

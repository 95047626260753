import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

export default withStyles({
  root: {
    '& svg': {
      cursor: props => props.clickable ? 'pointer' : 'default',
    },
  },
})(Grid);

import React from 'react';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '../Button';
import FileName from './Styled/FileName';
import FilePickerFormControl from './Styled/FilePickerFormControl';
import FilePickerLabel from './Styled/FilePickerLabel';
import FilePickerWrapper from './Styled/FilePickerWrapper';
import HiddenInput from './Styled/HiddenInput';
import trans from '../../../Translations/translation.service';
import {
  DROPZONE_DEFAULT_FILES_LIMIT,
  DROPZONE_DEFAULT_SIZE_LIMIT,
  DROPZONE_DEFAULT_VALID_FILES,
} from '../../../Constants';

class FilePicker extends React.Component {
  state = {
    files: null,
  };

  getFilesNames = () => {
    const { files } = this.state;

    if (Array.isArray(files)) {
      return files && files.map(file => file.name).reduce((names, name) => `${names}, ${name}`);
    } else {
      return files && files.name;
    }
  };

  handleChange = ({ target: { files } }) => {
    const { afterChange, filesLimit, index, name, onChange } = this.props;
    const value = filesLimit > 1 ? files : files[0] || null;
    if (typeof afterChange === 'function') {
      afterChange(value, name, index);
    }
    this.setState({ files: value });
    onChange(value);
  };

  render() {
    const {
      error,
      label,
      name,
      required,
      showError,
    } = this.props;

    return (
      <FilePickerFormControl
        fullWidth
        error={showError}
        required={required}
      >
        <FilePickerLabel>{label}</FilePickerLabel>
        <FilePickerWrapper htmlFor={name}>
          <Button
            color="primary"
            component="span"
            label={trans('default.forms.labels.browse')}
            type="button"
          />
          <HiddenInput
            id={name}
            name={name}
            onChange={this.handleChange}
            type="file"
          />
        </FilePickerWrapper>
        <FileName>{this.getFilesNames()}</FileName>
        <FormHelperText>{error}</FormHelperText>
      </FilePickerFormControl>
    );
  }
}

FilePicker.defaultProps = {
  acceptedFiles: DROPZONE_DEFAULT_VALID_FILES,
  filesLimit: DROPZONE_DEFAULT_FILES_LIMIT,
  maxFileSize: DROPZONE_DEFAULT_SIZE_LIMIT,
};

export default FilePicker;

import React from 'react';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';
import ResponsiveForm from '../../Form';
import { validate } from '../../../../Helpers';
import {
  EDITABLE_INPUTS,
  INPUTS,
  KEYWORD_FORM as form,
  KEYWORD_VALIDATIONS,
} from './config';

let EditKeywordForm = ({ account, handleSubmit, replies, loading = false, ...props }) => {
  return (
    <ResponsiveForm
      {...props}
      inputs={account && Array.isArray(account.short_codes) && account.short_codes.length ? EDITABLE_INPUTS : INPUTS}
      loading={loading}
      onSubmit={handleSubmit}
      values={{ replies }}
    />
  );
};

EditKeywordForm = reduxForm({
  form,
  validate: validate(KEYWORD_VALIDATIONS),
})(EditKeywordForm);

const selector = formValueSelector(form);

const mapStateToProps = ({
  accounts: { item: account },
  keywords: {
    item,
  },
  ...state
}) => ({
  account: account && account.data,
  initialValues: item ? item.data : {},
  replies: selector(state, 'replies'),
});

export default connect(
  mapStateToProps
)(EditKeywordForm);

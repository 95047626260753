import Close from '@material-ui/icons/Close';
import trans from '../../../../Translations/translation.service';
import {
  API_DATE_FORMAT, MAX_MESSAGE_SMS, MONTHS_SELECT_OPTIONS, PAYMENT_TYPES_SELECT_OPTIONS, SMS_CHAR_SIZE,
} from '../../../../Constants';

export const ADD_ACTIONS = [
  {
    color: 'secondary',
    handler: 'backToList',
    label: trans('modals.actions.backToList'),
    type: 'button',
  },
  {
    color: 'primary',
    label: trans('forms.actions.save'),
    type: 'submit',
  },
];
export const EDIT_ACTIONS = [
  {
    color: 'secondary',
    handler: 'backToList',
    label: trans('modals.actions.backToList'),
    type: 'button',
  },
  {
    color: 'primary',
    label: trans('forms.actions.save'),
    type: 'submit',
  },
];

export const DEFAULT_VALUES = {
  month: null,
  year: '',
  bank_id: null,
  transaction_id: '',
  transaction_type: null,
  transaction_date: null,
  transaction_src: null,
  account_id: null,
};

export const INPUTS = [
  {
    data: MONTHS_SELECT_OPTIONS,
    id: 'month',
    label: trans('forms.fields.client.payments.month'),
    name: 'month',
    required: true,
    sizes: {
      xs: 12,
      sm: 6,
      md: 4,
    },
    type: 'select',
  },
  {
    id: 'year',
    label: trans('forms.fields.client.payments.year'),
    name: 'year',
    required: true,
    sizes: {
      xs: 12,
      sm: 6,
      md: 4,
    },
    type: 'number',
  },
  {
    id: 'transaction-date',
    inputFormat: API_DATE_FORMAT,
    label: trans('forms.fields.admin.payments.transactionDate'),
    name: 'transaction_date',
    sizes: {
      xs: 12,
      sm: 6,
      md: 4,
    },
    type: 'datepicker',
  },
  {
    data: PAYMENT_TYPES_SELECT_OPTIONS,
    id: 'transaction_type',
    label: trans('forms.fields.client.payments.transactionType'),
    name: 'transaction_type',
    required: true,
    sizes: {
      xs: 12,
      sm: 6,
      md: 4,
    },
    type: 'select',
  },
  {
    id: 'bank',
    label: trans('forms.fields.client.payments.bank'),
    name: 'bank_id',
    sizes: {
      xs: 12,
      sm: 6,
      md: 4,
    },
    type: 'select',
  },
  {
    id: 'transaction-id',
    inputProps: {
      maxLength: 255,
    },
    label: trans('forms.fields.client.payments.transactionId'),
    name: 'transaction_id',
    sizes: {
      xs: 12,
      sm: 6,
      md: 4,
    },
  },
  {
    acceptedFiles: ['.jpg', '.png', '.pdf'],
    id: 'transaction-src-file',
    inputProps: {
      maxLength: 255,
    },
    label: trans('forms.fields.client.payments.transactionFile'),
    name: 'transaction_src_file',
    sizes: {
      xs: 12,
      sm: 6,
    },
    type: 'file',
  },
];

export const PAYMENT_FORM = 'payment';

export const PAYMENT_VALIDATIONS = [
  {
    field: 'month',
    message: ['default.forms.validations.isRequired'],
    method: 'isEmpty',
    validWhen: false,
  },
  {
    field: 'year',
    message: ['default.forms.validations.isRequired'],
    method: 'isEmpty',
    validWhen: false,
  },
  {
    field: 'transaction_type',
    message: ['default.forms.validations.isRequired'],
    method: 'isEmpty',
    validWhen: false,
  },
];

export const PAYMENT_PARSE_CONFIG = [
  {
    field: 'transaction_date',
    parser: 'date',
    params: [API_DATE_FORMAT],
  },
];

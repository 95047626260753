import React from 'react';
import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import moment from 'moment';
import Table from '../../../Components/Table';
import { actions as activitiesActions } from '../../../Redux/Api/Activities';
import { actions as allAccountsActions } from '../../../Redux/Api/AllAccounts';
import { actions as allUsersActions } from '../../../Redux/Api/AllUsers';
import {
  ADMIN_ACTIVITIES_TABLE_TITLES,
  ACTIVITIES_TYPES_ARRAY,
  ALL_SELECT_OPTION_F,
  ALL_SELECT_OPTION_M,
  ALL_SELECT_OPTION_VALUE as ALL,
  API_DATE_FORMAT,
} from '../../../Constants';
import {
  ADMIN_ACTIVITIES_FILTERS_INPUTS,
} from './config';
import { FILTERS_FORM as form } from '../../../Components/Filters/config';
import { getPage, parseActivityDescription, parseApiDatetime } from '../../../Helpers';
import trans from '../../../Translations/translation.service';

class AdminActivities extends React.Component {
  state = {
    account: ALL,
    filters: {},
  };

  fetchActivities = newPage => {
    const { filters } = this.state;
    const { location } = this.props;
    const page = getPage(newPage, location);

    this.props.fetchActivities({}, {
      query: { page, ...filters },
    });
  };

  fetchAllUsersFromAccount = account => {
    const { filters } = this.state;

    if (account === ALL) {
      delete filters.account;
    } else {
      filters.account =  account;
    }

    delete filters.user;
    this.props.change(form, 'user_id', ALL);

    this.setState({ filters, account }, () => {
      this.fetchAllUsers();
      this.fetchActivities();
    });
  };

  fetchActivitiesFromDateFrom = date => {
    const { filters } = this.state;

    const momentDate = moment(date, API_DATE_FORMAT);

    if (momentDate._isValid) {
      filters.from =  date;
    } else {
      delete filters.from;
    }

    this.setState({ filters }, () => {
      this.fetchActivities();
    });
  };

  fetchActivitiesFromDateTo = date => {
    const { filters } = this.state;

    const momentDate = moment(date, API_DATE_FORMAT);

    if (momentDate._isValid) {
      filters.to =  date;
    } else {
      delete filters.to;
    }

    this.setState({ filters }, () => {
      this.fetchActivities();
    });
  };

  fetchActivitiesFromType = type => {
    const { filters } = this.state;

    if (type === ALL) {
      delete filters.type;
    } else {
      filters.type =  type;
    }

    this.setState({ filters }, () => {
      this.fetchActivities();
    });
  };

  fetchActivitiesFromUser = user => {
    const { filters } = this.state;

    if (user === ALL) {
      delete filters.user;
    } else {
      filters.user =  user;
    }

    this.setState({ filters }, () => {
      this.fetchActivities();
    });
  };

  fetchAllUsers = () => {
    const { account } = this.state;

    if (account === ALL) {
      this.props.fetchAllUsers();
    } else {
      this.props.fetchAllUsers({}, { query: { account } });
    }
  };

  initFilters = () => {
    this.props.reset(form);
    this.props.change(form, 'from_date', undefined);
    this.props.change(form, 'to_date', undefined);
    this.props.change(form, 'account_id', ALL);
    this.props.change(form, 'user_id', ALL);
    this.props.change(form, 'type', ALL);
  };

  loading = () => {
    const {
      isFetchingAccounts,
      isFetchingActivities,
      isFetchingUsers,
    } = this.props;

    return isFetchingAccounts
      || isFetchingActivities
      || isFetchingUsers;
  };

  parseActivityData = ({
    account,
    created,
    decoded_description: decodedDescription,
    type,
    user,
  }) => [
    {
      text: account && account.company_name,
    },
    {
      text: user && user.username,
    },
    {
      text: trans(`default.activities.types.${ACTIVITIES_TYPES_ARRAY[type]}`),
    },
    {
      text: parseActivityDescription(decodedDescription),
    },
    {
      noWrap: true,
      text: parseApiDatetime(created),
    },
  ];

  parseAccounts = () => {
    const accounts = this.props.accounts.map(({ id: value, company_name: text }) => ({
      text, value,
    }));

    accounts.unshift(ALL_SELECT_OPTION_F);

    return accounts;
  };

  parseUsers = () => {
    const accounts = this.props.users.map(({ id: value, username: text }) => ({
      text, value,
    }));

    accounts.unshift(ALL_SELECT_OPTION_M);

    return accounts;
  };

  componentDidMount() {
    this.fetchActivities();
    this.fetchAllUsers();
    this.initFilters();
    this.props.fetchAllAccounts();
  }

  render() {
    const { activities, pagination } = this.props;
    const accounts = this.parseAccounts();
    const users = this.parseUsers();

    return (
      <Table
        data={activities}
        dataParser={this.parseActivityData}
        filtersProps={{
          afterChange: {
            account_id: this.fetchAllUsersFromAccount,
            from_date: this.fetchActivitiesFromDateFrom,
            to_date: this.fetchActivitiesFromDateTo,
            user_id: this.fetchActivitiesFromUser,
            type: this.fetchActivitiesFromType,
          },
          data: {
            account_id: accounts,
            user_id: users,
          },
          inputs: ADMIN_ACTIVITIES_FILTERS_INPUTS,
          loading: this.loading(),
        }}
        handleChangePage={this.fetchActivities}
        loading={this.loading()}
        pagination={pagination}
        titles={ADMIN_ACTIVITIES_TABLE_TITLES}
      />
    );
  }
}

const mapStateToProps = ({
  activities: {
    isFetching,
    items: [success, data, pagination],
  },
  allAccounts: {
    isFetching: isFetchingAccounts,
    items: accounts,
  },
  allUsers: {
    isFetching: isFetchingUsers,
    items: users,
  },
}) => ({
  isFetchingAccounts,
  isFetchingUsers,
  pagination,
  success,
  accounts: accounts[1] ? accounts[1] : [],
  activities: success ? data : [],
  isFetchingActivities: isFetching,
  users: users[1] ? users[1] : [],
});

export default connect(
  mapStateToProps,
  {
    ...activitiesActions,
    ...allAccountsActions,
    ...allUsersActions,
    change,
    reset,
  }
)(AdminActivities);

import trans from '../../Translations/translation.service';

export const ACCOUNTS_TABLE_TITLES = [
  {
    text: trans('tables.titles.admin.accounts.company'),
  },
  {
    text: trans('tables.titles.admin.accounts.number'),
  },
  {
    text: trans('tables.titles.admin.accounts.username'),
  },
  {
    text: trans('tables.titles.admin.accounts.type'),
  },
  {
    text: trans('tables.titles.admin.accounts.credits'),
  },
];

export const BANKS_TABLE_TITLES = [
  {
    text: trans('tables.titles.admin.banks.name'),
  },
];

export const BUSINESS_TYPES_TABLE_TITLES = [
  {
    text: trans('tables.titles.admin.businessTypes.name'),
  },
  {
    text: trans('tables.titles.admin.businessTypes.industry'),
  },
];

export const CAMPAIGNS_TABLE_TITLES = [
  {
    text: trans('tables.titles.client.campaigns.name'),
  },
  {
    text: trans('tables.titles.client.campaigns.group'),
  },
  {
    text: trans('tables.titles.client.campaigns.contacts'),
  },
  {
    text: trans('tables.titles.client.campaigns.contactsFile'),
  },
];

export const CONTACTS_TABLE_TITLES = [
  {
    text: trans('tables.titles.client.contacts.firstName'),
  },
  {
    text: trans('tables.titles.client.contacts.lastName'),
  },
  {
    text: trans('tables.titles.client.contacts.email'),
  },
  {
    text: trans('tables.titles.client.contacts.phone'),
  },
  {
    text: trans('tables.titles.client.contacts.carrier'),
  },
];

export const GROUPS_TABLE_TITLES = [
  {
    text: trans('tables.titles.client.groups.name'),
  },
];

export const INDUSTRIES_TABLE_TITLES = [
  {
    text: trans('tables.titles.admin.industries.name'),
  },
];

export const INTEGRATORS_TABLE_TITLES = [
  {
    text: trans('tables.titles.admin.integrators.name'),
  },
  {
    text: trans('tables.titles.admin.integrators.code'),
  },
  {
    text: trans('tables.titles.admin.integrators.credits'),
  },
];

export const KEYWORDS_TABLE_TITLES = [
  {
    text: trans('tables.titles.admin.keywords.word'),
  },
  {
    text: trans('tables.titles.admin.keywords.shortCode'),
  },
  {
    text: trans('tables.titles.admin.keywords.account'),
  },
];

export const CLIENT_KEYWORDS_TABLE_TITLES = [
  {
    text: trans('tables.titles.admin.keywords.word'),
  },
  {
    text: trans('tables.titles.admin.keywords.shortCode'),
  },
];

export const RECEIVED_MESSAGES_TABLE_TITLES = [
  {
    text: trans('tables.titles.admin.receivedMessages.sender'),
  },
  {
    text: trans('tables.titles.admin.receivedMessages.message'),
  },
  {
    text: trans('tables.titles.admin.receivedMessages.keyword'),
  },
  {
    text: trans('tables.titles.admin.receivedMessages.shortCode'),
  },
  {
    text: trans('tables.titles.admin.receivedMessages.date'),
  },
  {
    text: trans('tables.titles.admin.receivedMessages.time'),
  },
];

export const SENT_REPLIES_TABLE_TITLES = [
  {
    text: trans('tables.titles.admin.sentReplies.receipent'),
  },
  {
    text: trans('tables.titles.admin.sentReplies.message'),
  },
  {
    text: trans('tables.titles.admin.sentReplies.keyword'),
  },
  {
    text: trans('tables.titles.admin.sentReplies.shortCode'),
  },
  {
    text: trans('tables.titles.admin.sentReplies.date'),
  },
  {
    text: trans('tables.titles.admin.sentReplies.time'),
  },
];

export const NEWS_TABLE_TITLES = [
  {
    text: trans('tables.titles.admin.news.title'),
  },
  {
    text: trans('tables.titles.admin.news.content'),
  },
  {
    text: trans('tables.titles.admin.news.from'),
  },
  {
    text: trans('tables.titles.admin.news.to'),
  },
];

export const PAYMENTS_TABLE_TITLES = [
  {
    text: trans('tables.titles.client.payments.monthYear'),
  },
  {
    text: trans('tables.titles.client.payments.date'),
  },
  {
    text: trans('tables.titles.client.payments.type'),
  },
  {
    text: trans('tables.titles.client.payments.bank'),
  },
  {
    text: trans('tables.titles.client.payments.id'),
  },
  {
    text: trans('tables.titles.client.payments.file'),
  },
];

export const ADMIN_PAYMENTS_TABLE_TITLES = [
  {
    text: trans('tables.titles.admin.payments.monthYear'),
  },
  {
    text: trans('tables.titles.admin.payments.date'),
  },
  {
    text: trans('tables.titles.admin.payments.type'),
  },
  {
    text: trans('tables.titles.admin.payments.bank'),
  },
  {
    text: trans('tables.titles.admin.payments.id'),
  },
  {
    text: trans('tables.titles.admin.payments.file'),
  },
  {
    text: trans('tables.titles.admin.payments.account'),
  },
];

export const ADMIN_SCHEDULED_CAMPAIGNS_TABLE_TITLES = [
  {
    text: trans('tables.titles.admin.scheduledCampaigns.name'),
  },
  {
    text: trans('tables.titles.admin.scheduledCampaigns.group'),
  },
  {
    text: trans('tables.titles.admin.scheduledCampaigns.account'),
  },
  {
    text: trans('tables.titles.admin.scheduledCampaigns.date'),
  },
  {
    text: trans('tables.titles.admin.scheduledCampaigns.time'),
  },
];

export const ADMIN_ACTIVITIES_TABLE_TITLES = [
  {
    text: trans('tables.titles.admin.activities.account'),
  },
  {
    text: trans('tables.titles.admin.activities.user'),
  },
  {
    text: trans('tables.titles.admin.activities.type'),
  },
  {
    text: trans('tables.titles.admin.activities.description'),
  },
  {
    text: trans('tables.titles.admin.activities.dateTime'),
  },
];

export const ADMIN_REPORTS_SMS_TABLE_TITLES = [
  {
    text: trans('tables.titles.admin.reports.account'),
  },
  {
    text: trans('tables.titles.admin.reports.integrator'),
  },
  {
    text: trans('default.carriers.movistar'),
  },
  {
    text: trans('default.carriers.digitel'),
  },
  {
    text: trans('default.carriers.movilnet'),
  },
  {
    text: trans('tables.titles.admin.reports.sentMessages'),
  },
];

export const ADMIN_REPORTS_SHORT_CODES_TABLE_TITLES = [
  {
    text: trans('tables.titles.admin.reports.account'),
  },
  {
    text: trans('tables.titles.admin.reports.shortCode'),
  },
  {
    text: trans('default.carriers.movistar'),
  },
  {
    text: trans('default.carriers.digitel'),
  },
  {
    text: trans('default.carriers.movilnet'),
  },
  {
    text: trans('tables.titles.admin.reports.receivedMessages'),
  },
];

export const ADMIN_SENT_CAMPAIGNS_TABLE_TITLES = [
  {
    text: trans('tables.titles.admin.sentCampaigns.name'),
  },
  {
    text: trans('tables.titles.admin.sentCampaigns.group'),
  },
  {
    text: trans('tables.titles.admin.sentCampaigns.account'),
  },
  {
    text: trans('tables.titles.admin.sentCampaigns.date'),
  },
  {
    text: trans('tables.titles.admin.sentCampaigns.time'),
  },
  {
    text: trans('tables.titles.admin.sentCampaigns.status'),
  },
];

export const SCHEDULED_CAMPAIGNS_TABLE_TITLES = [
  {
    text: trans('tables.titles.client.scheduledCampaigns.name'),
  },
  {
    text: trans('tables.titles.client.scheduledCampaigns.group'),
  },
  {
    text: trans('tables.titles.client.scheduledCampaigns.date'),
  },
  {
    text: trans('tables.titles.client.scheduledCampaigns.time'),
  },
];

export const CLIENT_ACTIVITIES_TABLE_TITLES = [
  {
    text: trans('tables.titles.client.activities.user'),
  },
  {
    text: trans('tables.titles.client.activities.type'),
  },
  {
    text: trans('tables.titles.client.activities.description'),
  },
  {
    text: trans('tables.titles.client.activities.dateTime'),
  },
];

export const CLIENT_SENT_CAMPAIGNS_TABLE_TITLES = [
  {
    text: trans('tables.titles.client.sentCampaigns.name'),
  },
  {
    text: trans('tables.titles.client.sentCampaigns.group'),
  },
  {
    text: trans('tables.titles.client.sentCampaigns.date'),
  },
  {
    text: trans('tables.titles.client.sentCampaigns.time'),
  },
  {
    text: trans('tables.titles.client.sentCampaigns.status'),
  },
];

export const SENT_MESSAGES_TABLE_TITLES = [
  {
    text: trans('tables.titles.client.sentMessages.receipent'),
  },
  {
    text: trans('tables.titles.client.sentMessages.content'),
  },
  {
    text: trans('tables.titles.client.sentMessages.messageCount'),
  },
  {
    text: trans('tables.titles.client.sentMessages.campaign'),
  },
  {
    text: trans('tables.titles.client.sentMessages.integrator'),
  },
  // {
  //   text: trans('tables.titles.client.sentMessages.carrier'),
  // },
  {
    text: trans('tables.titles.client.sentMessages.dateTime'),
  },
];

export const ADMIN_SENT_MESSAGES_TABLE_TITLES = [
  {
    text: trans('tables.titles.admin.sentMessages.receipent'),
  },
  {
    text: trans('tables.titles.admin.sentMessages.content'),
  },
  {
    text: trans('tables.titles.admin.sentMessages.messageCount'),
  },
  {
    text: trans('tables.titles.admin.sentMessages.campaign'),
  },
  {
    text: trans('tables.titles.admin.sentMessages.integrator'),
  },
  // {
  //   text: trans('tables.titles.admin.sentMessages.carrier'),
  // },
  {
    text: trans('tables.titles.admin.sentMessages.account'),
  },
  {
    text: trans('tables.titles.admin.sentMessages.dateTime'),
  },
];

export const SHORT_CODES_TABLE_TITLES = [
  {
    text: trans('tables.titles.admin.shortCodes.code'),
  },
];

export const USERS_TABLE_TITLES = [
  {
    text: trans('tables.titles.client.users.username'),
  },
  {
    text: trans('tables.titles.client.users.status'),
  },
];

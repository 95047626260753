import Delete from './Action/Delete';
import Edit from './Action/Edit';
import trans from '../../Translations/translation.service';

export const TABLE_ACTIONS_CONFIG = {
  delete: {
    component: Delete,
    handler: 'handleDelete',
    label: trans('tables.actions.delete'),
  },
  edit: {
    component: Edit,
    handler: 'handleEdit',
    label: trans('tables.actions.edit'),
  },
};
export const STRIPED_TABLE = 'striped';
export const TABLE_ROWS = 10;

import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';

export default withStyles({
  root: {
    margin: '5px 5px 0',
    minHeight: 'calc(100vh - 109px)',
    position: 'relative',
  },
})(Card);

import { css } from 'styled-components';
import { fontFaceAttrs } from '../../Helpers';

export default css`
  ${
    fontFaceAttrs({
      family: 'Roboto',
      name: 'Roboto',
      variation: 'Light',
      weight: 300,
    })
  }

  ${
    fontFaceAttrs({
      family: 'Roboto',
      name: 'Roboto',
      variation: 'Thin',
      weight: 100,
    })
  }

  ${
    fontFaceAttrs({
      family: 'Roboto',
      name: 'Roboto',
      variation: 'Medium',
      weight: 500,
    })
  }

  ${
    fontFaceAttrs({
      family: 'Roboto',
      name: 'Roboto',
      variation: 'Bold',
      weight: 'bold',
    })
  }

  ${
    fontFaceAttrs({
      family: 'Roboto',
      name: 'Roboto',
      variation: 'Regular',
    })
  }

  ${
    fontFaceAttrs({
      family: 'Roboto',
      name: 'Roboto',
      variation: 'Black',
      weight: 900,
    })
  }
`;

import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import ResponsiveForm from '../../Form';
import { validate } from '../../../../Helpers';
import {
  DEFAULT_VALUES,
  INPUTS,
  NEW_FORM as form,
  NEW_VALIDATIONS,
} from './config';

let AddNewForm = ({ handleSubmit, loading = false, ...props }) => (
  <ResponsiveForm
    {...props}
    inputs={INPUTS}
    loading={loading}
    onSubmit={handleSubmit}
  />
);

AddNewForm = reduxForm({
  form,
  validate: validate(NEW_VALIDATIONS),
})(AddNewForm);

const mapStateToProps = () => ({
  initialValues: { ...DEFAULT_VALUES },
});

export default connect(
  mapStateToProps
)(AddNewForm);

import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { FieldArray } from 'redux-form';
import Grid from './Styled/ArrayFieldGrid';
import Field from './Field';

class ArrayFields extends React.Component {
  handleAddClick = () => {
    const { defaultItem, fields, afterChange = {} } = this.props;
    const afterChangeCallback = afterChange['newElement'];
    const currentCount = fields.length;

    fields.push(defaultItem ? { ...defaultItem } : {});

    if (typeof afterChangeCallback === 'function') {
      afterChangeCallback(currentCount + 1);
    }
  };

  handleRemoveClick = index => () => {
    const { fields, afterChange = {} } = this.props;
    const afterChangeCallback = afterChange['newElement'];
    const currentCount = fields.length;

    fields.remove(index);

    if (typeof afterChangeCallback === 'function') {
      afterChangeCallback(currentCount - 1);
    }
  };

  render() {
    const {
      addLabel,
      data,
      fields,
      fixed,
      inputs,
      size,
      spacing,
      values,
      afterChange = {},
    } = this.props;

    const renderedFields = fields.map((item, index) => {
      const itemValues = Array.isArray(values) && values[index];
      const disabled = itemValues && itemValues.active !== undefined ? !itemValues.active : false;

      return (
        <Grid item key={index} {...size}>
          <Grid container spacing={spacing}>
            {inputs.map(({ name, placeholder, remove, ...inputProps }, key) => (
              <Field
                afterChange={afterChange[name]}
                data={data[name]}
                disabled={disabled && name !== 'active'}
                index={index}
                key={key}
                name={`${item}.${name}`}
                iconHandleClick={remove && this.handleRemoveClick(index)}
                values={itemValues}
                {...inputProps}
              />
            ))}
          </Grid>
        </Grid>
      )
    });

    if (!fixed) {
      renderedFields.push(
        <Grid item key={fields.length} xs={12} lg={1}>
          <Tooltip title={addLabel}>
            <Fab
              color="primary"
              onClick={this.handleAddClick}
              size="small"
            >
              <AddIcon />
            </Fab>
          </Tooltip>
        </Grid>
      );
    }

    return renderedFields;
  }
}

ArrayFields.defaultProps = {
  addLabel: '',
  data: {},
  fixed: false,
};

export default ({ name, data, spacing, title, ...props }) => (
  <React.Fragment>
    {
      title &&
      <Grid item xs={12}>
        <Typography component="h6">
          {title}
        </Typography>
      </Grid>
    }
    <FieldArray
      component={ArrayFields}
      name={name}
      props={{
        data,
        spacing,
        ...props,
      }}
    />
  </React.Fragment>
);

import React from 'react';
import { connect } from 'react-redux';
import EditKeywordForm from '../../../../Components/Forms/Client/Keywords/EditKeyword';
import ModalsWrappedForm from '../../../../Components/Forms/ModalsWrappedForm';
import trans from '../../../../Translations/translation.service';
import { actions as allShortCodesActions } from '../../../../Redux/Api/AllShortCodes';
import { actions as keywordsActions, types } from '../../../../Redux/Api/Keywords';
import { PRIVATE_PATHS } from '../../../config';
import { FEMALE_CODE } from '../../../../Constants';
import { EDIT_ACTIONS, REPLY_PARSE_CONFIG } from '../../../../Components/Forms/Client/Keywords/config';
import { catchApiItemErrors, parseDataForApi } from '../../../../Helpers';

class EditKeyword extends React.Component {
  getKeyword = () => {
    const { id } = this.props.match.params;

    this.props.getKeyword(id);
  };

  loading = () => {
    const {
      isFetchingKeyword,
      isFetchingShortCodes,
      isUpdatingKeyword,
    } = this.props;
    return isFetchingKeyword
      || isFetchingShortCodes
      || isUpdatingKeyword;
  };

  parseShortCodes = () => (
    this.props.shortCodes.map(({ id: value, code: text }) => ({
      text, value,
    }))
  );

  updateKeyword = data => {
    data.replies = data.replies.map(reply => parseDataForApi(reply, REPLY_PARSE_CONFIG));

    return this.props.updateKeyword(data).catch(
      catchApiItemErrors,
    );
  };

  componentDidMount() {
    this.props.fetchAllShortCodes({}, { query: { client: true } });
    this.getKeyword();
  }

  render() {
    const { history, keyword, success } = this.props;
    const shortCodes = this.parseShortCodes();
    const subject = trans('resources.singular.keyword');

    return (
      <ModalsWrappedForm
        gender={FEMALE_CODE}
        history={history}
        loading={this.loading()}
        open={success}
        path={PRIVATE_PATHS.clientKeywords.path}
        subject={subject}
        successMessage="successOpSingF"
        type="edit"
      >
        {
          keyword &&
          <EditKeywordForm
            actions={EDIT_ACTIONS}
            data={{
              short_code_id: shortCodes,
            }}
            history={history}
            onSubmit={this.updateKeyword}
            path={PRIVATE_PATHS.clientKeywords.path}
          />
        }
      </ModalsWrappedForm>
    );
  }
}

const mapStateToProps = ({
  allShortCodes: {
    isFetching: isFetchingShortCodes,
    items: shortCodes,
  },
  keywords: { isFetchingItem, isUpdating, item },
  reduxRestResponse: { body, success, type },
}) => ({
  body,
  isFetchingShortCodes,
  isFetchingKeyword: isFetchingItem,
  isUpdatingKeyword: isUpdating,
  keyword: item,
  shortCodes: shortCodes[1] ? shortCodes[1] : [],
  success: type === types.UPDATE_KEYWORD && success,
});

export default connect(
  mapStateToProps,
  {
    ...allShortCodesActions,
    ...keywordsActions,
  }
)(EditKeyword);

import moment from 'moment';
import {
  API_DATE_FORMAT,
  API_DATETIME_FORMAT,
  API_TIME_FORMAT,
  DATE_FORMAT,
  DATETIME_FORMAT,
  TIME_FORMAT,
} from '../Constants';

export const parseApiDatetime = (dateTime, format = DATETIME_FORMAT) => (
  dateTime && moment(dateTime, API_DATETIME_FORMAT).format(format)
);

export const parseApiDate = (date, format = DATE_FORMAT) => (
  date && moment(date, API_DATE_FORMAT).format(format)
);

export const parseApiTime = (time, format = TIME_FORMAT) => (
  time && moment.utc(time, API_TIME_FORMAT).format(format)
);

import styled from 'styled-components';

export default styled.h2`
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  height: 28px;
  line-height: 14px;
  margin: 0;
`;

import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';

const Label = ({ help, label }) => (
  <FormControl fullWidth>
    <FormLabel>{label}</FormLabel>
    <FormHelperText>{help}</FormHelperText>
  </FormControl>
);

export default Label;

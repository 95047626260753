/** Entities types messages (ES)  */
export default {
  accounts: {
    corporate: 'Corporativa',
    demo: 'Demo',
    individual: 'Individual',
  },
  channels: {
    none: 'Ninguno',
  },
  genders: {
    female: 'Femenino',
    male: 'Masculino',
  },
  statuses: {
    accounts: {
      active: 'Acitva',
      inactive: 'Inactiva',
      suspended: 'Suspendida',
    },
    users: {
      active: 'Acitva',
      inactive: 'Inactiva',
    },
  },
  statusTexts: {
    accounts: {
      active: 'Cuenta Acitva',
      inactive: 'Cuenta Inactiva',
      suspended: 'Cuenta Suspendida',
    },
  },
};

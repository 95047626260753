import React from 'react';
import { connect } from 'react-redux';
import Table from '../../../Components/Table';
import trans from '../../../Translations/translation.service';
import { actions as industriesActions } from '../../../Redux/Api/Industries';
import {
  INDUSTRIES_TABLE_TITLES,
  DEFAULT_TABLE_ACTIONS,
  SUCCESS_CODE,
} from '../../../Constants';
import { PRIVATE_PATHS } from '../../config';
import { getPage } from '../../../Helpers';

class AdminIndustries extends React.Component {
  deleteIndustry = id => (
    this.props.deleteIndustry(id)
      .then(({ code }) => code === SUCCESS_CODE && this.fetchIndustries())
  );

  fetchIndustries = newPage => {
    const { location } = this.props;
    const page = getPage(newPage, location);

    this.props.fetchIndustries({}, { query: { page } });
  };

  parseIndustryData = ({
    name,
  }) => [
    {
      text: name,
    },
  ];

  componentDidMount() {
    this.fetchIndustries();
  }

  render() {
    const { history, industries, isDeletingIndustry, isFetchingIndustries, pagination } = this.props;
    const type = trans('resources.singular.industry');

    return (
      <Table
        actions={DEFAULT_TABLE_ACTIONS}
        actionButtonNames={{
          delete: ({ name }) => name,
        }}
        data={industries}
        dataParser={this.parseIndustryData}
        deleteWarningMessage="deleteOpSingF"
        editPath={PRIVATE_PATHS.adminEditIndustry.noParamsPath}
        handleChangePage={this.fetchIndustries}
        history={history}
        loading={isDeletingIndustry || isFetchingIndustries}
        onDeleteAcceptClick={this.deleteIndustry}
        pagination={pagination}
        titles={INDUSTRIES_TABLE_TITLES}
        type={type}
      />
    );
  }
}

const mapStateToProps = ({
  industries: {
    isDeleting,
    isFetching,
    items: [success, data, pagination],
  },
}) => ({
  pagination,
  success,
  industries: success ? data : [],
  isDeletingIndustry: isDeleting,
  isFetchingIndustries: isFetching,
});

export default connect(
  mapStateToProps,
  {
    ...industriesActions,
  }
)(AdminIndustries);

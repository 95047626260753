import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { change, formValueSelector, touch } from 'redux-form';
import AddCampaignForm from './AddCampaign';
import AddGroupForm from '../Groups/AddGroup';
import Modal from '../../../../Components/Modals/Modal';
import ModalsWrappedForm from '../../../../Components/Forms/ModalsWrappedForm';
import NewMessageForm from '../../../../Components/Forms/Client/Campaigns/NewMessage';
import TestMessageForm from './TestMessage';
import trans from '../../../../Translations/translation.service';
import { actions as accountsActions } from '../../../../Redux/Api/Accounts';
import { actions as allCampaignsActions } from '../../../../Redux/Api/AllCampaigns';
import { actions as allGroupsActions } from '../../../../Redux/Api/AllGroups';
import { actions as allIntegratorsActions } from '../../../../Redux/Api/AllIntegrators';
import { actions as reduxRestResponseActions } from '../../../../Redux/Ducks/ReduxRestResponse';
import { actions as campaignMessagesActions, types } from '../../../../Redux/Api/CampaignMessages';
import { PRIVATE_PATHS } from '../../../config';
import { DATE_FORMAT, FEMALE_CODE, TIME_FORMAT } from '../../../../Constants';
import {
  NEW_MESSAGE_FORM as form,
  TEST_MESSAGE_FORM as testForm,
  NEW_MESSAGE_PARSE_CONFIG,
  SCHEDULE_ACTIONS,
  SEND_ACTIONS,
} from '../../../../Components/Forms/Client/Campaigns/config';
import {
  catchApiItemErrors,
  getCampaignContactCount,
  getMessageCount,
  getSchedulesCount,
  parseDataForApi,
} from '../../../../Helpers';

class NewMessage extends React.Component {
  state = {
    openNewCampaignModal: false,
    openNewGroupModal: false,
    openTestMessageModal: false,
  };

  afterNewCampaign = campaignId => {
    const { formData } = this.props;

    if (formData.group) {
      this.props.change(form, 'campaign_id', campaignId);
      this.updateContactCount(campaignId);
    } else {
      this.props.change(form, 'campaign_id', null);
      this.resetContactCount();
    }
  };

  afterNewGroup = groupId => {
    this.props.change(form, 'group', groupId);
  };

  createCampaignMessage = data => {
    if (!data.schedule) {
      const now = moment();

      data.schedules = [{
        hidden: true,
        send_date: now.format(DATE_FORMAT),
        send_time: now.format(TIME_FORMAT),
      }];
    }
    return this.props.createCampaignMessage(
      parseDataForApi(data, NEW_MESSAGE_PARSE_CONFIG),
    ).catch(
      catchApiItemErrors,
    );
  };

  fetchAllCampaigns = group => {
    this.props.change(form, 'campaign_id', null);
    this.resetContactCount();

    if (group === 'new') {
      this.toggleOpenNewGroup();
    } else {
      this.props.fetchAllCampaigns({}, { query: { group, client: true } });
    }
  };

  filterAccountIntegrators = ({ value }) => {
    const { account } = this.props;

    return account
      && Array.isArray(account.subscriptions)
      && account.subscriptions.some(
        ({ integrator_id }) => integrator_id === value,
      );
  };

  getActions = formData => {
    const actions = formData.schedule
      ? [ ...SCHEDULE_ACTIONS ]
      : [ ...SEND_ACTIONS ];

    actions[1].disabled = !formData.content;
    actions[1].onClick = this.toggleOpenTestMessage;

    return actions;
  };

  loading = () => {
    const {
      isFetchingAccount,
      isFetchingCampaigns,
      isFetchingGroups,
      isFetchingIntegrators,
      isCreatingCampaignMessage,
    } = this.props;
    return isFetchingAccount
      || isFetchingCampaigns
      || isFetchingIntegrators
      || isFetchingGroups
      || isCreatingCampaignMessage;
  };

  parseCampaigns = () => {
    const campaignsOptions = this.props.campaigns.map(({ id: value, name: text }) => ({
      text, value,
    }));

    const newCampaignOption = {
      value: 'new',
      text: trans('default.newCampaign'),
    };

    return [newCampaignOption, ...campaignsOptions];
  };

  parseGroups = () => {
    const groupsOptions = this.props.groups.map(({ id: value, name: text }) => ({
      text, value,
    }));

    const newGroupOption = {
      value: 'new',
      text: trans('default.newGroup'),
    };

    return [newGroupOption, ...groupsOptions];
  };

  parseIntegrators = () => (
    this.props.integrators.map(({ id: value, name: text }) => ({
      text, value,
    }))
  );

  resetContactCount = () => {
    this.props.change(form, 'contact_count', 0);
    this.props.change(form, 'movistar_contact_count', 0);
    this.props.change(form, 'digitel_contact_count', 0);
    this.props.change(form, 'movilnet_contact_count', 0);
    this.props.change(form, 'total_messages', 0);
  };

  revalidateCount = content => {
    const { formData } = this.props;
    const schedulesCount = getSchedulesCount(formData);
    const messageCount = getMessageCount(content);
    this.props.change(
      testForm,
      'content',
      content,
    );
    this.props.change(
      form,
      'message_count',
      messageCount,
    );
    this.props.change(
      form,
      'total_messages',
      messageCount * (formData.contact_count || 0) * schedulesCount,
    );
    this.props.touch(form, 'total_messages', 'credits');
  };

  toggleOpenNewCampaign = added => {
    const { formData } = this.props;
    const { openNewCampaignModal } = this.state;

    if (openNewCampaignModal && added) {
      if (formData.group) {
        this.props.fetchAllCampaigns({}, { query: { group: formData.group, client: true } })
          .then(() => {
            this.updateContactCount(formData.campaign_id);
          });
      }
    } else if (openNewCampaignModal) {
      this.props.change(form, 'campaign_id', null);
      this.resetContactCount();
    } else {
      this.props.reset();
    }

    this.setState({ openNewCampaignModal: !openNewCampaignModal });
  };

  toggleOpenNewGroup = added => {
    const { formData } = this.props;
    const { openNewGroupModal } = this.state;

    if (openNewGroupModal && added) {
      if (formData.group) {
        this.props.fetchAllGroups({}, { query: { client: true } });
        this.props.fetchAllCampaigns({}, { query: { group: formData.group, client: true } });
      }
    } else if (openNewGroupModal) {
      this.props.change(form, 'group', null);
    } else {
      this.props.reset();
    }

    this.setState({ openNewGroupModal: !openNewGroupModal });
  };

  toggleOpenTestMessage = sent => {
    const { accountId, formData } = this.props;
    const { openTestMessageModal } = this.state;

    if (openTestMessageModal && sent) {
      this.props.getAccount(accountId)
        .then(() =>{
          if (formData.integrator_id) {
            this.updateCredits(formData.integrator_id);
          }
        });
    } else {
      this.props.reset();
    }

    this.setState({ openTestMessageModal: !openTestMessageModal });
  };

  updateContactCount = campaignId => {
    const { campaigns, formData } = this.props;
    const schedulesCount = getSchedulesCount(formData);

    if (campaignId === 'new') {
      this.toggleOpenNewCampaign();
    } else {
      const contactCount = getCampaignContactCount(campaigns, campaignId);
      Object.keys(contactCount).forEach(key => {
        this.props.change(form, key, contactCount[key]);
      });
      this.props.change(
        form,
        'total_messages',
        getMessageCount(formData.content) * (contactCount.contact_count || 0) * schedulesCount,
      );
    }
  };

  updateCredits = integratorId => {
    const { account } = this.props;
    const hasSubscriptions = Array.isArray(account.subscriptions);
    const subscription = hasSubscriptions
      ? account.subscriptions.find(({ integrator_id }) => (
        integrator_id === integratorId
      ))
      : null;
    this.props.change(
      form,
      'credits',
      subscription && subscription.current_credits - subscription.blocked_credits,
    );
  };

  updateTotalMessages = schedulesCount => {
    const { formData } = this.props;
    const messageCount = getMessageCount(formData.content);
    this.props.change(
      form,
      'total_messages',
      messageCount * (formData.contact_count || 0) * schedulesCount,
    );
  };

  updateTotalMessagesBySchdule = schedule => {
    const { formData } = this.props;
    const schedulesCount = getSchedulesCount({ ...formData, schedule });
    const messageCount = getMessageCount(formData.content);
    this.props.change(
      form,
      'total_messages',
      messageCount * (formData.contact_count || 0) * schedulesCount,
    );
  };

  componentDidMount() {
    const { accountId } = this.props;
    this.props.getAccount(accountId);
    this.props.fetchAllGroups({}, { query: { client: true } });
    this.props.fetchAllIntegrators({}, { query: { client: true } });
  }

  render() {
    const { formData, history, success } = this.props;
    const campaigns = this.parseCampaigns();
    const groups = this.parseGroups();
    const integrators = this.parseIntegrators();
    const subject = trans('resources.singular.campaign');
    const path = formData.schedule
      ? PRIVATE_PATHS.clientScheduledCampaigns.path
      : PRIVATE_PATHS.clientSentCampaigns.path;
    const type = formData.schedule ? 'schedule' : 'send';

    return (
      <ModalsWrappedForm
        gender={FEMALE_CODE}
        history={history}
        loading={this.loading()}
        open={success}
        path={path}
        subject={subject}
        successMessage="successOpSingF"
        type={type}
      >
        <NewMessageForm
          actions={this.getActions(formData)}
          afterChange={{
            campaign_id: this.updateContactCount,
            content: this.revalidateCount,
            group: this.fetchAllCampaigns,
            integrator_id: this.updateCredits,
            schedule: this.updateTotalMessagesBySchdule,
            schedules: {
              newElement: this.updateTotalMessages,
            },
          }}
          data={{
            campaign_id: campaigns,
            group: groups,
            integrator_id: integrators.filter(this.filterAccountIntegrators),
          }}
          history={history}
          onSubmit={this.createCampaignMessage}
          path={path}
        />
        <Modal
          noActions={true}
          open={this.state.openTestMessageModal}
          title={trans('modals.titles.sendTest')}
        >
          <TestMessageForm
            content={formData.content}
            onAcceptClick={this.toggleOpenTestMessage}
          />
        </Modal>
        <Modal
          noActions={true}
          open={this.state.openNewCampaignModal}
          size="big"
          title={trans('default.newCampaign')}
        >
          <AddCampaignForm
            afterSave={this.afterNewCampaign}
            groupId={formData.group}
            groupsLoaded={true}
            history={history}
            onAcceptClick={this.toggleOpenNewCampaign}
          />
        </Modal>
        <Modal
          noActions={true}
          open={this.state.openNewGroupModal}
          title={trans('default.newGroup')}
        >
          <AddGroupForm
            afterSave={this.afterNewGroup}
            history={history}
            onAcceptClick={this.toggleOpenNewGroup}
          />
        </Modal>
      </ModalsWrappedForm>
    );
  }
}

const selector = formValueSelector(form);

const mapStateToProps = ({
  allCampaigns: {
    isFetching: isFetchingCampaigns,
    items: campaigns,
  },
  allGroups: {
    isFetching: isFetchingGroups,
    items: groups,
  },
  allIntegrators: {
    isFetching: isFetchingIntegrators,
    items: integrators,
  },
  accounts: {
    isFetchingItem: isFetchingAccount,
    item,
  },
  campaignMessages: { isCreating },
  reduxRestResponse: { body, success, type },
  auth: { user },
  ...state
}) => ({
  body,
  isFetchingAccount,
  isFetchingCampaigns,
  isFetchingIntegrators,
  isFetchingGroups,
  account: item ? item.data : null,
  accountId: user && user.account_id,
  campaigns: campaigns[1] ? campaigns[1] : [],
  formData: selector(
    state,
    'campaign_id',
    'contact_count',
    'content',
    'group',
    'integrator_id',
    'schedule',
    'schedules'
  ),
  groups: groups[1] ? groups[1] : [],
  integrators: integrators[1] ? integrators[1] : [],
  isCreatingCampaignMessage: isCreating,
  success: type === types.CREATE_CAMPAIGN_MESSAGE && success,
});

export default connect(
  mapStateToProps,
  {
    change,
    touch,
    ...accountsActions,
    ...allCampaignsActions,
    ...allGroupsActions,
    ...allIntegratorsActions,
    ...campaignMessagesActions,
    ...reduxRestResponseActions,
  }
)(NewMessage);

import React from 'react';
import { connect } from 'react-redux';
import AddKeywordForm from '../../../../Components/Forms/Admin/Keywords/AddKeyword';
import ModalsWrappedForm from '../../../../Components/Forms/ModalsWrappedForm';
import trans from '../../../../Translations/translation.service';
import { actions as allAccountsActions } from '../../../../Redux/Api/AllAccounts';
import { actions as allShortCodesActions } from '../../../../Redux/Api/AllShortCodes';
import { actions as keywordsActions, types } from '../../../../Redux/Api/Keywords';
import { PRIVATE_PATHS } from '../../../config';
import { FEMALE_CODE } from '../../../../Constants';
import { ADD_ACTIONS } from '../../../../Components/Forms/Admin/Keywords/config';
import { catchApiItemErrors } from '../../../../Helpers';

class AddKeyword extends React.Component {
  createKeyword = data => {
    return this.props.createKeyword(data).catch(
      catchApiItemErrors,
    );
  };

  loading = () => {
    const {
      isFetchingAccounts,
      isFetchingShortCodes,
      isCreatingKeyword,
    } = this.props;
    return isFetchingAccounts
      || isFetchingShortCodes
      || isCreatingKeyword;
  };

  parseAccounts = () => (
    this.props.accounts.map(({ id: value, company_name: text }) => ({
      text, value,
    }))
  );

  parseShortCodes = () => (
    this.props.shortCodes.map(({ id: value, code: text }) => ({
      text, value,
    }))
  );

  componentDidMount() {
    this.props.fetchAllAccounts();
    this.props.fetchAllShortCodes();
  }

  render() {
    const { history, success } = this.props;
    const accounts = this.parseAccounts();
    const shortCodes = this.parseShortCodes();
    const subject = trans('resources.singular.keyword');

    return (
      <ModalsWrappedForm
        gender={FEMALE_CODE}
        history={history}
        loading={this.loading()}
        open={success}
        path={PRIVATE_PATHS.adminKeywords.path}
        subject={subject}
        successMessage="successOpSingF"
        type="add"
      >
        <AddKeywordForm
          actions={ADD_ACTIONS}
          data={{
            account_id: accounts,
            short_code_id: shortCodes,
          }}
          history={history}
          onSubmit={this.createKeyword}
          path={PRIVATE_PATHS.adminKeywords.path}
        />
      </ModalsWrappedForm>
    );
  }
}

const mapStateToProps = ({
  allAccounts: {
    isFetching: isFetchingAccounts,
    items: accounts,
  },
  allShortCodes: {
    isFetching: isFetchingShortCodes,
    items: shortCodes,
  },
  keywords: { isCreating },
  reduxRestResponse: { body, success, type },
}) => ({
  body,
  isFetchingAccounts,
  isFetchingShortCodes,
  accounts: accounts[1] ? accounts[1] : [],
  isCreatingKeyword: isCreating,
  shortCodes: shortCodes[1] ? shortCodes[1] : [],
  success: type === types.CREATE_KEYWORD && success,
});

export default connect(
  mapStateToProps,
  {
    ...allAccountsActions,
    ...allShortCodesActions,
    ...keywordsActions,
  }
)(AddKeyword);

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { COLORS } from '../../../Constants';

export default withStyles({
  root: {
    alignItems: 'center',
    borderRight: `1px solid ${COLORS.black1}`,
    display: 'flex',
    padding: '0 10px',
  },
})(Grid);

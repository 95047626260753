import { withStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import { COLORS } from '../../../Constants';
import { STRIPED_TABLE } from '../config';

export default withStyles({
  root: {
    '&:nth-child(odd)': {
      backgroundColor: ({ type }) => type === STRIPED_TABLE && COLORS.black7,
    },
  },
})(TableRow);

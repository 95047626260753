import React from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import ChartGrid from './Styled/StyledChartGrid';
import Filters from '../Filters';
import GeneralStat from './GeneralStat';
import Loading from '../Loading';
import { CARRIERS, CARRIER_COLORS, TIME_CHART_FORMAT } from '../../Constants';

class Dashboard extends React.Component {
  carrierChart = null;
  currentDayChart = null;
  dailyChart = null;

  initCharts = () => {
    if (this.props.carrierStats) {
      this.initCarrierChart();
    }

    if (this.props.hourlyStats) {
      this.initCurrentDayChart();
    }

    if (this.props.dailyStats) {
      this.initDailyChart();
    }
  };

  generateHourlyData = () => {
    const baseDate = moment().hours(0).minutes(0).seconds(0);
    const data = [];

    for (let i = 0; i < 24; i++) {
      const foundStat = this.props.hourlyStats.find(stat => stat.date === baseDate.format('HH'));

      if (foundStat) {
        data.push({
          ...foundStat,
          date: moment(`${foundStat.date}:00:00`, TIME_CHART_FORMAT).toDate(),
        });
      } else {
        data.push({
          messages: 0,
          date: baseDate.toDate(),
        });
      }

      baseDate.add(1, 'hours');
    }

    return data;
  }

  initCarrierChart = () => {
    this.carrierChart = am4core.create("carrier-chart", am4charts.PieChart);

    // Add and configure Series
    let pieSeries = this.carrierChart.series.push(new am4charts.PieSeries());
    pieSeries.tooltip.fontSize = '12px';
    pieSeries.dataFields.value = "messages";
    pieSeries.dataFields.category = "carrier";

    // Let's cut a hole in our Pie chart the size of 30% the radius
    pieSeries.innerRadius = am4core.percent(50);
    pieSeries.ticks.template.disabled = true;
    pieSeries.labels.template.disabled = true;

    let rgm = new am4core.RadialGradientModifier();
    rgm.brightnesses.push(-0.8, -0.8, -0.5, 0, - 0.5);
    pieSeries.slices.template.fillModifier = rgm;
    pieSeries.slices.template.strokeModifier = rgm;
    pieSeries.slices.template.strokeOpacity = 0.4;
    pieSeries.slices.template.strokeWidth = 0;
    pieSeries.slices.template.propertyFields.fill = "color";

    this.carrierChart.legend = new am4charts.Legend();
    this.carrierChart.legend.position = "right";
    this.carrierChart.legend.fontSize = '12px';
    this.carrierChart.innerRadius = am4core.percent(45);

    this.carrierChart.data = this.props.carrierStats.filter(stat => stat.carrier !== null).map(stat => ({
      ...stat,
      carrier: CARRIERS[stat.carrier],
      color: am4core.color(CARRIER_COLORS[stat.carrier]),
    }));
  };

  initCurrentDayChart = () => {
    // Create chart
    let data = this.generateHourlyData();

    this.currentDayChart = am4core.create("current-day-chart", am4charts.XYChart);
    this.currentDayChart.paddingRight = 20;
    this.currentDayChart.data = data;

    let dateAxis = this.currentDayChart.xAxes.push(new am4charts.DateAxis());
    dateAxis.baseInterval = {
      "timeUnit": "hour",
      "count": 1,
    };
    dateAxis.dateFormats.setKey("hour", "hh:mm a");
    dateAxis.tooltipDateFormat = "[font-size: 12px]hh:mm a[/]";
    dateAxis.fontSize = '12px';
    dateAxis.renderer.grid.template.stroke = am4core.color("#000000");
    dateAxis.renderer.grid.template.strokeOpacity = 0.1;
    dateAxis.renderer.grid.template.strokeWidth = 1;

    this.currentDayChart.events.on("ready", function () {
      dateAxis.zoomToDates(
        data[0].date,
        data[data.length - 1].date,
      );
    });

    let valueAxis = this.currentDayChart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;
    valueAxis.title.text = "[font-size: 12px]Messages Sent Today[/]";
    valueAxis.fontSize = '12px';
    valueAxis.renderer.grid.template.stroke = am4core.color("#000000");
    valueAxis.renderer.grid.template.strokeOpacity = 0.1;
    valueAxis.renderer.grid.template.strokeWidth = 1;

    let series = this.currentDayChart.series.push(new am4charts.ColumnSeries());
    series.dataFields.dateX = "date";
    series.dataFields.valueY = "messages";
    series.tooltipText = "[font-size: 12px]Messages: [font-size: 12px bold]{valueY}[/]";

    let columnTemplate = series.columns.template;
    columnTemplate.width = 20;
    columnTemplate.column.cornerRadiusTopLeft = 10;
    columnTemplate.column.cornerRadiusTopRight = 10;
    columnTemplate.strokeOpacity = 0;

    columnTemplate.adapter.add("fill", (fill, target) => {
      return am4core.color("#26c6da");
      // let dataItem = target.dataItem;
      // if (dataItem.valueY > 510) {
      //   return this.currentDayChart.colors.getIndex(0);
      // } else {
      //   return am4core.color("#a8b3b7");
      // }
    })

    // this.currentDayChart.cursor = new am4charts.XYCursor();
    // this.currentDayChart.cursor.lineY.opacity = 0;

    dateAxis.start = 0.8;
    dateAxis.keepSelection = true;

    let cursor = new am4charts.XYCursor();
    cursor.behavior = "panX";
    this.currentDayChart.cursor = cursor;
    cursor.lineX.disabled = true;
  };

  initDailyChart = () => {
    /* Chart code */
    // Themes begin
    am4core.useTheme(am4themes_animated);
    // Themes end

    this.dailyChart = am4core.create("daily-chart", am4charts.XYChart);

    this.dailyChart.data = this.props.dailyStats;

    // Create axes
    let dateAxis = this.dailyChart.xAxes.push(new am4charts.DateAxis());
    dateAxis.fontSize = '12px';
    dateAxis.renderer.minGridDistance = 60;
    dateAxis.tooltipDateFormat = "[font-size: 12px]MMM d[/]";
    dateAxis.renderer.grid.template.stroke = am4core.color("#000000");
    dateAxis.renderer.grid.template.strokeOpacity = 0.1;
    dateAxis.renderer.grid.template.strokeWidth = 1;

    let valueAxis = this.dailyChart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.fontSize = '12px';
    valueAxis.tooltip.fontSize = '12px';
    valueAxis.renderer.grid.template.stroke = am4core.color("#000000");
    valueAxis.renderer.grid.template.strokeOpacity = 0.1;
    valueAxis.renderer.grid.template.strokeWidth = 1;

    // Create series
    let series = this.dailyChart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = "messages";
    series.dataFields.dateX = "date";
    series.stroke = am4core.color('#03a9f3');

    series.tooltip.pointerOrientation = "vertical";

    this.dailyChart.cursor = new am4charts.XYCursor();
    this.dailyChart.cursor.snapToSeries = series;
    this.dailyChart.cursor.xAxis = dateAxis;

    this.dailyChart.scrollbarX = new am4core.Scrollbar();
  };

  componentDidMount() {
    this.initCharts();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loading && !this.props.loading) {
      this.initCharts();
    }
  }

  render() {
    const { filtersProps, generalStats, loading } = this.props;

    return (
      <Grid container>
        <Loading show={loading} />
        <Filters filtersProps={filtersProps} />
        {
          !loading &&
          <React.Fragment>
            {
              !!generalStats.length && generalStats.map((stat, key) => (
                <Grid item key={key} {...stat.size}>
                  <GeneralStat {...stat} />
                </Grid>
              ))
            }
            <ChartGrid item xs={12} id="daily-chart" />
            <ChartGrid item xs={12} md={8} id="current-day-chart" />
            <ChartGrid item xs={12} md={4} id="carrier-chart" />
          </React.Fragment>
        }
        </Grid>
    );
  }
}

Dashboard.defaultProps = {
  filtersProps: {},
  generalStats: [],
  loading: false,
};

export default Dashboard;

import React from 'react';
import Loading from '../Loading';
import { FORMS_SUCCESS_MODALS } from './config';

export default ({ children, loading, type, ...props }) => {
  const SuccessModal = FORMS_SUCCESS_MODALS[type];
  return (
    <div>
      <SuccessModal {...props} />
      <Loading show={loading} />
      {children}
    </div>
  );
};

import React from 'react';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import AddCreditsForm from '../../../../Components/Forms/Admin/Accounts/AddCredits';
import ModalsWrappedForm from '../../../../Components/Forms/ModalsWrappedForm';
import trans from '../../../../Translations/translation.service';
import { actions as accountsActions } from '../../../../Redux/Api/Accounts';
import { actions as allAccountsActions } from '../../../../Redux/Api/AllAccounts';
import { actions as allBanksActions } from '../../../../Redux/Api/AllBanks';
import { actions as allIntegratorsActions } from '../../../../Redux/Api/AllIntegrators';
import { actions as paymentsActions, types as paymentTypes } from '../../../../Redux/Api/Payments';
import { actions as subscriptionsActions, types as subscriptionTypes } from '../../../../Redux/Api/Subscriptions';
import { actions as fileUploadActions } from '../../../../Redux/Ducks/FileUpload';
import { PRIVATE_PATHS } from '../../../config';
import { MALE_CODE } from '../../../../Constants';
import {
  ADD_ACTIONS,
  ADD_CREDITS_FORM as form,
} from '../../../../Components/Forms/Admin/Accounts/config';

class AddCredits extends React.Component {
  state = {
    createdId: null,
  };

  addCredits = data => {
    const { account } = this.props;
    const { credits, integrator_id } = data;

    const subscription = account.subscriptions.find(subscription => subscription.integrator_id === integrator_id);

    subscription.current_credits += parseInt(credits);
    subscription.action = 'add';

    this.props.updateSubscription(subscription);
  };

  fetchAccount = accountId => {
    this.props.getAccount(accountId);
  };

  filterAccountIntegrators = ({ value }) => {
    const { account } = this.props;

    return account
      && Array.isArray(account.subscriptions)
      && account.subscriptions.some(
        ({ integrator_id }) => integrator_id === value,
      );
  };

  loading = () => {
    const {
      isCreatingPayments,
      isFetchingAccount,
      isFetchingAccounts,
      isFetchingBanks,
      isFetchingIntegrators,
      isUpdatingSubscription,
      isUpdatingPayments,
      isUploadingFile,
    } = this.props;
    return isCreatingPayments
      || isFetchingAccount
      || isFetchingAccounts
      || isFetchingBanks
      || isFetchingIntegrators
      || isUpdatingSubscription
      || isUpdatingPayments
      || isUploadingFile;
  };

  openSuccessModal = () => {
    const { subscriptionUpdateSuccess, fileUploadSuccess, success } = this.props;
    return subscriptionUpdateSuccess || success || fileUploadSuccess;
  };

  parseAccounts = () => (
    this.props.accounts.map(({ id: value, company_name: text }) => ({
      text, value,
    }))
  );

  parseBanks = () => (
    this.props.banks.map(({ id: value, name: text }) => ({
      text, value,
    }))
  );

  parseIntegrators = () => (
    this.props.integrators.map(({ id: value, name: text }) => ({
      text, value,
    }))
  );

  setCurrentCredits = integratorId => {
    const { account } = this.props;

    const subscription = account
      && Array.isArray(account.subscriptions)
      && !account.subscriptions.some(sub => typeof sub !== 'object' || !sub)
      && account.subscriptions.find(sub => sub.integrator_id === integratorId);

    this.props.change(
      form,
      'current_credits',
      subscription && subscription.current_credits - subscription.blocked_credits,
    );
  };

  componentDidMount() {
    this.props.fetchAllAccounts();
    this.props.fetchAllBanks();
    this.props.fetchAllIntegrators();
    this.props.uploadFileReset();
  }

  render() {
    const { history } = this.props;
    const accounts = this.parseAccounts();
    const banks = this.parseBanks();
    const integrators = this.parseIntegrators();
    const subject = trans('resources.plural.credits');

    return (
      <ModalsWrappedForm
        gender={MALE_CODE}
        history={history}
        loading={this.loading()}
        open={this.openSuccessModal()}
        path={PRIVATE_PATHS.adminAccounts.path}
        subject={subject}
        successMessage="successOpPlurM"
        type="add"
      >
        <AddCreditsForm
          actions={ADD_ACTIONS}
          afterChange={{
            account_id: this.fetchAccount,
            integrator_id: this.setCurrentCredits,
          }}
          data={{
            account_id: accounts,
            bank_id: banks,
            integrator_id: integrators.filter(this.filterAccountIntegrators),
          }}
          history={history}
          onSubmit={this.addCredits}
          path={PRIVATE_PATHS.adminAccounts.path}
        />
      </ModalsWrappedForm>
    );
  }
}

const mapStateToProps = ({
  accounts: {
    item: account,
    isFetchingItem: isFetchingAccount,
  },
  allAccounts: {
    isFetching: isFetchingAccounts,
    items: accounts,
  },
  allBanks: {
    isFetching: isFetchingBanks,
    items: banks,
  },
  allIntegrators: {
    isFetching: isFetchingIntegrators,
    items: integrators,
  },
  fileUpload: { pending, response, success: fileUploadSuccess },
  payments: { isCreating, isUpdating },
  reduxRestResponse: { body, success, type },
  subscriptions: {
    isUpdating: isUpdatingSubscription,
  },
  ...state
}) => ({
  body,
  isFetchingAccount,
  isFetchingAccounts,
  isFetchingBanks,
  isFetchingIntegrators,
  response,
  fileUploadSuccess,
  account: account ? account.data : {},
  accounts: accounts[1] ? accounts[1] : [],
  banks: banks[1] ? banks[1] : [],
  integrators: integrators[1] ? integrators[1] : [],
  isCreatingPayments: isCreating,
  isUpdatingSubscription: isUpdatingSubscription,
  isUpdatingPayments: isUpdating,
  isUploadingFile: pending,
  subscriptionUpdateSuccess: (
    type === subscriptionTypes.UPDATE_SUBSCRIPTION
  ) && success,
  success: (
    type === paymentTypes.CREATE_PAYMENT || type === paymentTypes.UPDATE_PAYMENT
  ) && success,
});

export default connect(
  mapStateToProps,
  {
    change,
    ...accountsActions,
    ...allAccountsActions,
    ...allBanksActions,
    ...allIntegratorsActions,
    ...fileUploadActions,
    ...paymentsActions,
    ...subscriptionsActions,
  }
)(AddCredits);

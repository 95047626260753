import React from 'react';
import { connect } from 'react-redux';
import Table from '../../../Components/Table';
import trans from '../../../Translations/translation.service';
import { actions as usersActions } from '../../../Redux/Api/Users';
import {
  API_STATUSES,
  DEFAULT_TABLE_ACTIONS,
  SUCCESS_CODE,
  USERS_TABLE_TITLES,
} from '../../../Constants';
import { PRIVATE_PATHS } from '../../config';
import { getPage } from '../../../Helpers';

class ClientUsers extends React.Component {
  deleteUser = id => (
    this.props.deleteUser(id)
      .then(({ code }) => code === SUCCESS_CODE && this.fetchUsers())
  );

  fetchUsers = newPage => {
    const { location } = this.props;
    const page = getPage(newPage, location);

    this.props.fetchUsers({}, { query: { page, client: true } });
  };

  parseUserData = ({
    username,
    status,
  }) => [
    {
      text: username,
    },
    {
      text: trans(`default.statuses.${API_STATUSES[status]}`),
    },
  ];

  componentDidMount() {
    this.fetchUsers();
  }

  render() {
    const { users, history, isFetchingUsers, pagination } = this.props;
    const type = trans('resources.singular.user');

    return (
      <Table
        actions={DEFAULT_TABLE_ACTIONS}
        actionButtonNames={{
          delete: ({ username }) => username,
        }}
        data={users}
        dataParser={this.parseUserData}
        deleteWarningMessage="deleteOpSingM"
        editPath={PRIVATE_PATHS.clientEditUser.noParamsPath}
        handleChangePage={this.fetchUsers}
        history={history}
        loading={isFetchingUsers}
        onDeleteAcceptClick={this.deleteUser}
        pagination={pagination}
        titles={USERS_TABLE_TITLES}
        type={type}
      />
    );
  }
}

const mapStateToProps = ({
  users: {
    isFetching,
    items: [success, data, pagination],
  },
}) => ({
  pagination,
  success,
  users: success ? data : [],
  isFetchingUsers: isFetching,
});

export default connect(
  mapStateToProps,
  {
    ...usersActions,
  }
)(ClientUsers);

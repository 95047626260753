import Lock from '@material-ui/icons/Lock';
import Person from '@material-ui/icons/Person';
import trans from '../../../Translations/translation.service';

export const ACTIONS = [
  {
    color: 'primary',
    label: trans('login.form.actions.signIn'),
    type: 'submit',
  },
];

export const DEFAULT_VALUES = {
  username: '',
  password: '',
};

export const INPUTS = [
  {
    id: 'username',
    icon: Person,
    label: trans('login.form.fields.username'),
    name: 'username',
    required: true,
    sizes: {
      xs: 12,
    },
  },
  {
    id: 'password',
    icon: Lock,
    label: trans('login.form.fields.password'),
    name: 'password',
    required: true,
    sizes: {
      xs: 12,
    },
    type: 'password',
  },
];

export const LOGIN_FORM = 'login';

export const LOGIN_VALIDATIONS = [
  {
    field: 'username',
    message: ['default.forms.validations.isRequired'],
    method: 'isEmpty',
    validWhen: false,
  },
  {
    field: 'password',
    message: ['default.forms.validations.isRequired'],
    method: 'isEmpty',
    validWhen: false,
  },
];

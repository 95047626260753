import React from 'react';
import MUIButton from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { MOBILE_SIZE, TABLET_SIZE } from '../../Redux/Ducks/WindowResize/config';

const Button = ({ label, size, type, valid, ...buttonProps }) => (
  <MUIButton
    disabled={buttonProps.disabled || (type === 'submit' && !valid)}
    size={size === MOBILE_SIZE || size === TABLET_SIZE ? 'small' : 'medium'}
    type={type}
    variant="contained"
    {...buttonProps}
  >
    {label}
  </MUIButton>
);

const mapStateToProps = ({
  windowResize: { size },
}) => ({
  size,
});

export default connect(mapStateToProps)(Button);

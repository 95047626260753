import styled from 'styled-components';

export default styled.button`
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer;
    flex-shrink: 0;
    line-height: 0;
    margin: 0;
    padding: 0;
`;

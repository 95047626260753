/** Menu component messages (ES)  */
export default {
  options: {
    titles: {
      activities: 'Actividades',
      activitiesList: 'Actividades',
      addPayment: 'Agregar Pago',
      addUser: 'Agregar Usuario',
      deleteAll: 'Eliminar Todo',
      export: 'Exportar',
      keywordsList: 'Palabras Clave',
      receivedMessagesList: 'Mensajes Recibidos',
      scheduledCampaigns: 'Campañas Programadas',
      sentCampaigns: 'Campañas Enviadas',
      sentMessages: 'Mensajes Enviados',
      sentRepliesList: 'Respuestas Enviadas',
      shortCodes: 'Números Cortos',
      shortCodesDashboard: 'Dashboard',
      shortCodesList: 'Números Cortos',
      tableView: 'Vista de Tabla',
      users: 'Usuarios',
      usersList: 'Usuarios',
      admin: {
        accounts: 'Cuentas',
        accountsList: 'Cuentas',
        addAccount: 'Agregar Cuenta',
        addBanks: 'Agregar Bancos',
        addBusinessTypes: 'Agregar Tipos de Negocio',
        addCredits: 'Agregar Créditos',
        addKeyword: 'Agregar Palabra Clave',
        addIndustries: 'Agregar Industrias',
        addNew: 'Agregar Noticia',
        addShortCodes: 'Agregar Números Cortos',
        banks: 'Bancos',
        banksList: 'Bancos',
        businessTypesList: 'Tipos de Negocio',
        campaigns: 'Campañas',
        companies: 'Empresas',
        industries: 'Industrias',
        industriesList: 'Industrias',
        integrators: 'Integradoras',
        integratorsList: 'Integradoras',
        messages: 'Mensajes',
        news: 'Noticias',
        newsList: 'Noticias',
        payments: 'Pagos',
        paymentsList: 'Pagos',
        reportsSMS: 'Reportes SMS',
        reportsShortCodes: 'Reportes Números Cortos',
      },
      client: {
        addCampaign: 'Agregar Campaña',
        addGroups: 'Agregar Grupos',
        campaigns: 'Campañas',
        campaignsList: 'Campañas',
        changeLogo: 'Cambiar Logo',
        changeTheme: 'Cambiar Tema',
        contacts: 'Contactos',
        contactsList: 'Contactos',
        groups: 'Grupos',
        groupsList: 'Grupos',
        messages: 'Mensajes',
        myAccount: 'Mi Cuenta',
        myAccountInfo: 'Mi Cuenta',
        myPayments: 'Mis Pagos',
        newMessage: 'Mensaje Nuevo',
        payments: 'Pagos',
        settings: 'Ajustes',
      },
    },
  },
};

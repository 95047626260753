import React from 'react';
import Logo from '../Logo';

export default (props) => (
  <Logo
    src="img/logos/infomail/small-white.svg"
    type="small-white-infomail-logo"
    {...props}
  />
);

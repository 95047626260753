import {
  OPTIONS,
  RESTRICTED_ROLES,
} from '../Constants';

export const getUserOptions = (role, permissions, hasShortCodes) => {
  let permissionsAreValid = true;
  const options = hasShortCodes ? OPTIONS[3] : OPTIONS[role];

  if (
    !Array.isArray(permissions)
    || permissions.some(permission => !permission || typeof permission !== 'object')
  ) {
    permissionsAreValid = false;
  }

  if (!RESTRICTED_ROLES.includes(role) || !permissionsAreValid) {
    return options;
  } else {
    const permissionsCodes = permissions.map(permission => permission.code);
    return options.filter(option => permissionsCodes.includes(option.code));
  }
};

import * as types from './types';
import {
	PENDING_STATUS,
	REDUX_REST_PREFIX,
	RESOLVED_STATUS,
} from './config';

const initialState = {
	body: {},
	code: 0,
	success: false,
	type: '',
};

export default (state = initialState, action) => {
	const { code, status, type } = action;
	let { body } = action;
	let success = false;

	if (type.startsWith(REDUX_REST_PREFIX)) {
		if (status === RESOLVED_STATUS) {
			success = true;
		} else if (status === PENDING_STATUS) {
			return initialState;
		}

		return {
			body,
			code,
			success,
			type,
		};
	} else {
		switch (type) {
			case types.RESET:
				return initialState;
			default:
				return state;
		}
	}
};

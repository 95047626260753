import React from 'react';
import { connect } from 'react-redux';
import AddBanksForm from '../../../../Components/Forms/Admin/Banks/AddBanks';
import ModalsWrappedForm from '../../../../Components/Forms/ModalsWrappedForm';
import trans from '../../../../Translations/translation.service';
import { actions as banksActions, types } from '../../../../Redux/Api/Banks';
import { PRIVATE_PATHS } from '../../../config';
import { MALE_CODE } from '../../../../Constants';
import { ADD_ACTIONS } from '../../../../Components/Forms/Admin/Banks/config';
import { catchApiArrayErrors } from '../../../../Helpers';

class AddBanks extends React.Component {
  createBanks = data => {
    return this.props.createManyBanks(data.banks).catch(
      catchApiArrayErrors('banks'),
    );
  };

  render() {
    const { history, success } = this.props;
    const subject = trans('resources.plural.banks');

    return (
      <ModalsWrappedForm
        gender={MALE_CODE}
        history={history}
        loading={this.props.isCreatingBanks}
        open={success}
        path={PRIVATE_PATHS.adminBanks.path}
        subject={subject}
        successMessage="successOpPlurM"
        type="add"
      >
        <AddBanksForm
          actions={ADD_ACTIONS}
          history={history}
          onSubmit={this.createBanks}
          path={PRIVATE_PATHS.adminBanks.path}
        />
      </ModalsWrappedForm>
    );
  }
}

const mapStateToProps = ({
  banks: { isCreatingMany },
  reduxRestResponse: { body, success, type },
}) => ({
  body,
  isCreatingBanks: isCreatingMany,
  success: type === types.CREATEMANY_BANKS && success,
});

export default connect(
  mapStateToProps,
  {
    ...banksActions,
  }
)(AddBanks);

import React from 'react';
import IconInputWrapper from './IconInputWrapper';
import InputGrid from './Styled/InputGrid';
import { DEFAULT_ICON_ALIGNMENT, DEFAULT_INPUT_TYPE, INPUT_TYPES_COMPONENTS } from './config';

export default ({
  propsByValues,
  icon,
  iconHandleClick,
  input,
  removeLabel,
  sizes,
  values,
  meta: { dirty, error, touched },
  clickableIcon = false,
  iconAlign = DEFAULT_ICON_ALIGNMENT,
  padding = false,
  topBorder = false,
  type = DEFAULT_INPUT_TYPE,
  ...inputProps
}) => {
  if (padding) {
    return <InputGrid item {...sizes}/>;
  }

  const errorMessage = (dirty || touched) && error;
  const showError = !!errorMessage;
  const InputComponent = INPUT_TYPES_COMPONENTS[type];
  const extraProps = typeof propsByValues === 'function' ? propsByValues(values) : {};

  const field = (
    <InputComponent
      error={errorMessage}
      showError={showError}
      type={type}
      {...input}
      {...inputProps}
      {...extraProps}
    />
  );
  const iconWrapperProps = {
    clickableIcon,
    field,
    icon,
    iconAlign,
    iconHandleClick,
    removeLabel,
    error: showError,
  };

  return (
    <InputGrid item topBorder={topBorder} {...sizes}>
      {
        icon
        ? <IconInputWrapper {...iconWrapperProps} />
        : field
      }
    </InputGrid>
  );
};

import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';

export default withStyles(theme => ({
  root: {
    display: 'flex',
    height: 16,
    padding: 0,
    width: 28,
  },
  switchBase: {
    color: theme.palette.grey[500],
    padding: 2,
    '&$checked': {
      color: theme.palette.common.white,
      transform: 'translateX(12px)',
      '& + $track': {
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        opacity: 1,
      },
    },
  },
  thumb: {
    boxShadow: 'none',
    height: 12,
    width: 12,
  },
  track: {
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    boxSizing: 'border-box',
    opacity: 1,
  },
  checked: {},
}))(Switch);

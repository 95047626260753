import styled from 'styled-components';

export default styled.div`
  a, div {
    align-items: center;
    color: white;
    cursor: pointer;
    display: flex;
    font-size: 12px;
    margin-left: 10px;
    text-decoration: none;
  }
`;

import * as types from './types';
import { updateTokenForHeaders } from '../../../Helpers';

const defaultState = {
  authenticated: false,
  expiresIn: 0,
  user: {},
  token: '',
};

const reducer = (state = defaultState, action = {}) => {
  const { payload, type } = action;

  switch (type) {
    case types.LOGIN:
      const { token } = payload;
      updateTokenForHeaders(token);
      return { ...state, ...payload, authenticated: true };

    case types.LOGOUT:
      return { ...defaultState };

    case types.SET_USER:
      const { user } = action;

      return { ...state, user };

    default: return state;
  }
};

// Reducer
export default reducer;

export default () => {
  const availableLocales = ['en', 'es'];
  let locales = window.navigator.languages.filter((lang) => (availableLocales.includes(lang))) || ['es'];
  const localStorageLocale = localStorage.getItem('locale');
  if (localStorageLocale) locales.unshift(localStorageLocale);
  const currentLocale = (locales.length > 0) ? locales[0] : 'es';
  return {
    availableLocales,
    locale: currentLocale,
  };
};

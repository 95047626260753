import { createResource } from 'redux-rest-resource';
import { HEADERS as headers, MESSAGES_URL } from '../../Constants';
import { transformFetchResponse as transformResponse } from '../../Helpers';

export const { types, actions, reducers } = createResource({
  name: 'message',
  url: `${MESSAGES_URL}/:id`,
  headers,
  actions: {
    fetch: {
      transformResponse,
    },
  },
});

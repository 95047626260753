import React from 'react';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';
import ResponsiveForm from '../../Form';
import { validate } from '../../../../Helpers';
import {
  DEFAULT_VALUES,
  CAMPAIGN_FORM as form,
  CAMPAIGN_CONTACTS_FILE_VALIDATIONS,
  CAMPAIGN_VALIDATIONS,
  INPUTS,
} from './config';

let AddCampaignForm = ({ formData, handleSubmit, loading = false, ...props }) => (
  <ResponsiveForm
    {...props}
    inputs={INPUTS}
    loading={loading}
    onSubmit={handleSubmit}
    values={formData}
  />
);

AddCampaignForm = reduxForm({
  form,
  validate: validate([
    ...CAMPAIGN_VALIDATIONS,
    ...CAMPAIGN_CONTACTS_FILE_VALIDATIONS,
  ]),
})(AddCampaignForm);

const selector = formValueSelector(form);

const mapStateToProps = state => ({
  formData: selector(state, 'contacts_src_file', 'file'),
  initialValues: { ...DEFAULT_VALUES },
});

export default connect(
  mapStateToProps
)(AddCampaignForm);

import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanel from './Styled/ExpansionPanel';
import ExpansionPanelDetails from './Styled/ExpansionPanelDetails';
import ExpansionPanelSummary from './Styled/ExpansionPanelSummary';
import Option from './Option';

export default ({
  canExpand,
  children,
  expanded,
  handleChange,
  option,
  selected,
}) => (
  <ExpansionPanel
    expanded={canExpand && expanded === option.name}
    onChange={handleChange(option.name)}
  >
    <ExpansionPanelSummary
      expandIcon={<ExpandMoreIcon />}
      IconButtonProps={{ selected }}
      selected={selected}
    >
      {children}
    </ExpansionPanelSummary>
    <ExpansionPanelDetails>
      {option.subMenu.map((subOption, key) => (
        <Option className="suboption" key={key} option={subOption} />
      ))}
    </ExpansionPanelDetails>
  </ExpansionPanel>
);

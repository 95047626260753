import { withStyles } from '@material-ui/core/styles';
import Warning from '@material-ui/icons/Warning';
import { MODAL_ICON_STYLE } from '../config';
import { COLORS } from '../../../Constants';

export default withStyles({
  root: {
    color: COLORS.yellow,
    ...MODAL_ICON_STYLE,
  },
})(Warning);

import React from 'react';
import { connect } from 'react-redux';
import Table from '../../../Components/Table';
import trans from '../../../Translations/translation.service';
import { actions as groupsActions } from '../../../Redux/Api/Groups';
import {
  GROUPS_TABLE_TITLES,
  DEFAULT_TABLE_ACTIONS,
  SUCCESS_CODE,
} from '../../../Constants';
import { PRIVATE_PATHS } from '../../config';
import { getPage } from '../../../Helpers';

class ClientGroups extends React.Component {
  deleteGroup = id => (
    this.props.deleteGroup(id)
      .then(({ code }) => code === SUCCESS_CODE && this.fetchGroups())
  );

  fetchGroups = newPage => {
    const { location } = this.props;
    const page = getPage(newPage, location);

    this.props.fetchGroups({}, { query: { page, client: true } });
  };

  getActions = row => {
    const { campaigns } = row;
    const removeActions = [];

    if (Array.isArray(campaigns) && campaigns.length > 0) {
      removeActions.push('delete');
    }

    return DEFAULT_TABLE_ACTIONS.filter(action => removeActions.indexOf(action) === -1);
  };

  parseGroupData = ({
    name,
  }) => [
    {
      text: name,
    },
  ];

  componentDidMount() {
    this.fetchGroups();
  }

  render() {
    const {
      groups,
      history,
      isDeletingGroup,
      isFetchingGroups,
      pagination,
    } = this.props;
    const type = trans('resources.singular.group');

    return (
      <Table
        actions={this.getActions}
        actionButtonNames={{
          delete: ({ name }) => name,
        }}
        data={groups}
        dataParser={this.parseGroupData}
        deleteWarningMessage="deleteOpSingM"
        editPath={PRIVATE_PATHS.clientEditGroup.noParamsPath}
        handleChangePage={this.fetchGroups}
        history={history}
        loading={isDeletingGroup || isFetchingGroups}
        onDeleteAcceptClick={this.deleteGroup}
        pagination={pagination}
        titles={GROUPS_TABLE_TITLES}
        type={type}
      />
    );
  }
}

const mapStateToProps = ({
  groups: {
    isDeleting,
    isFetching,
    items: [success, data, pagination],
  },
}) => ({
  pagination,
  success,
  groups: success ? data : [],
  isDeletingGroup: isDeleting,
  isFetchingGroups: isFetching,
});

export default connect(
  mapStateToProps,
  {
    ...groupsActions,
  }
)(ClientGroups);

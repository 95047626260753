import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import Dashboard from '../../Components/Dashboard';
import ReceivedMessages from '../Tables/Admin/ReceivedMessages';
import Title from '../../Components/Layouts/Default/Header/Styled/Title';
import trans from '../../Translations/translation.service';
import { actions as allAccountsActions } from '../../Redux/Api/AllAccounts';
import { actions as allKeywordsActions } from '../../Redux/Api/AllKeywords';
import { actions as allShortCodesActions } from '../../Redux/Api/AllShortCodes';
import { actions as messagesShortCodeStatsActions } from '../../Redux/Api/MessagesShortCodeStats';
import { actions as navBarActions } from '../../Redux/Ducks/Navbar';
import {
  ADMIN_SHORT_CODES_STATS_FILTERS_INPUTS,
} from './config';
import { FILTERS_FORM as form } from '../../Components/Filters/config';
import {
  ALL_SELECT_OPTION_F,
  ALL_SELECT_OPTION_M,
  ALL_SELECT_OPTION_VALUE as ALL,
  API_DATE_FORMAT,
  API_DATE_FORMAT_FIRST,
} from '../../Constants';
import { getCarriersGeneralStats } from '../../Helpers';
import { PRIVATE_PATHS } from '../config';

class AdminShortCodesDashboard extends React.Component {
  state = {
    account: ALL,
    shortCode: ALL,
    filters: {},
  };

  initFilters = () => {
    const today = moment();
    this.props.reset(form);
    this.props.change(form, 'from_date', today.format(API_DATE_FORMAT_FIRST));
    this.props.change(form, 'to_date', today.format(API_DATE_FORMAT));
    this.props.change(form, 'account_id', ALL);
    this.props.change(form, 'keyword_id', ALL);
    this.props.change(form, 'short_code_id', ALL);
    this.props.change(form, 'carrier', ALL);
  };

  componentDidMount() {
    this.props.fetchAllAccounts();
    this.fetchAllKeywords();
    this.fetchAllShortCodes();
    this.fetchMessagesShortCodeStats();
    this.initFilters();
    this.props.setOnSearch(this.handleSearch);
  }

  fetchAllKeywords = () => {
    const { account, shortCode } = this.state;

    if (account === ALL && shortCode === ALL) {
      this.props.fetchAllKeywords();
    } else if (account === ALL) {
      this.props.fetchAllKeywords({}, { query: { short_code: shortCode } });
    } else if (shortCode === ALL) {
      this.props.fetchAllKeywords({}, { query: { account } });
    } else {
      this.props.fetchAllKeywords({}, { query: { account, short_code: shortCode } });
    }
  };

  fetchAllShortCodes = () => {
    const { account } = this.state;

    if (account === ALL) {
      this.props.fetchAllShortCodes();
    } else {
      this.props.fetchAllShortCodes({}, { query: { account } });
    }
  };

  fetchAllKeywordsFromAccount = account => {
    const { filters } = this.state;

    if (account === ALL) {
      delete filters.account;
    } else {
      filters.account =  account;
    }

    delete filters.keyword_id;
    delete filters.short_code;
    this.props.change(form, 'keyword_id', ALL);
    this.props.change(form, 'short_code_id', ALL);

    this.setState({ account, filters },  () => {
      this.fetchAllKeywords();
      this.fetchAllShortCodes();
      this.fetchMessagesShortCodeStats();
    });
  };

  fetchAllKeywordsFromShortCode = shortCode => {
    const { filters } = this.state;

    if (shortCode === ALL) {
      delete filters.short_code;
    } else {
      filters.short_code =  shortCode;
    }

    delete filters.keyword_id;
    this.props.change(form, 'keyword_id', ALL);

    this.setState({ filters, shortCode }, () => {
      this.fetchAllKeywords();
      this.fetchMessagesShortCodeStats();
    });
  };

  fetchMessagesShortCodeStats = () => {
    const { filters } = this.state;

    this.props.fetchMessagesShortCodeStats({}, { query: filters });
  };

  fetchMessagesShortCodeStatsFromCarrier = carrier => {
    const { filters } = this.state;

    if (carrier === ALL) {
      delete filters.carrier;
    } else {
      filters.carrier =  carrier;
    }

    this.setState({ filters }, () => {
      this.fetchMessagesShortCodeStats();
    });
  };

  fetchMessagesShortCodeStatsFromDateFrom = date => {
    const { filters } = this.state;

    const momentDate = moment(date, API_DATE_FORMAT);

    if (momentDate._isValid) {
      filters.from =  date;
    } else {
      delete filters.from;
    }

    this.setState({ filters }, () => {
      this.fetchMessagesShortCodeStats();
    });
  };

  fetchMessagesShortCodeStatsFromDateTo = date => {
    const { filters } = this.state;

    const momentDate = moment(date, API_DATE_FORMAT);

    if (momentDate._isValid) {
      filters.to =  date;
    } else {
      delete filters.to;
    }

    this.setState({ filters }, () => {
      this.fetchMessagesShortCodeStats();
    });
  };

  fetchMessagesShortCodeStatsFromKeyword = keyword => {
    const { filters } = this.state;

    if (keyword === ALL) {
      delete filters.keyword_id;
    } else {
      filters.keyword_id =  keyword;
    }

    this.setState({ filters }, () => {
      this.fetchMessagesShortCodeStats();
    });
  };

  handleSearch = (search)  => {
    const { filters } = this.state;

    filters.search = search;

    this.setState({ filters }, () => {
      this.fetchMessagesShortCodeStats();
    });
  };

  loading = () => {
    const {
      isFetching,
      isFetchingAccount,
      isFetchingKeywords,
      isFetchingShortCodes,
    } = this.props;
    return isFetching
      || isFetchingAccount
      || isFetchingKeywords
      || isFetchingShortCodes;
  };

  parseAccounts = () => {
    const accounts = this.props.accounts.map(({ id: value, company_name: text }) => ({
      text, value,
    }));

    accounts.unshift(ALL_SELECT_OPTION_F);

    return accounts;
  };

  parseKeywords = () => {
    const keywords = this.props.keywords.map(({ id: value, word: text }) => ({
      text, value,
    }));

    keywords.unshift(ALL_SELECT_OPTION_F);

    return keywords;
  };

  parseShortCodes = () => {
    const shortCodes = this.props.shortCodes.map(({ id: value, code: text }) => ({
      text, value,
    }));

    shortCodes.unshift(ALL_SELECT_OPTION_M);

    return shortCodes;
  };

  render() {
    const messagesPath = PRIVATE_PATHS.adminReceivedMessages.path;
    const repliesPath = PRIVATE_PATHS.adminSentReplies.path;
    const { stats } = this.props;
    const [dailyStats, hourlyStats, carrierStats, generalStats] = stats;
    const accounts = this.parseAccounts();
    const keywords = this.parseKeywords();
    const shortCodes = this.parseShortCodes();
    const carrierGeneralStats = getCarriersGeneralStats(carrierStats, messagesPath);

    return (
      <>
        <Dashboard
          filtersProps={{
            afterChange: {
              account_id: this.fetchAllKeywordsFromAccount,
              from_date: this.fetchMessagesShortCodeStatsFromDateFrom,
              to_date: this.fetchMessagesShortCodeStatsFromDateTo,
              short_code_id: this.fetchAllKeywordsFromShortCode,
              keyword_id: this.fetchMessagesShortCodeStatsFromKeyword,
              carrier: this.fetchMessagesShortCodeStatsFromCarrier,
            },
            data: {
              account_id: accounts,
              keyword_id: keywords,
              short_code_id: shortCodes,
            },
            inputs: ADMIN_SHORT_CODES_STATS_FILTERS_INPUTS,
            loading: this.loading(),
          }}
          dailyStats={dailyStats}
          hourlyStats={hourlyStats}
          carrierStats={carrierStats}
          generalStats={generalStats && [
            {
              link: messagesPath,
              name: trans('default.stats.messages.received_messages'),
              value: generalStats.received_messages.received_messages,
              size: {
                xs: 12,
                lg: 3,
              },
            },
            {
              link: repliesPath,
              name: trans('default.stats.messages.sent_replies'),
              value: generalStats.sent_replies.sent_replies,
              size: {
                xs: 12,
                lg: 3,
              },
            },
            ...carrierGeneralStats,
          ]}
          loading={this.loading()}
        />
        <Title>{trans('default.titles.lastReceivedMessages')}</Title>
        <ReceivedMessages onDashboard order="created" showFilters={false} showPagination={false} sort="DESC" />
      </>
    );
  }
}

const mapStateToProps = ({
  allAccounts: {
    isFetching: isFetchingAccounts,
    items: accounts,
  },
  allKeywords: {
    isFetching: isFetchingKeywords,
    items: keywords,
  },
  allShortCodes: {
    isFetching: isFetchingShortCodes,
    items: shortCodes,
  },
  messagesShortCodeStats: {
    isFetching,
    items,
  },
}) => ({
  isFetching,
  isFetchingAccounts,
  isFetchingKeywords,
  isFetchingShortCodes,
  accounts: accounts[1] ? accounts[1] : [],
  keywords: keywords[1] ? keywords[1] : [],
  shortCodes: shortCodes[1] ? shortCodes[1] : [],
  stats: items,
});

export default connect(
  mapStateToProps,
  {
    change,
    reset,
    ...allAccountsActions,
    ...allKeywordsActions,
    ...allShortCodesActions,
    ...messagesShortCodeStatsActions,
    ...navBarActions,
  }
)(AdminShortCodesDashboard);

import React from 'react';
import { connect } from 'react-redux';
import Table from '../../../Components/Table';
import trans from '../../../Translations/translation.service';
import { actions as shortCodesActions } from '../../../Redux/Api/ShortCodes';
import {
  SHORT_CODES_TABLE_TITLES,
  DEFAULT_TABLE_ACTIONS,
  SUCCESS_CODE,
} from '../../../Constants';
import { PRIVATE_PATHS } from '../../config';
import { getPage } from '../../../Helpers';

class AdminShortCodes extends React.Component {
  deleteShortCode = id => (
    this.props.deleteShortCode(id)
      .then(({ code }) => code === SUCCESS_CODE && this.fetchShortCodes())
  );

  fetchShortCodes = newPage => {
    const { location } = this.props;
    const page = getPage(newPage, location);

    this.props.fetchShortCodes({}, { query: { page } });
  };

  parseShortCodeData = ({
    code,
  }) => [
    {
      text: code,
    },
  ];

  componentDidMount() {
    this.fetchShortCodes();
  }

  render() {
    const { history, isDeletingShortCode, isFetchingShortCodes, pagination, shortCodes } = this.props;
    const type = trans('resources.singular.shortCode');

    return (
      <Table
        actions={DEFAULT_TABLE_ACTIONS}
        actionButtonNames={{
          delete: ({ code }) => code,
        }}
        data={shortCodes}
        dataParser={this.parseShortCodeData}
        deleteWarningMessage="deleteOpSingM"
        editPath={PRIVATE_PATHS.adminEditShortCode.noParamsPath}
        handleChangePage={this.fetchShortCodes}
        history={history}
        loading={isDeletingShortCode || isFetchingShortCodes}
        onDeleteAcceptClick={this.deleteShortCode}
        pagination={pagination}
        titles={SHORT_CODES_TABLE_TITLES}
        type={type}
      />
    );
  }
}

const mapStateToProps = ({
  shortCodes: {
    isDeleting,
    isFetching,
    items: [success, data, pagination],
  },
}) => ({
  pagination,
  success,
  shortCodes: success ? data : [],
  isDeletingShortCode: isDeleting,
  isFetchingShortCodes: isFetching,
});

export default connect(
  mapStateToProps,
  {
    ...shortCodesActions,
  }
)(AdminShortCodes);

import React from 'react';
import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import moment from 'moment';
import Table from '../../../Components/Table';
import { actions as accountsActions } from '../../../Redux/Api/Accounts';
import {
  ADMIN_REPORTS_SMS_TABLE_TITLES,
  API_DATE_FORMAT,
  API_DATE_FORMAT_FIRST,
} from '../../../Constants';
import {
  ADMIN_REPORTS_FILTERS_INPUTS,
} from './config';
import { FILTERS_FORM as form } from '../../../Components/Filters/config';
import { getPage } from '../../../Helpers';

class AdminReportsSMS extends React.Component {
  state = {
    filters: {
      from: moment().format(API_DATE_FORMAT_FIRST),
    },
  };

  fetchAccounts = newPage => {
    const { filters } = this.state;
    const { location } = this.props;
    const page = getPage(newPage, location);

    this.props.fetchAccounts({}, {
      query: { page, ...filters, reports: 'sms' },
    });
  };

  fetchAccountsFromDateFrom = date => {
    const { filters } = this.state;

    const momentDate = moment(date, API_DATE_FORMAT);

    if (momentDate._isValid) {
      filters.from =  date;
    } else {
      delete filters.from;
    }

    this.setState({ filters }, () => {
      this.fetchAccounts();
    });
  };

  fetchAccountsFromDateTo = date => {
    const { filters } = this.state;

    const momentDate = moment(date, API_DATE_FORMAT);

    if (momentDate._isValid) {
      filters.to =  date;
    } else {
      delete filters.to;
    }

    this.setState({ filters }, () => {
      this.fetchAccounts();
    });
  };

  initFilters = () => {
    const today = moment();
    this.props.reset(form);
    this.props.change(form, 'from_date', today.format(API_DATE_FORMAT_FIRST));
    this.props.change(form, 'to_date', today.format(API_DATE_FORMAT));
  };

  loading = () => {
    const {
      isFetchingAccounts,
    } = this.props;

    return isFetchingAccounts;
  };

  parseAccountData = account => {
    if (!account.Integrators) {
      return [];
    }

    const {
      company_name,
      Integrators: { name: integrator },
      message_count,
      movistar_count,
      digitel_count,
      movilnet_count,
    } = account;

    return [
      {
        text: company_name,
      },
      {
        text: integrator,
      },
      {
        text: movistar_count,
      },
      {
        text: digitel_count,
      },
      {
        text: movilnet_count,
      },
      {
        text: message_count,
      },
    ];
  };

  componentDidMount() {
    this.fetchAccounts();
    this.initFilters();
  }

  render() {
    const { accounts, pagination } = this.props;

    return (
      <Table
        data={accounts}
        dataParser={this.parseAccountData}
        filtersProps={{
          afterChange: {
            from_date: this.fetchAccountsFromDateFrom,
            to_date: this.fetchAccountsFromDateTo,
          },
          inputs: ADMIN_REPORTS_FILTERS_INPUTS,
          loading: this.loading(),
        }}
        handleChangePage={this.fetchAccounts}
        loading={this.loading()}
        pagination={pagination}
        titles={ADMIN_REPORTS_SMS_TABLE_TITLES}
      />
    );
  }
}

const mapStateToProps = ({
  accounts: {
    isFetching,
    items: [success, data, pagination],
  },
}) => ({
  pagination,
  success,
  accounts: success ? data : [],
  isFetchingAccounts: isFetching,
});

export default connect(
  mapStateToProps,
  {
    ...accountsActions,
    change,
    reset,
  }
)(AdminReportsSMS);

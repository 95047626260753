import { createResource } from 'redux-rest-resource';
import { HEADERS as headers, NEWS_URL } from '../../Constants';
import { transformFetchResponse as transformResponse } from '../../Helpers';

export const { types, actions, reducers } = createResource({
  name: 'allNew',
  url: `${NEWS_URL}/all`,
  headers,
  actions: {
    fetch: {
      transformResponse,
    },
  },
});

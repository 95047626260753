/** Entities types messages (EN)  */
export default {
  accounts: {
    corporate: 'Corporate',
    demo: 'Demo',
    individual: 'Individual',
  },
  channels: {
    none: 'None',
  },
  genders: {
    female: 'Female',
    male: 'Male',
  },
  statuses: {
    accounts: {
      active: 'Acitve',
      inactive: 'Inactive',
      suspended: 'Suspended',
    },
    users: {
      active: 'Acitve',
      inactive: 'Inactive',
    },
  },
  statusTexts: {
    accounts: {
      active: 'Acitve Account',
      inactive: 'Inactive Account',
      suspended: 'Suspended Account',
    },
  },
};

import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from './Styled/DialogContent';
import SuccessIcon from './Styled/SuccessIcon';
import trans from '../../Translations/translation.service';

export default ({
  onAcceptClick,
  acceptText = '',
  action = '',
  open = false,
  subject = '',
  successText = '',
}) => {
  return (
    <Dialog
      open={open}
      onClose={onAcceptClick}
    >
      <DialogTitle>
        {trans('modals.titles.success')}
      </DialogTitle>
      <DialogContent>
        <SuccessIcon />
        <DialogContentText>
          {successText || trans('modals.messages.success')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onAcceptClick}>
          {acceptText || trans('modals.actions.accept')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import React from 'react';
import { connect } from 'react-redux';
import AddGroupsForm from '../../../../Components/Forms/Client/Groups/AddGroups';
import ModalsWrappedForm from '../../../../Components/Forms/ModalsWrappedForm';
import trans from '../../../../Translations/translation.service';
import { actions as groupsActions, types } from '../../../../Redux/Api/Groups';
import { PRIVATE_PATHS } from '../../../config';
import { MALE_CODE } from '../../../../Constants';
import { ADD_ACTIONS } from '../../../../Components/Forms/Client/Groups/config';
import { catchApiArrayErrors } from '../../../../Helpers';

class AddGroups extends React.Component {
  createGroups = data => {
    return this.props.createManyGroups(data.groups).catch(
      catchApiArrayErrors('groups'),
    );
  };

  render() {
    const { history, success } = this.props;
    const subject = trans('resources.plural.groups');

    return (
      <ModalsWrappedForm
        gender={MALE_CODE}
        history={history}
        loading={this.props.isCreatingGroups}
        open={success}
        path={PRIVATE_PATHS.clientGroups.path}
        subject={subject}
        successMessage="successOpPlurM"
        type="add"
      >
        <AddGroupsForm
          actions={ADD_ACTIONS}
          history={history}
          onSubmit={this.createGroups}
          path={PRIVATE_PATHS.clientGroups.path}
        />
      </ModalsWrappedForm>
    );
  }
}

const mapStateToProps = ({
  groups: { isCreatingMany },
  reduxRestResponse: { body, success, type },
}) => ({
  body,
  isCreatingGroups: isCreatingMany,
  success: type === types.CREATEMANY_GROUPS && success,
});

export default connect(
  mapStateToProps,
  {
    ...groupsActions,
  }
)(AddGroups);

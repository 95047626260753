import React from 'react';
import { connect } from 'react-redux';
import EditGroupForm from '../../../../Components/Forms/Client/Groups/EditGroup';
import ModalsWrappedForm from '../../../../Components/Forms/ModalsWrappedForm';
import trans from '../../../../Translations/translation.service';
import { actions as groupsActions, types } from '../../../../Redux/Api/Groups';
import { PRIVATE_PATHS } from '../../../config';
import { FEMALE_CODE } from '../../../../Constants';
import { EDIT_ACTIONS } from '../../../../Components/Forms/Client/Groups/config';
import { catchApiItemErrors } from '../../../../Helpers';

class EditGroup extends React.Component {
  getGroup = () => {
    const { id } = this.props.match.params;

    this.props.getGroup(id);
  };

  loading = () => {
    const { isFetchingGroup, isUpdatingGroup } = this.props;
    return isFetchingGroup || isUpdatingGroup;
  };

  updateGroup = data => {
    return this.props.updateGroup(data).catch(
      catchApiItemErrors,
    );
  };

  componentDidMount() {
    this.getGroup();
  }

  render() {
    const { group, history, success } = this.props;
    const subject = trans('resources.singular.group');

    return (
      <ModalsWrappedForm
        gender={FEMALE_CODE}
        history={history}
        loading={this.loading()}
        open={success}
        path={PRIVATE_PATHS.clientGroups.path}
        subject={subject}
        successMessage="successOpSingF"
        type="edit"
      >
        {
          group &&
          <EditGroupForm
            actions={EDIT_ACTIONS}
            history={history}
            onSubmit={this.updateGroup}
            path={PRIVATE_PATHS.clientGroups.path}
          />
        }
      </ModalsWrappedForm>
    );
  }
}

const mapStateToProps = ({
  groups: { isFetchingItem, isUpdating, item },
  reduxRestResponse: { body, success, type },
}) => ({
  body,
  group: item,
  isFetchingGroup: isFetchingItem,
  isUpdatingGroup: isUpdating,
  success: type === types.UPDATE_GROUP && success,
});

export default connect(
  mapStateToProps,
  {
    ...groupsActions,
  }
)(EditGroup);

import React from 'react';
import { connect } from 'react-redux';
import { actions as authActions } from '../../Redux/Ducks/Auth';
import { actions as usersActions, types } from '../../Redux/Api/Users';
import Button from './Styled/Button';
import PlayArrow from './Styled/PlayArrow';
import Wrapper from './Styled/Wrapper';

class AccountPlay extends React.Component {
  state = {
    tabOpened: false,
  };

  handleClick = () => {
    const { accountId, user } = this.props;
    const newUser = {
      account_id: accountId,
      id: user.id,
    };

    this.setState({ tabOpened: false }, () => {
      this.props.setUser({ ...user, account_id: accountId });
      this.props.updateUser(newUser);
    });
  };

  openTab = () => {
    console.log('redirecting to client dashboard');
    const win = window.open('/#/client-dashboard', '_blank');
    if (win != null) {
      win.focus();
    }
  };

  componentWillReceiveProps(nextProps) {
    const { success } = nextProps;
    const { tabOpened } = this.state;

    if (!tabOpened && success) {
      this.setState({ tabOpened: true }, this.openTab);
    }
  }

  render() {
    const { children } = this.props;

    return (
      <Wrapper>
        {children}
        <Button onClick={this.handleClick}>
          <PlayArrow />
        </Button>
      </Wrapper>
    );
  }
}

const mapStateToProps = ({
  auth: { user },
  reduxRestResponse: { success, type },
}) => ({
  success: type === types.UPDATE_USER && success,
  user,
});

export default connect(
  mapStateToProps,
  {
    ...authActions,
    ...usersActions,
  }
)(AccountPlay);

import React from 'react';
import { SMS_CHAR_SIZE } from '../Constants';
import trans from '../Translations/translation.service';

export const getCampaignContactCount = (campaigns, campaignId) => {
  if (
    !Array.isArray(campaigns)
    || campaigns.every(campaign => (
      typeof campaign !== 'object' || !campaign || campaign.id !== campaignId
    ))
  ) {
    return {};
  }

  const {
    contact_count,
    movistar_contact_count,
    digitel_contact_count,
    movilnet_contact_count,
  } = campaigns.find(camp => camp.id === campaignId);

  return {
    contact_count,
    movistar_contact_count,
    digitel_contact_count,
    movilnet_contact_count,
  };
}

export const getMessageCount = content => Math.ceil(
  (typeof content === 'string' ? content.length : 0) / SMS_CHAR_SIZE,
);

export const getSubscriptionName = subscription => {
  if (typeof subscription !== 'object' || !subscription) {
    return '';
  }

  const { integrator_name, integrator_id, service_name } = subscription;

  return `${service_name}${integrator_id ? ` - ${integrator_name}` : ''}`;
};

export const renderSubscriptionsData = subscriptions => {
  if (
    !Array.isArray(subscriptions)
    || subscriptions.some(subscription => typeof subscription !== 'object' || !subscription)
  ) {
    return null;
  }

  return subscriptions.map(({
    blocked_credits,
    current_credits,
    integrator,
    integrator_id,
    service,
  }) => (
    <p>
      {`${
        getSubscriptionName({
          integrator_id,
          integrator_name: integrator && integrator.name,
          service_name: service && service.name,
        })
      }: ${current_credits - blocked_credits}`}
    </p>
  ))
};

export const getSchedulesCount = formData => {
  const schedulesCount = Array.isArray(formData.schedules) ? formData.schedules.length : 0;
  return formData.schedule ? schedulesCount : 1;
};

export const getCampaignName = (campaignMessage, api, test) => {
  if (api) {
    return trans('default.messageTypes.api');
  } else if (test) {
    return trans('default.messageTypes.test');
  } else {
    return campaignMessage && campaignMessage.campaign && campaignMessage.campaign.name;
  }
};

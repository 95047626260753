export const API_ACCOUNT_TYPES = [
  'demo',
  'corporate',
  'individual',
];

export const API_CARRIERS = [
  'movistar',
  'digitel',
  'movilnet',
];

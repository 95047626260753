import trans from '../../../../Translations/translation.service';
import { API_DATE_FORMAT } from '../../../../Constants';
import { getSubscriptionName } from '../../../../Helpers';

export const UPDATE_ACCOUNT_ACTIONS = [
  {
    color: 'primary',
    label: trans('forms.actions.save'),
    type: 'submit',
  },
];

export const DEFAULT_VALUES = {
  username: '',
  password: '',
  password_confirm: '',
  account: {
    company: {
      business_name: '',
      phone: '',
      email: '',
      address: '',
    },
  },
  due_date: null,
  start_date: null,
  subscriptions: [],
  type: null,
  channel_id: null,
};

export const MY_ACCOUNT_INPUTS = [
  {
    fieldType: 'tab',
    name: 'user',
    tabs: [
      {
        code: 'company',
        label: trans('forms.tabs.client.myAccount.company'),
        inputs: [
          {
            disabled: true,
            id: 'account-company-business-name',
            label: trans('forms.fields.client.myAccount.companyBusinessName'),
            name: 'account.company.business_name',
            sizes: {
              xs: 12,
            },
          },
          {
            disabled: true,
            id: 'account-company-id-number',
            label: trans('forms.fields.client.myAccount.companyIdNumber'),
            name: 'account.company.id_number',
            sizes: {
              xs: 12,
            },
          },
          {
            disabled: true,
            id: 'account-company-email',
            label: trans('forms.fields.client.myAccount.companyEmail'),
            name: 'account.company.email',
            sizes: {
              xs: 12,
            },
          },
          {
            disabled: true,
            id: 'account-company-phone',
            label: trans('forms.fields.client.myAccount.companyPhone'),
            name: 'account.company.phone',
            sizes: {
              xs: 12,
            },
          },
          {
            disabled: true,
            id: 'account-company-address',
            label: trans('forms.fields.client.myAccount.companyAddress'),
            multiline: true,
            name: 'account.company.address',
            rows: 4,
            sizes: {
              xs: 12,
            },
          },
        ],
      },
      {
        code: 'settings',
        inputs: [
          {
            id: 'username',
            inputProps: {
              maxLength: 255,
            },
            label: trans('forms.fields.client.myAccount.username'),
            name: 'username',
            required: true,
            sizes: {
              xs: 12,
            },
          },
          {
            id: 'password',
            inputProps: {
              maxLength: 255,
            },
            label: trans('forms.fields.client.myAccount.password'),
            name: 'password',
            sizes: {
              xs: 12,
            },
            type: 'password',
          },
          {
            id: 'password-confirm',
            inputProps: {
              maxLength: 255,
            },
            label: trans('forms.fields.client.myAccount.passwordConfirm'),
            name: 'password_confirm',
            sizes: {
              xs: 12,
            },
            type: 'password',
          },
        ],
        label: trans('forms.tabs.client.myAccount.settings'),
      },
      {
        code: 'subscription',
        label: trans('forms.tabs.client.myAccount.subscription'),
        inputs: [
          {
            disabled: true,
            id: 'account-start-date',
            label: trans('forms.fields.client.myAccount.startDate'),
            name: 'account.start_date',
            sizes: {
              xs: 12,
            },
          },
          {
            disabled: true,
            id: 'account-account-due-date',
            label: trans('forms.fields.client.myAccount.dueDate'),
            name: 'account.due_date',
            sizes: {
              xs: 12,
            },
          },
          {
            disabled: true,
            id: 'account-channel',
            label: trans('forms.fields.client.myAccount.channel'),
            name: 'account.channel',
            sizes: {
              xs: 12,
            },
          },
          {
            disabled: true,
            id: 'account-type',
            label: trans('forms.fields.client.myAccount.accountType'),
            name: 'account.account_type',
            sizes: {
              xs: 12,
            },
          },
          {
            fieldType: 'array',
            fixed: true,
            name: 'account.subscriptions',
            inputs: [
              {
                disabled: true,
                id: 'details',
                name: 'details',
                propsByValues: values => (
                  values
                    ? {
                      label: getSubscriptionName(values),
                    }
                    : {
                      label: trans('forms.fields.client.myAccount.subscription'),
                    }
                ),
                sizes: {
                  xs: 12,
                },
              },
            ],
            size: {
              xs: 12,
            },
            title: trans('forms.fields.client.myAccount.subscriptionDetails'),
          },
        ],
      },
    ],
  }
];

export const MY_ACCOUNT_FORM = 'my-account';

export const MY_ACCOUNT_VALIDATIONS = [
  {
    field: 'settings',
    object: true,
    validations: [
      {
        field: 'username',
        message: ['default.forms.validations.isRequired'],
        method: 'isEmpty',
        validWhen: false,
      },
      {
        field: 'password',
        message: ['default.forms.validations.isRequired'],
        method: 'isEmpty',
        validWhen: false,
      },
      {
        field: 'password_confirm',
        message: ['default.forms.validations.isRequired'],
        method: 'isEmpty',
        validWhen: false,
      },
      {
        args: ['password'],
        field: 'password_confirm',
        message: [
          'default.forms.validations.matchesField',
          {
            field: trans('forms.fields.admin.accounts.clientPassword'),
          },
        ],
        method: 'matchesField',
        validWhen: true,
      },
    ],
  },
];

import React from 'react';
import { connect } from 'react-redux';
import AddBusinessTypesForm from '../../../../Components/Forms/Admin/BusinessTypes/AddBusinessTypes';
import ModalsWrappedForm from '../../../../Components/Forms/ModalsWrappedForm';
import trans from '../../../../Translations/translation.service';
import { actions as allIndustriesActions } from '../../../../Redux/Api/AllIndustries';
import { actions as businessTypesActions, types } from '../../../../Redux/Api/BusinessTypes';
import { PRIVATE_PATHS } from '../../../config';
import { MALE_CODE } from '../../../../Constants';
import { ADD_ACTIONS } from '../../../../Components/Forms/Admin/BusinessTypes/config';
import { catchApiArrayErrors } from '../../../../Helpers';

class AddBusinessTypes extends React.Component {
  createBusinessTypes = data => {
    return this.props.createManyBusinessTypes(data.businessTypes).catch(
      catchApiArrayErrors('businessTypes'),
    );
  };

  loading = () => {
    const { isCreatingBusinessTypes, isFetchingIndustries } = this.props;
    return isCreatingBusinessTypes || isFetchingIndustries;
  };

  parseIndustries = () => (
    this.props.industries.map(({ id: value, name: text }) => ({
      text, value,
    }))
  );

  componentDidMount() {
    this.props.fetchAllIndustries();
  }

  render() {
    const { history, success } = this.props;
    const industries = this.parseIndustries();
    const loading = this.loading();
    const subject = trans('resources.plural.businessTypes');

    return (
      <ModalsWrappedForm
        gender={MALE_CODE}
        history={history}
        loading={loading}
        open={success}
        path={PRIVATE_PATHS.adminBusinessTypes.path}
        subject={subject}
        successMessage="successOpPlurM"
        type="add"
      >
        <AddBusinessTypesForm
          actions={ADD_ACTIONS}
          data={{
            businessTypes: {
              industry_id: industries,
            },
          }}
          history={history}
          onSubmit={this.createBusinessTypes}
          path={PRIVATE_PATHS.adminBusinessTypes.path}
        />
      </ModalsWrappedForm>
    );
  }
}

const mapStateToProps = ({
  allIndustries: {
    isFetching,
    items,
  },
  businessTypes: { isCreatingMany },
  reduxRestResponse: { body, success, type },
}) => ({
  body,
  industries: items[1] ? items[1] : [],
  isCreatingBusinessTypes: isCreatingMany,
  isFetchingIndustries: isFetching,
  success: type === types.CREATEMANY_BUSINESS_TYPES && success,
});

export default connect(
  mapStateToProps,
  {
    ...allIndustriesActions,
    ...businessTypesActions,
  }
)(AddBusinessTypes);

import React from 'react';
import { connect } from 'react-redux';
import AddUserForm from '../../../../Components/Forms/Client/Users/AddUser';
import ModalsWrappedForm from '../../../../Components/Forms/ModalsWrappedForm';
import trans from '../../../../Translations/translation.service';
import { actions as allPermissionsActions } from '../../../../Redux/Api/AllPermissions';
import { actions as usersActions, types } from '../../../../Redux/Api/Users';
import { PRIVATE_PATHS } from '../../../config';
import {
  USERS_STATUSES_LIST,
  MALE_CODE,
  GENDERS_LIST,
} from '../../../../Constants';
import { ADD_ACTIONS } from '../../../../Components/Forms/Client/Users/config';
import {
  catchApiItemErrors,
} from '../../../../Helpers';

class AddUser extends React.Component {
  createUser = user => (
    this.props.createUser(user).catch(
      catchApiItemErrors,
    )
  );

  loading = () => {
    const {
      isCreatingUser,
      isFetchingPermissions,
    } = this.props;
    return isCreatingUser
      || isFetchingPermissions;
  };

  parsePermissions = () => (
    this.props.permissions.map(({ id: value, name: label }) => ({
      label, value,
    }))
  );

  componentDidMount() {
    this.props.fetchAllPermissions();
  }

  render() {
    const { history, success } = this.props;
    const permissions = this.parsePermissions();
    const subject = trans('resources.singular.user');

    return (
      <ModalsWrappedForm
        gender={MALE_CODE}
        history={history}
        loading={this.loading()}
        open={success}
        path={PRIVATE_PATHS.clientUsers.path}
        subject={subject}
        successMessage="successOpSingM"
        type="add"
      >
        <AddUserForm
          actions={ADD_ACTIONS}
          data={{
            user: {
              status: USERS_STATUSES_LIST,
              'permissions._ids': permissions,
              'person.gender': GENDERS_LIST,
            },
          }}
          history={history}
          onSubmit={this.createUser}
          path={PRIVATE_PATHS.clientUsers.path}
        />
      </ModalsWrappedForm>
    );
  }
}

const mapStateToProps = ({
  allPermissions: {
    isFetching: isFetchingPermissions,
    items: permissions,
  },
  users: { isCreating },
  reduxRestResponse: { body, success, type },
  ...state
}) => ({
  body,
  isFetchingPermissions,
  isCreatingUser: isCreating,
  permissions: permissions[1] ? permissions[1] : [],
  success: type === types.CREATE_USER && success,
});

export default connect(
  mapStateToProps,
  {
    ...allPermissionsActions,
    ...usersActions,
  }
)(AddUser);

import { withStyles } from '@material-ui/core/styles';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import { COLORS } from '../../../../../Constants';

export default withStyles({
  root: {
    display: 'block',
    padding: 0,
    paddingLeft: 26,
  },
})(ExpansionPanelDetails);

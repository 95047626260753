/** Modal component messages (ES)  */
export default {
  actions: {
    accept: 'Aceptar',
    backToList: 'Volver a la lista',
    cancel: 'Cancelar',
    sendTest: 'Mensaje de Prueba',
  },
  messages: {
    deleteAllF: '¿Deseas eliminar todas las {{ type }}?',
    deleteAllM: '¿Deseas eliminar todos los {{ type }}?',
    deleteOpPlurF: '¿Deseas eliminar estas {{ type }}?',
    deleteOpPlurM: '¿Deseas eliminar estos {{ type }}?',
    deleteOpSingF: '¿Deseas eliminar la {{ type }} "{{ subject }}"?',
    deleteOpSingM: '¿Deseas eliminar el {{ type }} "{{ subject }}"?',
    success: 'La operación se he realizado exitosamente',
    successOpPlurF: 'Las {{ subject }} han sido {{ action }}s exitosamente',
    successOpPlurM: 'Los {{ subject }} han sido {{ action }}s exitosamente',
    successOpSingF: 'La {{ subject }} ha sido {{ action }} exitosamente',
    successOpSingM: 'El {{ subject }} ha sido {{ action }} exitosamente',
    warning: '¿Deseas realizar esta operación?',
  },
  titles: {
    exportReceivedMessages: 'Exportar Mensajes Recibidos',
    sendTest: 'Mensaje de Prueba',
    success: 'Operación exitosa',
    warning: 'Confirmación',
  },
};

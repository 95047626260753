import styled from 'styled-components';

export default styled.div`
  display: inline-block;
  margin-right: 5px;
  vertical-align: middle;

  * {
    color: white;
  }

  .navbar-logo {
    display: inline-block;
    vertical-align: middle;
  }
`;

import { withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';

export default withStyles({
  input: {
    color: 'white',
  },
  root: {
    '&:before': {
      borderBottomColor: 'white',
    },
  },
})(Input);

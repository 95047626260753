import React from 'react';
import { connect } from 'react-redux';
import HeaderMenus from './HeaderMenus';
import Menu from './Menu';
import NavbarToggle from './NavbarToggle';
import SearchBar from '../../../Filters/SearchBar';
import StyledHeader from './Styled';
import Title from './Styled/Title';

const Header = ({
  hasNews,
  headerMenu,
  onSearch,
  search,
  searchPlaceholder,
  title,
  toggleOpen,
  logoPath = '',
}) => (
  <StyledHeader hasNews={hasNews}>
    <NavbarToggle logoPath={logoPath} />
    <Title>{title}</Title>
    {
      headerMenu
      && Array.isArray(headerMenu)
      && !!headerMenu.length
      && <Menu options={headerMenu} />
    }
    <HeaderMenus />
    {
      search &&
      <SearchBar placeholder={searchPlaceholder} onSearch={onSearch} />
    }
  </StyledHeader>
);

const mapStateToProps = ({
  navbar: {
    headerTitle,
    headerMenu,
    onSearch,
    search,
    searchPlaceholder,
  },
}) => ({
  title: headerTitle,
  headerMenu,
  onSearch,
  search,
  searchPlaceholder,
});

export default connect(mapStateToProps)(Header);

import React from 'react';
import { connect } from 'react-redux';
import Table from '../../../Components/Table';
import trans from '../../../Translations/translation.service';
import { actions as banksActions } from '../../../Redux/Api/Banks';
import {
  BANKS_TABLE_TITLES,
  DEFAULT_TABLE_ACTIONS,
  SUCCESS_CODE,
} from '../../../Constants';
import { PRIVATE_PATHS } from '../../config';
import { getPage } from '../../../Helpers';

class AdminBanks extends React.Component {
  deleteBank = id => (
    this.props.deleteBank(id)
      .then(({ code }) => code === SUCCESS_CODE && this.fetchBanks())
  );

  fetchBanks = newPage => {
    const { location } = this.props;
    const page = getPage(newPage, location);

    this.props.fetchBanks({}, { query: { page } });
  };

  parseBankData = ({
    name,
  }) => [
    {
      text: name,
    },
  ];

  componentDidMount() {
    this.fetchBanks();
  }

  render() {
    const { banks, history, isDeletingBank, isFetchingBanks, pagination } = this.props;
    const type = trans('resources.singular.bank');

    return (
      <Table
        actions={DEFAULT_TABLE_ACTIONS}
        actionButtonNames={{
          delete: ({ name }) => name,
        }}
        data={banks}
        dataParser={this.parseBankData}
        deleteWarningMessage="deleteOpSingM"
        editPath={PRIVATE_PATHS.adminEditBank.noParamsPath}
        handleChangePage={this.fetchBanks}
        history={history}
        loading={isDeletingBank || isFetchingBanks}
        onDeleteAcceptClick={this.deleteBank}
        pagination={pagination}
        titles={BANKS_TABLE_TITLES}
        type={type}
      />
    );
  }
}

const mapStateToProps = ({
  banks: {
    isDeleting,
    isFetching,
    items: [success, data, pagination],
  },
}) => ({
  pagination,
  success,
  banks: success ? data : [],
  isDeletingBank: isDeleting,
  isFetchingBanks: isFetching,
});

export default connect(
  mapStateToProps,
  {
    ...banksActions,
  }
)(AdminBanks);

import React from 'react';
import { connect } from 'react-redux';
import FileDownloadButton from './Styled/StyledFileDownloadButton';
import { actions as fileDownloadActions } from '../../Redux/Ducks/FileDownload';
class FileDownloadLink extends React.Component {
  handleClick = () => {
    const { fileName, fileUrl, fileRequestOptions } = this.props;
    this.props.downloadFile(fileUrl, fileRequestOptions, fileName);
  };

  render() {
    const { fileName } = this.props;

    return (
      <FileDownloadButton onClick={this.handleClick}>
        {fileName}
      </FileDownloadButton>
    );
  }
}

FileDownloadLink.defaultProps = {
  fileRequestOptions: {},
};

export default connect(null, { ...fileDownloadActions })(FileDownloadLink);

import React from 'react';
import FormSuccessModal from './FormSuccess';
import trans from '../../Translations/translation.service';

export default ({ gender, ...props }) => (
  <FormSuccessModal
    action={trans(`default.actions.scheduled${gender}`)}
    {...props}
  />
);

import styled from 'styled-components';
import { COLORS } from '../../../../../Constants';

export default styled.div`
  background-color: white;
  border-right: 1px solid ${COLORS.greyf0};
  bottom: 0;
  flex-shrink: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: center;
  transition: width .4s;
  z-index: 10;

  &, & .navbar-content {
    height: 100%;
    width: ${({ open }) => open ? '210px' : '70px'};
  }

  & .navbar-option-title {
    display: ${({ open }) => open ? 'block' : 'none'};
  }

  .navbar-option.suboption .navbar-option-title {
    color: ${COLORS.mediumBlue};
  }

  .navbar-option.suboption:hover .navbar-option-title {
    color: ${COLORS.mediumBlue};
  }

  .navbar-logo {
    padding: 9px 0;
  }

  .navbar-option-link {
    display: block;
    width: 100%;
  }

  .navbar-logout {
    padding-top: 14px;
  }

  .MuiExpansionPanelSummary-root.Mui-expanded {
    min-height: 48px;
  }

  .MuiExpansionPanelSummary-expandIcon {
    display: none;
    margin-right: 0;
  }

  & .MuiExpansionPanelSummary-expandIcon {
    display: ${({ open }) => open ? 'block' : 'none'};
  }

  /* & .navbar-option:not(.suboption) {
    padding-right: 0;
  } */
`;

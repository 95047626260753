import React from 'react';
import { Link } from 'react-router-dom';
import Name from './Styled/StyledGeneralStatName';
import Value from './Styled/StyledGeneralStatValue';
import Wrapper from './Styled/StyledGeneralStatWrapper';

export default ({ link, name, value }) => {
  const generalStat = (
    <>
      <div className="text">
        <Name>{name}</Name>
        <Value>{value || 0}</Value>
      </div>
      <div className="icon">
        <img alt="Chart" src="img/icons/chart.svg" />
      </div>
    </>
  );

  return (
    <Wrapper link={!!link}>
      {
        link
          ? (
            <Link to={link}>{generalStat}</Link>
          )
          : (
            <div>{generalStat}</div>
          )
      }
    </Wrapper>
  );
};

import React from 'react';
import { Link } from 'react-router-dom';
import ExpandableOption from './ExpandableOption';
import Option from './Styled/Option';
import OptionImg from './Styled/OptionImg';
import OptionTitle from './Styled/OptionTitle';

export default ({
  expandableProps,
  className = '',
  selectedOption = '',
  option: { image: Image, ...option },
}) => {
  const selected = selectedOption === option.name;
  const optionNode = (
    <Option
      className={`navbar-option ${className}`}
      selected={selected}
    >
      {
        Image &&
        <OptionImg>
          <Image className="navbar-option-image" />
        </OptionImg>
      }
      <OptionTitle className="navbar-option-title">
        {option.title}
      </OptionTitle>
    </Option>
  );

  return option.subMenu ?
    (
      <ExpandableOption
        option={option}
        selected={selected}
        {...expandableProps}
      >
        {optionNode}
      </ExpandableOption>
    )
    :
    (
      <Link className="navbar-option-link" to={option.path}>
        {optionNode}
      </Link>
    );
};

import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import ResponsiveForm from '../Form';
import { validate } from '../../../Helpers';
import {
  ACTIONS,
  DEFAULT_VALUES,
  INPUTS,
  LOGIN_FORM as form,
  LOGIN_VALIDATIONS,
} from './config';

let LoginForm = ({ handleSubmit, valid, loading = false }) => (
  <ResponsiveForm
    actions={ACTIONS}
    inputs={INPUTS}
    loading={loading}
    onSubmit={handleSubmit}
    spacing={0}
    valid={valid}
  />
);

LoginForm = reduxForm({
  form,
  validate: validate(LOGIN_VALIDATIONS),
})(LoginForm);

const mapStateToProps = () => ({
  initialValues: { ...DEFAULT_VALUES },
});

export default connect(
  mapStateToProps
)(LoginForm);

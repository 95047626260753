import React from 'react';
import { connect } from 'react-redux';
import EditNewForm from '../../../../Components/Forms/Admin/News/EditNew';
import ModalsWrappedForm from '../../../../Components/Forms/ModalsWrappedForm';
import trans from '../../../../Translations/translation.service';
import { actions as newsActions, types } from '../../../../Redux/Api/News';
import { PRIVATE_PATHS } from '../../../config';
import { FEMALE_CODE } from '../../../../Constants';
import { EDIT_ACTIONS, NEW_PARSE_CONFIG } from '../../../../Components/Forms/Admin/News/config';
import { parseDataForApi } from '../../../../Helpers';

class EditNew extends React.Component {
  getNew = () => {
    const { id } = this.props.match.params;

    this.props.getNew(id);
  };

  loading = () => {
    const { isFetchingNew, isUpdatingNew } = this.props;
    return isFetchingNew || isUpdatingNew;
  };

  updateNew = data => {
    this.props.updateNew(
      parseDataForApi(data, NEW_PARSE_CONFIG),
    )
  };

  componentDidMount() {
    this.getNew();
  }

  render() {
    const { history, newStory, success } = this.props;
    const loading = this.loading();
    const subject = trans('resources.singular.new');

    return (
      <ModalsWrappedForm
        gender={FEMALE_CODE}
        history={history}
        loading={loading}
        open={success}
        path={PRIVATE_PATHS.adminNews.path}
        subject={subject}
        successMessage="successOpSingF"
        type="edit"
      >
        {
          newStory &&
          <EditNewForm
            actions={EDIT_ACTIONS}
            history={history}
            onSubmit={this.updateNew}
            path={PRIVATE_PATHS.adminNews.path}
          />
        }
      </ModalsWrappedForm>
    );
  }
}

const mapStateToProps = ({
  news: { isFetchingItem, isUpdating, item },
  reduxRestResponse: { body, success, type },
}) => ({
  body,
  isFetchingNew: isFetchingItem,
  isUpdatingNew: isUpdating,
  newStory: item,
  success: type === types.UPDATE_NEW && success,
});

export default connect(
  mapStateToProps,
  {
    ...newsActions,
  }
)(EditNew);

import React from 'react';
import MomentUtils from '@date-io/moment';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from '@material-ui/pickers';
import moment from 'moment';
import TextField from './TextField';
import { TIME_FORMAT } from '../../../Constants';

class TimePicker extends React.Component {
  handlePickerChange = time => {
    const { afterChange, index, inputFormat, name, onChange } = this.props;
    const value = time && time.format(inputFormat);
    if (typeof afterChange === 'function') {
      afterChange(value, name, index);
    }
    onChange(value);
  };

  render() {
    const { value, inputFormat, ...props } = this.props;
    const timeValue =  moment(value, inputFormat);

    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <KeyboardTimePicker
          {...props}
          format={TIME_FORMAT}
          invalidTimeMessage=""
          onChange={this.handlePickerChange}
          TextFieldComponent={TextField}
          value={timeValue}
        />
      </MuiPickersUtilsProvider>
    );
  }
}

TimePicker.defaultProps = {
  inputFormat: TIME_FORMAT,
};

export default TimePicker;

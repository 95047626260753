import React from 'react';
import InfoContent from './Styled/InfoContent';
import InfoLabel from './Styled/InfoLabel';
import InfoWrapper from './Styled/InfoWrapper';

export default ({ label, content }) => (
  <InfoWrapper>
    <InfoLabel>{label}</InfoLabel>
    <InfoContent>{content}</InfoContent>
  </InfoWrapper>
);

/** Forms components messages (EN)  */
export default {
  actions: {
    apply: 'Apply',
    cancel: 'Cancel',
    clear: 'Clear',
    export: 'Export',
    save: 'Save',
    schedule: 'Schedule',
    send: 'Send',
  },
  fields: {
    admin: {
      accounts: {
        clientUsername: 'Username',
        clientPassword: 'Password',
        clientPasswordConfirm: 'Confirm Password',
        accountType: 'Type',
        status: 'Status',
        channelId: 'Channel',
        clientPersonFirstName: 'First Name',
        clientPersonLastName: 'Last Name',
        clientPersonIdNumber: 'ID Number',
        clientPersonAltIdNumber: 'RIF',
        clientPersonGender: 'Gender',
        clientPersonBirthDate: 'Birth Date',
        companyName: 'Name',
        companyBusinessName: 'Business Name',
        companyIdNumber: 'ID Number',
        companyBusinessTypeId: 'Business Type',
        companyEmail: 'Email',
        companyPhone: 'Phone',
        companyAddress: 'Address',
        startDate: 'Start Date',
        dueDate: 'Due Date',
        prepaid: 'Subscription type',
        prepaidOn: 'Prepaid',
        prepaidOff: 'Postpaid',
        shortCodeId: 'Short Code',
        subscriptionDetails: 'Subscription Details',
        subscriptionUnlimited: 'Unlimited',
        subscriptionUnlimitedOff: 'No',
        subscriptionUnlimitedOn: 'Yes',
        subscription: 'Subscription',
        subscriptionPrice: 'Price',
        subscriptionCredits: 'Credits',
        subscriptionTotal: 'Total',
        subscriptionUsername: 'Integrator\'s Username',
        subscriptionPassword: 'Integrator\'s Password',
        applyCredits: 'Apply Credits',
        shortCodes: 'Short Codes',
      },
      activitiesFilters: {
        from: 'From',
        to: 'To',
        account: 'Account',
        user: 'User',
        type: 'Type',
      },
      addCredits: {
        account: 'Account',
        integrator: 'Integrator',
        currentCredits: 'Current Credits',
        credits: 'Add Credits',
      },
      banks: {
        addBank: 'Add Bank',
        removeBank: 'Remove Bank',
        name: 'Name',
      },
      businessTypes: {
        addBusinessType: 'Add Business Type',
        removeBusinessType: 'Remove Business Type',
        name: 'Name',
        industry: 'Industry',
      },
      campaignsFilters: {
        from: 'From',
        to: 'To',
        account: 'Account',
      },
      dashboardFilters: {
        from: 'From',
        to: 'To',
        account: 'Account',
        shortCode: 'Short Code',
        keyword: 'Keyword',
        campaign: 'Campaign',
        api: 'From API',
        carrier: 'Carrier',
        integrator: 'Integrator',
      },
      industries: {
        addIndustry: 'Add Industry',
        removeIndustry: 'Remove Industry',
        name: 'Name',
      },
      integrators: {
        name: 'Name',
        code: 'Code',
      },
      keywords: {
        word: 'Word',
        shortCode: 'Short Code',
        account: 'Account',
        replies: 'Replies',
        addReply: 'Add Reply',
        removeReply: 'Remove Reply',
        replyUrl: 'From external API',
        replyUrlOff: 'No',
        replyUrlOn: 'Yes',
        replyFrom: 'From',
        replyTo: 'To',
        replyContentUrl: 'URL',
        replyContent: 'Content',
      },
      keywordsFilters: {
        account: 'Account',
        keyword: 'Word',
        shortCode: 'Short Code',
      },
      news: {
        title: 'Title',
        from: 'From',
        to: 'To',
        content: 'Content',
      },
      payments: {
        account: 'Account',
        month: 'Month',
        year: 'Year',
        transactionDate: 'Payment Date',
        transactionType: 'Payment Type',
        bank: 'Bank',
        transactionId: 'Reference Number',
        transactionFile: 'File',
      },
      paymentsFilters: {
        from: 'From',
        to: 'To',
        account: 'Account',
        month: 'Month',
        year: 'Year',
      },
      receivedMessages: {
        export: {
          from: 'From',
          to: 'To',
        },
      },
      removeCredits: {
        account: 'Account',
        integrator: 'Integrator',
        currentCredits: 'Current Credits',
        credits: 'Remove Credits',
      },
      reportsFilters: {
        from: 'From',
        to: 'To',
      },
      shortCodes: {
        addShortCode: 'Add Short Code',
        removeShortCode: 'Remove Short Code',
        code: 'Code',
      },
    },
    client: {
      activitiesFilters: {
        from: 'From',
        to: 'To',
        user: 'User',
        type: 'Type',
      },
      campaigns: {
        name: 'Name',
        group: 'Group',
        file: 'Contacts',
        fileOff: 'Manually',
        fileOn: 'From File',
        contactsFile: 'Contacts File',
        fileFields: 'Mandatory Fields',
        fileFieldsDesc: 'These fields must be present in the file. Please indicate the corresponding name for each one. If the fields are not found in the file the contacts won\'t be imported.',
        field: 'Field {{ pos }}',
        firstNameField: 'First Name',
        lastNameField: 'Last Name',
        emailField: 'Email',
        phoneField: 'Phone',
        contacts: 'Contacts',
        addContact: 'Add Contact',
        removeContact: 'Remove Contact',
      },
      campaignsFilters: {
        from: 'From',
        to: 'To',
      },
      groups: {
        addGroup: 'Add Group',
        removeGroup: 'Remove Group',
        name: 'Name',
      },
      keywordsFilters: {
        keyword: 'Word',
        shortCode: 'Short Code',
      },
      myAccount: {
        companyBusinessName: 'Business Name',
        companyIdNumber: 'ID Number',
        companyPhone: 'Phone Number',
        companyEmail: 'Email Address',
        companyAddress: 'Address',
        username: 'Username',
        password: 'New Password',
        passwordConfirm: 'Confirm Password',
        startDate: 'Start Date',
        dueDate: 'Due Date',
        channel: 'Channel',
        accountType: 'Type',
        subscriptionDetails: 'Subscription Details',
        subscription: 'Subscription',
        subscriptionPrice: 'Price',
        subscriptionCredits: 'Credits',
        subscriptionTotal: 'Total',
      },
      newMessage: {
        group: 'Group',
        campaign: 'Campaign',
        integrator: 'Integrator',
        contactCount: 'Contacts',
        movistarContactCount: 'Movistar',
        digitelContactCount: 'Digitel',
        movilnetContactCount: 'Movilnet',
        content: 'Content',
        messageCount: 'Messages Count',
        totalMessages: 'Total Messages To Send',
        credits: 'Credits',
        schedule: 'Schedule',
        addSchedule: 'Add Schedule',
        sendDate: 'Date',
        sendTime: 'Time',
        removeSchedule: 'Remove Schedule',
      },
      payments: {
        month: 'Month',
        year: 'Year',
        transactionDate: 'Payment Date',
        transactionType: 'Payment Type',
        bank: 'Bank',
        transactionId: 'Reference Number',
        transactionFile: 'File',
      },
      paymentsFilters: {
        from: 'From',
        to: 'To',
        month: 'Month',
        year: 'Year',
      },
      receivedMessages: {
        export: {
          from: 'From',
          to: 'To',
        },
      },
      testMessage: {
        integrator: 'Integrator',
        receipent: 'Receipent',
      },
      users: {
        username: 'Username',
        password: 'Password',
        passwordConfirm: 'Confirm Password',
        status: 'Status',
        personFirstName: 'First Name',
        personLastName: 'Last Name',
        personIdNumber: 'ID Number',
        personAltIdNumber: 'RIF',
        personGender: 'Gender',
        personBirthDate: 'Birth Date',
        permissions: 'Permissions',
      },
    },
  },
  labels: {
    filePicker: 'Drop a file here or click',
  },
  tabs: {
    admin: {
      accounts: {
        changePassword: 'Change Password',
        company: 'Company',
        credits: 'Credits',
        general: 'General',
        permissions: 'Permissions',
        personal: 'Personal',
        subscription: 'Subscription',
      },
    },
    client: {
      myAccount: {
        company: 'Company Information',
        settings: 'Settings',
        subscription: 'Subscription',
      },
      users: {
        changePassword: 'Change Password',
        general: 'General',
        personal: 'Personal',
        permissions: 'Permissions',
      },
    },
  },
};

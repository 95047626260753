import React from 'react';
import SuccessModal from './Success';
import trans from '../../Translations/translation.service';

class FormSuccess extends React.Component {
  backToList = () => {
    const { history, path } = this.props;
    history.push(path);
  };

  render() {
    const {
      acceptText,
      action,
      extraInfo,
      onAcceptClick,
      open,
      subject,
      successMessage,
    } = this.props;

    return (
      <SuccessModal
        acceptText={acceptText || trans('modals.actions.backToList')}
        open={open}
        onAcceptClick={onAcceptClick || this.backToList}
        successText={
          <React.Fragment>
            {trans(`modals.messages.${successMessage}`, { action, subject })}
            {extraInfo}
          </React.Fragment>
        }
      />
    );
  }
}

FormSuccess.defaultProps = {
  extraInfo: '',
};

export default FormSuccess;

import * as types from './types';

const defaultState = {
  headerMenu: [],
  headerMenuActions: {},
  headerTitle: '',
  locale: localStorage.getItem('locale') || 'es',
  onSearchChange: null,
  open: true,
  routeType: '',
  search: false,
  searchPlaceholder: '',
  selectedOption: '',
};

const reducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    case types.SET_HEADER_TITLE:
      const { headerTitle } = action;
      return { ...state, headerTitle };

    case types.SET_HEADER_MENU:
      const { headerMenu } = action;
      return { ...state, headerMenu };

    case types.SET_HEADER_MENU_ACTION:
      const { option, callback } = action;
      const { headerMenuActions } = state;

      return { ...state, headerMenuActions: { ...headerMenuActions, [option]: callback } };

    case types.SET_LOCALE:
      const { locale } = action;
      return { ...state, locale };

    case types.SET_ON_SEARCH:
      const { onSearch } = action;
      return { ...state, onSearch };

    case types.SET_ROUTE_TYPE:
      const { routeType } = action;
      return { ...state, routeType };

    case types.SET_OPEN:
      const { open } = action;
      return { ...state, open };

    case types.SET_SEARCH:
      const { search } = action;
      return { ...state, search };

    case types.SET_SEARCH_PLACEHOLDER:
      const { searchPlaceholder } = action;
      return { ...state, searchPlaceholder };

    case types.SET_SELECTED_OPTION:
      const { selectedOption } = action;
      return { ...state, selectedOption };

    default: return state;
  }
};

// Reducer
export default reducer;

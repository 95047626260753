import Close from '@material-ui/icons/Close';
import trans from '../../../../Translations/translation.service';

export const ADD_ACTIONS = [
  {
    color: 'secondary',
    handler: 'backToList',
    label: trans('modals.actions.backToList'),
    type: 'button',
  },
  {
    color: 'primary',
    label: trans('forms.actions.save'),
    type: 'submit',
  },
];
export const EDIT_ACTIONS = [
  {
    color: 'secondary',
    handler: 'backToList',
    label: trans('modals.actions.backToList'),
    type: 'button',
  },
  {
    color: 'primary',
    label: trans('forms.actions.save'),
    type: 'submit',
  },
];

export const DEFAULT_VALUES = {
  name: '',
};

export const INPUTS = [
  {
    fieldType: 'array',
    name: 'industries',
    inputs: [
      {
        clickableIcon: true,
        id: 'name',
        icon: Close,
        iconAlign: 'right',
        inputProps: {
          maxLength: 255,
        },
        label: trans('forms.fields.admin.industries.name'),
        name: 'name',
        remove: true,
        required: true,
        sizes: {
          xs: 12,
        },
      },
    ],
    size: {
      xs: 12,
      sm: 3,
    },
  },
];

export const EDIT_INPUTS = [
  {
    id: 'name',
    inputProps: {
      maxLength: 255,
    },
    label: trans('forms.fields.admin.industries.name'),
    name: 'name',
    required: true,
    sizes: {
      xs: 12,
      sm: 6,
      md: 4,
    },
  },
];

export const INDUSTRY_FORM = 'industry';
export const INDUSTRIES_FORM = 'industries';

export const INDUSTRY_VALIDATIONS = [
  {
    field: 'name',
    message: ['default.forms.validations.isRequired'],
    method: 'isEmpty',
    validWhen: false,
  },
];

import React from 'react';
import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew';
import Option from './Option';
import trans from '../../../../Translations/translation.service';
import { PRIVATE_PATHS } from '../../../../Routes/config';

export default () => (
  <div className="navbar-logout">
    <Option
      option={{
        image: PowerSettingsNew,
        path: PRIVATE_PATHS.logout.path,
        title: trans('default.logout'),
      }}
    />
  </div>
);

import React from 'react';
import moment from 'moment';
import trans from '../Translations/translation.service';
import {
  ACCOUNTS_TYPES_LIST,
  API_DATE_FORMAT,
  API_TIME_FORMAT,
  DEFAULT_SUBSCRIPTION,
  DATE_FORMAT,
  TIME_FORMAT,
} from '../Constants';

export const parseActivityDescription = description => {
  const keys = Object.keys(description);

  return keys.reduce((result, key) => {
    return [...result, `${trans(`default.activities.description.${key}`)}: ${description[key]}`, <br />];
  }, []);
};

export const parseDateForApi = (
  date,
  inFormat = DATE_FORMAT,
  outFormat = API_DATE_FORMAT,
) => {
  const momentDate = moment(date, inFormat);

  return momentDate._isValid ? momentDate.format(outFormat) : date;
};

export const parseTimeForApi = (
  time,
  inFormat = TIME_FORMAT,
  outFormat = API_TIME_FORMAT,
) => {
  const momentTime = moment(time, inFormat);

  return momentTime._isValid ? momentTime.format(outFormat) : time;
};

export const parseToggleArrayForApi = data => {
  if (
    !Array.isArray(data)
    || data.some(item => typeof item !== 'object' || !item)
  ) {
    return data;
  }

  return data.filter(item => item.active);
};

export const parseTransformArrayForApi = (array, copyFields, useParsers, data) => {
  if (
    (useParsers && typeof useParsers !== 'object')
    || typeof copyFields !== 'object'
    || !Array.isArray(array)
    || array.some(item => typeof item !== 'object' || !item)
  ) {
    return array;
  }

  return array.map(item => {
    if (useParsers) {
      Object.keys(useParsers).forEach(key => {
        const parser = PARSERS_CONFIG[useParsers[key]];

        if (typeof parser === 'function') {
          item[key] = parser(item[key]);
        }
      });
    }

    Object.keys(copyFields).forEach(key => {
      item[copyFields[key]] = data[key];
    });

    return item;
  });
};

export const PARSERS_CONFIG = {
  date: parseDateForApi,
  time: parseTimeForApi,
  toggleArray: parseToggleArrayForApi,
  transformArray: parseTransformArrayForApi,
};

export const getValueFromObject = (object, fieldPath) => {
  if (typeof object !== 'object' || !object || typeof fieldPath !== 'string') {
    return;
  }

  const fields = fieldPath.split('.');
  const value = fields.reduce((fieldData, field) => {
    if (fieldData && typeof fieldData === 'object') {
      return fieldData[field];
    }

    return fieldData;
  }, object);

  return value;
};

export const saveValueToObject = (object, fieldPath, value) => {
  if (typeof object !== 'object' || !object || typeof fieldPath !== 'string') {
    return;
  }

  const fields = fieldPath.split('.');
  const lastField = fields.pop();
  let data = object;

  fields.forEach(field => {
    const fieldValue = data[field];

    if (fieldValue && typeof fieldValue === 'object') {
      data = fieldValue;
    }
  });

  data[lastField] = value;
};

export const processParseOption = data => ({
  field,
  parser,
  params = [],
  passData = false,
}) => {
  if (
    typeof data !== 'object'
    || typeof field !== 'string'
    || typeof parser !== 'string'
    || !Array.isArray(params)
  ) {
    return;
  }

  let fieldValue = getValueFromObject(data, field);
  const parseFunction = PARSERS_CONFIG[parser];

  if (passData) {
    params.push(data);
  }

  if (fieldValue && typeof parseFunction === 'function') {
    fieldValue = parseFunction(fieldValue, ...params);
    saveValueToObject(data, field, fieldValue);
  }
};

export const parseDataForApi = (data, options) => {
  if (
    typeof data !== 'object'
    || !data
    || !Array.isArray(options)
    || options.some(option => typeof option !== 'object' || !option)
  ) {
    return data;
  }

  options.forEach(processParseOption(data));

  return data;
};

export const findSubscription = (subscription, subscriptions) => {
  if (
    typeof subscription !== 'object'
    || !subscription
    || !Array.isArray(subscriptions)
    || subscriptions.some(subscript => typeof subscript !== 'object' || !subscript)
  ) {
    return subscription;
  }

  const { integrator, service } = subscription;

  const current = subscriptions.find(subscript => (
    subscript.service_id === subscription.service_id
    && subscript.integrator_id === subscription.integrator_id
  ));

  return current
    ? {
      integrator,
      service,
      active: true,
      total: current.credits * current.price,
      ...current,
    }
    : subscription;
};

export const parseAccountSubscriptionData = (account, integrators, services) => {
  if (
    typeof account !== 'object'
    || !account
    || !Array.isArray(integrators)
    || integrators.some(integrator => typeof integrator !== 'object' || !integrator)
    || !Array.isArray(services)
    || services.some(service => typeof service !== 'object' || !service)
  ) {
    return account;
  }

  const subscriptions = [];

  services.forEach(service => {
    if (service.integrator) {
      integrators.forEach(integrator => (
        subscriptions.push(findSubscription({
          ...DEFAULT_SUBSCRIPTION,
          integrator_name: integrator.name,
          integrator_id: integrator.id,
          service_name: service.name,
          service_id: service.id,
        }, account.subscriptions))
      ));
    } else {
      subscriptions.push(findSubscription({
        ...DEFAULT_SUBSCRIPTION,
        service_name: service.name,
        service_id: service.id,
      }, account.subscriptions));
    }
  });

  account.subscriptions = subscriptions;

  return account;
};

export const parseAccountData = account => {
  if (
    typeof account !== 'object'
    || !account
    || !Array.isArray(account.short_codes)
    || account.short_codes.some(shortCode => typeof shortCode !== 'object' || !shortCode)
  ) {
    return account;
  }

  const accountData = { ...account };

  accountData.short_codes = { _ids: accountData.short_codes.map(shortCode => shortCode.id.toString()) };

  return accountData;
};

export const parseUserData = user => {
  if (
    typeof user !== 'object'
    || !user
    || !Array.isArray(user.permissions)
    || user.permissions.some(perm => typeof perm !== 'object' || !perm)
  ) {
    return user;
  }

  const userData = { ...user };

  userData.permissions = { _ids: userData.permissions.map(perm => perm.id.toString()) };

  return userData;
};

export const convertBytesToMbsOrKbs = filesize => {
  let size = '';
  // I know, not technically correct...
  if (filesize >= 1000000) {
    size = filesize / 1000000 + ' megabytes';
  } else if (filesize >= 1000) {
    size = filesize / 1000 + ' kilobytes';
  } else {
    size = filesize + ' bytes';
  }
  return size;
};

export const parseDataForMyAccount = (user, account, channels) => {
  const account_type = ACCOUNTS_TYPES_LIST.find(type => type.value === account.account_type).text;
  const channel = account.channel_id
    ? channels.find(channel => channel.id === account.channel_id).name
    : trans('types.channels.none');
  const start_date = moment(account.start_date, API_DATE_FORMAT).format(DATE_FORMAT);
  const due_date = moment(account.due_date, API_DATE_FORMAT).format(DATE_FORMAT);
  const subscriptions = account.subscriptions.map(sub => {
    const details = `
${trans('forms.fields.client.myAccount.subscriptionPrice')}: ${sub.price}
 - ${trans('forms.fields.client.myAccount.subscriptionCredits')}: ${sub.credits}
 - ${trans('forms.fields.client.myAccount.subscriptionTotal')}: ${sub.price * sub.credits}
`;

    return {
      ...sub,
      details,
    };
  });

  return {
    ...user,
    account: {
      ...account,
      account_type,
      channel,
      due_date,
      start_date,
      subscriptions,
    },
  };
};

import React from 'react';
import TableBody from '@material-ui/core/TableBody';
import Action from './Action';
import ActionCell from './Styled/ActionCell';
import TableCell from './Styled/TableCell';
import TableRow from './Styled/TableRow';
import {
  STRIPED_TABLE,
} from './config';

const renderActions = (actions, row, getActionName, getActionHandler) => {
  let finalActions = [];

  if (Array.isArray(actions)) {
    finalActions = actions;
  } else if (typeof actions === 'function') {
    finalActions = actions(row);
  }

  return finalActions.map((type, key) => (
    <Action
      key={key}
      name={getActionName(type, row)}
      onClick={getActionHandler(type)}
      type={type}
      value={row.id}
    />
  ));
};

export default ({
  actions,
  calculateNumber,
  data,
  dataParser,
  getActionHandler,
  getActionName,
  number,
  rows,
}) => (
  <TableBody>
    {Array.isArray(data) && data.map((row, key) => (
      <TableRow key={key} type={STRIPED_TABLE}>
        {
          number &&
          <ActionCell>
            {calculateNumber(key)}
          </ActionCell>
        }
        {dataParser(row).map(({ text, ...column}, columnKey) => (
          <TableCell
            {...column}
            key={columnKey}
          >
            {text}
          </TableCell>
        ))}
        {
          actions &&
          <ActionCell>
            {renderActions(actions, row, getActionName, getActionHandler)}
          </ActionCell>
        }
      </TableRow>
    ))}
  </TableBody>
);

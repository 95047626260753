export const fontFaceSvgUrl = (name, variation = '') => {
  const path = variation && `-${variation}`;
  return `svg#${name}${path}`;
};

export const fontFaceUrl = (name, variation = '', extension = 'eot') => {
  const path = variation && `-${variation}`;
  return `url('fonts/${name}/${name}${path}.${extension}')`;
};

export const fontFaceAttrs = ({
  family,
  name,
  style = 'normal',
  variation = '',
  weight = 'normal',
}) => `
  @font-face {
    font-family: ${family};
    src: ${fontFaceUrl(name, variation)};
    src: ${fontFaceUrl(name, variation, 'eot?#iefix')} format('embedded-opentype'),
        ${fontFaceUrl(name, variation, 'woff2')} format('woff2'),
        ${fontFaceUrl(name, variation, 'woff')} format('woff'),
        ${fontFaceUrl(name, variation, 'ttf')} format('truetype'),
        ${fontFaceUrl(name, variation, fontFaceSvgUrl(name, variation))} format('svg');
    font-weight: ${weight};
    font-style: ${style};
  }
`;

import { createGlobalStyle } from 'styled-components';
import Fonts from './Fonts';
import { COLORS } from '../../Constants';

export default createGlobalStyle`
  ${Fonts}

  body {
    background-color: ${COLORS.greyf0};
    margin: 0;
  }

  * {
    font-family: Roboto, sans-serif;
  }

  button:focus, input:focus, textarea:focus {
    outline: none;
  }

  a, a:hover {
    text-decoration: none;
  }

  label + .MuiInput-formControl {
    margin-top: 26px !important;
  }
`;

import trans from '../../../../Translations/translation.service';
import { API_DATE_FORMAT } from '../../../../Constants';
import { getSubscriptionName } from '../../../../Helpers';

export const ADD_ACTIONS = [
  {
    color: 'secondary',
    handler: 'backToList',
    label: trans('modals.actions.backToList'),
    type: 'button',
  },
  {
    color: 'primary',
    label: trans('forms.actions.save'),
    type: 'submit',
  },
];
export const EDIT_ACTIONS = [
  {
    color: 'secondary',
    handler: 'backToList',
    label: trans('modals.actions.backToList'),
    type: 'button',
  },
  {
    color: 'primary',
    label: trans('forms.actions.save'),
    type: 'submit',
  },
];

export const DEFAULT_VALUES = {
  account_type: null,
  apply: false,
  due_date: null,
  short_code_id: null,
  start_date: null,
  status: null,
  client: {
    username: '',
    password: '',
    password_confirm: '',
    person: {
      first_name: '',
      id_number: '',
      last_name: '',
    },
  },
  company: {
    business_name: '',
    business_type_id: null,
    id_number: '',
    name: '',
  },
  short_codes: {
    _ids: [],
  },
  subscriptions: [],
};

export const ADD_INPUTS = [
  {
    fieldType: 'tab',
    name: 'account',
    tabs: [
      {
        code: 'general',
        label: trans('forms.tabs.admin.accounts.general'),
        inputs: [
          {
            id: 'client-username',
            inputProps: {
              maxLength: 255,
            },
            label: trans('forms.fields.admin.accounts.clientUsername'),
            name: 'client.username',
            required: true,
            sizes: {
              xs: 12,
              sm: 6,
              md: 4,
            },
          },
          {
            id: 'client-password',
            inputProps: {
              maxLength: 255,
            },
            label: trans('forms.fields.admin.accounts.clientPassword'),
            name: 'client.password',
            required: true,
            sizes: {
              xs: 12,
              sm: 6,
              md: 4,
            },
            type: 'password',
          },
          {
            id: 'client-password-confirm',
            inputProps: {
              maxLength: 255,
            },
            label: trans('forms.fields.admin.accounts.clientPasswordConfirm'),
            name: 'client.password_confirm',
            required: true,
            sizes: {
              xs: 12,
              sm: 6,
              md: 4,
            },
            type: 'password',
          },
          {
            id: 'account-type',
            label: trans('forms.fields.admin.accounts.accountType'),
            name: 'account_type',
            required: true,
            sizes: {
              xs: 12,
              sm: 6,
              md: 4,
            },
            type: 'select',
          },
          {
            id: 'status',
            label: trans('forms.fields.admin.accounts.status'),
            name: 'status',
            required: true,
            sizes: {
              xs: 12,
              sm: 6,
              md: 4,
            },
            type: 'select',
          },
          {
            id: 'channel-id',
            label: trans('forms.fields.admin.accounts.channelId'),
            name: 'channel_id',
            sizes: {
              xs: 12,
              sm: 6,
              md: 4,
            },
            type: 'select',
          },
        ],
      },
      {
        code: 'company',
        label: trans('forms.tabs.admin.accounts.company'),
        inputs: [
          {
            id: 'company-name',
            inputProps: {
              maxLength: 255,
            },
            label: trans('forms.fields.admin.accounts.companyName'),
            name: 'company.name',
            required: true,
            sizes: {
              xs: 12,
              sm: 6,
              md: 4,
            },
          },
          {
            id: 'company-business-name',
            inputProps: {
              maxLength: 255,
            },
            label: trans('forms.fields.admin.accounts.companyBusinessName'),
            name: 'company.business_name',
            required: true,
            sizes: {
              xs: 12,
              sm: 6,
              md: 4,
            },
          },
          {
            id: 'company-id-number',
            inputProps: {
              maxLength: 255,
            },
            label: trans('forms.fields.admin.accounts.companyIdNumber'),
            name: 'company.id_number',
            required: true,
            sizes: {
              xs: 12,
              sm: 6,
              md: 4,
            },
          },
          {
            id: 'company-business-type-id',
            label: trans('forms.fields.admin.accounts.companyBusinessTypeId'),
            name: 'company.business_type_id',
            required: true,
            sizes: {
              xs: 12,
              sm: 6,
              md: 4,
            },
            type: 'select',
          },
          {
            id: 'company-email',
            inputProps: {
              maxLength: 255,
            },
            label: trans('forms.fields.admin.accounts.companyEmail'),
            name: 'company.email',
            sizes: {
              xs: 12,
              sm: 6,
              md: 4,
            },
          },
          {
            id: 'company-phone',
            inputProps: {
              maxLength: 255,
            },
            label: trans('forms.fields.admin.accounts.companyPhone'),
            name: 'company.phone',
            sizes: {
              xs: 12,
              sm: 6,
              md: 4,
            },
          },
          {
            id: 'company-address',
            label: trans('forms.fields.admin.accounts.companyAddress'),
            multiline: true,
            name: 'company.address',
            rows: 4,
            sizes: {
              xs: 12,
            },
          },
        ],
      },
      {
        code: 'personal',
        label: trans('forms.tabs.admin.accounts.personal'),
        inputs: [
          {
            id: 'client-person-first-name',
            inputProps: {
              maxLength: 255,
            },
            label: trans('forms.fields.admin.accounts.clientPersonFirstName'),
            name: 'client.person.first_name',
            sizes: {
              xs: 12,
              sm: 6,
              md: 4,
            },
          },
          {
            id: 'client-person-last-name',
            inputProps: {
              maxLength: 255,
            },
            label: trans('forms.fields.admin.accounts.clientPersonLastName'),
            name: 'client.person.last_name',
            sizes: {
              xs: 12,
              sm: 6,
              md: 4,
            },
          },
          {
            id: 'client-person-id-number',
            inputProps: {
              maxLength: 255,
            },
            label: trans('forms.fields.admin.accounts.clientPersonIdNumber'),
            name: 'client.person.id_number',
            sizes: {
              xs: 12,
              sm: 6,
              md: 4,
            },
          },
          {
            id: 'client-person-alt-id-number',
            inputProps: {
              maxLength: 255,
            },
            label: trans('forms.fields.admin.accounts.clientPersonAltIdNumber'),
            name: 'client.person.alt_id_number',
            sizes: {
              xs: 12,
              sm: 6,
              md: 4,
            },
          },
          {
            id: 'client-person-gender',
            label: trans('forms.fields.admin.accounts.clientPersonGender'),
            name: 'client.person.gender',
            sizes: {
              xs: 12,
              sm: 6,
              md: 4,
            },
            type: 'select',
          },
          {
            id: 'client-person-birth-date',
            inputFormat: API_DATE_FORMAT,
            label: trans('forms.fields.admin.accounts.clientPersonBirthDate'),
            name: 'client.person.birth_date',
            sizes: {
              xs: 12,
              sm: 6,
              md: 4,
            },
            type: 'datepicker',
          },
        ],
      },
      {
        code: 'subscription',
        label: trans('forms.tabs.admin.accounts.subscription'),
        inputs: [
          {
            id: 'start-date',
            inputFormat: API_DATE_FORMAT,
            label: trans('forms.fields.admin.accounts.startDate'),
            name: 'start_date',
            required: true,
            sizes: {
              xs: 12,
              sm: 6,
              md: 4,
            },
            type: 'datepicker',
          },
          {
            id: 'due-date',
            inputFormat: API_DATE_FORMAT,
            label: trans('forms.fields.admin.accounts.dueDate'),
            name: 'due_date',
            required: true,
            sizes: {
              xs: 12,
              sm: 6,
              md: 4,
            },
            type: 'datepicker',
          },
          {
            id: 'prepaid',
            label: trans('forms.fields.admin.accounts.prepaid'),
            labelOff: trans('forms.fields.admin.accounts.prepaidOff'),
            labelOn: trans('forms.fields.admin.accounts.prepaidOn'),
            name: 'prepaid',
            sizes: {
              xs: 12,
              sm: 6,
              md: 4,
            },
            type: 'twoWaySwitch',
          },
          {
            fieldType: 'array',
            fixed: true,
            name: 'subscriptions',
            inputs: [
              {
                id: 'active',
                name: 'active',
                propsByValues: values => (
                  values
                    ? {
                      label: getSubscriptionName(values),
                    }
                    : {
                      label: trans('forms.fields.admin.accounts.subscription'),
                    }
                ),
                sizes: {
                  xs: 12,
                  md: 2,
                },
                type: 'switch',
              },
              {
                id: 'price',
                label: trans('forms.fields.admin.accounts.subscriptionPrice'),
                name: 'price',
                required: true,
                sizes: {
                  xs: 12,
                  sm: 4,
                  md: 2,
                },
                type: 'number',
              },
              {
                id: 'credits',
                label: trans('forms.fields.admin.accounts.subscriptionCredits'),
                name: 'credits',
                required: true,
                sizes: {
                  xs: 12,
                  sm: 4,
                  md: 2,
                },
                type: 'number',
              },
              {
                id: 'total',
                inputProps: {
                  readOnly: true,
                },
                propsByValues: values => (
                  values
                    ? {
                      label: `${
                        trans('forms.fields.admin.accounts.subscriptionTotal')
                      } ${getSubscriptionName(values)}`,
                    }
                    : {
                      label: trans('forms.fields.admin.accounts.subscriptionTotal'),
                    }
                ),
                name: 'total',
                sizes: {
                  xs: 12,
                  sm: 4,
                  md: 2,
                },
                type: 'number',
              },
              {
                id: 'username',
                label: trans('forms.fields.admin.accounts.subscriptionUsername'),
                name: 'username',
                inputProps: {
                  maxLength: 255,
                },
                sizes: {
                  xs: 12,
                  sm: 6,
                  md: 2,
                },
              },
              {
                id: 'password',
                label: trans('forms.fields.admin.accounts.subscriptionPassword'),
                name: 'password',
                inputProps: {
                  maxLength: 255,
                },
                sizes: {
                  xs: 12,
                  sm: 6,
                  md: 2,
                },
              },
              // {
              //   id: 'unlimited',
              //   label: trans('forms.fields.admin.accounts.replyUrl'),
              //   labelOff: trans('forms.fields.admin.accounts.replyUrlOff'),
              //   labelOn: trans('forms.fields.admin.accounts.replyUrlOn'),
              //   name: 'unlimited',
              //   sizes: {
              //     xs: 12,
              //     md: 4,
              //   },
              //   type: 'twoWaySwitch',
              // },
            ],
            size: {
              xs: 12,
            },
            title: trans('forms.fields.admin.accounts.subscriptionDetails'),
          },
          {
            id: 'apply',
            name: 'apply',
            label: trans('forms.fields.admin.accounts.applyCredits'),
            sizes: {
              xs: 12,
            },
            type: 'switch',
          },
        ],
      },
      {
        code: 'permissions',
        label: trans('forms.tabs.admin.accounts.permissions'),
        inputs: [
          {
            id: 'short-codes',
            label: trans('forms.fields.admin.accounts.shortCodes'),
            name: 'short_codes._ids',
            sizes: {
              xs: 12,
            },
            type: 'multipleCheckbox',
          },
        ],
      },
    ],
  }
];

export const EDIT_INPUTS = [
  {
    fieldType: 'tab',
    name: 'account',
    tabs: [
      {
        code: 'general',
        inputs: [
          {
            id: 'client-username',
            inputProps: {
              maxLength: 255,
            },
            label: trans('forms.fields.admin.accounts.clientUsername'),
            name: 'client.username',
            required: true,
            sizes: {
              xs: 12,
              sm: 6,
              md: 4,
            },
          },
          {
            id: 'account-type',
            label: trans('forms.fields.admin.accounts.accountType'),
            name: 'account_type',
            required: true,
            sizes: {
              xs: 12,
              sm: 6,
              md: 4,
            },
            type: 'select',
          },
          {
            id: 'status',
            label: trans('forms.fields.admin.accounts.status'),
            name: 'status',
            required: true,
            sizes: {
              xs: 12,
              sm: 6,
              md: 4,
            },
            type: 'select',
          },
          {
            id: 'channel-id',
            label: trans('forms.fields.admin.accounts.channelId'),
            name: 'channel_id',
            sizes: {
              xs: 12,
              sm: 6,
              md: 4,
            },
            type: 'select',
          },
        ],
        label: trans('forms.tabs.admin.accounts.general'),
      },
      {
        code: 'company',
        inputs: [
          {
            id: 'company-name',
            inputProps: {
              maxLength: 255,
            },
            label: trans('forms.fields.admin.accounts.companyName'),
            name: 'company.name',
            required: true,
            sizes: {
              xs: 12,
              sm: 6,
              md: 4,
            },
          },
          {
            id: 'company-business-name',
            inputProps: {
              maxLength: 255,
            },
            label: trans('forms.fields.admin.accounts.companyBusinessName'),
            name: 'company.business_name',
            required: true,
            sizes: {
              xs: 12,
              sm: 6,
              md: 4,
            },
          },
          {
            id: 'company-id-number',
            inputProps: {
              maxLength: 255,
            },
            label: trans('forms.fields.admin.accounts.companyIdNumber'),
            name: 'company.id_number',
            required: true,
            sizes: {
              xs: 12,
              sm: 6,
              md: 4,
            },
          },
          {
            id: 'company-business-type-id',
            label: trans('forms.fields.admin.accounts.companyBusinessTypeId'),
            name: 'company.business_type_id',
            required: true,
            sizes: {
              xs: 12,
              sm: 6,
              md: 4,
            },
            type: 'select',
          },
          {
            id: 'company-email',
            inputProps: {
              maxLength: 255,
            },
            label: trans('forms.fields.admin.accounts.companyEmail'),
            name: 'company.email',
            sizes: {
              xs: 12,
              sm: 6,
              md: 4,
            },
          },
          {
            id: 'company-phone',
            inputProps: {
              maxLength: 255,
            },
            label: trans('forms.fields.admin.accounts.companyPhone'),
            name: 'company.phone',
            sizes: {
              xs: 12,
              sm: 6,
              md: 4,
            },
          },
          {
            id: 'company-address',
            label: trans('forms.fields.admin.accounts.companyAddress'),
            multiline: true,
            name: 'company.address',
            rows: 4,
            sizes: {
              xs: 12,
            },
          },
        ],
        label: trans('forms.tabs.admin.accounts.company'),
      },
      {
        code: 'personal',
        inputs: [
          {
            id: 'client-person-first-name',
            inputProps: {
              maxLength: 255,
            },
            label: trans('forms.fields.admin.accounts.clientPersonFirstName'),
            name: 'client.person.first_name',
            sizes: {
              xs: 12,
              sm: 6,
              md: 4,
            },
          },
          {
            id: 'client-person-last-name',
            inputProps: {
              maxLength: 255,
            },
            label: trans('forms.fields.admin.accounts.clientPersonLastName'),
            name: 'client.person.last_name',
            sizes: {
              xs: 12,
              sm: 6,
              md: 4,
            },
          },
          {
            id: 'client-person-id-number',
            inputProps: {
              maxLength: 255,
            },
            label: trans('forms.fields.admin.accounts.clientPersonIdNumber'),
            name: 'client.person.id_number',
            sizes: {
              xs: 12,
              sm: 6,
              md: 4,
            },
          },
          {
            id: 'client-person-alt-id-number',
            inputProps: {
              maxLength: 255,
            },
            label: trans('forms.fields.admin.accounts.clientPersonAltIdNumber'),
            name: 'client.person.alt_id_number',
            sizes: {
              xs: 12,
              sm: 6,
              md: 4,
            },
          },
          {
            id: 'client-person-gender',
            label: trans('forms.fields.admin.accounts.clientPersonGender'),
            name: 'client.person.gender',
            sizes: {
              xs: 12,
              sm: 6,
              md: 4,
            },
            type: 'select',
          },
          {
            id: 'client-person-birt-date',
            inputFormat: API_DATE_FORMAT,
            label: trans('forms.fields.admin.accounts.clientPersonBirthDate'),
            name: 'client.person.birth_date',
            sizes: {
              xs: 12,
              sm: 6,
              md: 4,
            },
            type: 'datepicker',
          },
        ],
        label: trans('forms.tabs.admin.accounts.personal'),
      },
      {
        code: 'subscription',
        inputs: [
          {
            id: 'start-date',
            inputFormat: API_DATE_FORMAT,
            label: trans('forms.fields.admin.accounts.startDate'),
            name: 'start_date',
            required: true,
            sizes: {
              xs: 12,
              sm: 6,
              md: 4,
            },
            type: 'datepicker',
          },
          {
            id: 'due-date',
            inputFormat: API_DATE_FORMAT,
            label: trans('forms.fields.admin.accounts.dueDate'),
            name: 'due_date',
            required: true,
            sizes: {
              xs: 12,
              sm: 6,
              md: 4,
            },
            type: 'datepicker',
          },
          {
            id: 'prepaid',
            label: trans('forms.fields.admin.accounts.prepaid'),
            labelOff: trans('forms.fields.admin.accounts.prepaidOff'),
            labelOn: trans('forms.fields.admin.accounts.prepaidOn'),
            name: 'prepaid',
            sizes: {
              xs: 12,
              sm: 6,
              md: 4,
            },
            type: 'twoWaySwitch',
          },
          {
            fieldType: 'array',
            fixed: true,
            name: 'subscriptions',
            inputs: [
              {
                id: 'active',
                name: 'active',
                propsByValues: values => (
                  values
                    ? {
                      label: getSubscriptionName(values),
                    }
                    : {
                      label: trans('forms.fields.admin.accounts.subscription'),
                    }
                ),
                sizes: {
                  xs: 12,
                  md: 2,
                },
                type: 'switch',
              },
              {
                id: 'price',
                label: trans('forms.fields.admin.accounts.subscriptionPrice'),
                name: 'price',
                required: true,
                sizes: {
                  xs: 12,
                  sm: 6,
                  md: 2,
                },
                type: 'number',
              },
              {
                id: 'credits',
                label: trans('forms.fields.admin.accounts.subscriptionCredits'),
                name: 'credits',
                required: true,
                sizes: {
                  xs: 12,
                  sm: 6,
                  md: 2,
                },
                type: 'number',
              },
              {
                id: 'total',
                inputProps: {
                  readOnly: true,
                },
                propsByValues: values => (
                  values
                    ? {
                      label: `${
                        trans('forms.fields.admin.accounts.subscriptionTotal')
                      } ${getSubscriptionName(values)}`,
                    }
                    : {
                      label: trans('forms.fields.admin.accounts.subscriptionTotal'),
                    }
                ),
                name: 'total',
                sizes: {
                  xs: 12,
                  sm: 4,
                  md: 2,
                },
                type: 'number',
              },
              {
                id: 'username',
                label: trans('forms.fields.admin.accounts.subscriptionUsername'),
                name: 'username',
                inputProps: {
                  maxLength: 255,
                },
                sizes: {
                  xs: 12,
                  sm: 6,
                  md: 2,
                },
              },
              {
                id: 'password',
                label: trans('forms.fields.admin.accounts.subscriptionPassword'),
                name: 'password',
                inputProps: {
                  maxLength: 255,
                },
                sizes: {
                  xs: 12,
                  sm: 6,
                  md: 2,
                },
              },
              // {
              //   id: 'unlimited',
              //   label: trans('forms.fields.admin.accounts.replyUrl'),
              //   labelOff: trans('forms.fields.admin.accounts.replyUrlOff'),
              //   labelOn: trans('forms.fields.admin.accounts.replyUrlOn'),
              //   name: 'unlimited',
              //   sizes: {
              //     xs: 12,
              //     md: 4,
              //   },
              //   type: 'twoWaySwitch',
              // },
            ],
            label: trans('forms.tabs.admin.accounts.subscription'),
            size: {
              xs: 12,
            },
            title: trans('forms.fields.admin.accounts.subscriptionDetails'),
          },
        ],
        label: trans('forms.tabs.admin.accounts.subscription'),
      },
      {
        code: 'permissions',
        label: trans('forms.tabs.admin.accounts.permissions'),
        inputs: [
          {
            id: 'short-codes',
            label: trans('forms.fields.admin.accounts.shortCodes'),
            name: 'short_codes._ids',
            sizes: {
              xs: 12,
            },
            type: 'multipleCheckbox',
          },
        ],
      },
      {
        code: 'change_password',
        inputs: [
          {
            id: 'client-password',
            inputProps: {
              maxLength: 255,
            },
            label: trans('forms.fields.admin.accounts.clientPassword'),
            name: 'client.password',
            sizes: {
              xs: 12,
              sm: 6,
              md: 4,
            },
            type: 'password',
          },
          {
            id: 'client-password-confirm',
            inputProps: {
              maxLength: 255,
            },
            label: trans('forms.fields.admin.accounts.clientPasswordConfirm'),
            name: 'client.password_confirm',
            sizes: {
              xs: 12,
              sm: 6,
              md: 4,
            },
            type: 'password',
          },
        ],
        label: trans('forms.tabs.admin.accounts.changePassword'),
      },
    ],
  }
];

export const ACCOUNT_VALIDATIONS = [
  {
    field: 'account_type',
    message: ['default.forms.validations.isRequired'],
    method: 'isNull',
    validWhen: false,
  },
  {
    field: 'due_date',
    message: ['default.forms.validations.isRequired'],
    method: 'isEmpty',
    validWhen: false,
  },
  {
    field: 'start_date',
    message: ['default.forms.validations.isRequired'],
    method: 'isEmpty',
    validWhen: false,
  },
  {
    field: 'status',
    message: ['default.forms.validations.isRequired'],
    method: 'isNull',
    validWhen: false,
  },
  {
    field: 'client',
    object: true,
    validations: [
      {
        field: 'username',
        message: ['default.forms.validations.isRequired'],
        method: 'isEmpty',
        validWhen: false,
      },
      {
        field: 'password',
        message: ['default.forms.validations.isRequired'],
        method: 'isEmpty',
        validWhen: false,
      },
      {
        field: 'password_confirm',
        message: ['default.forms.validations.isRequired'],
        method: 'isEmpty',
        validWhen: false,
      },
      {
        args: ['password'],
        field: 'password_confirm',
        message: [
          'default.forms.validations.matchesField',
          {
            field: trans('forms.fields.admin.accounts.clientPassword'),
          },
        ],
        method: 'matchesField',
        validWhen: true,
      },
    ],
  },
  {
    field: 'company',
    object: true,
    validations: [
      {
        field: 'name',
        message: ['default.forms.validations.isRequired'],
        method: 'isEmpty',
        validWhen: false,
      },
      {
        field: 'business_name',
        message: ['default.forms.validations.isRequired'],
        method: 'isEmpty',
        validWhen: false,
      },
      {
        field: 'id_number',
        message: ['default.forms.validations.isRequired'],
        method: 'isEmpty',
        validWhen: false,
      },
      {
        field: 'business_type_id',
        message: ['default.forms.validations.isRequired'],
        method: 'isEmpty',
        validWhen: false,
      },
    ],
  },
  {
    array: true,
    field: 'subscriptions',
    validations: [
      {
        args: ['active'],
        field: 'price',
        message: ['default.forms.validations.isRequired'],
        method: 'isEmptyIf',
        validWhen: false,
      },
      {
        args: [{ gt: 0 }, 'active'],
        field: 'price',
        message: ['default.forms.validations.isHigherThan', { min: 0 }],
        method: 'isFloatIf',
        validWhen: true,
      },
      {
        args: ['active'],
        field: 'credits',
        message: ['default.forms.validations.isRequired'],
        method: 'isEmptyIf',
        validWhen: false,
      },
      {
        args: [{ min: 1 }, 'active'],
        field: 'credits',
        message: ['default.forms.validations.isHigherThan', { min: 0 }],
        method: 'isIntIf',
        validWhen: true,
      },
    ],
  }
];

export const ACCOUNT_PARSE_CONFIG = [
  {
    field: 'start_date',
    parser: 'date',
    params: [API_DATE_FORMAT],
  },
  {
    field: 'due_date',
    parser: 'date',
    params: [API_DATE_FORMAT],
  },
  {
    field: 'client.person.birth_date',
    parser: 'date',
    params: [API_DATE_FORMAT],
  },
  {
    field: 'subscriptions',
    parser: 'toggleArray',
  },
];

export const DEFAULT_ADD_CREDITS_VALUES = {
  account_id: null,
  integrator_id: null,
  credits: 0,
};

export const ADD_CREDITS_INPUTS = [
  {
    id: 'account',
    label: trans('forms.fields.admin.addCredits.account'),
    name: 'account_id',
    required: true,
    sizes: {
      xs: 12,
      sm: 6,
      md: 4,
    },
    type: 'select',
  },
  {
    condition: ({ account_id }) => account_id,
    id: 'integrator',
    label: trans('forms.fields.admin.addCredits.integrator'),
    name: 'integrator_id',
    required: true,
    sizes: {
      xs: 12,
      sm: 6,
      md: 4,
    },
    type: 'select',
  },
  {
    condition: ({ integrator_id }) => integrator_id,
    id: 'current_credits',
    inputProps: {
      readOnly: true,
    },
    label: trans('forms.fields.admin.addCredits.currentCredits'),
    name: 'current_credits',
    sizes: {
      xs: 12,
      sm: 6,
      md: 2,
    },
    type: 'number',
  },
  {
    condition: ({ integrator_id }) => integrator_id,
    id: 'credits',
    label: trans('forms.fields.admin.addCredits.credits'),
    name: 'credits',
    required: true,
    sizes: {
      xs: 12,
      sm: 6,
      md: 2,
    },
    type: 'number',
  },
];

export const ADD_CREDITS_VALIDATIONS = [
  {
    field: 'account_id',
    message: ['default.forms.validations.isRequired'],
    method: 'isEmpty',
    validWhen: false,
  },
  {
    field: 'integrator_id',
    message: ['default.forms.validations.isRequired'],
    method: 'isEmpty',
    validWhen: false,
  },
  {
    field: 'credits',
    message: ['default.forms.validations.isRequired'],
    method: 'isEmpty',
    validWhen: false,
  },
];

export const ACCOUNT_FORM = 'account';
export const ADD_CREDITS_FORM = 'addCredits';

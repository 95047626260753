import React from 'react';
import Grid from '@material-ui/core/Grid';
import Actions from './Actions';
import Fields from './Fields';

class Form extends React.Component {
  backToList = () => {
    const { history, path } = this.props;
    history.push(path);
  };

  parseActions = () => {
    const { actions } = this.props;

    return actions.map(action => {
      const { handler } = action;
      const onClick = handler && this[handler];

      return ({
        onClick,
        ...action,
      })
    });
  };

  render() {
    const {
      afterChange,
      data,
      inputs,
      loading,
      onSubmit,
      spacing,
      valid,
      values,
    } = this.props;
    const fieldsProps = {
      afterChange,
      data,
      inputs,
      spacing,
      values,
    };
    const actions = this.parseActions();

    return (
      <form onSubmit={onSubmit}>
        <Grid container spacing={spacing}>
          <Fields {...fieldsProps} />
        </Grid>
        {
          !!actions.length &&
          <Actions
            actions={actions}
            loading={loading}
            spacing={spacing}
            valid={valid}
          />
        }
      </form>
    );
  }
}

Form.defaultProps = {
  actions: [],
  loading: false,
  spacing: 4,
};

export default Form;

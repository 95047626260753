import React from 'react';
import Typography from '@material-ui/core/Typography';

export default ({ maxLength, value }) => (
  <Typography component="p">
    {typeof value === 'string' && value.length}
    {
      maxLength &&
      <React.Fragment>
        &nbsp;/&nbsp;
        <Typography color="secondary" component="span">
          {maxLength}
        </Typography>
      </React.Fragment>
    }
  </Typography>
);

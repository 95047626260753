import { createResource } from 'redux-rest-resource';
import { HEADERS as headers, SHORT_CODES_URL } from '../../Constants';
import { transformFetchResponse as transformResponse } from '../../Helpers';

export const { types, actions, reducers } = createResource({
  name: 'shortCode',
  url: `${SHORT_CODES_URL}/:id`,
  headers,
  actions: {
    createMany: {
      gerundName: 'creatingMany',
      isArray: true,
      method: 'POST',
      url: `${SHORT_CODES_URL}/many`,
    },
    fetch: {
      transformResponse,
    },
  },
});

import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import ResponsiveForm from '../../Form';
import { validate } from '../../../../Helpers';
import {
  INTEGRATOR_FORM as form,
  INTEGRATOR_VALIDATIONS,
  EDIT_INPUTS,
} from './config';

let EditIntegratorForm = ({ handleSubmit, loading = false, ...props }) => (
  <ResponsiveForm
    {...props}
    inputs={EDIT_INPUTS}
    loading={loading}
    onSubmit={handleSubmit}
  />
);

EditIntegratorForm = reduxForm({
  form,
  validate: validate(INTEGRATOR_VALIDATIONS),
})(EditIntegratorForm);

const mapStateToProps = ({
  integrators: {
    item,
  },
}) => ({
  initialValues: item ? item.data : {},
});

export default connect(
  mapStateToProps
)(EditIntegratorForm);

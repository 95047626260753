import React from 'react';
import ReactDOM from 'react-dom';
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';
import * as serviceWorker from './serviceWorker';
import Routes from './Routes';
import { configureStore } from './Redux/Store';
import { THEME } from './Constants';

const { persistor, store } = configureStore();

am4core.useTheme(am4themes_animated);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider theme={THEME}>
        <Routes />
      </ThemeProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

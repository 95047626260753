import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from './Styled/NormalDialogContent';
import trans from '../../Translations/translation.service';

export default ({
  children,
  onAcceptClick,
  acceptText = '',
  action = '',
  noActions = false,
  open = false,
  size = '',
  subject = '',
  successText = '',
  title = '',
}) => {
  return (
    <Dialog
      maxWidth={size === 'big' ? 'md' : 'sm'}
      open={open}
      onClose={onAcceptClick}
    >
      <DialogTitle>
        {title}
      </DialogTitle>
      <DialogContent size={size}>
        <DialogContentText>
          {children}
        </DialogContentText>
      </DialogContent>
      {
        !noActions &&
        <DialogActions>
          <Button onClick={onAcceptClick}>
            {acceptText || trans('modals.actions.accept')}
          </Button>
        </DialogActions>
      }
    </Dialog>
  );
}

import React from 'react';
import Box from '../../Components/Login/Styled/Box';
import Form from '../../Components/Login/Form';
import GlobalStyles from '../../Components/GlobalStyles';
import WhiteInfomailLogo from '../../Components/Logos/Infomail/White.jsx';
import Wrapper from '../../Components/Login/Styled/Wrapper';

export default () => (
  <Wrapper>
    <Box>
      <WhiteInfomailLogo />
      <Form />
    </Box>
    <GlobalStyles />
  </Wrapper>
);

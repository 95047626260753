import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import ResponsiveForm from '../../Form';
import { validate } from '../../../../Helpers';
import {
  PAYMENT_FORM as form,
  PAYMENT_VALIDATIONS,
  INPUTS,
} from './config';

let EditPaymentForm = ({ formData, handleSubmit, loading = false, ...props }) => (
  <ResponsiveForm
    {...props}
    inputs={INPUTS}
    loading={loading}
    onSubmit={handleSubmit}
    values={formData}
  />
);

EditPaymentForm = reduxForm({
  form,
  validate: validate(PAYMENT_VALIDATIONS),
})(EditPaymentForm);

const mapStateToProps = ({
  payments: {
    item,
  },
  ...state
}) => ({
  initialValues: item ? item.data : {},
});

export default connect(
  mapStateToProps
)(EditPaymentForm);

import styled from 'styled-components';
import { COLORS } from '../../../Constants';

export default styled.div`
    background-color: ${COLORS.grey5};
    box-sizing: border-box;
    font-size: 14px;
    height: 30px;
    left: 0;
    line-height: 20px;
    padding: 5px;
    position: fixed;
    top: 0;
    white-space: nowrap;
    width: 100%;
    z-index: 1000;

    &>div {
        height: 100%;
        overflow: hidden;

        &>div {
            display: flex;
            animation: slide 90s linear infinite;

            &:hover {
                animation-play-state: paused;
            }
        }
    }

    @keyframes slide {
        0% {
            transform: translateX(calc(100vw - 20px));
        }
        100% {
            transform: translateX(-100%);
        }
    }

    @-webkit-keyframes slide {
        0% {
            transform: translateX(calc(100vw - 20px));
        }
        100% {
            transform: translateX(-100%);
        }
    }
`;

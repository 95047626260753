import styled from 'styled-components';

export default styled.div`
  max-width: 360px;
  text-align: center;
  width: 90%;

  .white-infomail-logo {
    margin-bottom: 20px;
    width: 60%;
  }
`;

import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import { parse } from 'query-string';
import Table from '../../../Components/Table';
import EllipsisCell from '../../../Components/Table/EllipsisCell';
import { actions as allAccountsActions } from '../../../Redux/Api/AllAccounts';
import { actions as allCampaignsActions } from '../../../Redux/Api/AllCampaigns';
import { actions as allIntegratorsActions } from '../../../Redux/Api/AllIntegrators';
import { actions as navBarActions } from '../../../Redux/Ducks/Navbar';
import { actions as sentMessagesActions } from '../../../Redux/Api/Messages';
import {
  ADMIN_SMS_STATS_FILTERS_INPUTS,
  CARRIERS_SELECT_OPTIONS,
} from '../../Dashboards/config';
import { FILTERS_FORM as form } from '../../../Components/Filters/config';
import {
  ALL_SELECT_OPTION_F,
  ALL_SELECT_OPTION_VALUE as ALL,
  API_DATE_FORMAT,
  API_DATE_FORMAT_FIRST,
  ADMIN_SENT_MESSAGES_TABLE_TITLES,
} from '../../../Constants';
import { getCampaignName, getPage, parseApiDate, parseApiTime } from '../../../Helpers';

class ClientSentMessages extends React.Component {
  state = {
    account: ALL,
    filters: {
      from: moment().format(API_DATE_FORMAT_FIRST),
      // to: moment().add(1,'d').format(API_DATE_FORMAT_FIRST),
    },
  };

  fetchAllCampaigns = () => {
    const { account } = this.state;

    if (account === ALL) {
      this.props.fetchAllCampaigns();
    } else {
      this.props.fetchAllCampaigns({}, { query: { account } });
    }
  };

  fetchMessages = newPage => {
    const { filters } = this.state;
    const { location, order_by, sort_by } = this.props;
    const page = getPage(newPage, location);
    const sorting = { sort_by, order_by };

    this.props.fetchMessages({}, { query: { page, outgoing: true, ...filters, ...sorting } });
  };

  fetchDataFromAccount = account => {
    const { filters } = this.state;

    if (account === ALL) {
      delete filters.account;
    } else {
      filters.account =  account;
    }

    delete filters.campaign;
    this.props.change(form, 'campaign', ALL);

    this.setState({ account },  () => {
      this.fetchAllCampaigns();
      this.fetchMessages();
    });
  };

  fetchMessagesFromApi = api => {
    const { filters } = this.state;

    if (api) {
      filters.api = api;
    } else {
      delete filters.api;
    }

    this.setState({ filters }, () => {
      this.fetchMessages();
    });
  };

  fetchMessagesFromCampaign = campaign => {
    const { filters } = this.state;

    if (campaign === ALL) {
      delete filters.campaign;
    } else {
      filters.campaign =  campaign;
    }

    this.setState({ filters }, () => {
      this.fetchMessages();
    });
  };

  fetchMessagesFromCarrier = carrier => {
    const { filters } = this.state;

    if (carrier === ALL) {
      delete filters.carrier;
    } else {
      filters.carrier =  carrier;
    }

    this.setState({ filters }, () => {
      this.fetchMessages();
    });
  };

  fetchMessagesFromDateFrom = date => {
    const { filters } = this.state;

    const momentDate = moment(date, API_DATE_FORMAT);

    if (momentDate._isValid) {
      filters.from =  date;
    } else {
      delete filters.from;
    }

    this.setState({ filters }, () => {
      this.fetchMessages();
    });
  };

  fetchMessagesFromDateTo = date => {
    const { filters } = this.state;

    const momentDate = moment(date, API_DATE_FORMAT);

    if (momentDate._isValid) {
      filters.to =  date;
    } else {
      delete filters.to;
    }

    this.setState({ filters }, () => {
      this.fetchMessages();
    });
  };

  fetchMessagesFromIntegrator = integrator => {
    const { filters } = this.state;

    if (integrator === ALL) {
      delete filters.integrator;
    } else {
      filters.integrator =  integrator;
    }

    this.setState({ filters }, () => {
      this.fetchMessages();
    });
  };

  handleSearch = (search)  => {
    const { filters } = this.state;

    filters.receipent = search;

    this.setState({ filters }, () => {
      this.fetchMessages();
    });
  };

  parseSentMessageData = ({
    campaign_message,
    receipent,
    content,
    message_count,
    integrator,
    carrier,
    user,
    send_date,
    send_time,
    api,
    test,
  }) => [
    {
      text: receipent,
    },
    {
      text: <EllipsisCell text={content} />,
    },
    {
      text: message_count,
    },
    {
      text: getCampaignName(campaign_message, api, test),
    },
    {
      text: integrator && integrator.name,
    },
    // {
    //   text: CARRIERS[carrier],
    // },
    {
      text: user && user.account && user.account.company && user.account.company.name,
    },
    {
      noWrap: true,
      text: `${parseApiDate(send_date)} ${parseApiTime(send_time)}`,
    },
  ];

  getDefaultCarrier = () => {
    const { filters } = this.state;
    const { location } = this.props;
    const query = location && parse(location.search);

    if (query && query.carrier) {
        filters.carrier = query.carrier;
        this.setState({ filters }, this.fetchMessages);

        return query.carrier;
    } else {
      this.fetchMessages();
    }

    return ALL;
  };

  initFilters = () => {
    const today = moment();
    this.props.reset(form);
    this.props.change(form, 'from_date', today.format(API_DATE_FORMAT_FIRST));
    this.props.change(form, 'to_date', today.format(API_DATE_FORMAT));
    this.props.change(form, 'account_id', ALL);
    this.props.change(form, 'campaign_id', ALL);
    this.props.change(form, 'carrier', this.getDefaultCarrier());
    this.props.change(form, 'integrator_id', ALL);
  };

  componentDidMount() {
    this.fetchAllCampaigns();
    this.initFilters();
    this.props.fetchAllAccounts();
    this.props.fetchAllIntegrators();
    this.props.setOnSearch(this.handleSearch);
  }

  loading = () => {
    const {
      isFetchingAccounts,
      isFetchingCampaigns,
      isFetchingIntegrators,
    } = this.props;
    return isFetchingAccounts
      || isFetchingCampaigns
      || isFetchingIntegrators;
  };

  parseAccounts = () => {
    const accounts = this.props.accounts.map(({ id: value, company_name: text }) => ({
      text, value,
    }));

    accounts.unshift(ALL_SELECT_OPTION_F);

    return accounts;
  };

  parseCampaigns = () => {
    const campaigns = this.props.campaigns.map(({ id: value, name: text }) => ({
      text, value,
    }));

     campaigns.unshift(ALL_SELECT_OPTION_F);

    return campaigns;
  };

  parseIntegrators = () => {
    const integrators = this.props.integrators.map(({ id: value, name: text }) => ({
      text, value,
    }));

    integrators.unshift(ALL_SELECT_OPTION_F);

    return integrators;
  };

  render() {
    const {
      isFetchingSentMessages,
      pagination,
      sentMessages,
      showFilters,
      showPagination,
      onDashboard
    } = this.props;

    const accounts = this.parseAccounts();
    const campaigns = this.parseCampaigns();
    const integrators = this.parseIntegrators();

    return (
      <Table
        data={isFetchingSentMessages ? [] : sentMessages}
        dataParser={this.parseSentMessageData}
        handleChangePage={this.fetchMessages}
        loading={!onDashboard && isFetchingSentMessages}
        pagination={pagination}
        showPagination={showPagination}
        titles={ADMIN_SENT_MESSAGES_TABLE_TITLES}
        filtersProps={showFilters && {
          afterChange: {
            account_id: this.fetchDataFromAccount,
            api: this.fetchMessagesFromApi,
            campaign_id: this.fetchMessagesFromCampaign,
            from_date: this.fetchMessagesFromDateFrom,
            to_date: this.fetchMessagesFromDateTo,
            carrier: this.fetchMessagesFromCarrier,
            integrator_id: this.fetchMessagesFromIntegrator,
          },
          data: {
            account_id: accounts,
            campaign_id: campaigns,
            integrator_id: integrators,
            carrier: CARRIERS_SELECT_OPTIONS,
          },
          inputs: ADMIN_SMS_STATS_FILTERS_INPUTS,
          loading: this.loading(),
        }}
      />
    );
  }
}

ClientSentMessages.defaultProps = {
  order_by: 'created',
  showFilters: true,
  showPagination: true,
  sort_by: 'DESC',
};

const mapStateToProps = ({
  allAccounts: {
    isFetching: isFetchingAccounts,
    items: accounts,
  },
  allCampaigns: {
    isFetching: isFetchingCampaigns,
    items: campaigns,
  },
  allIntegrators: {
    isFetching: isFetchingIntegrators,
    items: integrators,
  },
  messages: {
    isFetching,
    items: [success, data, pagination],
  },
}) => ({
  accounts: accounts[1] ? accounts[1] : [],
  campaigns: campaigns[1] ? campaigns[1] : [],
  isFetchingSentMessages: isFetching,
  sentMessages: success ? data : [],
  integrators: integrators[1] ? integrators[1] : [],
  isFetchingAccounts,
  isFetchingCampaigns,
  isFetchingIntegrators,
  pagination,
  success,
});

export default connect(
  mapStateToProps,
  {
    ...allAccountsActions,
    ...allCampaignsActions,
    ...allIntegratorsActions,
    ...navBarActions,
    ...sentMessagesActions,
    change,
    reset,
  }
)(ClientSentMessages);

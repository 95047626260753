import React from 'react';
import StyledWrapper from './Styled/Wrapper';

export default ({ children, handleMouseEnter, handleMouseLeave, hasNews, open, className = '' }) => (
  <StyledWrapper
    className={className}
    hasNews={hasNews}
    open={open}
    onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave}
  >
    <div className="navbar-content">
      {children}
    </div>
  </StyledWrapper>
);

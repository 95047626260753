import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import ResponsiveForm from '../../Form';
import { validate } from '../../../../Helpers';
import {
  DEFAULT_VALUES,
  ADD_INPUTS,
  GROUP_FORM as form,
  GROUP_VALIDATIONS,
} from './config';

let AddGroupsForm = ({ handleSubmit, loading = false, ...props }) => (
  <ResponsiveForm
    {...props}
    inputs={ADD_INPUTS}
    loading={loading}
    onSubmit={handleSubmit}
  />
);

AddGroupsForm = reduxForm({
  form,
  validate: validate(GROUP_VALIDATIONS),
})(AddGroupsForm);

const mapStateToProps = () => ({
  initialValues: { ...DEFAULT_VALUES },
});

export default connect(
  mapStateToProps
)(AddGroupsForm);

import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import AccountCircle from '@material-ui/icons/AccountCircle';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuItem from '@material-ui/core/MenuItem';
import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew';
import FlagIcon from './Styled/StyledFlagIcon';
import ListItemText from './Styled/StyledListItemText';
import StyledMenu from './Styled/StyledMUIMenu';
import StyledHeaderMenus from './Styled/StyledHeaderMenus';
import { CLIENT_ROUTE_TYPE } from '../../../../Constants';
import { PRIVATE_PATHS } from '../../../../Routes/config';
import trans from '../../../../Translations/translation.service';
import { actions as navbarActions } from '../../../../Redux/Ducks/Navbar';

class HeaderMenus extends React.Component {
  state= {
    accountMenuAnchor: null,
    localeMenuAnchor: null,
  };

  getUsername = () => {
    const { account, routeType, user } = this.props;

    return routeType !== CLIENT_ROUTE_TYPE
      ? user.username
      : account.company_name;
  };

  setAccountMenuAnchor = (event = null) => {
    this.setState({ accountMenuAnchor: event && event.currentTarget });
  };

  setLocale = locale => {
    localStorage.setItem('locale', locale);
    this.props.setLocale(locale);
    this.setState({ locale, localeMenuAnchor: null }, () => {
      window.location.reload();
    });
  };

  setLocaleMenuAnchor = (event = null) => {
    this.setState({ localeMenuAnchor: event && event.currentTarget });
  };

  render() {
    const { accountMenuAnchor, localeMenuAnchor } = this.state;
    const { locale } = this.props;

    return (
      <StyledHeaderMenus>
        <IconButton
          color="inherit"
          edge="end"
          onClick={this.setLocaleMenuAnchor}
        >
          <FlagIcon src={`img/icons/${locale}.svg`} />
        </IconButton>
        <StyledMenu
          anchorEl={localeMenuAnchor}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          elevation={0}
          getContentAnchorEl={null}
          keepMounted
          onClose={() => this.setLocaleMenuAnchor()}
          open={!!localeMenuAnchor}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MenuItem onClick={() => this.setLocale('en')}>
            <ListItemIcon>
              <FlagIcon src='img/icons/en.svg' />
            </ListItemIcon>
            <ListItemText>{trans('default.langs.en')}</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => this.setLocale('es')}>
            <ListItemIcon>
              <FlagIcon src='img/icons/es.svg' />
            </ListItemIcon>
            <ListItemText>{trans('default.langs.es')}</ListItemText>
          </MenuItem>
        </StyledMenu>
        <IconButton
          color="inherit"
          edge="end"
          onClick={this.setAccountMenuAnchor}
        >
          <AccountCircle />
        </IconButton>
        <StyledMenu
          anchorEl={accountMenuAnchor}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          elevation={0}
          getContentAnchorEl={null}
          keepMounted
          onClose={() => this.setAccountMenuAnchor()}
          open={!!accountMenuAnchor}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MenuItem>
            <ListItemIcon />
            <ListItemText>{this.getUsername()}</ListItemText>
          </MenuItem>
          <Link to={PRIVATE_PATHS.logout.path}>
            <MenuItem>
              <ListItemIcon>
                <PowerSettingsNew />
              </ListItemIcon>
              <ListItemText>{trans('default.logout')}</ListItemText>
            </MenuItem>
          </Link>
        </StyledMenu>
      </StyledHeaderMenus>
    );
  }
}

const mapStateToProps = ({
  accounts: {
    item: account,
  },
  auth: { user },
  navbar: { locale, routeType },
}) => ({
  account: account ? account.data : {},
  locale,
  routeType,
  user,
});

export default connect(mapStateToProps, {
  ...navbarActions,
})(HeaderMenus);

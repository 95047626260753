import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import ResponsiveForm from '../../Form';
import { validate } from '../../../../Helpers';
import {
  DEFAULT_ADD_VALUES,
  INPUTS,
  BUSINESS_TYPES_FORM as form,
  BUSINESS_TYPES_VALIDATIONS,
} from './config';

let AddBusinessTypesForm = ({ handleSubmit, loading = false, ...props }) => (
  <ResponsiveForm
    {...props}
    inputs={INPUTS}
    loading={loading}
    onSubmit={handleSubmit}
  />
);

AddBusinessTypesForm = reduxForm({
  form,
  validate: validate(BUSINESS_TYPES_VALIDATIONS),
})(AddBusinessTypesForm);

const mapStateToProps = () => ({
  initialValues: { ...DEFAULT_ADD_VALUES },
});

export default connect(
  mapStateToProps
)(AddBusinessTypesForm);

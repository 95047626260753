import styled from 'styled-components';

export default styled.div`
  font-size: 12px;
  height: 30px;
  line-height: 30px;
  padding: 0 15px;
  text-align: right;

  * {
    vertical-align: middle;
  }

  .scroll-up {
    height: 14px;
    transform: rotate(-90deg);
  }
`;

import AccountBalance from '@material-ui/icons/AccountBalance';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Add from '@material-ui/icons/Add';
import Assessment from '@material-ui/icons/Assessment';
import Assignment from '@material-ui/icons/Assignment';
import BusinessCenter from '@material-ui/icons/BusinessCenter';
import Contacts from '@material-ui/icons/Contacts';
import Delete from '@material-ui/icons/Delete';
import Email from '@material-ui/icons/Email';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import MonetizationOn from '@material-ui/icons/MonetizationOn';
import People from '@material-ui/icons/People';
import Person from '@material-ui/icons/Person';
import PhoneAndroid from '@material-ui/icons/PhoneAndroid';
import RecordVoiceOver from '@material-ui/icons/RecordVoiceOver';
import Settings from '@material-ui/icons/Settings';
import SettingsInputAntenna from '@material-ui/icons/SettingsInputAntenna';
import TableChart from '@material-ui/icons/TableChart';
import trans from '../Translations/translation.service';

export const OPTIONS = [
  [
    {
      name: 'adminAccounts',
      image: AccountCircle,
      title: trans('menu.options.titles.admin.accounts'),
      subMenu: [
        {
          name: 'adminAddAccount',
          path: '/admin-add-account',
          title: trans('menu.options.titles.admin.addAccount'),
        },
        {
          name: 'adminAddCredits',
          path: '/admin-add-credits',
          title: trans('menu.options.titles.admin.addCredits'),
        },
        {
          name: 'adminAccounts',
          path: '/admin-accounts',
          title: trans('menu.options.titles.admin.accountsList'),
        },
        {
          name: 'adminReportsSMS',
          path: '/admin-reports-sms',
          title: trans('menu.options.titles.admin.reportsSMS'),
        },
      ],
    },
    {
      name: 'adminActivities',
      image: Assessment,
      title: trans('menu.options.titles.activities'),
      subMenu: [
        {
          name: 'adminActivities',
          path: '/admin-activities',
          title: trans('menu.options.titles.activitiesList'),
        },
      ],
    },
    {
      name: 'adminMessages',
      image: Email,
      title: trans('menu.options.titles.admin.messages'),
      subMenu: [
        {
          name: 'adminSentMessages',
          path: '/admin-sent-messages',
          title: trans('menu.options.titles.sentMessages'),
        },
      ],
    },
    {
      name: 'adminCampaigns',
      image: RecordVoiceOver,
      title: trans('menu.options.titles.admin.campaigns'),
      subMenu: [
        {
          name: 'adminScheduledCampaigns',
          path: '/admin-scheduled-campaigns',
          title: trans('menu.options.titles.scheduledCampaigns'),
        },
        {
          name: 'adminSentCampaigns',
          path: '/admin-sent-campaigns',
          title: trans('menu.options.titles.sentCampaigns'),
        },
      ],
    },
    {
      name: 'adminIntegrators',
      image: SettingsInputAntenna,
      title: trans('menu.options.titles.admin.integrators'),
      subMenu: [
        {
          name: 'adminIntegrators',
          path: '/admin-integrators',
          title: trans('menu.options.titles.admin.integratorsList'),
        },
      ],
    },
    {
      name: 'adminPayments',
      image: MonetizationOn,
      title: trans('menu.options.titles.admin.payments'),
      subMenu: [
        {
          name: 'adminAddPayment',
          path: '/admin-add-payment',
          title: trans('menu.options.titles.addPayment'),
        },
        {
          name: 'adminPaymentsList',
          path: '/admin-payments',
          title: trans('menu.options.titles.admin.paymentsList'),
        },
      ],
    },
    {
      name: 'adminBanks',
      image: AccountBalance,
      title: trans('menu.options.titles.admin.banks'),
      subMenu: [
        {
          name: 'admin_add_banks',
          path: '/admin-add-banks',
          title: trans('menu.options.titles.admin.addBanks'),
        },
        {
          name: 'adminBanks',
          path: '/admin-banks',
          title: trans('menu.options.titles.admin.banksList'),
        },
      ],
    },
    {
      name: 'adminCompanies',
      image: BusinessCenter,
      title: trans('menu.options.titles.admin.companies'),
      subMenu: [
        {
          name: 'adminAddBusinessTypes',
          path: '/admin-add-business-types',
          title: trans('menu.options.titles.admin.addBusinessTypes'),
        },
        {
          name: 'adminAddIndustries',
          path: '/admin-add-industries',
          title: trans('menu.options.titles.admin.addIndustries'),
        },
        {
          name: 'adminBusinessTypes',
          path: '/admin-business-types',
          title: trans('menu.options.titles.admin.businessTypesList'),
        },
        {
          name: 'adminIndustries',
          path: '/admin-industries',
          title: trans('menu.options.titles.admin.industriesList'),
        },
      ],
    },
    {
      name: 'adminNews',
      image: LibraryBooks,
      title: trans('menu.options.titles.admin.news'),
      subMenu: [
        {
          name: 'adminAddNew',
          path: '/admin-add-new',
          title: trans('menu.options.titles.admin.addNew'),
        },
        {
          name: 'adminNews',
          path: '/admin-news',
          title: trans('menu.options.titles.admin.newsList'),
        },
      ],
    },
    {
      name: 'adminShortCodes',
      image: PhoneAndroid,
      title: trans('menu.options.titles.shortCodes'),
      subMenu: [
        {
          name: 'adminShortCodeDashboard',
          path: '/admin-short-codes-dashboard',
          title: trans('menu.options.titles.shortCodesDashboard'),
        },
        {
          name: 'adminAddKeyword',
          path: '/admin-add-keyword',
          title: trans('menu.options.titles.admin.addKeyword'),
        },
        {
          name: 'adminAddShortCodes',
          path: '/admin-add-short-codes',
          title: trans('menu.options.titles.admin.addShortCodes'),
        },
        {
          name: 'adminKeywords',
          path: '/admin-keywords',
          title: trans('menu.options.titles.keywordsList'),
        },
        {
          name: 'adminShortCodes',
          path: '/admin-short-codes',
          title: trans('menu.options.titles.shortCodesList'),
        },
        {
          name: 'adminReceivedMessages',
          path: '/admin-received-messages',
          title: trans('menu.options.titles.receivedMessagesList'),
        },
        {
          name: 'adminSentReplies',
          path: '/admin-sent-replies',
          title: trans('menu.options.titles.sentRepliesList'),
        },
        {
          name: 'adminReportsShortCodes',
          path: '/admin-reports-short-codes',
          title: trans('menu.options.titles.admin.reportsShortCodes'),
        },
      ],
    },
  ],
  [
    {
      code: 'my_account',
      name: 'myAccount',
      image: AccountCircle,
      title: trans('menu.options.titles.client.myAccount'),
      subMenu: [
        {
          name: 'myAccount',
          path: '/my-account',
          title: trans('menu.options.titles.client.myAccount'),
        },
      ],
    },
    {
      name: 'clientUsers',
      image: Person,
      title: trans('menu.options.titles.users'),
      subMenu: [
        {
          name: 'clientAddUser',
          path: '/client-add-user',
          title: trans('menu.options.titles.addUser'),
        },
        {
          name: 'clientUsers',
          path: '/client-users',
          title: trans('menu.options.titles.usersList'),
        },
      ],
    },
    {
      name: 'clientActivities',
      image: Assessment,
      title: trans('menu.options.titles.activities'),
      subMenu: [
        {
          name: 'clientActivities',
          path: '/client-activities',
          title: trans('menu.options.titles.activitiesList'),
        },
      ],
    },
    {
      name: 'clientMessages',
      image: Email,
      title: trans('menu.options.titles.client.messages'),
      subMenu: [
        {
          name: 'clientNewMessage',
          path: '/client-new-message',
          title: trans('menu.options.titles.client.newMessage'),
        },
        {
          name: 'clientSentMessages',
          path: '/client-sent-messages',
          title: trans('menu.options.titles.sentMessages'),
        },
      ],
    },
    {
      name: 'clientCampaigns',
      image: RecordVoiceOver,
      title: trans('menu.options.titles.client.campaigns'),
      subMenu: [
        {
          name: 'clientAddCampaign',
          path: '/client-add-campaign',
          title: trans('menu.options.titles.client.addCampaign'),
        },
        {
          name: 'clientCampaigns',
          path: '/client-campaigns',
          title: trans('menu.options.titles.client.campaignsList'),
        },
        {
          name: 'clientScheduledCampaigns',
          path: '/client-scheduled-campaigns',
          title: trans('menu.options.titles.scheduledCampaigns'),
        },
        {
          name: 'clientSentCampaigns',
          path: '/client-sent-campaigns',
          title: trans('menu.options.titles.sentCampaigns'),
        },
      ],
    },
    {
      name: 'clientGroups',
      image: People,
      title: trans('menu.options.titles.client.groups'),
      subMenu: [
        {
          name: 'clientAddGroups',
          path: '/client-add-groups',
          title: trans('menu.options.titles.client.addGroups'),
        },
        {
          name: 'clientGroups',
          path: '/client-groups',
          title: trans('menu.options.titles.client.groupsList'),
        },
      ],
    },
    {
      name: 'clientContacts',
      image: Contacts,
      title: trans('menu.options.titles.client.contacts'),
      subMenu: [
        {
          name: 'clientContacts',
          path: '/client-contacts',
          title: trans('menu.options.titles.client.contactsList'),
        },
      ],
    },
    {
      name: 'clientPayments',
      image: MonetizationOn,
      title: trans('menu.options.titles.client.payments'),
      subMenu: [
        // {
        //   name: 'clientAddPayment',
        //   path: '/client-add-payment',
        //   title: trans('menu.options.titles.addPayment'),
        // },
        {
          name: 'clientPayments',
          path: '/client-payments',
          title: trans('menu.options.titles.client.myPayments'),
        },
      ],
    },
    {
      name: 'clientShortCodes',
      image: PhoneAndroid,
      title: trans('menu.options.titles.shortCodes'),
      subMenu: [
        {
          name: 'clientShortCodeDashboard',
          path: '/client-short-codes-dashboard',
          title: trans('menu.options.titles.shortCodesDashboard'),
        },
        {
          name: 'clientKeywords',
          path: '/client-keywords',
          title: trans('menu.options.titles.keywordsList'),
        },
        {
          name: 'clientReceivedMessages',
          path: '/client-received-messages',
          title: trans('menu.options.titles.receivedMessagesList'),
        },
        {
          name: 'clientSentReplies',
          path: '/client-sent-replies',
          title: trans('menu.options.titles.sentRepliesList'),
        },
      ],
    },
    // {
    //   name: 'clientSettings',
    //   image: Settings,
    //   title: trans('menu.options.titles.client.settings'),
    //   subMenu: [
    //     {
    //       name: 'clientChangeTheme',
    //       path: '/client-change-theme',
    //       title: trans('menu.options.titles.client.changeTheme'),
    //     },
    //     {
    //       name: 'clientChangeLogo',
    //       path: '/client-change-logo',
    //       title: trans('menu.options.titles.client.changeLogo'),
    //     },
    //   ],
    // },
  ],
  [
    {
      code: 'my_account',
      name: 'myAccount',
      image: AccountCircle,
      title: trans('menu.options.titles.client.myAccount'),
      subMenu: [
        {
          name: 'myAccount',
          path: '/my-account',
          title: trans('menu.options.titles.client.myAccount'),
        },
      ],
    },
    {
      code: 'users',
      name: 'clientUsers',
      image: Person,
      title: trans('menu.options.titles.users'),
      subMenu: [
        {
          name: 'clientAddUser',
          path: '/client-add-user',
          title: trans('menu.options.titles.addUser'),
        },
        {
          name: 'clientUsers',
          path: '/client-users',
          title: trans('menu.options.titles.usersList'),
        },
      ],
    },
    {
      code: 'activities',
      name: 'clientActivities',
      image: Assessment,
      title: trans('menu.options.titles.activities'),
      subMenu: [
        {
          name: 'clientActivities',
          path: '/client-activities',
          title: trans('menu.options.titles.activitiesList'),
        },
      ],
    },
    {
      code: 'messages',
      name: 'clientMessages',
      image: Email,
      title: trans('menu.options.titles.client.messages'),
      subMenu: [
        {
          name: 'clientNewMessage',
          path: '/client-new-message',
          title: trans('menu.options.titles.client.newMessage'),
        },
        {
          name: 'clientSentMessages',
          path: '/client-sent-messages',
          title: trans('menu.options.titles.sentMessages'),
        },
      ],
    },
    {
      code: 'campaigns',
      name: 'clientCampaigns',
      image: RecordVoiceOver,
      title: trans('menu.options.titles.client.campaigns'),
      subMenu: [
        {
          name: 'clientAddCampaign',
          path: '/client-add-campaign',
          title: trans('menu.options.titles.client.addCampaign'),
        },
        {
          name: 'clientCampaigns',
          path: '/client-campaigns',
          title: trans('menu.options.titles.client.campaignsList'),
        },
        {
          name: 'clientScheduledCampaigns',
          path: '/client-scheduled-campaigns',
          title: trans('menu.options.titles.scheduledCampaigns'),
        },
        {
          name: 'clientSentCampaigns',
          path: '/client-sent-campaigns',
          title: trans('menu.options.titles.sentCampaigns'),
        },
      ],
    },
    {
      code: 'campaigns',
      name: 'clientGroups',
      image: People,
      title: trans('menu.options.titles.client.groups'),
      subMenu: [
        {
          name: 'clientAddGroups',
          path: '/client-add-groups',
          title: trans('menu.options.titles.client.addGroups'),
        },
        {
          name: 'clientGroups',
          path: '/client-groups',
          title: trans('menu.options.titles.client.groupsList'),
        },
      ],
    },
    {
      code: 'campaigns',
      name: 'clientContacts',
      image: Contacts,
      title: trans('menu.options.titles.client.contacts'),
      subMenu: [
        {
          name: 'clientContacts',
          path: '/client-contacts',
          title: trans('menu.options.titles.client.contactsList'),
        },
      ],
    },
    {
      code: 'payments',
      name: 'clientPayments',
      image: MonetizationOn,
      title: trans('menu.options.titles.client.payments'),
      subMenu: [
        // {
        //   name: 'clientAddPayment',
        //   path: '/client-add-payment',
        //   title: trans('menu.options.titles.addPayment'),
        // },
        {
          name: 'clientPayments',
          path: '/client-payments',
          title: trans('menu.options.titles.client.myPayments'),
        },
      ],
    },
    {
      code: 'short_codes',
      name: 'clientShortCodes',
      image: PhoneAndroid,
      title: trans('menu.options.titles.shortCodes'),
      subMenu: [
        {
          name: 'clientShortCodeDashboard',
          path: '/client-short-codes-dashboard',
          title: trans('menu.options.titles.shortCodesDashboard'),
        },
        {
          name: 'clientKeywords',
          path: '/client-keywords',
          title: trans('menu.options.titles.keywordsList'),
        },
        {
          name: 'clientReceivedMessages',
          path: '/client-received-messages',
          title: trans('menu.options.titles.receivedMessagesList'),
        },
        {
          name: 'clientSentReplies',
          path: '/client-sent-replies',
          title: trans('menu.options.titles.sentRepliesList'),
        },
      ],
    },
    // {
    //   name: 'clientSettings',
    //   image: Settings,
    //   title: trans('menu.options.titles.client.settings'),
    //   subMenu: [
    //     {
    //       name: 'clientChangeTheme',
    //       path: '/client-change-theme',
    //       title: trans('menu.options.titles.client.changeTheme'),
    //     },
    //     {
    //       name: 'clientChangeLogo',
    //       path: '/client-change-logo',
    //       title: trans('menu.options.titles.client.changeLogo'),
    //     },
    //   ],
    // },
  ],
  [
    {
      code: 'my_account',
      name: 'myAccount',
      image: AccountCircle,
      title: trans('menu.options.titles.client.myAccount'),
      subMenu: [
        {
          name: 'myAccount',
          path: '/my-account',
          title: trans('menu.options.titles.client.myAccount'),
        },
      ],
    },
    {
      code: 'users',
      name: 'clientUsers',
      image: Person,
      title: trans('menu.options.titles.users'),
      subMenu: [
        {
          name: 'clientAddUser',
          path: '/client-add-user',
          title: trans('menu.options.titles.addUser'),
        },
        {
          name: 'clientUsers',
          path: '/client-users',
          title: trans('menu.options.titles.usersList'),
        },
      ],
    },
    {
      code: 'activities',
      name: 'clientActivities',
      image: Assessment,
      title: trans('menu.options.titles.activities'),
      subMenu: [
        {
          name: 'clientActivities',
          path: '/client-activities',
          title: trans('menu.options.titles.activitiesList'),
        },
      ],
    },
    {
      code: 'messages',
      name: 'clientMessages',
      image: Email,
      title: trans('menu.options.titles.client.messages'),
      subMenu: [
        {
          name: 'clientNewMessage',
          path: '/client-new-message',
          title: trans('menu.options.titles.client.newMessage'),
        },
        {
          name: 'clientSentMessages',
          path: '/client-sent-messages',
          title: trans('menu.options.titles.sentMessages'),
        },
      ],
    },
    {
      code: 'campaigns',
      name: 'clientCampaigns',
      image: RecordVoiceOver,
      title: trans('menu.options.titles.client.campaigns'),
      subMenu: [
        {
          name: 'clientAddCampaign',
          path: '/client-add-campaign',
          title: trans('menu.options.titles.client.addCampaign'),
        },
        {
          name: 'clientCampaigns',
          path: '/client-campaigns',
          title: trans('menu.options.titles.client.campaignsList'),
        },
        {
          name: 'clientScheduledCampaigns',
          path: '/client-scheduled-campaigns',
          title: trans('menu.options.titles.scheduledCampaigns'),
        },
        {
          name: 'clientSentCampaigns',
          path: '/client-sent-campaigns',
          title: trans('menu.options.titles.sentCampaigns'),
        },
      ],
    },
    {
      code: 'campaigns',
      name: 'clientGroups',
      image: People,
      title: trans('menu.options.titles.client.groups'),
      subMenu: [
        {
          name: 'clientAddGroups',
          path: '/client-add-groups',
          title: trans('menu.options.titles.client.addGroups'),
        },
        {
          name: 'clientGroups',
          path: '/client-groups',
          title: trans('menu.options.titles.client.groupsList'),
        },
      ],
    },
    {
      code: 'campaigns',
      name: 'clientContacts',
      image: Contacts,
      title: trans('menu.options.titles.client.contacts'),
      subMenu: [
        {
          name: 'clientContacts',
          path: '/client-contacts',
          title: trans('menu.options.titles.client.contactsList'),
        },
      ],
    },
    {
      code: 'payments',
      name: 'clientPayments',
      image: MonetizationOn,
      title: trans('menu.options.titles.client.payments'),
      subMenu: [
        // {
        //   name: 'clientAddPayment',
        //   path: '/client-add-payment',
        //   title: trans('menu.options.titles.addPayment'),
        // },
        {
          name: 'clientPayments',
          path: '/client-payments',
          title: trans('menu.options.titles.client.myPayments'),
        },
      ],
    },
    {
      code: 'short_codes',
      name: 'clientShortCodes',
      image: PhoneAndroid,
      title: trans('menu.options.titles.shortCodes'),
      subMenu: [
        {
          name: 'clientShortCodeDashboard',
          path: '/client-short-codes-dashboard',
          title: trans('menu.options.titles.shortCodesDashboard'),
        },
        {
          name: 'clientAddKeyword',
          path: '/client-add-keyword',
          title: trans('menu.options.titles.admin.addKeyword'),
        },
        {
          name: 'clientKeywords',
          path: '/client-keywords',
          title: trans('menu.options.titles.keywordsList'),
        },
        {
          name: 'clientReceivedMessages',
          path: '/client-received-messages',
          title: trans('menu.options.titles.receivedMessagesList'),
        },
        {
          name: 'clientSentReplies',
          path: '/client-sent-replies',
          title: trans('menu.options.titles.sentRepliesList'),
        },
      ],
    },
  ],
];

export const ADMIN_ROUTE_TYPE = 'admin';
export const CLIENT_ROUTE_TYPE = 'client';

export const ADMIN_ACCOUNTS_LIST_HEADER_MENU = [
  {
    icon: Add,
    name: 'addAccount',
    path: '/admin-add-account',
    text: trans('menu.options.titles.admin.addAccount'),
  },
  {
    icon: Add,
    name: 'addCredits',
    path: '/admin-add-credits',
    text: trans('menu.options.titles.admin.addCredits'),
  },
];

export const ADMIN_ADD_CREDITS_HEADER_MENU = [
  {
    icon: Add,
    name: 'addAccount',
    path: '/admin-add-account',
    text: trans('menu.options.titles.admin.addAccount'),
  },
];

export const ADMIN_BANKS_LIST_HEADER_MENU = [
  {
    icon: Add,
    name: 'addBanks',
    path: '/admin-add-banks',
    text: trans('menu.options.titles.admin.addBanks'),
  },
];

export const ADMIN_KEYWORDS_HEADER_MENU = [
  {
    icon: Assignment,
    name: 'export',
    text: trans('menu.options.titles.export'),
  },
];

export const ADMIN_PAYMENTS_LIST_HEADER_MENU = [
  {
    icon: Add,
    name: 'addPayment',
    path: '/admin-add-payment',
    text: trans('menu.options.titles.addPayment'),
  },
];

export const ADMIN_RECEIVED_MESSAGES_HEADER_MENU = [
  {
    icon: Assignment,
    name: 'export',
    text: trans('menu.options.titles.export'),
  },
];

export const ADMIN_SHORT_CODES_DASHBOARD_HEADER_MENU = [
  {
    icon: Add,
    name: 'addKeyword',
    path: '/admin-add-keyword',
    text: trans('menu.options.titles.admin.addKeyword'),
  },
  {
    icon: Add,
    name: 'addShortCodes',
    path: '/admin-add-short-codes',
    text: trans('menu.options.titles.admin.addShortCodes'),
  },
  {
    icon: TableChart,
    name: 'tableView',
    path: '/admin-received-messages',
    text: trans('menu.options.titles.tableView'),
  },
];

export const CLIENT_CONTACTS_HEADER_MENU = [
  {
    icon: Delete,
    name: 'deleteAll',
    text: trans('menu.options.titles.deleteAll'),
  },
];

export const CLIENT_KEYWORDS_HEADER_MENU = [
  {
    icon: Assignment,
    name: 'export',
    text: trans('menu.options.titles.export'),
  },
];

export const CLIENT_RECEIVED_MESSAGES_HEADER_MENU = [
  {
    icon: Assignment,
    name: 'export',
    text: trans('menu.options.titles.export'),
  },
];

export const CLIENT_SHORT_CODES_DASHBOARD_HEADER_MENU = [
  {
    icon: TableChart,
    name: 'tableView',
    path: '/client-received-messages',
    text: trans('menu.options.titles.tableView'),
  },
];

/** Login component messages (EN)  */
export default {
  form: {
    actions: {
      signIn: 'Sign In',
    },
    fields: {
      username: 'Username',
      password: 'Password',
    },
    title: 'Sign in to start your session',
  },
};

import { SubmissionError } from 'redux-form';

export const getFieldFirstError = errors => {
  if (typeof errors !== 'object' || !errors) {
    return '';
  }

  const keys = Object.keys(errors);
  const error = errors[keys[0]];

  return typeof error === 'string' ? error : parseApiItemErrors({ [keys[0]]: error });
};

export const parseApiItemErrors = apiErrors => {
  const errors = {};

  if (typeof apiErrors !== 'object' || !apiErrors) {
    return errors;
  }

  const fields = Object.keys(apiErrors);

  fields.forEach(field => {
    errors[field] = getFieldFirstError(apiErrors[field]);
  });

  return errors;
};

export const parseApiArrayErrors = (apiErrors, field) => {
  const errors = { [field]: [] };

  if (!Array.isArray(apiErrors)) {
    return errors;
  }

  apiErrors.forEach((itemErrors, index) => {
    const hasErrors = !Array.isArray(itemErrors);

    if (hasErrors) {
      errors[field][index] = parseApiItemErrors(itemErrors);
    }
  });

  return errors;
};

export const extractResponseErrors = response => {
  if (typeof response !== 'object') {
    return null;
  }

  return response.data && response.data.errors;
};

export const catchApiItemErrors = err => {
  const errors = extractResponseErrors(err.body);

  throw new SubmissionError(parseApiItemErrors(errors));
};

export const catchApiArrayErrors = field => err => {
  const errors = extractResponseErrors(err.body);

  throw new SubmissionError(parseApiArrayErrors(errors, field));
};

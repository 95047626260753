import React from 'react';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';
import ResponsiveForm from '../../Form';
import { parseDataForMyAccount, validate } from '../../../../Helpers';
import {
  MY_ACCOUNT_INPUTS,
  MY_ACCOUNT_FORM as form,
  MY_ACCOUNT_VALIDATIONS,
} from './config';

let MyAccountForm = ({ handleSubmit, subscriptions, loading = false, ...props }) => (
  <ResponsiveForm
    {...props}
    inputs={MY_ACCOUNT_INPUTS}
    loading={loading}
    onSubmit={handleSubmit}
    values={{ 'account.subscriptions': subscriptions }}
  />
);

MyAccountForm = reduxForm({
  form,
  validate: validate(MY_ACCOUNT_VALIDATIONS),
})(MyAccountForm);

const selector = formValueSelector(form);

const mapStateToProps = ({
  accounts: {
    item: account,
  },
  allChannels: {
    items: channels,
  },
  users: {
    item: user,
  },
  ...state
}) => ({
  initialValues: account && user ? parseDataForMyAccount(user.data, account.data, channels[1]) : {},
  subscriptions: selector(state, 'account.subscriptions'),
});

export default connect(
  mapStateToProps
)(MyAccountForm);

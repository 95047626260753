import React from 'react';
import Menu from './Styled/Menu';
import Option from './Option';

export default ({
  canExpand,
  expanded,
  handleChange,
  hasNews,
  selectedOption,
  options = []
}) => (
  <Menu hasNews={hasNews}>
    {options.map((option, key) => (
      <Option
        expandableProps={{
          canExpand,
          expanded,
          handleChange,
        }}
        key={key}
        option={option}
        selectedOption={selectedOption}
      />
    ))}
  </Menu>
);

import { createMuiTheme } from '@material-ui/core/styles';

export const COLORS = {
  carriers: {
    digitel: '#5e0d88',
    movilnet: '#ff6600',
    movistar: '#019df4',
  },
  black1: 'rgba(0, 0, 0, 0.1)',
  black54: 'rgba(0, 0, 0, 0.54)',
  black7: 'rgba(0, 0, 0, 0.07)',
  darkBlue: '#1a237e',
  green: '#6cc788',
  grey5: '#555',
  greye0: '#e0e0e0',
  greyf0: '#f0f0f0',
  lightBlue: '#03a9f3',
  mediumBlue: '#1e88e5',
  mediumRed: '#eb1f28',
  red: '#f44336',
  turquoise: '#69c8c4',
  veryDarkBlue: '#131a58',
  white67: 'rgba(255, 255, 255, 0.67)',
  white87: 'rgba(255, 255, 255, 0.87)',
  yellow: '#f39c1b',
};

export const THEME = createMuiTheme({
  palette: {
    error: {
      main: COLORS.mediumRed,
    },
    primary: {
      main: COLORS.mediumBlue,
    },
    secondary: {
      main: COLORS.mediumRed,
    },
  },
});

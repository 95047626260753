import axios from 'axios';
import * as types from './types';

// Action Creators
const downloadFilePending = () => ({
  type: types.DOWNLOAD_FILE_PENDING,
});

const downloadFileSuccess = response => ({
  type: types.DOWNLOAD_FILE_SUCCESS,
  response,
});

const downloadFileError = () => ({
  type: types.DOWNLOAD_FILE_ERROR,
});

export const downloadFileReset = () => ({
  type: types.DOWNLOAD_FILE_RESET,
});

export const downloadFile = (
  url,
  options = {},
  name,
) => dispatch => {
  dispatch(downloadFilePending());

  return axios.get(url, { responseType: 'blob', ...options })
    .then(res => {
      const { data, headers, error } = res;

      if(error) {
        throw(error);
      }

      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', name);
      document.body.appendChild(link);
      link.click();

      dispatch(downloadFileSuccess(res));

      return res;
    })
    .catch(error => {
      console.log('error', error);
      dispatch(downloadFileError());
    });
};

import React from 'react';
import { connect } from 'react-redux';
import { actions as accountsActions } from '../../../Redux/Api/Accounts';
import { actions as allNewsActions } from '../../../Redux/Api/AllNews';
import { actions as allPermissionsActions } from '../../../Redux/Api/AllPermissions';
import GlobalStyles from '../../../Components/GlobalStyles';
import Content from '../../../Components/Layouts/Default/Content';
import ContentWrapper from '../../../Components/Layouts/Default/Styled/ContentWrapper';
import Footer from '../../../Components/Layouts/Default/Footer';
import Header from '../../../Components/Layouts/Default/Header';
import Navbar from '../../../Components/Layouts/Default/Navbar';
import News from '../../../Components/News';
import WindowResize from '../../../Components/Containers/WindowResize';
import Wrapper from '../../../Components/Layouts/Default/Styled/Wrapper';
import { ADMIN_ROLE, CLIENT_ROLE, CLIENT_ROUTE_TYPE } from '../../../Constants';
import { DEFAULT_PRIVATE_PATHS_OBJECT } from '../../config';
import { getUserOptions } from '../../../Helpers';



class DefaultLayout extends React.Component {
  state = {
    routeType: null,
  };

  fetchData = routeType => {
    if (routeType === CLIENT_ROUTE_TYPE) {
      this.props.fetchAllNews();
      this.props.fetchAllPermissions();
    }
  };

  getRoleForOptions = () => {
    const { routeType, user } = this.props;

    return user.role === ADMIN_ROLE && routeType === CLIENT_ROUTE_TYPE ? CLIENT_ROLE : user.role;
  };

  hasShortCodes = () => {
    const { account, routeType } = this.props;

    return routeType === CLIENT_ROUTE_TYPE
      && account
      && Array.isArray(account.short_codes)
      && account.short_codes.length;
  };

  componentDidMount() {
    const { routeType } = this.props;

    this.fetchData(routeType);
  }

  componentWillReceiveProps(newProps) {
    const { routeType, user } = newProps;
    const { routeType: stateRouteType } = this.state;

    if (user.account_id && routeType === CLIENT_ROUTE_TYPE && !stateRouteType) {
      this.setState({ routeType }, () => {
        this.fetchData(routeType);
        this.props.getAccount(user.account_id);
      });
    }
  }

  render() {
    const { children, news, permissions, routeType, selectedOption } = this.props;
    const hasNews = routeType === CLIENT_ROUTE_TYPE && !!news.length;

    return (
      <WindowResize>
        {
          hasNews &&
          <News news={news} />
        }
        <Header
          hasNews={hasNews}
          logoPath={DEFAULT_PRIVATE_PATHS_OBJECT[routeType]}
        />
        <Wrapper hasNews={hasNews}>
          <Navbar
            hasNews={hasNews}
            options={
              getUserOptions(this.getRoleForOptions(), permissions, this.hasShortCodes())
            }
            selectedOption={selectedOption}
          />
          <ContentWrapper>
            <Content>
              {children}
            </Content>
            <Footer />
          </ContentWrapper>
          <GlobalStyles />
        </Wrapper>
      </WindowResize>
    );
  }
}

const mapStateToProps = ({
  accounts: { isFetchingItem, isUpdating, item },
  allNews: {
    isFetching: isFetchingNews,
    items: news,
  },
  allPermissions: {
    isFetching: isFetchingPermissions,
    items: permissions,
  },
  auth: { user },
  navbar: { locale, routeType, selectedOption },
}) => ({
  isFetchingNews,
  isFetchingPermissions,
  locale,
  routeType,
  selectedOption,
  user,
  account: item && item.data,
  news: news[1] ? news[1] : [],
  permissions: permissions[1] ? permissions[1] : [],
});

export default connect(
  mapStateToProps,
  {
    ...accountsActions,
    ...allNewsActions,
    ...allPermissionsActions,
  }
)(DefaultLayout);

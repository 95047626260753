import defaultMessages from './Default';
import forms from './Forms';
import login from './Login';
import menu from './Menu';
import modals from './Modals';
import resources from './Resources';
import tables from './Tables';
import types from './Types';

const messages = {
  default: defaultMessages,
  forms,
  login,
  menu,
  modals,
  resources,
  tables,
  types,
};

/**
 * Spanish Messages
 */
export default messages;

import React from 'react';
import MUITextField from './Styled/TextField';
import TextFieldCharCount from './TextFieldCharCount';

class TextField extends React.Component {
  handleChange = evt => {
    const { target: { name, value } } = evt;
    const { afterChange, index, onChange } = this.props;
    if (typeof afterChange === 'function') {
      afterChange(value, name, index);
    }
    onChange(evt);
  };

  render() {
    const { charCount, error, showError, type, ...props } = this.props;
    let { value } = this.props;
    value = type === 'number' && typeof value === 'number' ? value : value || '';
    return (
      <React.Fragment>
        <MUITextField
          {...props}
          error={showError}
          fullWidth
          helperText={error}
          onChange={this.handleChange}
          type={type}
          value={value}
        />
        {
          charCount &&
          <TextFieldCharCount
            maxLength={props.inputProps && props.inputProps.maxLength}
            value={value}
          />
        }
      </React.Fragment>
    );
  }
}

export default TextField;

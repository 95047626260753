import React from 'react';
import MomentUtils from '@date-io/moment';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import moment from 'moment';
import TextField from './TextField';
import { DATE_FORMAT } from '../../../Constants';

class DatePicker extends React.Component {
  handlePickerChange = date => {
    const { afterChange, index, inputFormat, name, onChange } = this.props;
    const value = date && date.format(inputFormat);
    if (typeof afterChange === 'function') {
      afterChange(value, name, index);
    }
    onChange(value);
  };

  render() {
    const { value, inputFormat, ...props } = this.props;
    const dateValue =  moment(value, inputFormat);

    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <KeyboardDatePicker
          {...props}
          format={DATE_FORMAT}
          invalidDateMessage=""
          onChange={this.handlePickerChange}
          TextFieldComponent={TextField}
          value={dateValue._isValid ? dateValue : null}
        />
      </MuiPickersUtilsProvider>
    );
  }
}

DatePicker.defaultProps = {
  inputFormat: DATE_FORMAT,
};

export default DatePicker;

import React from 'react';
import { connect } from 'react-redux';
import { actions as windowSizeActions } from '../../Redux/Ducks/WindowResize';

class WindowResize extends React.Component {
  handleResize = () => {
    this.props.setWindowSize();
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  render() {
    return this.props.children;
  }
}

export default connect(null, { ...windowSizeActions })(WindowResize);

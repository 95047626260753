import React from 'react';
import { connect } from 'react-redux';
import Table from '../../../Components/Table';
import trans from '../../../Translations/translation.service';
import { actions as businessTypesActions } from '../../../Redux/Api/BusinessTypes';
import {
  BUSINESS_TYPES_TABLE_TITLES,
  DEFAULT_TABLE_ACTIONS,
  SUCCESS_CODE,
} from '../../../Constants';
import { PRIVATE_PATHS } from '../../config';
import { getPage } from '../../../Helpers';

class AdminBusinessTypes extends React.Component {
  deleteBusinessType = id => (
    this.props.deleteBusinessType(id)
      .then(({ code }) => code === SUCCESS_CODE && this.fetchBusinessTypes())
  );

  fetchBusinessTypes = newPage => {
    const { location } = this.props;
    const page = getPage(newPage, location);

    this.props.fetchBusinessTypes({}, { query: { page } });
  };

  parseBusinessTypeData = ({
    name,
    industry: { name: industry },
  }) => [
    {
      text: name,
    },
    {
      text: industry,
    }
  ];

  componentDidMount() {
    this.fetchBusinessTypes();
  }

  render() {
    const { businessTypes, history, isDeletingBusinessType, isFetchingBusinessTypes, pagination } = this.props;
    const type = trans('resources.singular.businessType');

    return (
      <Table
        actions={DEFAULT_TABLE_ACTIONS}
        actionButtonNames={{
          delete: ({ name }) => name,
        }}
        data={businessTypes}
        dataParser={this.parseBusinessTypeData}
        deleteWarningMessage="deleteOpSingM"
        editPath={PRIVATE_PATHS.adminEditBusinessType.noParamsPath}
        handleChangePage={this.fetchBusinessTypes}
        history={history}
        loading={isDeletingBusinessType || isFetchingBusinessTypes}
        onDeleteAcceptClick={this.deleteBusinessType}
        pagination={pagination}
        titles={BUSINESS_TYPES_TABLE_TITLES}
        type={type}
      />
    );
  }
}

const mapStateToProps = ({
  businessTypes: {
    isDeleting,
    isFetching,
    items: [success, data, pagination],
  },
}) => ({
  pagination,
  success,
  businessTypes: success ? data : [],
  isDeletingBusinessType: isDeleting,
  isFetchingBusinessTypes: isFetching,
});

export default connect(
  mapStateToProps,
  {
    ...businessTypesActions,
  }
)(AdminBusinessTypes);

/** Tables components messages (ES)  */
export default {
  actions: {
    delete: 'Eliminar',
    edit: 'Editar',
  },
  titles: {
    admin: {
      accounts: {
        number: '# de Cuenta',
        company: 'Compañía',
        username: 'Nombre de Usuario',
        type: 'Tipo',
        credits: 'Créditos',
      },
      activities: {
        account: 'Cuenta',
        user: 'Usuario',
        type: 'Tipo',
        description: 'Descripción',
        dateTime: 'Fecha y Hora',
      },
      banks: {
        name: 'Nombre',
      },
      businessTypes: {
        name: 'Name',
        industry: 'Industry',
      },
      industries: {
        name: 'Nombre',
      },
      integrators: {
        name: 'Nombre',
        code: 'Código',
        credits: 'Créditos',
      },
      keywords: {
        word: 'Palabra',
        shortCode: 'Número Corto',
        account: 'Cuenta',
      },
      news: {
        title: 'Título',
        content: 'Contenido',
        from: 'Desde',
        to: 'Hasta',
      },
      payments: {
        monthYear: 'Mes y Año',
        date: 'Fecha',
        type: 'Tipo',
        bank: 'Banco',
        id: 'Referencia',
        file: 'Comprobante',
        account: 'Cuenta',
      },
      receivedMessages: {
        sender: 'Remitente',
        message: 'Mensaje',
        keyword: 'Palabra Clave',
        shortCode: 'Número',
        date: 'Fecha',
        time: 'Hora',
      },
      reports: {
        account: 'Cuenta',
        integrator: 'Integradora',
        shortCode: 'Número Corto',
        sentMessages: 'Mensajes Enviados',
        receivedMessages: 'Mensajes Recibidos',
      },
      scheduledCampaigns: {
        name: 'Nombre',
        group: 'Grupo',
        account: 'Cuenta',
        date: 'Fecha',
        time: 'Hora',
      },
      sentCampaigns: {
        name: 'Nombre',
        group: 'Grupo',
        account: 'Cuenta',
        contacts: 'Contactos',
        date: 'Fecha',
        time: 'Time',
        status: 'Estatus',
      },
      sentMessages: {
        receipent: 'Destinatario',
        content: 'Contenido',
        messageCount: 'Total Mensajes',
        group: 'Grupo',
        campaign: 'Campaña',
        integrator: 'Integradora',
        carrier: 'Operadora',
        account: 'Cuenta',
        dateTime: 'Fecha y Hora',
      },
      sentReplies: {
        receipent: 'Destinatario',
        message: 'Mensaje',
        keyword: 'Palabra Clave',
        shortCode: 'Número',
        date: 'Fecha',
        time: 'Hora',
      },
      shortCodes: {
        code: 'Número',
      },
    },
    client: {
      activities: {
        user: 'Usuario',
        type: 'Tipo',
        description: 'Descripción',
        dateTime: 'Fecha y Hora',
      },
      campaigns: {
        name: 'Nombre',
        group: 'Grupo',
        contacts: 'Contactos',
        contactsFile: 'Archivo de Contactos',
      },
      contacts: {
        firstName: 'Nombre',
        lastName: 'Apellido',
        email: 'Email',
        phone: 'Teléfono',
        carrier: 'Operadora',
      },
      groups: {
        name: 'Nombre',
      },
      payments: {
        monthYear: 'Mes y Año',
        date: 'Fecha',
        type: 'Tipo',
        bank: 'Banco',
        id: 'Referencia',
        file: 'Comprobante',
      },
      scheduledCampaigns: {
        name: 'Nombre',
        group: 'Grupo',
        date: 'Fecha',
        time: 'Hora',
      },
      sentCampaigns: {
        name: 'Nombre',
        group: 'Grupo',
        contacts: 'Contactos',
        date: 'Fecha',
        time: 'Time',
        status: 'Estatus',
      },
      sentMessages: {
        receipent: 'Destinatario',
        content: 'Contenido',
        messageCount: 'Total Mensajes',
        group: 'Grupo',
        campaign: 'Campaña',
        integrator: 'Integradora',
        carrier: 'Operadora',
        dateTime: 'Fecha y Hora',
      },
      users: {
        username: 'Nombre de Usuario',
        status: 'Estatus',
      },
    },
    actions: 'Acciones',
  },
};

import React from 'react';
import { connect } from 'react-redux';
import { change, formValueSelector } from 'redux-form';
import AddAccountForm from '../../../../Components/Forms/Admin/Accounts/AddAccount';
import ModalsWrappedForm from '../../../../Components/Forms/ModalsWrappedForm';
import trans from '../../../../Translations/translation.service';
import { actions as allBusinessTypesActions } from '../../../../Redux/Api/AllBusinessTypes';
import { actions as allChannelsActions } from '../../../../Redux/Api/AllChannels';
import { actions as allIntegratorsActions } from '../../../../Redux/Api/AllIntegrators';
import { actions as allServicesActions } from '../../../../Redux/Api/AllServices';
import { actions as allShortCodesActions } from '../../../../Redux/Api/AllShortCodes';
import { actions as accountsActions, types } from '../../../../Redux/Api/Accounts';
import { PRIVATE_PATHS } from '../../../config';
import {
  ACCOUNTS_TYPES_LIST,
  ACCOUNTS_STATUSES_LIST,
  FEMALE_CODE,
  GENDERS_LIST,
} from '../../../../Constants';
import {
  ADD_ACTIONS,
  ACCOUNT_FORM as form,
  ACCOUNT_PARSE_CONFIG,
} from '../../../../Components/Forms/Admin/Accounts/config';
import {
  catchApiItemErrors,
  parseDataForApi,
} from '../../../../Helpers';

class AddAccount extends React.Component {
  createAccount = account => (
    this.props.createAccount(
      parseDataForApi(account, ACCOUNT_PARSE_CONFIG)
    ).catch(
      catchApiItemErrors,
    )
  );

  loading = () => {
    const {
      isFetchingBusinessTypes,
      isFetchingChannels,
      isFetchingIntegrators,
      isFetchingServices,
      isFetchingShortCodes,
      isCreatingAccount,
    } = this.props;
    return isFetchingBusinessTypes
      || isFetchingChannels
      || isFetchingIntegrators
      || isFetchingServices
      || isFetchingShortCodes
      || isCreatingAccount;
  };

  parseBusinessTypes = () => (
    this.props.businessTypes.map(({ id: value, name: text }) => ({
      text, value,
    }))
  );

  parseChannels = () => (
    this.props.channels.map(({ account_count, name, id: value }) => {
      const resource = account_count === 1 ? 'singular.account' : 'plural.accounts';
      const text = `${
        name
      } (${
        account_count
      } ${
        trans(`resources.${resource}`)
      })`;
      return ({ text, value });
    })
  );

  parseShortCodes = () => (
    this.props.shortCodes.map(({ id: value, code: label }) => ({
      label, value,
    }))
  );

  updateSubscriptionTotalFromCredits = (credits, name, index) => {
    const { subscriptions } = this.props;
    const price = parseFloat(subscriptions[index].price);

    this.props.change(
      form,
      name.replace('credits', 'total'),
      price * parseInt(credits),
    );
  };

  updateSubscriptionTotalFromPrice = (price, name, index) => {
    const { subscriptions } = this.props;
    const credits = parseInt(subscriptions[index].credits);

    this.props.change(
      form,
      name.replace('price', 'total'),
      credits * parseFloat(price),
    );
  };

  componentDidMount() {
    this.props.fetchAllBusinessTypes();
    this.props.fetchAllChannels();
    this.props.fetchAllIntegrators();
    this.props.fetchAllServices();
    this.props.fetchAllShortCodes();
  }

  render() {
    const { history, integrators, services, success } = this.props;
    const businessTypes = this.parseBusinessTypes();
    const channels = this.parseChannels();
    const shortCodes = this.parseShortCodes();
    const subject = trans('resources.singular.account');

    return (
      <ModalsWrappedForm
        gender={FEMALE_CODE}
        history={history}
        loading={this.loading()}
        open={success}
        path={PRIVATE_PATHS.adminAccounts.path}
        subject={subject}
        successMessage="successOpSingF"
        type="add"
      >
        {
          !!integrators.length && !!services.length &&
          <AddAccountForm
            afterChange={{
              account: {
                subscription: {
                  subscriptions: {
                    credits: this.updateSubscriptionTotalFromCredits,
                    price: this.updateSubscriptionTotalFromPrice,
                  },
                },
              },
            }}
            actions={ADD_ACTIONS}
            data={{
              account: {
                account_type: ACCOUNTS_TYPES_LIST,
                channel_id: channels,
                status: ACCOUNTS_STATUSES_LIST,
                'company.business_type_id': businessTypes,
                'client.person.gender': GENDERS_LIST,
                'short_codes._ids': shortCodes,
              },
            }}
            history={history}
            onSubmit={this.createAccount}
            path={PRIVATE_PATHS.adminAccounts.path}
          />
        }
      </ModalsWrappedForm>
    );
  }
}

const selector = formValueSelector(form);

const mapStateToProps = ({
  allBusinessTypes: {
    isFetching: isFetchingBusinessTypes,
    items: businessTypes,
  },
  allChannels: {
    isFetching: isFetchingChannels,
    items: channels,
  },
  allIntegrators: {
    isFetching: isFetchingIntegrators,
    items: integrators,
  },
  allServices: {
    isFetching: isFetchingServices,
    items: services,
  },
  allShortCodes: {
    isFetching: isFetchingShortCodes,
    items: shortCodes,
  },
  accounts: { isCreating },
  reduxRestResponse: { body, success, type },
  ...state
}) => ({
  body,
  isFetchingBusinessTypes,
  isFetchingChannels,
  isFetchingIntegrators,
  isFetchingServices,
  isFetchingShortCodes,
  businessTypes: businessTypes[1] ? businessTypes[1] : [],
  channels: channels[1] ? channels[1] : [],
  integrators: integrators[1] ? integrators[1] : [],
  isCreatingAccount: isCreating,
  services: services[1] ? services[1] : [],
  shortCodes: shortCodes[1] ? shortCodes[1] : [],
  subscriptions: selector(state, 'subscriptions'),
  success: type === types.CREATE_ACCOUNT && success,
});

export default connect(
  mapStateToProps,
  {
    change,
    ...accountsActions,
    ...allBusinessTypesActions,
    ...allChannelsActions,
    ...allIntegratorsActions,
    ...allServicesActions,
    ...allShortCodesActions,
  }
)(AddAccount);

import React from 'react';
import MUITable from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import DeleteWarningModal from '../Modals/DeleteWarning';
import Filters from '../Filters';
import Loading from '../Loading';
import Pagination from './Pagination';
import TableBody from './TableBody';
import TableHead from './TableHead';
import {
  TABLE_ACTIONS_CONFIG,
  TABLE_ROWS,
} from './config';

class Table extends React.Component {
  state = {
    deleteItemId: null,
    deleteSubject: '',
    showDeleteConfirm: false,
  };

  calculateNumber = key => {
    const { pagination, rows } = this.props;
    return pagination && (pagination.current_page - 1) * rows + key + 1;
  };

  getActionHandler = type => {
    const { handler } = TABLE_ACTIONS_CONFIG[type];
    return this[handler];
  };

  getActionName = (type, item) => {
    const { actionButtonNames } = this.props;
    const actionButtonNameGetter = actionButtonNames[type];
    const hasGetter = typeof actionButtonNameGetter === 'function';

    return hasGetter && actionButtonNameGetter(item);
  };

  handleDelete = e => {
    const { name: deleteSubject, value: deleteItemId } = e.currentTarget;

    this.setState({ deleteItemId, deleteSubject, showDeleteConfirm: true });
  };

  handleDeleteAcceptClick = () => {
    const { deleteItemId } = this.state;
    const { onDeleteAcceptClick } = this.props;

    if (typeof onDeleteAcceptClick === 'function') {
      onDeleteAcceptClick(deleteItemId);
    }

    this.setState({ showDeleteConfirm: false });
  };

  handleDeleteCancelClick = () => this.setState({
    deleteItemId: null,
    showDeleteConfirm: false,
  });

  handleEdit = e => {
    const { value } = e.currentTarget;
    const { editPath, history } = this.props;

    history.push(`${editPath}/${value}`);
  };

  handleChangePage = page => {
    const { handleChangePage } = this.props;

    this.insertUrlParam('page', page);

    handleChangePage(page);
  };

  insertUrlParam = (key, value) => {
    if (window.history.pushState) {
        const { location } = this.props;
        let hash = window.location.hash;

        if (hash) {
          hash = hash.split('?')[0];
        }

        const searchParams = new URLSearchParams(location && location.search);
        searchParams.set(key, value);

        const newurl = window.location.protocol + "//"
          + window.location.host
          + window.location.pathname
          + hash + '?' + searchParams.toString();
        window.history.pushState({path: newurl}, '', newurl);
    }
  };

  render() {
    const {
      deleteSubject,
      showDeleteConfirm,
    } = this.state;
    const {
      actions,
      classes,
      data,
      dataParser,
      deleteWarningMessage,
      filtersProps,
      loading,
      number,
      pagination,
      rows,
      showPagination,
      titles,
      type,
    } = this.props;
    const tableBodyProps = {
      actions,
      data,
      dataParser,
      number,
      rows,
      calculateNumber: this.calculateNumber,
      getActionHandler: this.getActionHandler,
      getActionName: this.getActionName,
    };
    const tableHeadProps = {
      actions,
      number,
      titles,
    };

    return (
      <React.Fragment>
        <Loading show={loading} />
        <DeleteWarningModal
          onAcceptClick={this.handleDeleteAcceptClick}
          onCancelClick={this.handleDeleteCancelClick}
          open={showDeleteConfirm}
          subject={deleteSubject}
          type={type}
          warningMessage={deleteWarningMessage}
        />
        <Paper elevation={3}>
          {filtersProps && <Filters filtersProps={filtersProps} />}
          <div className={classes.root}>
            <MUITable>
              <TableHead {...tableHeadProps} />
              <TableBody {...tableBodyProps} />
            </MUITable>
            </div>
        </Paper>
        {
          showPagination && pagination &&
          <Pagination
            activePage={pagination.current_page}
            itemsCountPerPage={10}
            totalItemsCount={pagination.count}
            pageRangeDisplayed={10}
            onChange={this.handleChangePage}
          />
        }
      </React.Fragment>
    );
  }
}

Table.defaultProps = {
  actionButtonNames: {},
  number: true,
  rows: TABLE_ROWS,
  showPagination: true,
};

const styles = theme => ({
  root: {
    [theme.breakpoints.down('md')]: {
      maxWidth: '960px',
      overflow: 'auto',
    },
  },
});

const StyledTable = withStyles(styles)(Table);

export default withRouter(StyledTable);

import validator from 'validator';
import trans from '../Translations/translation.service';
import { PHONE_NUMBER_FORMAT } from '../Constants';

class FormValidator {
  constructor(validations) {
    // validations is an array of rules specific to a form
    this.validations = validations;
  }

  dateIsBefore = (value, field, data) => (
    !value || !data[field] || validator.isBefore(value, data[field])
  );

  isCellPhoneNumber = value => (
    !value || typeof value !== 'string' || PHONE_NUMBER_FORMAT.test(value.trim())
  );

  isEmptyIf = (value, field, data) => (
    data[field] && validator.isEmpty(value)
  );

  isFloatIf = (value, options, field, data) => (
    !value || !data[field] || validator.isFloat(value, options)
  );

  isGreaterThanField = (value, field, data) => (
    !value.toString() || !data[field] || validator.isInt(value, { min: data[field] })
  );

  isIntIf = (value, options, field, data) => (
    !value || !data[field] || validator.isInt(value, options)
  );

  isNull = value => value === null;

  isURLIf = (value, field, compare, options, data) => (
    data[field] !== compare || validator.isURL(value, options)
  );

  matchesField = (value, field, data) => (
    !value || !data[field] || value === data[field]
  );

  validations = [];

  validateArrayField = (state, rule) => {
    const validation = [];

    state[rule.field].forEach((value, index) => {
      const validator = new FormValidator(rule.validations);
      const result = validator.validate(value);

      if (Object.keys(result).length) {
        validation[index] = result;
      }
    });

    return validation.length ? validation : null;
  };

  validateObjectField = (state, rule) => {
    const validator = new FormValidator(rule.validations);
    const result = validator.validate(state[rule.field]);

    return Object.keys(result).length ? result : null;
  };

  validateSingleField = (state, rule) => {
    let validation = '';
    const { args, field, message, method, validWhen } = rule;
    // if the field isn't already marked invalid by an earlier rule
    if (state && state[field] !== undefined && !validation[field]) {
      // determine the field value, the method to invoke and
      // optional args from the rule definition
      const value = state[field] !== null && state[field] !== undefined ?
        state[field].toString() : '';
      const finalArgs = args ? [...args] : [];
      let finalMethod = null;

      if (validator[method]) {
        finalMethod = validator[method];
      } else if (this[method]) {
        finalMethod = this[method];
        finalArgs.push(state);
      }

      // call the validation_method with the current field value
      // as the first argument, any additional arguments, and the
      // whole state as a final argument.  If the result doesn't
      // match the rule.validWhen property, then modify the
      // validation object for the field and set the isValid
      // field to false
      if(finalMethod && finalMethod(value, ...finalArgs) !== validWhen) {
        validation = Array.isArray(message) ? trans(...message) : message;
      }
    }

    return validation;
  };

  validate = state => {
    // start out assuming valid
    let validation = {};
    // for each validation rule
    this.validations.forEach(rule => {
      let result = null;

      if (rule.array && Array.isArray(state[rule.field])) {
        result = this.validateArrayField(state, rule);
      } else if (rule.object && typeof state[rule.field] === 'object') {
        result = this.validateObjectField(state, rule);
      } else {
        result = this.validateSingleField(state, rule);
      }

      if (result) {
        validation[rule.field] = result;
      }
    });

    return validation;
  };
}

export const validate = validations => {
  const validator = new FormValidator(validations);

  return values => validator.validate(values);
};

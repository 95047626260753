import React from 'react';
import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import Table from '../../../Components/Table';
import trans from '../../../Translations/translation.service';
import { actions as allKeywordsActions } from '../../../Redux/Api/AllKeywords';
import { actions as allShortCodesActions } from '../../../Redux/Api/AllShortCodes';
import { actions as fileDownloadActions } from '../../../Redux/Ducks/FileDownload';
import { actions as keywordsActions } from '../../../Redux/Api/Keywords';
import { actions as navBarActions } from '../../../Redux/Ducks/Navbar';
import {
  ALL_SELECT_OPTION_F,
  ALL_SELECT_OPTION_M,
  ALL_SELECT_OPTION_VALUE as ALL,
  CLIENT_KEYWORDS_TABLE_TITLES,
  DEFAULT_TABLE_ACTIONS,
  HEADERS as headers,
  KEYWORDS_URL,
  SUCCESS_CODE,
} from '../../../Constants';
import {
  CLIENT_KEYWORDS_FILTERS_INPUTS,
} from './config';
import { FILTERS_FORM as form } from '../../../Components/Filters/config';
import { PRIVATE_PATHS } from '../../config';
import { getPage } from '../../../Helpers';

class ClientKeywords extends React.Component {
  state = {
    shortCode: ALL,
    filters: {},
  };

  deleteKeyword = id => (
    this.props.deleteKeyword(id)
      .then(({ code }) => code === SUCCESS_CODE && this.fetchKeywords())
  );

  fetchAllKeywords = () => {
    const { shortCode } = this.state;

    if (shortCode === ALL) {
      this.props.fetchAllKeywords({}, { query: { client: true } });
    } else {
      this.props.fetchAllKeywords({}, { query: { client: true, short_code: shortCode } });
    }
  };

  fetchAllKeywordsFromShortCode = shortCode => {
    const { filters } = this.state;

    if (shortCode === ALL) {
      delete filters.short_code;
    } else {
      filters.short_code =  shortCode;
    }

    delete filters.keyword;
    this.props.change(form, 'keyword_id', ALL);

    this.setState({ filters, shortCode }, () => {
      this.fetchAllKeywords();
      this.fetchKeywords();
    });
  };

  fetchKeywords = newPage => {
    const { filters } = this.state;
    const { location } = this.props;
    const page = getPage(newPage, location);

    this.props.fetchKeywords({}, { query: { page, client: true, ...filters } });
  };

  handleSearch = (search)  => {
    const { filters } = this.state;

    filters.word = search;

    this.setState({ filters }, () => {
      this.fetchKeywords();
    });
  };

  fetchKeywordsFromKeyword = keyword => {
    const { filters } = this.state;

    if (keyword === ALL) {
      delete filters.keyword;
    } else {
      filters.keyword =  keyword;
    }

    this.setState({ filters }, () => {
      this.fetchKeywords();
    });
  };

  initFilters = () => {
    this.props.reset(form);
    this.props.change(form, 'keyword_id', ALL);
    this.props.change(form, 'short_code_id', ALL);
  };

  loading = () => {
    const {
      isDownloadingFile,
      isFetchingAllKeywords,
      isFetchingKeywords,
      isFetchingShortCodes,
    } = this.props;
    return isDownloadingFile
      || isFetchingAllKeywords
      || isFetchingKeywords
      || isFetchingShortCodes;
  };

  parseKeywordData = ({
    word,
    short_code: { code },
  }) => [
    {
      text: word,
    },
    {
      text: code,
    },
  ];

  parseKeywords = () => {
    const keywords = this.props.allKeywords.map(({ id: value, word: text }) => ({
      text, value,
    }));

    keywords.unshift(ALL_SELECT_OPTION_F);

    return keywords;
  };

  parseShortCodes = () => {
    const shortCodes = this.props.shortCodes.map(({ id: value, code: text }) => ({
      text, value,
    }));

    shortCodes.unshift(ALL_SELECT_OPTION_M);

    return shortCodes;
  };

  export = () => {
    this.props.downloadFile(
      `${KEYWORDS_URL}/export`,
      { headers, params: { client: true } },
      'keywords.xlsx'
    );
  };

  setMenuActions = () => {
    this.props.setHeaderMenuAction('export', this.export);
  };



  getActions = row => {
    const { short_code } = row;
    const { shortCodes } = this.props;
    const removeActions = [];

    if (!shortCodes.some(shortCode => shortCode.id === short_code.id)) {
      removeActions.push('delete');
    }

    return DEFAULT_TABLE_ACTIONS.filter(action => removeActions.indexOf(action) === -1);
  };

  componentDidMount() {
    this.fetchKeywords();
    this.props.setOnSearch(this.handleSearch);
    this.fetchAllKeywords();
    this.props.fetchAllShortCodes({}, { query: { client: true } });
    this.initFilters();
    this.setMenuActions();
  }

  render() {
    const { keywords, history, pagination } = this.props;
    const allKeywords = this.parseKeywords();
    const shortCodes = this.parseShortCodes();
    const type = trans('resources.singular.keyword');

    return (
      <Table
        actions={this.getActions}
        actionButtonNames={{
          delete: ({ word }) => word,
        }}
        data={keywords}
        dataParser={this.parseKeywordData}
        deleteWarningMessage="deleteOpSingF"
        editPath={PRIVATE_PATHS.clientEditKeyword.noParamsPath}
        filtersProps={{
          afterChange: {
            short_code_id: this.fetchAllKeywordsFromShortCode,
            keyword_id: this.fetchKeywordsFromKeyword,
          },
          data: {
            keyword_id: allKeywords,
            short_code_id: shortCodes,
          },
          inputs: CLIENT_KEYWORDS_FILTERS_INPUTS,
          loading: this.loading(),
        }}
        handleChangePage={this.fetchKeywords}
        history={history}
        loading={this.loading()}
        onDeleteAcceptClick={this.deleteKeyword}
        pagination={pagination}
        titles={CLIENT_KEYWORDS_TABLE_TITLES}
        type={type}
      />
    );
  }
}

const mapStateToProps = ({
  allKeywords: {
    isFetching: isFetchingAllKeywords,
    items: keywords,
  },
  allShortCodes: {
    isFetching: isFetchingShortCodes,
    items: shortCodes,
  },
  fileDownload: { pending },
  keywords: {
    isFetching,
    items: [success, data, pagination],
  },
}) => ({
  isFetchingAllKeywords,
  isFetchingShortCodes,
  pagination,
  success,
  allKeywords: keywords[1] ? keywords[1] : [],
  shortCodes: shortCodes[1] ? shortCodes[1] : [],
  keywords: success ? data : [],
  isDownloadingFile: pending,
  isFetchingKeywords: isFetching,
});

export default connect(
  mapStateToProps,
  {
    change,
    reset,
    ...allKeywordsActions,
    ...allShortCodesActions,
    ...fileDownloadActions,
    ...keywordsActions,
    ...navBarActions,
  }
)(ClientKeywords);

import React from 'react';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';
import ResponsiveForm from '../../Form';
import { validate } from '../../../../Helpers';
import {
  CAMPAIGN_FORM as form,
  CAMPAIGN_VALIDATIONS,
  INPUTS,
} from './config';

let EditCampaignForm = ({ formData, handleSubmit, loading = false, ...props }) => (
  <ResponsiveForm
    {...props}
    inputs={INPUTS}
    loading={loading}
    onSubmit={handleSubmit}
    values={formData}
  />
);

EditCampaignForm = reduxForm({
  form,
  validate: validate(CAMPAIGN_VALIDATIONS),
})(EditCampaignForm);

const selector = formValueSelector(form);

const mapStateToProps = ({
  campaigns: {
    item,
  },
  ...state
}) => ({
  formData: selector(state, 'contacts_src_file', 'file'),
  initialValues: item ? item.data : {},
});

export default connect(
  mapStateToProps
)(EditCampaignForm);

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

export default withStyles({
  root: {
    color: 'black',
    fontSize: '1rem',
    marginTop: '4px',
    padding: '0 4px !important',
    transform: 'scale(0.75)',
    transformOrigin: 'top left',
    whiteSpace: 'nowrap',
  },
})(Grid);

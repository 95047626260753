import React from 'react';
import ArrowRightAlt from '@material-ui/icons/ArrowRightAlt';
import StyledFooter from './Styled/Footer';
import { APP_NAME, APP_VERSION } from '../../../Constants';

export default () => (
  <StyledFooter>
    <span>
      © Copyright
      <strong>
        &nbsp;{APP_NAME}&nbsp;
      </strong>
    </span>
    <span>
      - Version {APP_VERSION}
    </span>
    <ArrowRightAlt className="scroll-up" />
  </StyledFooter>
);

import styled from 'styled-components';
import { COLORS } from '../../../Constants';

export default styled.div`
    display: flex;

    span.title {
        color: ${COLORS.greye0};
        margin-right: 5px;
    }

    span.content {
        color: white;
        margin-right: 20px;
    }
`;
import React from 'react';
import { connect } from 'react-redux';
import AddGroupForm from '../../../../Components/Forms/Client/Groups/AddGroup';
import ModalsWrappedForm from '../../../../Components/Forms/ModalsWrappedForm';
import trans from '../../../../Translations/translation.service';
import { actions as groupsActions, types } from '../../../../Redux/Api/Groups';
import { PRIVATE_PATHS } from '../../../config';
import { MALE_CODE } from '../../../../Constants';
import { ADD_ACTIONS, ADD_MODAL_ACTIONS } from '../../../../Components/Forms/Client/Groups/config';
import { catchApiItemErrors } from '../../../../Helpers';

class AddGroup extends React.Component {
  createGroup = data => {
    const { afterSave } = this.props;

    return this.props.createGroup(data)
      .then(res => {
        const { id } = res.body.data;

        if (afterSave) {
          afterSave(id);
        }
      })
      .catch(
        catchApiItemErrors,
      );
  };

  getActions = () =>{
    const { onAcceptClick } = this.props;

    if (onAcceptClick) {
      const actions = [...ADD_MODAL_ACTIONS];
      actions[0].onClick = () => onAcceptClick();
      return actions;
    } else {
      return ADD_ACTIONS;
    }
  };

  render() {
    const { history, onAcceptClick, success } = this.props;
    const subject = trans('resources.singular.group');

    return (
      <ModalsWrappedForm
        acceptText={
          onAcceptClick
            ? trans('modals.actions.accept')
            : trans('modals.actions.backToList')
        }
        gender={MALE_CODE}
        history={history}
        loading={this.props.isCreatingGroup}
        onAcceptClick={onAcceptClick && (() => onAcceptClick(true))}
        open={success}
        path={PRIVATE_PATHS.clientGroups.path}
        subject={subject}
        successMessage="successOpSingM"
        type="add"
      >
        <AddGroupForm
          actions={this.getActions()}
          history={history}
          onSubmit={this.createGroup}
          path={PRIVATE_PATHS.clientGroups.path}
        />
      </ModalsWrappedForm>
    );
  }
}

const mapStateToProps = ({
  groups: { isCreating },
  reduxRestResponse: { body, success, type },
}) => ({
  body,
  isCreatingGroup: isCreating,
  success: type === types.CREATE_GROUP && success,
});

export default connect(
  mapStateToProps,
  {
    ...groupsActions,
  }
)(AddGroup);

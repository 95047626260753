import React from 'react';
import { connect } from 'react-redux';
import { change, formValueSelector } from 'redux-form';
import AddCampaignForm from '../../../../Components/Forms/Client/Campaigns/AddCampaign';
import ContactsImportStats from '../../../../Components/ContactsImportStats';
import ModalsWrappedForm from '../../../../Components/Forms/ModalsWrappedForm';
import trans from '../../../../Translations/translation.service';
import { actions as allGroupsActions } from '../../../../Redux/Api/AllGroups';
import { actions as campaignsActions, types } from '../../../../Redux/Api/Campaigns';
import { actions as fileUploadActions } from '../../../../Redux/Ducks/FileUpload';
import { PRIVATE_PATHS } from '../../../config';
import { CAMPAIGNS_URL, FEMALE_CODE, JSON_HEADERS as headers } from '../../../../Constants';
import { ADD_ACTIONS, ADD_MODAL_ACTIONS, CAMPAIGN_FORM as form } from '../../../../Components/Forms/Client/Campaigns/config';
import { catchApiItemErrors } from '../../../../Helpers';

class AddCampaign extends React.Component {
  state = {
    createdId: null,
  };

  createCampaign = ({ contacts_src_file, contacts, ...data }) => {
    const { afterSave } = this.props;
    const { createdId } = this.state;

    if (!data.file) {
      data.contacts = contacts;
    }

    if (!createdId) {
      return this.props.createCampaign(data)
        .then(async res => {
          const { id } = res.body.data;

          this.setState({ createdId: id });

          if (afterSave) {
            afterSave(id);
          }

          if (contacts_src_file) {
            await this.uploadContactsFile(id, contacts_src_file);
          }
        })
        .catch(
          catchApiItemErrors,
        );
    } else {
      return this.props.updateCampaign({ id: createdId, ...data })
        .then(async res => {
          if (contacts_src_file) {
            await this.uploadContactsFile(createdId, contacts_src_file);
          }
        })
        .catch(
          catchApiItemErrors,
        );
    }
  };

  getActions = () =>{
    const { onAcceptClick } = this.props;

    if (onAcceptClick) {
      const actions = [...ADD_MODAL_ACTIONS];
      actions[0].onClick = () => onAcceptClick();
      return actions;
    } else {
      return ADD_ACTIONS;
    }
  };

  getImportStats = () => {
    const { file, response } = this.props;

    if (file && response && response.data && response.data.results) {
      const { results } = response.data;

      return (
        <ContactsImportStats {...results} />
      );
    } else {
      return;
    }
  };

  loading = () => {
    const {
      isCreatingCampaign,
      isFetchingGroups,
      isUpdatingCampaign,
      isUploadingFile,
    } = this.props;
    return isCreatingCampaign
      || isFetchingGroups
      || isUpdatingCampaign
      || isUploadingFile;
  };

  openSuccessModal = () => {
    const { file, fileUploadSuccess, success } = this.props;

    return success && (!file || fileUploadSuccess);
  };

  parseGroups = () => (
    this.props.groups.map(({ id: value, name: text }) => ({
      text, value,
    }))
  );

  uploadContactsFile = (id, contacts_src_file) => (
    this.props.uploadFile(
      `${CAMPAIGNS_URL}/${id}`,
      {
        contacts_src_file,
        contacts_src_file_name: contacts_src_file.name,
      },
      {
        headers,
      },
    )
  );

  componentDidMount() {
    const { groupId, groupsLoaded } = this.props;

    this.props.uploadFileReset();

    if (!groupsLoaded) {
      this.props.fetchAllGroups({}, { query: { client: true } });
    }

    if (groupId) {
      this.props.change(form, 'group_id', groupId);
    }
  }

  render() {
    const { history, onAcceptClick } = this.props;
    const groups = this.parseGroups();
    const importStats = this.getImportStats();
    const subject = trans('resources.singular.campaign');

    return (
      <ModalsWrappedForm
        acceptText={
          onAcceptClick
            ? trans('modals.actions.accept')
            : trans('modals.actions.backToList')
        }
        extraInfo={importStats}
        gender={FEMALE_CODE}
        history={history}
        loading={this.loading()}
        onAcceptClick={onAcceptClick && (() => onAcceptClick(true))}
        open={this.openSuccessModal()}
        path={PRIVATE_PATHS.clientCampaigns.path}
        subject={subject}
        successMessage="successOpSingF"
        type="add"
      >
        <AddCampaignForm
          actions={this.getActions()}
          data={{
            group_id: groups,
          }}
          history={history}
          onSubmit={this.createCampaign}
          path={PRIVATE_PATHS.clientCampaigns.path}
        />
      </ModalsWrappedForm>
    );
  }
}

const selector = formValueSelector(form);

const mapStateToProps = ({
  allGroups: {
    isFetching: isFetchingGroups,
    items: groups,
  },
  campaigns: { isCreating, isUpdating },
  fileUpload: { pending, response, success: fileUploadSuccess },
  reduxRestResponse: { body, success, type },
  ...state
}) => ({
  body,
  isFetchingGroups,
  response,
  file: selector(state, 'file'),
  fileUploadSuccess: !selector(state, 'contacts_src_file') || fileUploadSuccess,
  groups: groups[1] ? groups[1] : [],
  isCreatingCampaign: isCreating,
  isUpdatingCampaign: isUpdating,
  isUploadingFile: pending,
  success: (
    type === types.CREATE_CAMPAIGN || type === types.UPDATE_CAMPAIGN
  ) && success,
});

export default connect(
  mapStateToProps,
  {
    ...allGroupsActions,
    ...campaignsActions,
    ...fileUploadActions,
    change,
  }
)(AddCampaign);

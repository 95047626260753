import React from 'react';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';
import ResponsiveForm from '../../Form';
import { validate } from '../../../../Helpers';
import {
  INPUTS,
  KEYWORD_FORM as form,
  KEYWORD_VALIDATIONS,
} from './config';

let EditKeywordForm = ({ handleSubmit, replies, loading = false, ...props }) => (
  <ResponsiveForm
    {...props}
    inputs={INPUTS}
    loading={loading}
    onSubmit={handleSubmit}
    values={{ replies }}
  />
);

EditKeywordForm = reduxForm({
  form,
  validate: validate(KEYWORD_VALIDATIONS),
})(EditKeywordForm);

const selector = formValueSelector(form);

const mapStateToProps = ({
  keywords: {
    item,
  },
  ...state
}) => ({
  initialValues: item ? item.data : {},
  replies: selector(state, 'replies'),
});

export default connect(
  mapStateToProps
)(EditKeywordForm);

import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from './Styled/DialogContent';
import WarningIcon from './Styled/WarningIcon';
import trans from '../../Translations/translation.service';

export default ({
  onAcceptClick,
  onCancelClick,
  acceptText = '',
  cancelText = '',
  open = false,
  warningText = '',
}) => {
  return (
    <Dialog
      open={open}
      onClose={onCancelClick}
    >
      <DialogTitle>
        {trans('modals.titles.warning')}
      </DialogTitle>
      <DialogContent>
        <WarningIcon />
        <DialogContentText>
          {warningText || trans('modals.messages.warning')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onAcceptClick}>
          {acceptText || trans('modals.actions.accept')}
        </Button>
        <Button onClick={onCancelClick}>
          {cancelText || trans('modals.actions.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

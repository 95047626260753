import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from './Styled/InputLabel';
import MenuItem from './Styled/MenuItem';
import MUISelect from './Styled/Select';

class Select extends React.Component {
  handleChange = evt => {
    const { target: { name, value } } = evt;
    const { afterChange, index, onChange } = this.props;
    if (typeof afterChange === 'function') {
      afterChange(value, name, index);
    }
    onChange(evt);
  };

  render() {
    const {
      data,
      error,
      label,
      name,
      required,
      showError,
      shrink,
      value,
    } = this.props;

    return (
      <FormControl
        fullWidth
        error={showError}
        required={required}
      >
        <InputLabel shrink={shrink} htmlFor={name}>{label}</InputLabel>
        <MUISelect
          onChange={this.handleChange}
          value={value}
        >
          {
            Array.isArray(data) && data.map(({ text, value }, key) => (
              <MenuItem key={key} value={value}>
                {text}
              </MenuItem>
            ))
          }
        </MUISelect>
        {!!error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    );
  }
}

export default Select;

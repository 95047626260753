import trans from '../Translations/translation.service';
import { COLORS } from './Styles';

export const APP_NAME = 'Infomail Network';
export const APP_SHORT_NAME = 'Infomail';
export const APP_VERSION = '2.0';

export const ADMIN_ROLE = 0;
export const CLIENT_ROLE = 1
export const EMPLOYEE_ROLE = 2;

export const RESTRICTED_ROLES = [
  EMPLOYEE_ROLE,
];

export const STATUSES = {
  active: 0,
  inactive: 1,
};

export const FEMALE_CODE = 'F';
export const MALE_CODE = 'M';

export const SMS_CHAR_SIZE = 160;
export const MAX_MESSAGE_SMS = 4;

export const DEFAULT_SUBSCRIPTION = {
  active: false,
  price: 0,
  credits: 0,
  total: 0,
  username: null,
  password: null,
  unlimited: false,
  integrator: '',
  integrator_id: null,
  service: '',
  service_id: null,
};

export const DROPZONE_DEFAULT_FILES_LIMIT = 1;
export const DROPZONE_DEFAULT_SIZE_LIMIT = 10000000;
export const DROPZONE_DEFAULT_VALID_FILES = [];

export const CARRIER_COLORS = [
  COLORS.carriers.movistar,
  COLORS.carriers.digitel,
  COLORS.carriers.movilnet,
];

export const CARRIERS = [
  trans('default.carriers.movistar'),
  trans('default.carriers.digitel'),
  trans('default.carriers.movilnet'),
];

export const ALL_SELECT_OPTION_VALUE = 'all';

export const ALL_SELECT_OPTION_F =  {
  text: trans('default.filters.allF'),
  value: ALL_SELECT_OPTION_VALUE,
};

export const ALL_SELECT_OPTION_M =  {
  text: trans('default.filters.allM'),
  value: ALL_SELECT_OPTION_VALUE,
};

export const MONTHS_LIST = [
  'january', 'february', 'march', 'april', 'may', 'june',
  'july', 'august', 'september', 'october', 'november', 'december',
];

export const MONTHS_SELECT_OPTIONS = [
  ...MONTHS_LIST.map((month, value) => ({
    text: trans(`default.months.${month}`),
    value,
  })),
];

export const PAYMENT_TYPES_LIST = [
  'sameBankTransfer',
  'otherBankTransfer',
  'cash',
  'deposit',
];

export const PAYMENT_TYPES_SELECT_OPTIONS = [
  {
    text: trans(`default.paymentTypes.sameBankTransfer`),
    value: 0,
  },
  {
    text: trans(`default.paymentTypes.otherBankTransfer`),
    value: 1,
  },
  {
    text: trans(`default.paymentTypes.cash`),
    value: 2,
  },
  {
    text: trans(`default.paymentTypes.deposit`),
    value: 3,
  },
];

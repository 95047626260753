import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Switch from './Styled/Switch';
import SwitchLabel from './Styled/SwitchLabel';

class TwoWaySwitch extends React.Component {
  handleSwitchChange = (evt, checked) => {
    const { afterChange, index, name, onChange } = this.props;
    if (typeof afterChange === 'function') {
      afterChange(checked, name, index);
    }
    onChange(evt, checked);
  };

  render() {
    const { disabled, label, labelOff, labelOn, name, value } = this.props;

    return (
      <Typography component="div">
        <Grid container alignItems="center" spacing={1}>
          <SwitchLabel item xs={12}>{label}</SwitchLabel>
          <Grid item>{labelOff}</Grid>
          <Grid item>
            <Switch
              checked={value}
              disabled={disabled}
              inputProps={{ name }}
              name={name}
              onChange={this.handleSwitchChange}
            />
          </Grid>
          <Grid item>{labelOn}</Grid>
        </Grid>
      </Typography>
    );
  }
}

export default TwoWaySwitch;

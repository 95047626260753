import React from 'react';
import { connect } from 'react-redux';
import Table from '../../../Components/Table';
import trans from '../../../Translations/translation.service';
import { actions as scheduledCampaignsActions } from '../../../Redux/Api/Schedules';
import {
  DELETE_TABLE_ACTIONS,
  SCHEDULED_CAMPAIGNS_TABLE_TITLES,
  SUCCESS_CODE,
} from '../../../Constants';
import { getPage, parseApiDate, parseApiTime } from '../../../Helpers';

class ClientScheduledCampaigns extends React.Component {
  deleteCampaign = id => (
    this.props.deleteSchedule(id)
      .then(({ code }) => code === SUCCESS_CODE && this.fetchCampaigns())
  );

  fetchCampaigns = newPage => {
    const { location } = this.props;
    const page = getPage(newPage, location);

    this.props.fetchSchedules({}, { query: { page, client: true, scheduled: true } });
  };

  loading = () => {
    const {
      isDeletingCampaign,
      isFetchingCampaigns,
    } = this.props;

    return isDeletingCampaign
      || isFetchingCampaigns;
  };

  parseScheduledCampaignData = ({
    campaign_message,
    send_date: date,
    send_time: time,
  }) => [
    {
      text: campaign_message && campaign_message.campaign && campaign_message.campaign.name,
    },
    {
      text: campaign_message && campaign_message.campaign && campaign_message.campaign.group && campaign_message.campaign.group.name,
    },
    {
      noWrap: true,
      text: parseApiDate(date),
    },
    {
      noWrap: true,
      text: parseApiTime(time),
    },
  ];

  componentDidMount() {
    this.fetchCampaigns();
  }

  render() {
    const { scheduledCampaigns, pagination } = this.props;
    const type = trans('resources.singular.campaign');

    return (
      <Table
        actions={DELETE_TABLE_ACTIONS}
        actionButtonNames={{
          delete: ({ campaign_message: { campaign: { name } } }) => name,
        }}
        data={scheduledCampaigns}
        dataParser={this.parseScheduledCampaignData}
        deleteWarningMessage="deleteOpSingF"
        handleChangePage={this.fetchScheduledCampaigns}
        loading={this.loading()}
        onDeleteAcceptClick={this.deleteCampaign}
        pagination={pagination}
        titles={SCHEDULED_CAMPAIGNS_TABLE_TITLES}
        type={type}
      />
    );
  }
}

const mapStateToProps = ({
  schedules: {
    isFetching,
    isDeleting,
    items: [success, data, pagination],
  },
}) => ({
  pagination,
  success,
  scheduledCampaigns: success ? data : [],
  isDeletingCampaign: isDeleting,
  isFetchingCampaigns: isFetching,
});

export default connect(
  mapStateToProps,
  {
    ...scheduledCampaignsActions,
  }
)(ClientScheduledCampaigns);

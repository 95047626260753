import React from 'react';
import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import moment from 'moment';
import Table from '../../../Components/Table';
import { actions as activitiesActions } from '../../../Redux/Api/Activities';
import { actions as allUsersActions } from '../../../Redux/Api/AllUsers';
import {
  ACTIVITIES_TYPES_ARRAY,
  ALL_SELECT_OPTION_M,
  ALL_SELECT_OPTION_VALUE as ALL,
  API_DATE_FORMAT,
  CLIENT_ACTIVITIES_TABLE_TITLES,
} from '../../../Constants';
import {
  CLIENT_ACTIVITIES_FILTERS_INPUTS,
} from './config';
import { FILTERS_FORM as form } from '../../../Components/Filters/config';
import { getPage, parseActivityDescription, parseApiDatetime } from '../../../Helpers';
import trans from '../../../Translations/translation.service';

class ClientActivities extends React.Component {
  state = {
    filters: {},
  };

  fetchActivities = newPage => {
    const { filters } = this.state;
    const { location } = this.props;
    const page = getPage(newPage, location);

    this.props.fetchActivities({}, {
      query: { page, client: true, ...filters },
    });
  };

  fetchActivitiesFromDateFrom = date => {
    const { filters } = this.state;

    const momentDate = moment(date, API_DATE_FORMAT);

    if (momentDate._isValid) {
      filters.from =  date;
    } else {
      delete filters.from;
    }

    this.setState({ filters }, () => {
      this.fetchActivities();
    });
  };

  fetchActivitiesFromDateTo = date => {
    const { filters } = this.state;

    const momentDate = moment(date, API_DATE_FORMAT);

    if (momentDate._isValid) {
      filters.to =  date;
    } else {
      delete filters.to;
    }

    this.setState({ filters }, () => {
      this.fetchActivities();
    });
  };

  fetchActivitiesFromType = type => {
    const { filters } = this.state;

    if (type === ALL) {
      delete filters.type;
    } else {
      filters.type =  type;
    }

    this.setState({ filters }, () => {
      this.fetchActivities();
    });
  };

  fetchActivitiesFromUser = user => {
    const { filters } = this.state;

    if (user === ALL) {
      delete filters.user;
    } else {
      filters.user =  user;
    }

    this.setState({ filters }, () => {
      this.fetchActivities();
    });
  };

  initFilters = () => {
    this.props.reset(form);
    this.props.change(form, 'from_date', undefined);
    this.props.change(form, 'to_date', undefined);
    this.props.change(form, 'user_id', ALL);
    this.props.change(form, 'type', ALL);
  };

  loading = () => {
    const {
      isFetchingActivities,
      isFetchingUsers,
    } = this.props;

    return isFetchingActivities
      || isFetchingUsers;
  };

  parseActivityData = ({
    created,
    decoded_description: decodedDescription,
    type,
    user,
  }) => [
    {
      text: user && user.username,
    },
    {
      text: trans(`default.activities.types.${ACTIVITIES_TYPES_ARRAY[type]}`),
    },
    {
      text: parseActivityDescription(decodedDescription),
    },
    {
      noWrap: true,
      text: parseApiDatetime(created),
    },
  ];

  parseUsers = () => {
    const accounts = this.props.users.map(({ id: value, username: text }) => ({
      text, value,
    }));

    accounts.unshift(ALL_SELECT_OPTION_M);

    return accounts;
  };

  componentDidMount() {
    this.fetchActivities();
    this.initFilters();
    this.props.fetchAllUsers({}, { query: { client: true } });
  }

  render() {
    const { activities, pagination } = this.props;
    const users = this.parseUsers();

    return (
      <Table
        // actions={DELETE_TABLE_ACTIONS}
        data={activities}
        dataParser={this.parseActivityData}
        filtersProps={{
          afterChange: {
            from_date: this.fetchActivitiesFromDateFrom,
            to_date: this.fetchActivitiesFromDateTo,
            user_id: this.fetchActivitiesFromUser,
            type: this.fetchActivitiesFromType,
          },
          data: {
            user_id: users,
          },
          inputs: CLIENT_ACTIVITIES_FILTERS_INPUTS,
          loading: this.loading(),
        }}
        handleChangePage={this.fetchActivities}
        loading={this.loading()}
        pagination={pagination}
        titles={CLIENT_ACTIVITIES_TABLE_TITLES}
      />
    );
  }
}

const mapStateToProps = ({
  activities: {
    isFetching,
    items: [success, data, pagination],
  },
  allUsers: {
    isFetching: isFetchingUsers,
    items: users,
  },
}) => ({
  isFetchingUsers,
  pagination,
  success,
  activities: success ? data : [],
  isFetchingActivities: isFetching,
  users: users[1] ? users[1] : [],
});

export default connect(
  mapStateToProps,
  {
    ...activitiesActions,
    ...allUsersActions,
    change,
    reset,
  }
)(ClientActivities);

import React from 'react';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';
import ResponsiveForm from '../../Form';
import { validate } from '../../../../Helpers';
import {
  DEFAULT_VALUES,
  EDITABLE_INPUTS,
  KEYWORD_FORM as form,
  KEYWORD_VALIDATIONS,
} from './config';

let AddKeywordForm = ({ handleSubmit, replies, loading = false, ...props }) => (
  <ResponsiveForm
    {...props}
    inputs={EDITABLE_INPUTS}
    loading={loading}
    onSubmit={handleSubmit}
    values={{ replies }}
  />
);

AddKeywordForm = reduxForm({
  form,
  validate: validate(KEYWORD_VALIDATIONS),
})(AddKeywordForm);

const selector = formValueSelector(form);

const mapStateToProps = state => ({
  initialValues: { ...DEFAULT_VALUES },
  replies: selector(state, 'replies'),
});

export default connect(
  mapStateToProps
)(AddKeywordForm);

import React from 'react';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import ModalsWrappedForm from '../../../../Components/Forms/ModalsWrappedForm';
import TestMessageForm from '../../../../Components/Forms/Client/Campaigns/TestMessage';
import trans from '../../../../Translations/translation.service';
import { actions as messagesActions, types } from '../../../../Redux/Api/Messages';
import { MALE_CODE } from '../../../../Constants';
import {
  TEST_MESSAGE_FORM as form,
  SEND_TEST_ACTIONS,
} from '../../../../Components/Forms/Client/Campaigns/config';
import { catchApiItemErrors } from '../../../../Helpers';

class TestMessage extends React.Component {
  createMessage = data => {
    return this.props.createMessage(data).catch(
      catchApiItemErrors,
    );
  };

  filterAccountIntegrators = ({ value }) => {
    const { account } = this.props;

    return account
      && Array.isArray(account.subscriptions)
      && account.subscriptions.some(
        ({ integrator_id }) => integrator_id === value,
      );
  };

  getActions = () => {
    const { onAcceptClick } = this.props;
    const actions = [ ...SEND_TEST_ACTIONS ];
    actions[0].onClick = () => onAcceptClick();
    return actions;
  };

  loading = () => {
    const {
      isCreatingMessage,
    } = this.props;
    return isCreatingMessage;
  };

  parseIntegrators = () => (
    this.props.integrators.map(({ id: value, name: text }) => ({
      text, value,
    }))
  );

  setContent = () => {
    const { content } = this.props;
    this.props.change(
      form,
      'content',
      content,
    );
  };

  updateCredits = integratorId => {
    const { account } = this.props;
    const hasSubscriptions = Array.isArray(account.subscriptions);
    const subscription = hasSubscriptions
      ? account.subscriptions.find(({ integrator_id }) => (
        integrator_id === integratorId
      ))
      : null;
    this.props.change(
      form,
      'credits',
      subscription && subscription.current_credits - subscription.blocked_credits,
    );
  };

  componentDidMount() {
    this.setContent();
  }

  render() {
    const { account, onAcceptClick, success } = this.props;
    const integrators = this.parseIntegrators();
    const subject = trans('resources.singular.message');

    return (
      <ModalsWrappedForm
        acceptText={trans('modals.actions.accept')}
        gender={MALE_CODE}
        loading={this.loading()}
        onAcceptClick={() => onAcceptClick(true)}
        open={success}
        subject={subject}
        successMessage="successOpSingM"
        type="send"
      >
        {
          account &&
          <TestMessageForm
            actions={this.getActions()}
            data={{
              integrator_id: integrators.filter(this.filterAccountIntegrators),
            }}
            onSubmit={this.createMessage}
          />
        }
      </ModalsWrappedForm>
    );
  }
}

const mapStateToProps = ({
  allIntegrators: {
    items: integrators,
  },
  accounts: {
    item,
  },
  messages: { isCreating },
  reduxRestResponse: { body, success, type },
  auth: { user },
  ...state
}) => ({
  body,
  account: item ? item.data : null,
  accountId: user && user.account_id,
  integrators: integrators[1] ? integrators[1] : [],
  isCreatingMessage: isCreating,
  success: type === types.CREATE_MESSAGE && success,
});

export default connect(
  mapStateToProps,
  {
    change,
    ...messagesActions,
  }
)(TestMessage);

import React from 'react';
import Grid from '@material-ui/core/Grid';
import Tab from '@material-ui/core/Tab';
import Fields from './Fields';
import FormTab from './Styled/FormTab';
import Tabs from './Styled/Tabs';

class TabFields extends React.Component {
  state = {
    tab: 0,
  };

  handleTabChange = (e, tab) => {
    this.setState({ tab });
  }

  render() {
    const { tab } = this.state;
    const { afterChange, data, spacing, tabs, values } = this.props;
    const fieldsProps = {
      data,
      spacing,
      values,
    };

    return (
      <React.Fragment>
        <Tabs
          value={tab}
          onChange={this.handleTabChange}
          indicatorColor="secondary"
          variant="scrollable"
          scrollButtons="auto"
        >
          {
            tabs.map(({ label }, key) => (
              <Tab key={key} label={label} />
            ))
          }
        </Tabs>
          {
            tabs.map(({ code, inputs }, key) => (
              <FormTab item hide={tab !== key} key={key} xs={12}>
                <Grid container spacing={spacing}>
                  <Fields
                    afterChange={afterChange && afterChange[code]}
                    inputs={inputs}
                    {...fieldsProps}
                  />
                </Grid>
              </FormTab>
            ))
          }
      </React.Fragment>
    );
  }
}

export default TabFields;

import styled from 'styled-components';
import { COLORS } from '../../../Constants';

export default styled.div`
  align-items: center;
  background-color: ${COLORS.turquoise};
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;
`;

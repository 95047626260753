import { CARRIERS } from '../Constants';

export const getCarriersGeneralStats = (stats, path) => {
    const carrierStats = [];

    if (!Array.isArray(stats) || stats.some(item => typeof item !== 'object' || !item)) {
        return carrierStats;
    }

    CARRIERS.forEach((carrier, key) => {
        const carrierStat = { link: `${path}?carrier=${key}`, name: carrier, value: 0, size: { xs: 12, lg: 2 } };
        const foundStat = stats.find(stat => stat.carrier === key);

        if (foundStat) {
            carrierStat.value = foundStat.messages;
        }

        carrierStats.push(carrierStat);
    });

    return carrierStats;
};

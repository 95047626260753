/** Global messages (EN)  */
export default {
  actions: {
    addedM: 'added',
    addedF: 'added',
    editedM: 'edited',
    editedF: 'edited',
    deletedM: 'deleted',
    deletedF: 'deleted',
    scheduledM: 'scheduled',
    scheduledF: 'scheduled',
    sentM: 'sent',
    sentF: 'sent',
  },
  activities: {
    description: {
      campaign: 'Campaign',
      companyName: 'Name',
      contacts: 'Contacts',
      credits: 'Credits',
      integrator: 'Integrator',
      ip: 'IP Address',
      keyword: 'Keyword',
      number: 'Number',
      sms: 'Used SMS',
      scheduledFor: 'Scheduled for',
      username: 'Username',
    },
    types: {
      campaignScheduled: 'Campaign Scheduled',
      campaignSent: 'Campaign Sent',
      apiMessageSent: 'API Message Sent',
      testMessageSent: 'Test Message Sent',
      campaignDeleted: 'Campaign Deleted',
      creditsAdded: 'Credits Added',
      logIn: 'Log In',
      logOut: 'Log Out',
      scheduledCampaignDeleted: 'Scheduled Campaign Deleted',
      userCreated: 'User Created',
      userDeleted: 'User Deleted',
      accountCreated: 'Account Created',
      accountDeleted: 'Account Deleted',
    },
  },
  filters: {
    filters: 'Filters',
    search: 'Search',
    searchByNumber: 'Search by number',
    searchByWord: 'Search by word',
    allF: 'All',
    allM: 'All',
  },
  forms: {
    labels: {
      browse: 'Browse',
    },
    messages: {
      fileAdded: 'File {{ fileName }} successfully added.',
      fileRejected: 'File {{ fileName }} was rejected. ',
      fileRemoved: 'File {{ fileName }} removed.',
    },
    validations: {
      dateIsBefore: 'This must be a date before the field "{{ field }}"',
      fileSizeTooBig: 'File is too big. Size limit is ',
      fileTypeNotSupported: 'File type not supported. ',
      isCellPhoneNumber: 'This must be a valid cellphone number',
      isEmail: 'This must be a valid email',
      isHigherThan: 'This must be higher than {{ min }}',
      isRequired: 'This field cannot be empty',
      isUrl: 'This must be a valid URL',
      matchesField: 'This must match the value of the field "{{ field }}"',
      maxFilesLength: 'Maximum allowed number of files exceeded. Only {{ filesLimit }} allowed',
      notEnoughCredits: 'Not enough credits. Pick another integrator',
    },
  },
  loading : {
    dontRefresh: "Don't refresh the app",
    pleaseWait: 'Please wait! This may take some time.',
  },
  messageTypes: {
    api: 'API',
    test: 'Test',
  },
  months: {
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December',
  },
  newCampaign: 'New Campaign',
  newGroup: 'New Group',
  paymentTypes: {
    sameBankTransfer: 'Transfer From The Same Bank',
    otherBankTransfer: 'Transfer From Other Bank',
    cash: 'Cash',
    deposit: 'Deposit',
  },
  titles: {
    activities: 'Activities',
    addPayment: 'Add Payment',
    addUser: 'Add User',
    editKeyword: 'Edit Keyword',
    editPayment: 'Edit Payment',
    editUser: 'Edit User',
    keywords: 'Keywords',
    lastReceivedMessages: 'Last Received Messages',
    lastSentMessages: 'Last Sent Messages',
    receivedMessages: 'Received Messages',
    scheduledCampaigns: 'Scheduled Campaigns',
    sentCampaigns: 'Sent Campaigns',
    sentMessages: 'Sent Messages',
    sentReplies: 'Sent Replies',
    shortCodesDashboard: 'Dashboard - Short Codes',
    smsDashboard: 'Dashboard - SMS',
    users: 'Users',
    admin: {
      accounts: 'Accounts',
      addAccount: 'Add Account',
      addBanks: 'Add Banks',
      addBusinessTypes: 'Add Business Types',
      addCredits: 'Add Credits',
      addIndustries: 'Add Industries',
      addKeyword: 'Add Keyword',
      addNew: 'Add New',
      addShortCodes: 'Add Short Codes',
      banks: 'Banks',
      businessTypes: 'Business Types',
      editAccount: 'Edit Account',
      editBank: 'Edit Bank',
      editBusinessType: 'Edit Business Types',
      editIndustry: 'Edit Industry',
      editIntegrator: 'Edit Integrator',
      editNew: 'Edit New',
      editShortCode: 'Edit Short Code',
      industries: 'Industries',
      integrators: 'Integrators',
      news: 'News',
      payments: 'Payments',
      reportsSMS: 'Reports SMS',
      reportsShortCodes: 'Reports Short Codes',
      shortCodes: 'Short Codes',
    },
    client: {
      addCampaign: 'Add Campaign',
      addGroups: 'Add Groups',
      campaigns: 'Campaigns',
      contacts: 'Contacts',
      editCampaign: 'Edit Campaign',
      editGroup: 'Edit Group',
      groups: 'Groups',
      myAccount: 'My Account',
      myPayments: 'My Payments',
      newMessage: 'New Message',
    },
  },
  carriers: {
    movistar: 'Movistar',
    digitel: 'Digitel',
    movilnet: 'Movilnet',
  },
  langs: {
    en: 'English',
    es: 'Spanish',
  },
  logout: 'Logout',
  stats: {
    messages: {
      available_credits: 'Available Credits',
      received_messages: 'Received Messages',
      scheduled_campaigns: 'Scheduled Campaigns',
      sent_messages: 'Sent Messages',
      sent_replies: 'Sent Replies',
    },
    contacts: {
      accepted: 'Accepted Contacts',
      rejected: 'Rejected Contacts',
    },
  },
  statuses: {
    active: 'Active',
    inactive: 'Inactive',
  },
};

import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import Logout from './Logout';
import Menu from './Menu';
import Wrapper from './Wrapper';

class Navbar extends React.Component {
  state = {
    canExpand: false,
    expanded: false,
  };

  toggleCanExpand = () => {
    let { expanded } = this.state;
    const { canExpand } = this.state;

    if (canExpand) {
      expanded = false;
    }

    this.setState({ expanded, canExpand: !canExpand });
  };

  handleChange = panel => (event, isExpanded) => {
    this.setState({ expanded: isExpanded ? panel : false });
  };

  handleMouseEnter = () => {
    this.toggleCanExpand();
  };

  handleMouseLeave = () => {
    this.toggleCanExpand();
  };

  render() {
    const { canExpand, expanded } = this.state;
    const { classes, hasNews, open, options, selectedOption } = this.props;

    return (
      <Wrapper
        className={classes.root}
        hasNews={hasNews}
        handleMouseEnter={this.handleMouseEnter}
        handleMouseLeave={this.handleMouseLeave}
        open={open}
      >
        <Menu
          canExpand={canExpand}
          expanded={expanded}
          handleChange={this.handleChange}
          hasNews={hasNews}
          options={options}
          selectedOption={selectedOption}
        />
        <Logout />
      </Wrapper>
    );
  }
}

Navbar.defaultProps = {
  options: [],
  selectedOption: '',
};

const mapStateToProps = ({
  navbar: { open },
}) => ({
  open,
});

const styles = theme => ({
  root: {
    [theme.breakpoints.down('md')]: {
      position: 'absolute',
      '&, & .navbar-content': {
        width: props => props.open ? '210px' : '0px',
      },
    },
  },
});

const StyledNavbar = withStyles(styles)(Navbar);

export default connect(mapStateToProps)(StyledNavbar);

/** Resources names (ES)  */
export default {
  plural: {
    accounts: 'Cuentas',
    banks: 'Bancos',
    businessTypes: 'Tipos de Negocio',
    campaigns: 'Campañas',
    contacts: 'Contactos',
    credits: 'Créditos',
    groups: 'Grupos',
    industries: 'Industrias',
    integrators: 'Integradoras',
    keywords: 'Palabras Clave',
    messages: 'Mensajes',
    news: 'Noticias',
    payments: 'Pagos',
    shortCodes: 'Números Cortos',
    users: 'Usuarios',
  },
  singular: {
    account: 'Cuenta',
    bank: 'Banco',
    businessType: 'Tipo de Negocio',
    campaign: 'Campaña',
    contact: 'Contacto',
    group: 'Grupo',
    industry: 'Industria',
    integrator: 'Integradora',
    keyword: 'Palabra Clave',
    message: 'Mensaje',
    new: 'Noticia',
    payment: 'Pago',
    shortCode: 'Número Corto',
    user: 'Usuario',
  },
};

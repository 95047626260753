import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import ResponsiveForm from '../../Form';
import { validate } from '../../../../Helpers';
import {
  DEFAULT_ADD_VALUES,
  INPUTS,
  SHORT_CODES_FORM as form,
  SHORT_CODES_VALIDATIONS,
} from './config';

let AddShortCodesForm = ({ handleSubmit, loading = false, ...props }) => (
  <ResponsiveForm
    {...props}
    inputs={INPUTS}
    loading={loading}
    onSubmit={handleSubmit}
  />
);

AddShortCodesForm = reduxForm({
  form,
  validate: validate(SHORT_CODES_VALIDATIONS),
})(AddShortCodesForm);

const mapStateToProps = () => ({
  initialValues: { ...DEFAULT_ADD_VALUES },
});

export default connect(
  mapStateToProps
)(AddShortCodesForm);

import React from 'react';
import { connect } from 'react-redux';
import EditIndustryForm from '../../../../Components/Forms/Admin/Industries/EditIndustry';
import ModalsWrappedForm from '../../../../Components/Forms/ModalsWrappedForm';
import trans from '../../../../Translations/translation.service';
import { actions as industriesActions, types } from '../../../../Redux/Api/Industries';
import { PRIVATE_PATHS } from '../../../config';
import { FEMALE_CODE } from '../../../../Constants';
import { EDIT_ACTIONS } from '../../../../Components/Forms/Admin/Industries/config';
import { catchApiItemErrors } from '../../../../Helpers';

class EditIndustry extends React.Component {
  getIndustry = () => {
    const { id } = this.props.match.params;

    this.props.getIndustry(id);
  };

  loading = () => {
    const { isFetchingIndustry, isUpdatingIndustry } = this.props;
    return isFetchingIndustry || isUpdatingIndustry;
  };

  updateIndustry = data => {
    return this.props.updateIndustry(data).catch(
      catchApiItemErrors,
    );
  };

  componentDidMount() {
    this.getIndustry();
  }

  render() {
    const { history, industry, success } = this.props;
    const loading = this.loading();
    const subject = trans('resources.singular.industry');

    return (
      <ModalsWrappedForm
        gender={FEMALE_CODE}
        history={history}
        loading={loading}
        open={success}
        path={PRIVATE_PATHS.adminIndustries.path}
        subject={subject}
        successMessage="successOpSingF"
        type="edit"
      >
        {
          industry &&
          <EditIndustryForm
            actions={EDIT_ACTIONS}
            history={history}
            onSubmit={this.updateIndustry}
            path={PRIVATE_PATHS.adminIndustries.path}
          />
        }
      </ModalsWrappedForm>
    );
  }
}

const mapStateToProps = ({
  industries: { isFetchingItem, isUpdating, item },
  reduxRestResponse: { body, success, type },
}) => ({
  body,
  industry: item,
  isFetchingIndustry: isFetchingItem,
  isUpdatingIndustry: isUpdating,
  success: type === types.UPDATE_INDUSTRY && success,
});

export default connect(
  mapStateToProps,
  {
    ...industriesActions,
  }
)(EditIndustry);

export const API_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';
export const API_DATE_FORMAT = 'YYYY-MM-DD';
export const API_DATE_FORMAT_FIRST = 'YYYY-MM-01';
export const API_TIME_FORMAT = 'HH:mm:ss';
export const DATE_FORMAT = 'DD-MM-YYYY';
export const DATETIME_FORMAT = 'DD-MM-YYYY hh:mm A';
export const TIME_FORMAT = 'hh:mm A';
export const TIME_CHART_FORMAT = 'HH:mm:ss';

export const PHONE_NUMBER_FORMAT = /^(((\+?58)?(\s|-)?)|0)(414|424|416|426|412)(\s|-)?(\d{3})(\s|-)?(\d{4})$/;

import React from 'react';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';
import ResponsiveForm from '../../Form';
import {
  TEST_MESSAGE_DEFAULT_VALUES,
  TEST_MESSAGE_FORM as form,
  TEST_MESSAGE_INPUTS,
  // NEW_MESSAGE_VALIDATIONS,
} from './config';

let NewMessageForm = ({ handleSubmit, message, loading = false, ...props }) => (
  <ResponsiveForm
    {...props}
    inputs={TEST_MESSAGE_INPUTS}
    loading={loading}
    onSubmit={handleSubmit}
    values={{ message }}
  />
);

NewMessageForm = reduxForm({
  form,
  // validate: validate(NEW_MESSAGE_VALIDATIONS),
})(NewMessageForm);

const selector = formValueSelector(form);

const mapStateToProps = state => ({
  initialValues: { ...TEST_MESSAGE_DEFAULT_VALUES },
  message: selector(
    state,
    'integrator_id',
  ),
});

export default connect(
  mapStateToProps
)(NewMessageForm);

import React from 'react';
import { connect } from 'react-redux';
import AccountPlay from '../../../Components/AccountPlay';
import Table from '../../../Components/Table';
import trans from '../../../Translations/translation.service';
import { actions as accountsActions } from '../../../Redux/Api/Accounts';
import { getPage, renderSubscriptionsData } from '../../../Helpers';
import {
  ACCOUNTS_TABLE_TITLES,
  API_ACCOUNT_TYPES,
  DEFAULT_TABLE_ACTIONS,
  SUCCESS_CODE,
} from '../../../Constants';
import { PRIVATE_PATHS } from '../../config';

class AdminAccounts extends React.Component {
  deleteAccount = id => (
    this.props.deleteAccount(id)
      .then(({ code }) => code === SUCCESS_CODE && this.fetchAccounts())
  );

  fetchAccounts = newPage => {
    const { location } = this.props;
    const page = getPage(newPage, location);

    this.props.fetchAccounts({}, { query: { page } });
  };

  loading = () => {
    const {
      isDeletingAccount,
      isFetchingAccounts,
      isUpdatingUser,
    } = this.props;

    return isDeletingAccount
      || isFetchingAccounts
      || isUpdatingUser;
  };

  parseAccountData = ({
    account_type,
    client,
    company_name,
    id,
    number,
    subscriptions,
  }) => [
    {
      text: (
        <AccountPlay accountId={id}>{company_name}</AccountPlay>
      ),
    },
    {
      text: number,
    },
    {
      text: client && client.username,
    },
    {
      text: trans(`types.accounts.${API_ACCOUNT_TYPES[account_type]}`),
    },
    {
      text: renderSubscriptionsData(subscriptions),
    }
  ];

  componentDidMount() {
    this.fetchAccounts();
  }

  render() {
    const {
      accounts,
      history,
      pagination,
    } = this.props;
    const type = trans('resources.singular.account');

    return (
      <Table
        actions={DEFAULT_TABLE_ACTIONS}
        actionButtonNames={{
          delete: ({ company_name }) => company_name,
        }}
        data={accounts}
        dataParser={this.parseAccountData}
        deleteWarningMessage="deleteOpSingF"
        editPath={PRIVATE_PATHS.adminEditAccount.noParamsPath}
        handleChangePage={this.fetchAccounts}
        history={history}
        loading={this.loading()}
        onDeleteAcceptClick={this.deleteAccount}
        pagination={pagination}
        titles={ACCOUNTS_TABLE_TITLES}
        type={type}
      />
    );
  }
}

const mapStateToProps = ({
  accounts: {
    isDeleting,
    isFetching,
    items: [success, data, pagination],
  },
  users: { isUpdating },
}) => ({
  pagination,
  success,
  accounts: success ? data : [],
  isDeletingAccount: isDeleting,
  isFetchingAccounts: isFetching,
  isUpdatingUser: isUpdating,
});

export default connect(
  mapStateToProps,
  {
    ...accountsActions,
  }
)(AdminAccounts);

import React from 'react';
import { reduxForm } from 'redux-form';
import ResponsiveForm from '../Forms/Form';
import StyledFiltersForm from './Styled/StyledFiltersForm';
import { FILTERS_FORM as form } from './config';

let FiltersForm = ({
    className,
    handleSubmit,
    filtersProps: {
        inputs = [],
        loading = false,
        ...props
    },
}) => (
    <StyledFiltersForm className={className}>
        <ResponsiveForm
            {...props}
            inputs={inputs}
            loading={loading}
            onSubmit={handleSubmit}
        />
    </StyledFiltersForm>
);

FiltersForm = reduxForm({
  form,
})(FiltersForm);

export default FiltersForm;

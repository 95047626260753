import styled from 'styled-components';

export default styled.div`
  height: 36px;
  margin-top: ${props => props.spacing*8}px;
  text-align: ${props => props.loading ? 'center' : 'right'};

  button {
    margin-left: 15px;
  }
`;

export const SET_HEADER_TITLE = 'infomail/navbar/SET_HEADER_TITLE';
export const SET_HEADER_MENU = 'infomail/navbar/SET_HEADER_MENU';
export const SET_HEADER_MENU_ACTION = 'infomail/navbar/SET_HEADER_MENU_ACTION';
export const SET_LOCALE = 'infomail/navbar/SET_LOCALE';
export const SET_OPEN = 'infomail/navbar/SET_OPEN';
export const SET_ON_SEARCH = 'infomail/navbar/SET_ON_SEARCH';
export const SET_ROUTE_TYPE = 'infomail/navbar/SET_ROUTE_TYPE';
export const SET_SEARCH = 'infomail/navbar/SET_SEARCH';
export const SET_SEARCH_PLACEHOLDER = 'infomail/navbar/SET_SEARCH_PLACEHOLDER';
export const SET_SELECTED_OPTION = 'infomail/navbar/SET_SELECTED_OPTION';

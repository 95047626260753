import React from 'react';
import { Field } from 'redux-form';
import Input from './Input';

export default ({ data, name, normalize, ...props }) => (
  <Field
    component={Input}
    data={data}
    name={name}
    normalize={normalize}
    props={props}
  />
);

import { createResource } from 'redux-rest-resource';
import { HEADERS as headers, GROUPS_URL } from '../../Constants';
import { transformFetchResponse as transformResponse } from '../../Helpers';

export const { types, actions, reducers } = createResource({
  name: 'allGroup',
  pluralName: 'allGroups',
  url: `${GROUPS_URL}/all`,
  headers,
  actions: {
    fetch: {
      transformResponse,
    },
  },
});

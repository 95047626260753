import React from 'react';
import { connect } from 'react-redux';
import FileDownloadLink from '../../../Components/FileDownloadLink';
import Table from '../../../Components/Table';
import trans from '../../../Translations/translation.service';
import { actions as campaignsActions } from '../../../Redux/Api/Campaigns';
import {
  CAMPAIGNS_TABLE_TITLES,
  DEFAULT_TABLE_ACTIONS,
  HEADERS as headers,
  SUCCESS_CODE,
} from '../../../Constants';
import { PRIVATE_PATHS } from '../../config';
import { getPage } from '../../../Helpers';

class ClientCampaigns extends React.Component {
  deleteCampaign = id => (
    this.props.deleteCampaign(id)
      .then(({ code }) => code === SUCCESS_CODE && this.fetchCampaigns())
  );

  fetchCampaigns = newPage => {
    const { location } = this.props;
    const page = getPage(newPage, location);

    this.props.fetchCampaigns({}, { query: { page, client: true } });
  };

  getActions = row => {
    const { queued_count, scheduled_count } = row;
    const removeActions = [];

    if (scheduled_count && scheduled_count > 0) {
      removeActions.push('edit');
    }

    if (queued_count > 0) {
      removeActions.push('delete');
      removeActions.push('edit');
    }

    return DEFAULT_TABLE_ACTIONS.filter(action => removeActions.indexOf(action) === -1);
  };

  loading = () => {
    const {
      isDeletingCampaign,
      isDownloadingFile,
      isFetchingCampaigns,
    } = this.props;

    return isDeletingCampaign
      || isDownloadingFile
      || isFetchingCampaigns;
  };

  parseCampaignData = ({
    contact_count,
    contacts_src_download_path,
    contacts_src_file_name,
    id,
    name,
    group,
  }) => [
    {
      text: name,
    },
    {
      text: group && group.name,
    },
    {
      text: contact_count,
    },
    {
      text: contacts_src_file_name
        ? <FileDownloadLink
          fileName={contacts_src_file_name}
          fileRequestOptions={{ headers }}
          fileUrl={contacts_src_download_path}
        />
        : ''
    },
  ];

  componentDidMount() {
    this.fetchCampaigns();
  }

  render() {
    const {
      campaigns,
      history,
      pagination,
    } = this.props;
    const type = trans('resources.singular.campaign');

    return (
      <Table
        actions={this.getActions}
        actionButtonNames={{
          delete: ({ name }) => name,
        }}
        data={campaigns}
        dataParser={this.parseCampaignData}
        deleteWarningMessage="deleteOpSingF"
        editPath={PRIVATE_PATHS.clientEditCampaign.noParamsPath}
        handleChangePage={this.fetchCampaigns}
        history={history}
        loading={this.loading()}
        onDeleteAcceptClick={this.deleteCampaign}
        pagination={pagination}
        titles={CAMPAIGNS_TABLE_TITLES}
        type={type}
      />
    );
  }
}

const mapStateToProps = ({
  campaigns: {
    isDeleting,
    isFetching,
    items: [success, data, pagination],
  },
  fileDownload: { pending },
}) => ({
  pagination,
  success,
  campaigns: success ? data : [],
  isDeletingCampaign: isDeleting,
  isDownloadingFile: pending,
  isFetchingCampaigns: isFetching,
});

export default connect(
  mapStateToProps,
  {
    ...campaignsActions,
  }
)(ClientCampaigns);

import Logout from '../Logout';
import { ADMIN_PRIVATE_PATHS, ADMIN_PRIVATE_PATHS_CONFIG } from './AdminPrivatePaths';
import { CLIENT_PRIVATE_PATHS, CLIENT_PRIVATE_PATHS_CONFIG } from './ClientPrivatePaths';

export const PRIVATE_PATHS = {
  ...ADMIN_PRIVATE_PATHS,
  ...CLIENT_PRIVATE_PATHS,
  logout: {
    path: '/logout',
  },
};
export const DEFAULT_PRIVATE_PATHS_OBJECT = {
  admin: PRIVATE_PATHS.adminDashboard.path,
  client: PRIVATE_PATHS.clientDashboard.path,
};

export const DEFAULT_PRIVATE_PATHS_ARRAY = [
  PRIVATE_PATHS.adminDashboard.path,
  PRIVATE_PATHS.clientDashboard.path,
  PRIVATE_PATHS.clientDashboard.path,
];

export const PRIVATE_PATHS_CONFIG = [
  ...ADMIN_PRIVATE_PATHS_CONFIG,
  ...CLIENT_PRIVATE_PATHS_CONFIG,
  {
    component: Logout,
    ...PRIVATE_PATHS.logout,
  },
];

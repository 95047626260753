import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import ResponsiveForm from '../../Form';
import { validate } from '../../../../Helpers';
import {
  INPUTS,
  NEW_FORM as form,
  NEW_VALIDATIONS,
} from './config';

let EditNewForm = ({ handleSubmit, loading = false, ...props }) => (
  <ResponsiveForm
    {...props}
    inputs={INPUTS}
    loading={loading}
    onSubmit={handleSubmit}
  />
);

EditNewForm = reduxForm({
  form,
  validate: validate(NEW_VALIDATIONS),
})(EditNewForm);

const mapStateToProps = ({
  news: {
    item,
  },
}) => ({
  initialValues: item ? item.data : {},
});

export default connect(
  mapStateToProps
)(EditNewForm);

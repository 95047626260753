import React from 'react';
import { HashRouter as Router, Redirect, Switch } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import { PRIVATE_PATHS_CONFIG, PUBLIC_PATHS_CONFIG } from './config';
class Routes extends React.Component {
  render() {
    return (
      <Router>
        <Switch>
          {
            PRIVATE_PATHS_CONFIG.map((options, key) => (
              <PrivateRoute key={key} {...options}/>
            ))
          }
          {
            PUBLIC_PATHS_CONFIG.map((options, key) => (
              <PublicRoute exact key={key} {...options}/>
            ))
          }
          <Redirect from='*' to='/' />
        </Switch>
      </Router>
    );
  }
}

export default Routes;

import React from 'react';
import { connect } from 'react-redux';
import AddNewForm from '../../../../Components/Forms/Admin/News/AddNew';
import ModalsWrappedForm from '../../../../Components/Forms/ModalsWrappedForm';
import trans from '../../../../Translations/translation.service';
import { actions as newsActions, types } from '../../../../Redux/Api/News';
import { PRIVATE_PATHS } from '../../../config';
import { FEMALE_CODE } from '../../../../Constants';
import { ADD_ACTIONS } from '../../../../Components/Forms/Admin/News/config';

class AddNew extends React.Component {
  render() {
    const { history, success } = this.props;
    const subject = trans('resources.singular.new');

    return (
      <ModalsWrappedForm
        gender={FEMALE_CODE}
        history={history}
        loading={this.props.isCreatingNew}
        open={success}
        path={PRIVATE_PATHS.adminNews.path}
        subject={subject}
        successMessage="successOpSingF"
        type="add"
      >
        <AddNewForm
          actions={ADD_ACTIONS}
          history={history}
          onSubmit={this.props.createNew}
          path={PRIVATE_PATHS.adminNews.path}
        />
      </ModalsWrappedForm>
    );
  }
}

const mapStateToProps = ({
  news: { isCreating },
  reduxRestResponse: { body, success, type },
}) => ({
  body,
  isCreatingNew: isCreating,
  success: type === types.CREATE_NEW && success,
});

export default connect(
  mapStateToProps,
  {
    ...newsActions,
  }
)(AddNew);

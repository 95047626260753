import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import ResponsiveForm from '../../Form';
import { parseUserData, validate } from '../../../../Helpers';
import {
  EDIT_INPUTS,
  USER_FORM as form,
  USER_VALIDATIONS,
} from './config';

let EditUserForm = ({ handleSubmit, loading = false, ...props }) => (
  <ResponsiveForm
    {...props}
    inputs={EDIT_INPUTS}
    loading={loading}
    onSubmit={handleSubmit}
  />
);

EditUserForm = reduxForm({
  form,
  validate: validate(USER_VALIDATIONS),
})(EditUserForm);

const mapStateToProps = ({
  users: {
    item,
  },
  ...state
}) => ({
  initialValues: item ? parseUserData(item.data) : {},
});

export default connect(
  mapStateToProps
)(EditUserForm);

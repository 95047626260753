import React from 'react';
import WarningModal from './Warning';
import trans from '../../Translations/translation.service';

const DeleteWarning = ({
  subject,
  type,
  warningMessage,
  ...props
}) => (
  <WarningModal
    warningText={trans(`modals.messages.${warningMessage}`, { type, subject })}
    {...props}
  />
);

export default DeleteWarning;

import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TwoWaySwitch  from './TwoWaySwitch';

class MultipleCheckbox extends React.Component {
  handleMultipleCheckboxChange = value => {
    const { afterChange, index, name, onChange } = this.props;
    if (typeof afterChange === 'function') {
      afterChange(name, value, index);
    }

    onChange(value);
  };

  handleOptionChange = (evt, checked) => {
    const { name } = evt.currentTarget;
    const value = [...this.props.value];

    if (checked) {
      value.push(name);
    } else {
      const index = value.indexOf(name);
      value.splice(index, 1);
    }

    this.handleMultipleCheckboxChange( value);
  };

  render() {
    const { data, label, value } = this.props;
    console.log('value', value);

    return (
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs={12}>
          <Typography component="h6">
            {label}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container alignItems="center" spacing={1}>
              {
                data.map((option, key) => (
                  <Grid item key={key} xs={12} sm={6} md={4}>
                    <TwoWaySwitch
                      labelOn={option.label}
                      name={option.value}
                      onChange={this.handleOptionChange}
                      value={value.includes(option.value.toString())}
                    />
                  </Grid>
                ))
              }
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

MultipleCheckbox.defaultProps = {
  data: [],
};

export default MultipleCheckbox;

import { withStyles } from '@material-ui/core/styles';
import PlayArrow from '@material-ui/icons/PlayArrow';
import { COLORS } from '../../../Constants';

export default withStyles({
  root: {
    color: COLORS.red,
    fontSize: 15,
  },
})(PlayArrow);

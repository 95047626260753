import ClientActivities from '../Tables/Client/Activities';
import ClientAddCampaign from '../Forms/Client/Campaigns/AddCampaign';
import ClientAddGroups from '../Forms/Client/Groups/AddGroups';
import ClientAddKeyword from '../Forms/Client/Keywords/AddKeyword';
import ClientAddPayment from '../Forms/Client/Payments/AddPayment';
import ClientAddUser from '../Forms/Client/Users/AddUser';
import ClientCampaigns from '../Tables/Client/Campaigns';
import ClientContacts from '../Tables/Client/Contacts';
import ClientDashboard from '../Dashboards/Client';
import ClientEditCampaign from '../Forms/Client/Campaigns/EditCampaign';
import ClientEditGroup from '../Forms/Client/Groups/EditGroup';
import ClientEditKeyword from '../Forms/Client/Keywords/EditKeyword';
import ClientEditPayment from '../Forms/Client/Payments/EditPayment';
import ClientEditUser from '../Forms/Client/Users/EditUser';
import ClientGroups from '../Tables/Client/Groups';
import ClientKeywords from '../Tables/Client/Keywords';
import ClientNewMessage from '../Forms/Client/Campaigns/NewMessage';
import ClientPayments from '../Tables/Client/Payments';
import ClientReceivedMessages from '../Tables/Client/ReceivedMessages';
import ClientScheduledCampaigns from '../Tables/Client/ScheduledCampaigns';
import ClientSentCampaigns from '../Tables/Client/SentCampaigns';
import ClientSentMessages from '../Tables/Client/SentMessages';
import ClientSentReplies from '../Tables/Client/SentReplies';
import ClientShortCodesDashboard from '../Dashboards/ClientShortCodes';
import ClientUsers from '../Tables/Client/Users';
import Profile from '../Profile';
import trans from '../../Translations/translation.service';
import {
  CLIENT_CONTACTS_HEADER_MENU,
  CLIENT_KEYWORDS_HEADER_MENU,
  CLIENT_RECEIVED_MESSAGES_HEADER_MENU,
  CLIENT_ROUTE_TYPE,
  CLIENT_SHORT_CODES_DASHBOARD_HEADER_MENU,
} from '../../Constants';

export const CLIENT_PRIVATE_PATHS = {
  clientActivities: {
    path: '/client-activities',
    routeType: CLIENT_ROUTE_TYPE,
    selectedOption: 'clientActivities',
    title: trans('default.titles.activities'),
  },
  clientAddCampaign: {
    path: '/client-add-campaign',
    routeType: CLIENT_ROUTE_TYPE,
    selectedOption: 'clientCampaigns',
    title: trans('default.titles.client.addCampaign'),
  },
  clientAddGroups: {
    path: '/client-add-groups',
    routeType: CLIENT_ROUTE_TYPE,
    selectedOption: 'clientGroups',
    title: trans('default.titles.client.addGroups'),
  },
  clientAddKeyword: {
    path: '/client-add-keyword',
    routeType: CLIENT_ROUTE_TYPE,
    selectedOption: 'clientShortCodes',
    title: trans('default.titles.admin.addKeyword'),
  },
  clientAddPayment: {
    path: '/client-add-payment',
    routeType: CLIENT_ROUTE_TYPE,
    selectedOption: 'clientPayments',
    title: trans('default.titles.addPayment'),
  },
  clientAddUser: {
    path: '/client-add-user',
    routeType: CLIENT_ROUTE_TYPE,
    selectedOption: 'clientUsers',
    title: trans('default.titles.addUser'),
  },
  clientCampaigns: {
    path: '/client-campaigns',
    routeType: CLIENT_ROUTE_TYPE,
    selectedOption: 'clientCampaigns',
    title: trans('default.titles.client.campaigns'),
  },
  clientContacts: {
    //headerMenu: CLIENT_CONTACTS_HEADER_MENU,
    path: '/client-contacts',
    routeType: CLIENT_ROUTE_TYPE,
    selectedOption: 'clientContacts',
    title: trans('default.titles.client.contacts'),
  },
  clientDashboard: {
    path: '/client-dashboard',
    routeType: CLIENT_ROUTE_TYPE,
    search: true,
    searchPlaceholder: trans('default.filters.searchByNumber'),
    title: trans('default.titles.smsDashboard'),
  },
  clientEditCampaign: {
    noParamsPath: '/client-edit-campaign',
    path: '/client-edit-campaign/:id',
    routeType: CLIENT_ROUTE_TYPE,
    selectedOption: 'clientCampaigns',
    title: trans('default.titles.client.editCampaign'),
  },
  clientEditGroup: {
    noParamsPath: '/client-edit-group',
    path: '/client-edit-group/:id',
    routeType: CLIENT_ROUTE_TYPE,
    selectedOption: 'clientGroups',
    title: trans('default.titles.client.editGroup'),
  },
  clientEditKeyword: {
    noParamsPath: '/client-edit-keyword',
    path: '/client-edit-keyword/:id',
    routeType: CLIENT_ROUTE_TYPE,
    selectedOption: 'clientSortCodes',
    title: trans('default.titles.editKeyword'),
  },
  clientEditPayment: {
    noParamsPath: '/client-edit-payment',
    path: '/client-edit-payment/:id',
    routeType: CLIENT_ROUTE_TYPE,
    selectedOption: 'clientPayments',
    title: trans('default.titles.editPayment'),
  },
  clientEditUser: {
    noParamsPath: '/client-edit-user',
    path: '/client-edit-user/:id',
    routeType: CLIENT_ROUTE_TYPE,
    selectedOption: 'clientUsers',
    title: trans('default.titles.editUser'),
  },
  clientGroups: {
    path: '/client-groups',
    routeType: CLIENT_ROUTE_TYPE,
    selectedOption: 'clientGroups',
    title: trans('default.titles.client.groups'),
  },
  clientKeywords: {
    headerMenu: CLIENT_KEYWORDS_HEADER_MENU,
    path: '/client-keywords',
    routeType: CLIENT_ROUTE_TYPE,
    search: true,
    searchPlaceholder: trans('default.filters.searchByWord'),
    selectedOption: 'clientShortCodes',
    title: trans('default.titles.keywords'),
  },
  clientNewMessage: {
    path: '/client-new-message',
    routeType: CLIENT_ROUTE_TYPE,
    selectedOption: 'clientMessages',
    title: trans('default.titles.client.newMessage'),
  },
  clientPayments: {
    path: '/client-payments',
    routeType: CLIENT_ROUTE_TYPE,
    selectedOption: 'clientPayments',
    title: trans('default.titles.client.myPayments'),
  },
  clientReceivedMessages: {
    headerMenu: CLIENT_RECEIVED_MESSAGES_HEADER_MENU,
    path: '/client-received-messages',
    routeType: CLIENT_ROUTE_TYPE,
    search: true,
    searchPlaceholder: trans('default.filters.searchByNumber'),
    selectedOption: 'clientShortCodes',
    title: trans('default.titles.receivedMessages'),
  },
  clientScheduledCampaigns: {
    path: '/client-scheduled-campaigns',
    routeType: CLIENT_ROUTE_TYPE,
    selectedOption: 'clientCampaigns',
    title: trans('default.titles.scheduledCampaigns'),
  },
  clientSentCampaigns: {
    path: '/client-sent-campaigns',
    routeType: CLIENT_ROUTE_TYPE,
    selectedOption: 'clientCampaigns',
    title: trans('default.titles.sentCampaigns'),
  },
  clientSentMessages: {
    path: '/client-sent-messages',
    routeType: CLIENT_ROUTE_TYPE,
    search: true,
    searchPlaceholder: trans('default.filters.searchByNumber'),
    selectedOption: 'clientMessages',
    title: trans('default.titles.sentMessages'),
  },
  clientSentReplies: {
    path: '/client-sent-replies',
    routeType: CLIENT_ROUTE_TYPE,
    search: true,
    searchPlaceholder: trans('default.filters.searchByNumber'),
    selectedOption: 'clientShortCodes',
    title: trans('default.titles.sentReplies'),
  },
  clientShortCodesDashboard: {
    headerMenu: CLIENT_SHORT_CODES_DASHBOARD_HEADER_MENU,
    path: '/client-short-codes-dashboard',
    routeType: CLIENT_ROUTE_TYPE,
    search: true,
    searchPlaceholder: trans('default.filters.searchByNumber'),
    selectedOption: 'clientShortCodes',
    title: trans('default.titles.shortCodesDashboard'),
  },
  clientUsers: {
    path: '/client-users',
    routeType: CLIENT_ROUTE_TYPE,
    selectedOption: 'clientUsers',
    title: trans('default.titles.users'),
  },
  myAccount: {
    path: '/my-account',
    routeType: CLIENT_ROUTE_TYPE,
    selectedOption: 'myAccount',
    title: trans('default.titles.client.myAccount'),
  },
};
export const CLIENT_PRIVATE_PATHS_CONFIG = [
  {
    component: ClientActivities,
    ...CLIENT_PRIVATE_PATHS.clientActivities,
  },
  {
    component: ClientAddCampaign,
    ...CLIENT_PRIVATE_PATHS.clientAddCampaign,
  },
  {
    component: ClientAddGroups,
    ...CLIENT_PRIVATE_PATHS.clientAddGroups,
  },
  {
    component: ClientAddKeyword,
    ...CLIENT_PRIVATE_PATHS.clientAddKeyword,
  },
  {
    component: ClientAddPayment,
    ...CLIENT_PRIVATE_PATHS.clientAddPayment,
  },
  {
    component: ClientAddUser,
    ...CLIENT_PRIVATE_PATHS.clientAddUser,
  },
  {
    component: ClientCampaigns,
    ...CLIENT_PRIVATE_PATHS.clientCampaigns,
  },
  {
    component: ClientContacts,
    ...CLIENT_PRIVATE_PATHS.clientContacts,
  },
  {
    component: ClientDashboard,
    ...CLIENT_PRIVATE_PATHS.clientDashboard,
  },
  {
    component: ClientEditGroup,
    ...CLIENT_PRIVATE_PATHS.clientEditGroup,
  },
  {
    component: ClientEditCampaign,
    ...CLIENT_PRIVATE_PATHS.clientEditCampaign,
  },
  {
    component: ClientEditKeyword,
    ...CLIENT_PRIVATE_PATHS.clientEditKeyword,
  },
  {
    component: ClientEditPayment,
    ...CLIENT_PRIVATE_PATHS.clientEditPayment,
  },
  {
    component: ClientEditUser,
    ...CLIENT_PRIVATE_PATHS.clientEditUser,
  },
  {
    component: ClientGroups,
    ...CLIENT_PRIVATE_PATHS.clientGroups,
  },
  {
    component: ClientKeywords,
    ...CLIENT_PRIVATE_PATHS.clientKeywords,
  },
  {
    component: ClientNewMessage,
    ...CLIENT_PRIVATE_PATHS.clientNewMessage,
  },
  {
    component: ClientPayments,
    ...CLIENT_PRIVATE_PATHS.clientPayments,
  },
  {
    component: ClientReceivedMessages,
    ...CLIENT_PRIVATE_PATHS.clientReceivedMessages,
  },
  {
    component: ClientScheduledCampaigns,
    ...CLIENT_PRIVATE_PATHS.clientScheduledCampaigns,
  },
  {
    component: ClientSentCampaigns,
    ...CLIENT_PRIVATE_PATHS.clientSentCampaigns,
  },
  {
    component: ClientSentMessages,
    ...CLIENT_PRIVATE_PATHS.clientSentMessages,
  },
  {
    component: ClientSentReplies,
    ...CLIENT_PRIVATE_PATHS.clientSentReplies,
  },
  {
    component: ClientShortCodesDashboard,
    ...CLIENT_PRIVATE_PATHS.clientShortCodesDashboard,
  },
  {
    component: ClientUsers,
    ...CLIENT_PRIVATE_PATHS.clientUsers,
  },
  {
    component: Profile,
    ...CLIENT_PRIVATE_PATHS.myAccount,
  },
];

import React from 'react';
import { connect } from 'react-redux';
import Table from '../../../Components/Table';
import trans from '../../../Translations/translation.service';
import { actions as newsActions } from '../../../Redux/Api/News';
import {
  NEWS_TABLE_TITLES,
  DEFAULT_TABLE_ACTIONS,
  SUCCESS_CODE,
} from '../../../Constants';
import { PRIVATE_PATHS } from '../../config';
import { getPage, parseApiDate } from '../../../Helpers';

class AdminNews extends React.Component {
  deleteNew = id => (
    this.props.deleteNew(id)
      .then(({ code }) => code === SUCCESS_CODE && this.fetchNews())
  );

  fetchNews = newPage => {
    const { location } = this.props;
    const page = getPage(newPage, location);

    this.props.fetchNews({}, { query: { page } });
  };

  parseNewData = ({
    title,
    content,
    from_date: fromDate,
    to_date: toDate,
  }) => [
    {
      text: title,
    },
    {
      text: content,
    },
    {
      noWrap: true,
      text: parseApiDate(fromDate),
    },
    {
      noWrap: true,
      text: parseApiDate(toDate),
    },
  ];

  componentDidMount() {
    this.fetchNews();
  }

  render() {
    const { news, history, isDeletingNew, isFetchingNews, pagination } = this.props;
    const type = trans('resources.singular.new');

    return (
      <Table
        actions={DEFAULT_TABLE_ACTIONS}
        actionButtonNames={{
          delete: ({ title }) => title,
        }}
        data={news}
        dataParser={this.parseNewData}
        deleteWarningMessage="deleteOpSingF"
        editPath={PRIVATE_PATHS.adminEditNew.noParamsPath}
        handleChangePage={this.fetchNews}
        history={history}
        loading={isDeletingNew || isFetchingNews}
        onDeleteAcceptClick={this.deleteNew}
        pagination={pagination}
        titles={NEWS_TABLE_TITLES}
        type={type}
      />
    );
  }
}

const mapStateToProps = ({
  news: {
    isDeleting,
    isFetching,
    items: [success, data, pagination],
  },
}) => ({
  pagination,
  success,
  news: success ? data : [],
  isDeletingNew: isDeleting,
  isFetchingNews: isFetching,
});

export default connect(
  mapStateToProps,
  {
    ...newsActions,
  }
)(AdminNews);

import React from 'react';
import { connect } from 'react-redux';
import { actions as authActions } from '../../Redux/Ducks/Auth';
import Loading from '../../Components/Loading';

class Logout extends React.Component {
  componentWillMount() {
    this.props.logout();
  }

  render() {
    const { isLoggingOut } = this.props;

    return (
      <Loading show={isLoggingOut} />
    );
  }
}

const mapStateToProps = ({
  users: {
    isLoggingOut,
  },
}) => ({
  isLoggingOut,
});

export default connect(
  mapStateToProps,
  {
    ...authActions,
  }
)(Logout);

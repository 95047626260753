import React from 'react';
import { FORM_FIELD_COMPONENTS } from './config';

export default ({
  inputs,
  spacing,
  afterChange = {},
  data = {},
  values = {},
}) => (
  inputs.map(({ condition, fieldType, name, ...fieldProps }, key) => {
    const show = typeof condition !== 'function' || condition(values);
    let Field = FORM_FIELD_COMPONENTS[fieldType];
    Field = Field || FORM_FIELD_COMPONENTS.defaultField;

    return show && (
      <Field
        afterChange={afterChange[name]}
        data={data[name]}
        key={key}
        name={name}
        spacing={spacing}
        values={fieldType === 'array' ? values[name] : values}
        {...fieldProps}
      />
    );
  })
);

/** Modal component messages (EN)  */
export default {
  actions: {
    accept: 'Accept',
    backToList: 'Back to list',
    cancel: 'Cancel',
    sendTest: 'Send Test Message',
  },
  messages: {
    deleteAllF: 'Do you want to delete all {{ type }}?',
    deleteAllM: 'Do you want to delete all {{ type }}?',
    deleteOpPlurF: 'Do you want to delete these {{ type }}?',
    deleteOpPlurM: 'Do you want to delete these {{ type }}?',
    deleteOpSingF: 'Do you want to delete the {{ type }} "{{ subject }}"?',
    deleteOpSingM: 'Do you want to delete the {{ type }} "{{ subject }}"?',
    success: 'The operation has been done successfully',
    successOpPlurF: 'The {{ subject }} have been successfully {{ action }}',
    successOpPlurM: 'The {{ subject }} have been successfully {{ action }}',
    successOpSingF: 'The {{ subject }} has been successfully {{ action }}',
    successOpSingM: 'The {{ subject }} has been successfully {{ action }}',
    warning: 'Do you wish to perfom this action?',
  },
  titles: {
    exportReceivedMessages: 'Export Received Messages',
    sendTest: 'Send Test Message',
    success: 'Operation successful',
    warning: 'Confirmation',
  },
};

import { withStyles } from '@material-ui/core/styles';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import { COLORS } from '../../../Constants';

export default withStyles({
  content: {
    margin: 0,
  },
  expanded: {
    marginBottom: '0 !important',
    marginTop: '0 !important',
    minHeight: '48px !important',
  },
  expandIcon: {
    color: ({ selected }) => selected ? 'white' : COLORS.mediumBlue,
  },
  root: {
    backgroundColor: ({ selected }) => selected ? COLORS.mediumBlue : 'transparent',
    padding: 0,
  },
})(ExpansionPanelSummary);

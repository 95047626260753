import { withStyles } from '@material-ui/core/styles';
import CheckCircleOutlined from '@material-ui/icons/CheckCircleOutlined';
import { MODAL_ICON_STYLE } from '../config';

export default withStyles({
  root: {
    color: 'green',
    ...MODAL_ICON_STYLE,
  },
})(CheckCircleOutlined);

import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

export default withStyles({
  root: {
    "& .MuiFormLabel-root": {
      color: 'black',
      fontSize: '1rem',
    },
    '& input.MuiInputBase-input': {
      fontSize: '0.9rem',
      height: '20px',
    },
    '& textarea.MuiInputBase-input': {
      fontSize: '0.9rem',
    },
  },
})(TextField);

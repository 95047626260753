import React from 'react';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Logo from '../Navbar/Logo';
import StyledMenuToggle from './Styled/StyledMenuToggle';
import { actions as navBarActions } from '../../../../Redux/Ducks/Navbar';

const NavbarToggle = ({ logoPath, open, toggleOpen }) => (
  <StyledMenuToggle>
    <Logo path={logoPath} />
    <IconButton onClick={() => toggleOpen(!open)}>
      <MenuIcon />
    </IconButton>
  </StyledMenuToggle>
);

const mapStateToProps = ({
  navbar: {
    open,
  },
}) => ({
  open,
});

export default connect(
  mapStateToProps,
  {
    ...navBarActions,
  },
)(NavbarToggle);

import Close from '@material-ui/icons/Close';
import trans from '../../../../Translations/translation.service';
import { API_DATE_FORMAT } from '../../../../Constants';

export const ADD_ACTIONS = [
  {
    color: 'secondary',
    handler: 'backToList',
    label: trans('modals.actions.backToList'),
    type: 'button',
  },
  {
    color: 'primary',
    label: trans('forms.actions.save'),
    type: 'submit',
  },
];
export const EDIT_ACTIONS = [
  {
    color: 'secondary',
    handler: 'backToList',
    label: trans('modals.actions.backToList'),
    type: 'button',
  },
  {
    color: 'primary',
    label: trans('forms.actions.save'),
    type: 'submit',
  },
];
export const EXPORT_FILTERS_ACTIONS = [
  {
    color: 'secondary',
    label: trans('modals.actions.cancel'),
    type: 'button',
  },
  {
    color: 'primary',
    label: trans('forms.actions.export'),
    type: 'submit',
  },
];

export const DEFAULT_ADD_VALUES = {
  shortCodes: [],
};

export const DEFAULT_VALUES = {
  code: '',
};

export const ADMIN_EXPORT_RECEIVED_MESSAGES_FILTERS_INPUTS = [
  {
    id: 'from',
    inputFormat: API_DATE_FORMAT,
    InputLabelProps: {
      shrink: true,
    },
    label: trans('forms.fields.admin.receivedMessages.export.from'),
    name: 'from_date',
    sizes: {
      xs: 12,
    },
    type: 'datepicker',
  },
  {
    id: 'to',
    inputFormat: API_DATE_FORMAT,
    InputLabelProps: {
      shrink: true,
    },
    label: trans('forms.fields.admin.receivedMessages.export.to'),
    name: 'to_date',
    sizes: {
      xs: 12,
    },
    type: 'datepicker',
  },
];

export const INPUTS = [
  {
    fieldType: 'array',
    name: 'shortCodes',
    inputs: [
      {
        clickableIcon: true,
        id: 'code',
        icon: Close,
        iconAlign: 'right',
        inputProps: {
          maxLength: 255,
        },
        label: trans('forms.fields.admin.shortCodes.code'),
        name: 'code',
        remove: true,
        required: true,
        sizes: {
          xs: 12,
        },
      },
    ],
    size: {
      xs: 12,
      sm: 3,
    },
  },
];

export const EDIT_INPUTS = [
  {
    id: 'code',
    inputProps: {
      maxLength: 255,
    },
    label: trans('forms.fields.admin.shortCodes.code'),
    name: 'code',
    required: true,
    sizes: {
      xs: 12,
      sm: 6,
      md: 4,
    },
  },
];

export const SHORT_CODE_FORM = 'shortCode';
export const SHORT_CODES_FORM = 'shortCodes';
export const EXPORT_FILTERS_FORM = 'exportFilters';

export const SHORT_CODES_VALIDATIONS = [
  {
    array: true,
    field: 'shortCodes',
    validations: [
      {
        field: 'code',
        message: ['default.forms.validations.isRequired'],
        method: 'isEmpty',
        validWhen: false,
      },
    ],
  },
];

export const SHORT_CODE_VALIDATIONS = [
  {
    field: 'code',
    message: ['default.forms.validations.isRequired'],
    method: 'isEmpty',
    validWhen: false,
  },
];

import { createResource } from 'redux-rest-resource';
import {
  HEADERS as headers,
  LOGIN_URL,
  LOGOUT_URL,
  PUBLIC_HEADERS,
  USERS_URL,
} from '../../Constants';
import { transformFetchResponse as transformResponse } from '../../Helpers';

export const { types, actions, reducers } = createResource({
  name: 'user',
  url: `${USERS_URL}/:id`,
  headers,
  actions: {
    fetch: {
      transformResponse,
    },
    login: {
      gerundName: 'loggingIn',
      headers: PUBLIC_HEADERS,
      method: 'POST',
      url: LOGIN_URL,
    },
    logout: {
      gerundName: 'loggingOut',
      method: 'POST',
      url: LOGOUT_URL,
    },
  },
});

import styled from 'styled-components';

export default styled.div`
  align-items: center;
  background-color: white;
  bottom: 0;
  display: ${props => props.show ? 'flex' : 'none'};
  flex-direction: column;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9999;
`;

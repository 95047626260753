import React from 'react';
import { connect } from 'react-redux';
import AddShortCodesForm from '../../../../Components/Forms/Admin/ShortCodes/AddShortCodes';
import ModalsWrappedForm from '../../../../Components/Forms/ModalsWrappedForm';
import trans from '../../../../Translations/translation.service';
import { actions as shortCodesActions, types } from '../../../../Redux/Api/ShortCodes';
import { PRIVATE_PATHS } from '../../../config';
import { MALE_CODE } from '../../../../Constants';
import { ADD_ACTIONS } from '../../../../Components/Forms/Admin/ShortCodes/config';
import { catchApiArrayErrors } from '../../../../Helpers';

class AddShortCodes extends React.Component {
  createShortCodes = data => {
    return this.props.createManyShortCodes(data.shortCodes).catch(
      catchApiArrayErrors('shortCodes'),
    );
  };

  render() {
    const { history, success } = this.props;
    const subject = trans('resources.plural.shortCodes');

    return (
      <ModalsWrappedForm
        gender={MALE_CODE}
        history={history}
        loading={this.props.isCreatingShortCodes}
        open={success}
        path={PRIVATE_PATHS.adminShortCodes.path}
        subject={subject}
        successMessage="successOpPlurM"
        type="add"
      >
        <AddShortCodesForm
          actions={ADD_ACTIONS}
          history={history}
          onSubmit={this.createShortCodes}
          path={PRIVATE_PATHS.adminShortCodes.path}
        />
      </ModalsWrappedForm>
    );
  }
}

const mapStateToProps = ({
  shortCodes: { isCreatingMany },
  reduxRestResponse: { body, success, type },
}) => ({
  body,
  isCreatingShortCodes: isCreatingMany,
  success: type === types.CREATEMANY_SHORT_CODES && success,
});

export default connect(
  mapStateToProps,
  {
    ...shortCodesActions,
  }
)(AddShortCodes);

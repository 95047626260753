import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import Dashboard from '../../Components/Dashboard';
import SentMessages from '../Tables/Client/SentMessages';
import Title from '../../Components/Layouts/Default/Header/Styled/Title';
import trans from '../../Translations/translation.service';
import { actions as allCampaignsActions } from '../../Redux/Api/AllCampaigns';
import { actions as allIntegratorsActions } from '../../Redux/Api/AllIntegrators';
import { actions as messagesStatsActions } from '../../Redux/Api/MessagesStats';
import {
  CLIENT_SMS_STATS_FILTERS_INPUTS,
} from './config';
import { FILTERS_FORM as form } from '../../Components/Filters/config';
import {
  ALL_SELECT_OPTION_F,
  ALL_SELECT_OPTION_VALUE as ALL,
  API_DATE_FORMAT,
  API_DATE_FORMAT_FIRST,
} from '../../Constants';
import { getCarriersGeneralStats } from '../../Helpers';
import { PRIVATE_PATHS } from '../config';

class ClientDashboard extends React.Component {
  state = {
    filters: {},
  };

  initFilters = () => {
    const today = moment();
    this.props.reset(form);
    this.props.change(form, 'from_date', today.format(API_DATE_FORMAT_FIRST));
    this.props.change(form, 'to_date', today.format(API_DATE_FORMAT));
    this.props.change(form, 'campaign_id', ALL);
    this.props.change(form, 'carrier', ALL);
    this.props.change(form, 'integrator_id', ALL);
  };

  componentDidMount() {
    this.props.fetchAllCampaigns({}, { query: { client: true } });
    this.props.fetchAllIntegrators({}, { query: { client: true } });
    this.fetchMessagesStats();
    this.initFilters();
  }

  fetchMessagesStats = () => {
    const { filters } = this.state;

    this.props.fetchMessagesStats({}, { query: { client: true, ...filters } });
  };

  fetchMessagesStatsFromApi = api => {
    const { filters } = this.state;

    if (api) {
      filters.api = api;
    } else {
      delete filters.api;
    }

    this.setState({ filters }, () => {
      this.fetchMessagesStats();
    });
  };

  fetchMessagesStatsFromCampaign = campaign => {
    const { filters } = this.state;

    if (campaign === ALL) {
      delete filters.campaign;
    } else {
      filters.campaign =  campaign;
    }

    this.setState({ filters }, () => {
      this.fetchMessagesStats();
    });
  };

  fetchMessagesStatsFromCarrier = carrier => {
    const { filters } = this.state;

    if (carrier === ALL) {
      delete filters.carrier;
    } else {
      filters.carrier =  carrier;
    }

    this.setState({ filters }, () => {
      this.fetchMessagesStats();
    });
  };

  fetchMessagesStatsFromDateFrom = date => {
    const { filters } = this.state;

    const momentDate = moment(date, API_DATE_FORMAT);

    if (momentDate._isValid) {
      filters.from =  date;
    } else {
      delete filters.from;
    }

    this.setState({ filters }, () => {
      this.fetchMessagesStats();
    });
  };

  fetchMessagesStatsFromDateTo = date => {
    const { filters } = this.state;

    const momentDate = moment(date, API_DATE_FORMAT);

    if (momentDate._isValid) {
      filters.to =  date;
    } else {
      delete filters.to;
    }

    this.setState({ filters }, () => {
      this.fetchMessagesStats();
    });
  };

  loading = () => {
    const {
      isFetchingCampaigns,
      isFetchingIntegrators,
    } = this.props;
    return isFetchingCampaigns
      || isFetchingIntegrators;
  };

  parseCampaigns = () => {
    const campaigns = this.props.campaigns.map(({ id: value, name: text }) => ({
      text, value,
    }));

    campaigns.unshift(ALL_SELECT_OPTION_F);

    return campaigns;
  };

  parseIntegrators = () => {
    const integrators = this.props.integrators.map(({ id: value, name: text }) => ({
      text, value,
    }));

    integrators.unshift(ALL_SELECT_OPTION_F);

    return integrators;
  };

  render() {
    const messagesPath = PRIVATE_PATHS.clientSentMessages.path;
    const scheduledPath = PRIVATE_PATHS.clientScheduledCampaigns.path;
    const { isFetching, stats } = this.props;
    const [dailyStats, hourlyStats, carrierStats, generalStats] = stats;
    const campaigns = this.parseCampaigns();
    const integrators = this.parseIntegrators();
    const carrierGeneralStats = getCarriersGeneralStats(carrierStats, messagesPath);

    return (
      <>
        <Dashboard
          filtersProps={{
            afterChange: {
              api: this.fetchMessagesStatsFromApi,
              campaign_id: this.fetchMessagesStatsFromCampaign,
              from_date: this.fetchMessagesStatsFromDateFrom,
              to_date: this.fetchMessagesStatsFromDateTo,
              carrier: this.fetchMessagesStatsFromCarrier,
              integrator_id: this.fetchMessagesStatsFromIntegrator,
            },
            data: {
              campaign_id: campaigns,
              integrator_id: integrators,
            },
            inputs: CLIENT_SMS_STATS_FILTERS_INPUTS,
            loading: this.loading(),
          }}
          dailyStats={dailyStats}
          hourlyStats={hourlyStats}
          carrierStats={carrierStats}
          generalStats={generalStats && [
            {
              name: trans('default.stats.messages.available_credits'),
              value: generalStats.available_credits.available_credits,
              size: {
                xs: 12,
                lg: 2,
              },
            },
            {
              link: messagesPath,
              name: trans('default.stats.messages.sent_messages'),
              value: generalStats.sent_messages.sent_messages,
              size: {
                xs: 12,
                lg: 2,
              },
            },
            {
              link: scheduledPath,
              name: trans('default.stats.messages.scheduled_campaigns'),
              value: generalStats.scheduled_campaigns.scheduled_campaigns,
              size: {
                xs: 12,
                lg: 2,
              },
            },
            ...carrierGeneralStats,
          ]}
          loading={isFetching}
        />
        <Title>{trans('default.titles.lastSentMessages')}</Title>
        <SentMessages onDashboard showFilters={false} showPagination={false} sort_by="DESC" />
      </>
    );
  }
}

const mapStateToProps = ({
  allCampaigns: {
    isFetching: isFetchingCampaigns,
    items: campaigns,
  },
  allIntegrators: {
    isFetching: isFetchingIntegrators,
    items: integrators,
  },
  messagesStats: {
    isFetching,
    items,
  },
}) => ({
  isFetching,
  isFetchingCampaigns,
  isFetchingIntegrators,
  campaigns: campaigns[1] ? campaigns[1] : [],
  integrators: integrators[1] ? integrators[1] : [],
  stats: items,
});

export default connect(
  mapStateToProps,
  {
    ...allCampaignsActions,
    ...allIntegratorsActions,
    ...messagesStatsActions,
    change,
    reset,
  }
)(ClientDashboard);

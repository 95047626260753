const Authorization = `Bearer ${localStorage.getItem('token')}`;

export const HEADERS = {
  Authorization,
};

export const FILE_HEADERS = {
  Authorization,
  'Content-Type': undefined,
};

export const JSON_HEADERS = {
  Authorization,
  Accept: 'application/json',
};

export const PUBLIC_HEADERS = {
  Authorization: undefined,
};

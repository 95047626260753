import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import ResponsiveForm from '../../Form';
import { validate } from '../../../../Helpers';
import {
  GROUP_FORM as form,
  GROUP_VALIDATIONS,
  EDIT_INPUTS,
} from './config';

let EditGroupForm = ({ handleSubmit, loading = false, ...props }) => (
  <ResponsiveForm
    {...props}
    inputs={EDIT_INPUTS}
    loading={loading}
    onSubmit={handleSubmit}
  />
);

EditGroupForm = reduxForm({
  form,
  validate: validate(GROUP_VALIDATIONS),
})(EditGroupForm);

const mapStateToProps = ({
  groups: {
    item,
  },
}) => ({
  initialValues: item ? item.data : {},
});

export default connect(
  mapStateToProps
)(EditGroupForm);

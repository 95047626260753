import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import ActionsWrapper from './Styled/ActionsWrapper';
import Button from './Button';

export default ({ actions, valid, loading = false, spacing = 4 }) => (
  <ActionsWrapper loading={loading} spacing={spacing}>
    {!loading && actions.map((buttonProps, key) => (
      <Button key={key} valid={valid} {...buttonProps} />
    ))}
    {loading && <CircularProgress color="primary" size={36} />}
  </ActionsWrapper>
);

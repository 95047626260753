import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import ResponsiveForm from '../../Form';
import { validate } from '../../../../Helpers';
import {
  DEFAULT_VALUES,
  PAYMENT_FORM as form,
  PAYMENT_VALIDATIONS,
  INPUTS,
} from './config';

let AddPaymentForm = ({ formData, handleSubmit, loading = false, ...props }) => (
  <ResponsiveForm
    {...props}
    inputs={INPUTS}
    loading={loading}
    onSubmit={handleSubmit}
    values={formData}
  />
);

AddPaymentForm = reduxForm({
  form,
  validate: validate([
    ...PAYMENT_VALIDATIONS,
  ]),
})(AddPaymentForm);

const mapStateToProps = state => ({
  initialValues: { ...DEFAULT_VALUES },
});

export default connect(
  mapStateToProps
)(AddPaymentForm);

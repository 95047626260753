import React from 'react';
import Typography from '@material-ui/core/Typography';
import trans from '../../Translations/translation.service';

export default ({ accepted, rejected }) => (
  <React.Fragment>
    <Typography variant="body2">
      {trans('default.stats.contacts.accepted')}: <Typography
        color="primary"
        component="span"
      >
        {accepted}
      </Typography>
    </Typography>
    <Typography variant="body2">
      {trans('default.stats.contacts.rejected')}: <Typography
        color="error"
        component="span"
      >
        {rejected}
      </Typography>
    </Typography>
  </React.Fragment>
);

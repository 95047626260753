import trans from '../../../Translations/translation.service';
import { ALL_SELECT_OPTION_M, API_DATE_FORMAT } from '../../../Constants';

export const ACTIVITY_TYPES_SELECT_OPTIONS = [
    ALL_SELECT_OPTION_M,
    {
        text: trans('default.activities.types.apiMessageSent'),
        value: 1,
    },
    {
        text: trans('default.activities.types.campaignDeleted'),
        value: 4,
    },
    {
        text: trans('default.activities.types.campaignScheduled'),
        value: 3,
    },
    {
        text: trans('default.activities.types.campaignSent'),
        value: 0,
    },
    {
        text: trans('default.activities.types.creditsAdded'),
        value: 5,
    },
    {
        text: trans('default.activities.types.testMessageSent'),
        value: 2,
    },
    {
        text: trans('default.activities.types.logIn'),
        value: 6,
    },
    {
        text: trans('default.activities.types.logOut'),
        value: 7,
    },
    {
        text: trans('default.activities.types.accountCreated'),
        value: 11,
    },
];

export const CLIENT_ACTIVITIES_FILTERS_INPUTS = [
  {
    id: 'from',
    inputFormat: API_DATE_FORMAT,
    InputLabelProps: {
      shrink: true,
    },
    label: trans('forms.fields.client.activitiesFilters.from'),
    name: 'from_date',
    sizes: {
      xs: 12,
      sm: 6,
      md: 2,
    },
    type: 'datepicker',
  },
  {
    id: 'to',
    inputFormat: API_DATE_FORMAT,
    InputLabelProps: {
      shrink: true,
    },
    label: trans('forms.fields.client.activitiesFilters.to'),
    name: 'to_date',
    sizes: {
      xs: 12,
      sm: 6,
      md: 2,
    },
    type: 'datepicker',
  },
  {
    id: 'user',
    label: trans('forms.fields.client.activitiesFilters.user'),
    name: 'user_id',
    sizes: {
      xs: 12,
      sm: 6,
      md: 2,
    },
    type: 'select',
  },
  {
    data: ACTIVITY_TYPES_SELECT_OPTIONS,
    id: 'type',
    label: trans('forms.fields.client.activitiesFilters.type'),
    name: 'type',
    sizes: {
      xs: 12,
      sm: 6,
      md: 2,
    },
    type: 'select',
  },
];

export const CLIENT_KEYWORDS_FILTERS_INPUTS = [
  {
    id: 'short-code',
    label: trans('forms.fields.client.keywordsFilters.shortCode'),
    name: 'short_code_id',
    sizes: {
      xs: 12,
      sm: 6,
      md: 2,
    },
    type: 'select',
  },
  {
    id: 'keyword',
    label: trans('forms.fields.client.keywordsFilters.keyword'),
    name: 'keyword_id',
    sizes: {
      xs: 12,
      sm: 6,
      md: 2,
    },
    type: 'select',
  },
];

export const CLIENT_PAYMENTS_FILTERS_INPUTS = [
    {
      id: 'from',
      inputFormat: API_DATE_FORMAT,
      InputLabelProps: {
        shrink: true,
      },
      label: trans('forms.fields.client.paymentsFilters.from'),
      name: 'from_date',
      sizes: {
        xs: 12,
        sm: 6,
        md: 2,
      },
      type: 'datepicker',
    },
    {
      id: 'to',
      inputFormat: API_DATE_FORMAT,
      InputLabelProps: {
        shrink: true,
      },
      label: trans('forms.fields.client.paymentsFilters.to'),
      name: 'to_date',
      sizes: {
        xs: 12,
        sm: 6,
        md: 2,
      },
      type: 'datepicker',
    },
    {
      id: 'month',
      label: trans('forms.fields.client.paymentsFilters.month'),
      name: 'month',
      sizes: {
        xs: 12,
        sm: 6,
        md: 2,
      },
      type: 'select',
    },
    {
      id: 'year',
      label: trans('forms.fields.client.paymentsFilters.year'),
      name: 'year',
      sizes: {
        xs: 12,
        sm: 6,
        md: 2,
      },
      type: 'select',
    },
];

export const CLIENT_SENT_CAMPAIGNS_FILTERS_INPUTS = [
  {
    id: 'from',
    inputFormat: API_DATE_FORMAT,
    InputLabelProps: {
      shrink: true,
    },
    label: trans('forms.fields.client.campaignsFilters.from'),
    name: 'from_date',
    sizes: {
      xs: 12,
      sm: 6,
      md: 2,
    },
    type: 'datepicker',
  },
  {
    id: 'to',
    inputFormat: API_DATE_FORMAT,
    InputLabelProps: {
      shrink: true,
    },
    label: trans('forms.fields.client.campaignsFilters.to'),
    name: 'to_date',
    sizes: {
      xs: 12,
      sm: 6,
      md: 2,
    },
    type: 'datepicker',
  },
];

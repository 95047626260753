export const DESKTOP_SIZE = 'desktop';
export const MOBILE_SIZE = 'mobile';
export const TABLET_SIZE = 'tablet';

export const DIMENSIONS = [
  {
    min: 960,
    size: DESKTOP_SIZE,
  },
  {
    max: 600,
    size: MOBILE_SIZE,
  },
  {
    max: 960,
    min: 600,
    size: TABLET_SIZE,
  },
];

import React from 'react';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';
import ResponsiveForm from '../../Form';
import { validate } from '../../../../Helpers';
import {
  DEFAULT_ADD_CREDITS_VALUES,
  ADD_CREDITS_FORM as form,
  ADD_CREDITS_VALIDATIONS,
  ADD_CREDITS_INPUTS as INPUTS,
} from './config';

let AddCreditsForm = ({ formData, handleSubmit, loading = false, ...props }) => (
  <ResponsiveForm
    {...props}
    inputs={INPUTS}
    loading={loading}
    onSubmit={handleSubmit}
    values={formData}
  />
);

AddCreditsForm = reduxForm({
  form,
  validate: validate([
    ...ADD_CREDITS_VALIDATIONS,
  ]),
})(AddCreditsForm);

const selector = formValueSelector(form);

const mapStateToProps = state => ({
  formData: selector(state, 'account_id', 'integrator_id'),
  initialValues: { ...DEFAULT_ADD_CREDITS_VALUES },
});

export default connect(
  mapStateToProps
)(AddCreditsForm);

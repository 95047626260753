import * as types from './types';

const defaultState = {
  pending: false,
  response: null,
  success: false,
};

const reducer = (state = defaultState, action) => {
  switch(action.type) {
    case types.UPLOAD_FILE_PENDING:
      return {
        ...defaultState,
        pending: true,
      };
    case types.UPLOAD_FILE_SUCCESS:
      const { response } = action;
      return {
        ...defaultState,
        response,
        pending: false,
        success: true,
      };
    case types.UPLOAD_FILE_ERROR:
      return {
        ...defaultState,
        pending: false,
        success: false,
      };
      case types.UPLOAD_FILE_RESET:
        return { ...defaultState };
    default:
      return state;
  }
};

// Reducer
export default reducer;

import Close from '@material-ui/icons/Close';
import trans from '../../../../Translations/translation.service';
import { MAX_MESSAGE_SMS, SMS_CHAR_SIZE } from '../../../../Constants';

export const ADD_ACTIONS = [
  {
    color: 'secondary',
    handler: 'backToList',
    label: trans('modals.actions.backToList'),
    type: 'button',
  },
  {
    color: 'primary',
    label: trans('forms.actions.save'),
    type: 'submit',
  },
];

export const ADD_MODAL_ACTIONS = [
  {
    color: 'secondary',
    label: trans('modals.actions.cancel'),
    type: 'button',
  },
  {
    color: 'primary',
    label: trans('forms.actions.save'),
    type: 'submit',
  },
];

export const EDIT_ACTIONS = [
  {
    color: 'secondary',
    handler: 'backToList',
    label: trans('modals.actions.backToList'),
    type: 'button',
  },
  {
    color: 'primary',
    label: trans('forms.actions.save'),
    type: 'submit',
  },
];

export const SCHEDULE_ACTIONS = [
  {
    color: 'secondary',
    handler: 'backToList',
    label: trans('modals.actions.cancel'),
    type: 'button',
  },
  {
    color: 'secondary',
    label: trans('modals.actions.sendTest'),
    type: 'button',
  },
  {
    color: 'primary',
    label: trans('forms.actions.schedule'),
    type: 'submit',
  },
];

export const SEND_TEST_ACTIONS = [
  {
    color: 'secondary',
    label: trans('modals.actions.cancel'),
    type: 'button',
  },
  {
    color: 'primary',
    label: trans('forms.actions.send'),
    type: 'submit',
  },
];

export const SEND_ACTIONS = [
  {
    color: 'secondary',
    handler: 'backToList',
    label: trans('modals.actions.cancel'),
    type: 'button',
  },
  {
    color: 'secondary',
    label: trans('modals.actions.sendTest'),
    type: 'button',
  },
  {
    color: 'primary',
    label: trans('forms.actions.send'),
    type: 'submit',
  },
];

export const DEFAULT_VALUES = {
  name: '',
  group_id: null,
  file: false,
  contacts_src_file: null,
  first_name_field: '',
  last_name_field: '',
  email_field: '',
  phone_field: '',
};
export const NEW_MESSAGE_DEFAULT_VALUES = {
  group: null,
  campaign_id: null,
  integrator_id: null,
  contact_count: 0,
  movistar_contact_count: 0,
  digitel_contact_count: 0,
  movilnet_contact_count: 0,
  content: '',
  message_count: 0,
  total_messages: 0,
  credits: 0,
  schedule: false,
  schedules: [],
};
export const TEST_MESSAGE_DEFAULT_VALUES = {
  content: '',
  integrator_id: null,
  receipent: '',
  test: true,
};

export const TEST_MESSAGE_INPUTS = [
  {
    id: 'integrator',
    label: trans('forms.fields.client.testMessage.integrator'),
    name: 'integrator_id',
    required: true,
    sizes: {
      xs: 12,
    },
    type: 'select',
  },
  {
    id: 'receipent',
    label: trans('forms.fields.client.testMessage.receipent'),
    name: 'receipent',
    required: true,
    sizes: {
      xs: 12,
    },
  },
];

export const NEW_MESSAGE_INPUTS = [
  {
    id: 'integrator',
    label: trans('forms.fields.client.newMessage.integrator'),
    name: 'integrator_id',
    placeholder: trans('forms.fields.client.newMessage.integrator'),
    required: true,
    shrink: true,
    sizes: {
      xs: 12,
      sm: 6,
      md: 4,
    },
    type: 'select',
  },
  {
    padding: true,
    sizes: {
      xs: 12,
      sm: 6,
      md: 4,
    },
  },
  {
    padding: true,
    sizes: {
      xs: 12,
      sm: 6,
      md: 4,
    },
  },
  {
    id: 'group',
    label: trans('forms.fields.client.newMessage.group'),
    name: 'group',
    placeholder: trans('forms.fields.client.newMessage.group'),
    required: true,
    shrink: true,
    sizes: {
      xs: 12,
      sm: 6,
      md: 4,
    },
    topBorder: true,
    type: 'select',
  },
  {
    id: 'campaign',
    label: trans('forms.fields.client.newMessage.campaign'),
    name: 'campaign_id',
    placeholder: trans('forms.fields.client.newMessage.campaign'),
    required: true,
    shrink: true,
    sizes: {
      xs: 12,
      sm: 6,
      md: 4,
    },
    topBorder: true,
    type: 'select',
  },
  {
    id: 'contact-count',
    inputProps: {
      readOnly: true,
    },
    label: trans('forms.fields.client.newMessage.contactCount'),
    name: 'contact_count',
    sizes: {
      xs: 12,
      sm: 6,
      md: 4,
    },
    topBorder: true,
    type: 'number',
  },
  {
    id: 'movistar-contact-count',
    inputProps: {
      readOnly: true,
    },
    label: trans('forms.fields.client.newMessage.movistarContactCount'),
    name: 'movistar_contact_count',
    sizes: {
      xs: 12,
      sm: 6,
      md: 4,
    },
    topBorder: true,
    type: 'number',
  },
  {
    id: 'digitel-contact-count',
    inputProps: {
      readOnly: true,
    },
    label: trans('forms.fields.client.newMessage.digitelContactCount'),
    name: 'digitel_contact_count',
    sizes: {
      xs: 12,
      sm: 6,
      md: 4,
    },
    topBorder: true,
    type: 'number',
  },
  {
    id: 'movilnet-contact-count',
    inputProps: {
      readOnly: true,
    },
    label: trans('forms.fields.client.newMessage.movilnetContactCount'),
    name: 'movilnet_contact_count',
    sizes: {
      xs: 12,
      sm: 6,
      md: 4,
    },
    topBorder: true,
    type: 'number',
  },
  {
    id: 'content',
    charCount: true,
    InputLabelProps: {
      shrink: true,
    },
    inputProps: {
      maxLength: MAX_MESSAGE_SMS * SMS_CHAR_SIZE,
    },
    label: trans('forms.fields.client.newMessage.content'),
    multiline: true,
    name: 'content',
    required: true,
    rows: 4,
    sizes: {
      xs: 12,
    },
    topBorder: true,
  },
  {
    id: 'message-count',
    inputProps: {
      readOnly: true,
    },
    label: trans('forms.fields.client.newMessage.messageCount'),
    name: 'message_count',
    sizes: {
      xs: 12,
      sm: 6,
      md: 4,
    },
    topBorder: true,
    type: 'number',
  },
  {
    id: 'total-messages',
    inputProps: {
      readOnly: true,
    },
    label: trans('forms.fields.client.newMessage.totalMessages'),
    name: 'total_messages',
    sizes: {
      xs: 12,
      sm: 6,
      md: 4,
    },
    topBorder: true,
    type: 'number',
  },
  {
    id: 'credits',
    inputProps: {
      readOnly: true,
    },
    label: trans('forms.fields.client.newMessage.credits'),
    name: 'credits',
    sizes: {
      xs: 12,
      sm: 6,
      md: 4,
    },
    topBorder: true,
    type: 'number',
  },
  {
    id: 'schedule',
    label: trans('forms.fields.client.newMessage.schedule'),
    name: 'schedule',
    sizes: {
      xs: 12,
    },
    topBorder: true,
    type: 'switch',
  },
  {
    addLabel: trans('forms.fields.client.newMessage.addSchedule'),
    condition: ({ message: { schedule } }) => schedule,
    defaultItem: {
      send_date: '',
      send_time: '',
      hidden: false,
    },
    fieldType: 'array',
    name: 'schedules',
    inputs: [
      {
        id: 'send-date',
        inputProps: {
          maxLength: 255,
        },
        label: trans('forms.fields.client.newMessage.sendDate'),
        name: 'send_date',
        required: true,
        sizes: {
          xs: 12,
          sm: 6,
          md: 4,
        },
        type: 'datepicker',
      },
      {
        clickableIcon: true,
        icon: Close,
        iconAlign: 'right',
        id: 'send-time',
        inputProps: {
          maxLength: 255,
        },
        label: trans('forms.fields.client.newMessage.sendTime'),
        name: 'send_time',
        remove: true,
        removeLabel: trans('forms.fields.client.newMessage.removeSchedule'),
        required: true,
        sizes: {
          xs: 12,
          sm: 6,
          md: 4,
        },
        type: 'timepicker',
      },
    ],
    size: {
      xs: 12,
    },
  },
];

const contactsFileFieldsCondition = ({ contacts_src_file, file }) => (
  file && contacts_src_file
);

export const INPUTS = [
  {
    id: 'name',
    inputProps: {
      maxLength: 255,
    },
    label: trans('forms.fields.client.campaigns.name'),
    name: 'name',
    required: true,
    sizes: {
      xs: 12,
      sm: 6,
      md: 4,
    },
  },
  {
    id: 'group',
    label: trans('forms.fields.client.campaigns.group'),
    name: 'group_id',
    required: true,
    sizes: {
      xs: 12,
      sm: 6,
      md: 4,
    },
    type: 'select',
  },
  {
    id: 'file',
    label: trans('forms.fields.client.campaigns.file'),
    labelOff: trans('forms.fields.client.campaigns.fileOff'),
    labelOn: trans('forms.fields.client.campaigns.fileOn'),
    name: 'file',
    sizes: {
      xs: 12,
      sm: 6,
      md: 4,
    },
    type: 'twoWaySwitch',
  },
  {
    acceptedFiles: ['.csv', '.xls', '.xlsx'],
    condition: ({ file }) => file,
    id: 'contacts-src-file',
    inputProps: {
      maxLength: 255,
    },
    label: trans('forms.fields.client.campaigns.contactsFile'),
    name: 'contacts_src_file',
    required: true,
    sizes: {
      xs: 12,
      sm: 6,
    },
    type: 'file',
  },
  {
    condition: contactsFileFieldsCondition,
    id: 'file-fields',
    help: trans('forms.fields.client.campaigns.fileFieldsDesc'),
    label: trans('forms.fields.client.campaigns.fileFields'),
    name: 'file_fields',
    sizes: {
      xs: 12,
    },
    type: 'label',
  },
  {
    condition: contactsFileFieldsCondition,
    id: 'first-name-field',
    InputLabelProps: {
      shrink: true,
    },
    inputProps: {
      maxLength: 255,
    },
    label: trans('forms.fields.client.campaigns.field', { pos: 1 }),
    name: 'first_name_field',
    placeholder: trans('forms.fields.client.campaigns.firstNameField'),
    required: true,
    sizes: {
      xs: 12,
      sm: 6,
      md: 3,
    },
  },
  {
    condition: contactsFileFieldsCondition,
    id: 'last-name-field',
    InputLabelProps: {
      shrink: true,
    },
    inputProps: {
      maxLength: 255,
    },
    label: trans('forms.fields.client.campaigns.field', { pos: 2 }),
    name: 'last_name_field',
    placeholder: trans('forms.fields.client.campaigns.lastNameField'),
    required: true,
    sizes: {
      xs: 12,
      sm: 6,
      md: 3,
    },
  },
  {
    condition: contactsFileFieldsCondition,
    id: 'phone-field',
    InputLabelProps: {
      shrink: true,
    },
    inputProps: {
      maxLength: 255,
    },
    label: trans('forms.fields.client.campaigns.field', { pos: 3 }),
    name: 'phone_field',
    placeholder: trans('forms.fields.client.campaigns.phoneField'),
    required: true,
    sizes: {
      xs: 12,
      sm: 6,
      md: 3,
    },
  },
  {
    condition: contactsFileFieldsCondition,
    id: 'email-field',
    InputLabelProps: {
      shrink: true,
    },
    inputProps: {
      maxLength: 255,
    },
    label: trans('forms.fields.client.campaigns.field', { pos: 4 }),
    name: 'email_field',
    placeholder: trans('forms.fields.client.campaigns.emailField'),
    required: true,
    sizes: {
      xs: 12,
      sm: 6,
      md: 3,
    },
  },
  {
    addLabel: trans('forms.fields.client.campaigns.addContact'),
    condition: ({ file }) => !file,
    defaultItem: {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
    },
    fieldType: 'array',
    name: 'contacts',
    inputs: [
      {
        id: 'first-name',
        inputProps: {
          maxLength: 255,
        },
        label: trans('forms.fields.client.campaigns.firstNameField'),
        name: 'first_name',
        required: true,
        sizes: {
          xs: 12,
          sm: 6,
          md: 4,
        },
      },
      {
        id: 'last-name',
        inputProps: {
          maxLength: 255,
        },
        label: trans('forms.fields.client.campaigns.lastNameField'),
        name: 'last_name',
        required: true,
        sizes: {
          xs: 12,
          sm: 6,
          md: 4,
        },
      },
      // {
      //   id: 'email',
      //   inputProps: {
      //     maxLength: 255,
      //   },
      //   label: trans('forms.fields.client.campaigns.emailField'),
      //   name: 'email',
      //   sizes: {
      //     xs: 12,
      //     sm: 6,
      //     md: 3,
      //   },
      // },
      {
        clickableIcon: true,
        icon: Close,
        iconAlign: 'right',
        id: 'phone',
        inputProps: {
          maxLength: 255,
        },
        label: trans('forms.fields.client.campaigns.phoneField'),
        name: 'phone',
        remove: true,
        removeLabel: trans('forms.fields.client.campaigns.removeContact'),
        sizes: {
          xs: 12,
          sm: 6,
          md: 4,
        },
      },
    ],
    size: {
      xs: 12,
    },
    title: trans('forms.fields.client.campaigns.contacts'),
  },
];

export const CAMPAIGN_FORM = 'campaign';
export const NEW_MESSAGE_FORM = 'new_message';
export const TEST_MESSAGE_FORM = 'test_message';

export const CAMPAIGN_CONTACTS_FILE_VALIDATIONS = [
  {
    field: 'contacts_src_file',
    message: ['default.forms.validations.isRequired'],
    method: 'isEmpty',
    validWhen: false,
  },
];

export const CAMPAIGN_VALIDATIONS = [
  {
    field: 'name',
    message: ['default.forms.validations.isRequired'],
    method: 'isEmpty',
    validWhen: false,
  },
  {
    field: 'group_id',
    message: ['default.forms.validations.isRequired'],
    method: 'isEmpty',
    validWhen: false,
  },
  {
    field: 'first_name_field',
    message: ['default.forms.validations.isRequired'],
    method: 'isEmpty',
    validWhen: false,
  },
  {
    field: 'last_name_field',
    message: ['default.forms.validations.isRequired'],
    method: 'isEmpty',
    validWhen: false,
  },
  {
    field: 'email_field',
    message: ['default.forms.validations.isRequired'],
    method: 'isEmpty',
    validWhen: false,
  },
  {
    field: 'phone_field',
    message: ['default.forms.validations.isRequired'],
    method: 'isEmpty',
    validWhen: false,
  },
  {
    array: true,
    field: 'contacts',
    validations: [
      {
        field: 'first_name',
        message: ['default.forms.validations.isRequired'],
        method: 'isEmpty',
        validWhen: false,
      },
      {
        field: 'last_name',
        message: ['default.forms.validations.isRequired'],
        method: 'isEmpty',
        validWhen: false,
      },
      // {
      //   field: 'email',
      //   message: ['default.forms.validations.isEmail'],
      //   method: 'isEmail',
      //   validWhen: true,
      // },
      {
        field: 'phone',
        message: ['default.forms.validations.isRequired'],
        method: 'isEmpty',
        validWhen: false,
      },
      {
        field: 'phone',
        message: ['default.forms.validations.isCellPhoneNumber'],
        method: 'isCellPhoneNumber',
        validWhen: true,
      },
    ],
  },
];

export const NEW_MESSAGE_VALIDATIONS = [
  {
    field: 'group',
    message: ['default.forms.validations.isRequired'],
    method: 'isEmpty',
    validWhen: false,
  },
  {
    field: 'campaign_id',
    message: ['default.forms.validations.isRequired'],
    method: 'isEmpty',
    validWhen: false,
  },
  {
    field: 'integrator_id',
    message: ['default.forms.validations.isRequired'],
    method: 'isEmpty',
    validWhen: false,
  },
  {
    field: 'content',
    message: ['default.forms.validations.isRequired'],
    method: 'isEmpty',
    validWhen: false,
  },
  {
    args: [{ min: 1 }],
    field: 'total_messages',
    message: ['default.forms.validations.isHigherThan', { min: 0 }],
    method: 'isInt',
    validWhen: true,
  },
  {
    args: ['total_messages'],
    field: 'credits',
    message: ['default.forms.validations.notEnoughCredits'],
    method: 'isGreaterThanField',
    validWhen: true,
  },
  {
    array: true,
    field: 'schedules',
    validations: [
      {
        field: 'send_date',
        message: ['default.forms.validations.isRequired'],
        method: 'isEmpty',
        validWhen: false,
      },
      {
        field: 'send_time',
        message: ['default.forms.validations.isRequired'],
        method: 'isEmpty',
        validWhen: false,
      },
    ],
  },
];

export const NEW_MESSAGE_PARSE_CONFIG = [
  {
    field: 'schedules',
    parser: 'transformArray',
    params: [
      {},
      {
        send_date: 'date',
        send_time: 'time',
      },
    ],
    passData: true,
  },
];

import React from 'react';
import { connect } from 'react-redux';
import EditIntegratorForm from '../../../../Components/Forms/Admin/Integrators/EditIntegrator';
import ModalsWrappedForm from '../../../../Components/Forms/ModalsWrappedForm';
import trans from '../../../../Translations/translation.service';
import { actions as integratorsActions, types } from '../../../../Redux/Api/Integrators';
import { PRIVATE_PATHS } from '../../../config';
import { FEMALE_CODE } from '../../../../Constants';
import { EDIT_ACTIONS } from '../../../../Components/Forms/Admin/Integrators/config';
import { catchApiItemErrors } from '../../../../Helpers';

class EditIntegrator extends React.Component {
  getIntegrator = () => {
    const { id } = this.props.match.params;

    this.props.getIntegrator(id);
  };

  loading = () => {
    const { isFetchingIntegrator, isUpdatingIntegrator } = this.props;
    return isFetchingIntegrator || isUpdatingIntegrator;
  };

  updateIntegrator = data => {
    return this.props.updateIntegrator(data).catch(
      catchApiItemErrors,
    );
  };

  componentDidMount() {
    this.getIntegrator();
  }

  render() {
    const { integrator, history, success } = this.props;
    const loading = this.loading();
    const subject = trans('resources.singular.integrator');

    return (
      <ModalsWrappedForm
        gender={FEMALE_CODE}
        history={history}
        loading={loading}
        open={success}
        path={PRIVATE_PATHS.adminIntegrators.path}
        subject={subject}
        successMessage="successOpSingM"
        type="edit"
      >
        {
          integrator &&
          <EditIntegratorForm
            actions={EDIT_ACTIONS}
            history={history}
            onSubmit={this.updateIntegrator}
            path={PRIVATE_PATHS.adminIntegrators.path}
          />
        }
      </ModalsWrappedForm>
    );
  }
}

const mapStateToProps = ({
  integrators: { isFetchingItem, isUpdating, item },
  reduxRestResponse: { body, success, type },
}) => ({
  body,
  integrator: item,
  isFetchingIntegrator: isFetchingItem,
  isUpdatingIntegrator: isUpdating,
  success: type === types.UPDATE_INTEGRATOR && success,
});

export default connect(
  mapStateToProps,
  {
    ...integratorsActions,
  }
)(EditIntegrator);

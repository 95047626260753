/** Tables components messages (EN)  */
export default {
  actions: {
    delete: 'Delete',
    edit: 'Edit',
  },
  titles: {
    admin: {
      accounts: {
        number: 'Account #',
        company: 'Company',
        username: 'Username',
        type: 'Type',
        credits: 'Credits',
      },
      activities: {
        account: 'Account',
        user: 'User',
        type: 'Type',
        description: 'Description',
        dateTime: 'Date and Time',
      },
      banks: {
        name: 'Name',
      },
      businessTypes: {
        name: 'Name',
        industry: 'Industry',
      },
      industries: {
        name: 'Name',
      },
      integrators: {
        name: 'Name',
        code: 'Code',
        credits: 'Credits',
      },
      keywords: {
        word: 'Word',
        shortCode: 'Short Code',
        account: 'Account',
      },
      news: {
        title: 'Title',
        content: 'Content',
        from: 'From',
        to: 'To',
      },
      payments: {
        monthYear: 'Month & Year',
        date: 'Date',
        type: 'Type',
        bank: 'Bank',
        id: 'Reference',
        file: 'File',
        account: 'Account',
      },
      receivedMessages: {
        sender: 'Sender',
        message: 'Message',
        keyword: 'Keyword',
        shortCode: 'Short Code',
        date: 'Date',
        time: 'Time',
      },
      reports: {
        account: 'Account',
        integrator: 'Integrator',
        shortCode: 'Short Code',
        sentMessages: 'Sent Messages',
        receivedMessages: 'Received Messages',
      },
      scheduledCampaigns: {
        name: 'Name',
        group: 'Group',
        account: 'Account',
        date: 'Date',
        time: 'Time',
      },
      sentCampaigns: {
        name: 'Name',
        group: 'Group',
        account: 'Account',
        contacts: 'Contacts',
        date: 'Date',
        time: 'Time',
        status: 'Status',
      },
      sentMessages: {
        receipent: 'Receipent',
        content: 'Content',
        messageCount: 'Message Count',
        campaign: 'Campaign',
        integrator: 'Integrator',
        carrier: 'Carrier',
        account: 'Account',
        dateTime: 'Date and Time',
      },
      sentReplies: {
        receipent: 'Receipent',
        message: 'Message',
        keyword: 'Keyword',
        shortCode: 'Short Code',
        date: 'Date',
        time: 'Time',
      },
      shortCodes: {
        code: 'Code',
      },
    },
    client: {
      activities: {
        user: 'User',
        type: 'Type',
        description: 'Description',
        dateTime: 'Date and Time',
      },
      campaigns: {
        name: 'Name',
        group: 'Group',
        contacts: 'Contacts',
        contactsFile: 'Contacts File',
      },
      contacts: {
        firstName: 'First Name',
        lastName: 'Last Name',
        email: 'Email',
        phone: 'Phone',
        carrier: 'Carrier',
      },
      groups: {
        name: 'Name',
      },
      payments: {
        monthYear: 'Month & Year',
        date: 'Date',
        type: 'Type',
        bank: 'Bank',
        id: 'Reference',
        file: 'File',
      },
      scheduledCampaigns: {
        name: 'Name',
        group: 'Group',
        date: 'Date',
        time: 'Time',
      },
      sentCampaigns: {
        name: 'Name',
        group: 'Group',
        contacts: 'Contacts',
        date: 'Date',
        time: 'Time',
        status: 'Status',
      },
      sentMessages: {
        receipent: 'Receipent',
        content: 'Content',
        messageCount: 'Message Count',
        campaign: 'Campaign',
        integrator: 'Integrator',
        carrier: 'Carrier',
        dateTime: 'Date and Time',
      },
      users: {
        username: 'Username',
        status: 'Status',
      },
    },
    actions: 'Actions',
  },
};

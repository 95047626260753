import styled from 'styled-components';
import { COLORS } from '../../../Constants';

export default styled.div`
  align-items: center;
  background-color: ${COLORS.mediumBlue};
  border-radius: 2px;
  box-shadow: 0 2px 5px rgba(0,0,0,.08);
  display: flex;
  margin: 2.5px;
  padding: 0

  &>a, &>div {
    display: flex;
    padding: 12px;
    width: 100%;
  }

  .text {
    flex-grow: 1;
    max-width: calc(100% - 38px);
  }

  .icon {
    flex-shrink: 0;

    img {
      margin-left: 10px;
      width: 28px;
    }
  }
`;

export const MODAL_ICON_STYLE = {
  height: '80px',
  margin: '20px 0',
  width: '80px',
};

export const SUCCESS_MESSAGES = {
  successOpPlurF: 'successOpPlurF',
  successOpPlurM: 'successOpPlurM',
  successOpSingF: 'successOpSingF',
  successOpSingM: 'successOpSingM',
};

import * as types from './types';
import { actions as usersActions } from '../../Api/Users';

// Action Creators
export const loginUser = user => dispatch => {
  dispatch(usersActions.loginUser(user))
  .then(({ body: { data: payload } }) => {
    dispatch({
      payload,
      type: types.LOGIN,
    });
  });
};

export const logout = (api = true) => dispatch => {
  if (api) {
    dispatch(usersActions.logoutUser())
    .then(({ body: { data: payload } }) => {
      dispatch({
        payload,
        type: types.LOGOUT,
      });
    });
  } else {
    dispatch({
      type: types.LOGOUT,
    });
  }
};

export const setUser = user => ({
  type: types.SET_USER,
  user,
});

import React from 'react';
import TableHead from '@material-ui/core/TableHead';
import ActionCell from './Styled/ActionCell';
import TableCell from './Styled/TableCell';
import TableRow from './Styled/TableRow';
import trans from '../../Translations/translation.service';

export default ({ actions, number, titles }) => (
  <TableHead>
    <TableRow>
      {
        number &&
        <ActionCell>#</ActionCell>
      }
      {titles.map(({ text, ...title }, key) => (
        <TableCell
          {...title}
          key={key}
        >
          <strong>{text}</strong>
        </TableCell>
      ))}
      {
        actions &&
        <ActionCell>
          <strong>{trans('tables.titles.actions')}</strong>
        </ActionCell>
      }
    </TableRow>
  </TableHead>
);

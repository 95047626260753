import React from 'react';
import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import moment from 'moment';
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import CheckCircle from '../../../Components/Table/Styled/StatusCheck';
import Table from '../../../Components/Table';
import { actions as allAccountsActions } from '../../../Redux/Api/AllAccounts';
import { actions as sentCampaignsActions } from '../../../Redux/Api/Schedules';
import {
  ADMIN_SENT_CAMPAIGNS_TABLE_TITLES,
  ALL_SELECT_OPTION_F,
  ALL_SELECT_OPTION_VALUE as ALL,
  API_DATE_FORMAT,
} from '../../../Constants';
import {
  ADMIN_SENT_CAMPAIGNS_FILTERS_INPUTS,
} from './config';
import { FILTERS_FORM as form } from '../../../Components/Filters/config';
import { getPage, parseApiDate, parseApiTime } from '../../../Helpers';

class AdminSentCampaigns extends React.Component {
  state = {
    filters: {},
  };

  fetchSentCampaignsFromAccount = account => {
    const { filters } = this.state;

    if (account === ALL) {
      delete filters.account;
    } else {
      filters.account =  account;
    }

    this.setState({ filters }, () => {
      this.fetchSchedules();
    });
  };

  fetchSentCampaignsFromDateFrom = date => {
    const { filters } = this.state;

    const momentDate = moment(date, API_DATE_FORMAT);

    if (momentDate._isValid) {
      filters.from =  date;
    } else {
      delete filters.from;
    }

    this.setState({ filters }, () => {
      this.fetchSchedules();
    });
  };

  fetchSentCampaignsFromDateTo = date => {
    const { filters } = this.state;

    const momentDate = moment(date, API_DATE_FORMAT);

    if (momentDate._isValid) {
      filters.to =  date;
    } else {
      delete filters.to;
    }

    this.setState({ filters }, () => {
      this.fetchSchedules();
    });
  };

  fetchSchedules = newPage => {
    const { filters } = this.state;
    const { location } = this.props;
    const page = getPage(newPage, location);

    this.props.fetchSchedules({}, { query: { page, ...filters } });
  };

  initFilters = () => {
    this.props.reset(form);
    this.props.change(form, 'from_date', undefined);
    this.props.change(form, 'to_date', undefined);
    this.props.change(form, 'account_id', ALL);
  };

  loading = () => {
    const {
      isFetchingAccounts,
      isFetchingSentCampaigns,
    } = this.props;

    return isFetchingAccounts
      || isFetchingSentCampaigns;
  };

  parseSentCampaignData = ({
    campaign_message,
    queued,
    sent,
    send_date: date,
    send_time: time,
  }) => [
    {
      text: campaign_message && campaign_message.campaign && campaign_message.campaign.name,
    },
    {
      text: campaign_message
      && campaign_message.campaign
      && campaign_message.campaign.group
      && campaign_message.campaign.group.name,
    },
    {
      text: campaign_message
      && campaign_message.campaign
      && campaign_message.campaign.group
      && campaign_message.campaign.group.account
      && campaign_message.campaign.group.account.company_name,
    },
    {
      noWrap: true,
      text: parseApiDate(date),
    },
    {
      noWrap: true,
      text: parseApiTime(time),
    },
    {
      text: queued || sent ? <CheckCircle /> : <RemoveCircle color="error" />,
    },
  ];

  parseAccounts = () => {
    const accounts = this.props.accounts.map(({ id: value, company_name: text }) => ({
      text, value,
    }));

    accounts.unshift(ALL_SELECT_OPTION_F);

    return accounts;
  };

  componentDidMount() {
    this.fetchSchedules();
    this.initFilters();
    this.props.fetchAllAccounts();
  }

  render() {
    const { sentCampaigns, pagination } = this.props;
    const accounts = this.parseAccounts();

    return (
      <Table
        // actions={DELETE_TABLE_ACTIONS}
        data={sentCampaigns}
        dataParser={this.parseSentCampaignData}
        filtersProps={{
          afterChange: {
            account_id: this.fetchSentCampaignsFromAccount,
            from_date: this.fetchSentCampaignsFromDateFrom,
            to_date: this.fetchSentCampaignsFromDateTo,
          },
          data: {
            account_id: accounts,
          },
          inputs: ADMIN_SENT_CAMPAIGNS_FILTERS_INPUTS,
          loading: this.loading(),
        }}
        handleChangePage={this.fetchSchedules}
        loading={this.loading()}
        pagination={pagination}
        titles={ADMIN_SENT_CAMPAIGNS_TABLE_TITLES}
      />
    );
  }
}

const mapStateToProps = ({
  allAccounts: {
    isFetching: isFetchingAccounts,
    items: accounts,
  },
  schedules: {
    isFetching,
    items: [success, data, pagination],
  },
}) => ({
  isFetchingAccounts,
  pagination,
  success,
  accounts: accounts[1] ? accounts[1] : [],
  sentCampaigns: success ? data : [],
  isFetchingSentCampaigns: isFetching,
});

export default connect(
  mapStateToProps,
  {
    ...allAccountsActions,
    ...sentCampaignsActions,
    change,
    reset,
  }
)(AdminSentCampaigns);

import React from 'react';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';
import ResponsiveForm from '../../Form';
import { validate } from '../../../../Helpers';
import {
  NEW_MESSAGE_DEFAULT_VALUES,
  NEW_MESSAGE_FORM as form,
  NEW_MESSAGE_INPUTS,
  NEW_MESSAGE_VALIDATIONS,
} from './config';

let NewMessageForm = ({ handleSubmit, message, loading = false, ...props }) => (
  <ResponsiveForm
    {...props}
    inputs={NEW_MESSAGE_INPUTS}
    loading={loading}
    onSubmit={handleSubmit}
    values={{ message }}
  />
);

NewMessageForm = reduxForm({
  form,
  validate: validate(NEW_MESSAGE_VALIDATIONS),
})(NewMessageForm);

const selector = formValueSelector(form);

const mapStateToProps = state => ({
  initialValues: { ...NEW_MESSAGE_DEFAULT_VALUES },
  message: selector(
    state,
    'content',
    'integrator_id',
    'contact_count',
    'schedule',
  ),
});

export default connect(
  mapStateToProps
)(NewMessageForm);

/** Menu component messages (EN)  */
export default {
  options: {
    titles: {
      activities: 'Activities',
      activitiesList: 'Activities',
      addPayment: 'Add Payment',
      addUser: 'Add User',
      deleteAll: 'Delete All',
      export: 'Export',
      keywordsList: 'Keywords List',
      receivedMessagesList: 'Received Messages',
      scheduledCampaigns: 'Scheduled Campaigns',
      sentCampaigns: 'Sent Campaigns',
      sentMessages: 'Sent Messages',
      sentRepliesList: 'Sent Replies',
      shortCodes: 'Short Codes',
      shortCodesDashboard: 'Dashboard',
      shortCodesList: 'Short Codes List',
      tableView: 'Table View',
      users: 'Users',
      usersList: 'Users List',
      admin: {
        accounts: 'Accounts',
        accountsList: 'Accounts List',
        addAccount: 'Add Account',
        addBanks: 'Add Banks',
        addBusinessTypes: 'Add Business Types',
        addCredits: 'Add Credits',
        addKeyword: 'Add Keyword',
        addIndustries: 'Add Industries',
        addNew: 'Add New',
        addShortCodes: 'Add Short Codes',
        banks: 'Banks',
        banksList: 'Banks List',
        businessTypesList: 'Business Types List',
        campaigns: 'Campaigns',
        companies: 'Companies',
        industries: 'Industries',
        industriesList: 'Industries List',
        integrators: 'Integrators',
        integratorsList: 'Integrators List',
        messages: 'Messages',
        news: 'News',
        newsList: 'News List',
        payments: 'Payments',
        paymentsList: 'Payments List',
        reportsSMS: 'Reports SMS',
        reportsShortCodes: 'Reports Short Codes',
      },
      client: {
        addCampaign: 'Add Campaign',
        addGroups: 'Add Groups',
        campaigns: 'Campaigns',
        campaignsList: 'Campaigns List',
        changeLogo: 'Change Logo',
        changeTheme: 'Change Theme',
        contacts: 'Contacts',
        contactsList: 'Contacts List',
        groups: 'Groups',
        groupsList: 'Groups List',
        messages: 'Messages',
        myAccount: 'My Account',
        myAccountInfo: 'My Account Info',
        myPayments: 'My Payments',
        newMessage: 'New Message',
        payments: 'Payments',
        settings: 'Settings',
      },
    },
  },
};

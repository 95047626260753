import React from 'react';
import { connect } from 'react-redux';
import EditBusinessTypeForm from '../../../../Components/Forms/Admin/BusinessTypes/EditBusinessType';
import ModalsWrappedForm from '../../../../Components/Forms/ModalsWrappedForm';
import trans from '../../../../Translations/translation.service';
import { actions as allIndustriesActions } from '../../../../Redux/Api/AllIndustries';
import { actions as businessTypesActions, types } from '../../../../Redux/Api/BusinessTypes';
import { PRIVATE_PATHS } from '../../../config';
import { MALE_CODE } from '../../../../Constants';
import { EDIT_ACTIONS } from '../../../../Components/Forms/Admin/BusinessTypes/config';
import { catchApiItemErrors } from '../../../../Helpers';

class EditBusinessType extends React.Component {
  getBusinessType = () => {
    const { id } = this.props.match.params;

    this.props.getBusinessType(id);
  };

  loading = () => {
    const {
      isFetchingBusinessType,
      isFetchingIndustries,
      isUpdatingBusinessType,
    } = this.props;
    return isFetchingBusinessType
      || isFetchingIndustries
      || isUpdatingBusinessType;
  };

  parseIndustries = () => (
    this.props.industries.map(({ id: value, name: text }) => ({
      text, value,
    }))
  );

  updateBusinessType = data => {
    return this.props.updateBusinessType(data).catch(
      catchApiItemErrors,
    );
  };

  componentDidMount() {
    this.getBusinessType();
    this.props.fetchAllIndustries();
  }

  render() {
    const { businessType, history, success } = this.props;
    const industries = this.parseIndustries();
    const loading = this.loading();
    const subject = trans('resources.singular.businessType');

    return (
      <ModalsWrappedForm
        gender={MALE_CODE}
        history={history}
        loading={loading}
        open={success}
        path={PRIVATE_PATHS.adminBusinessTypes.path}
        subject={subject}
        successMessage="successOpSingM"
        type="edit"
      >
        {
          businessType &&
          <EditBusinessTypeForm
            actions={EDIT_ACTIONS}
            data={{
              industry_id: industries,
            }}
            history={history}
            onSubmit={this.updateBusinessType}
            path={PRIVATE_PATHS.adminBusinessTypes.path}
          />
        }
      </ModalsWrappedForm>
    );
  }
}

const mapStateToProps = ({
  allIndustries: {
    isFetching,
    items,
  },
  businessTypes: { isFetchingItem, isUpdating, item },
  reduxRestResponse: { body, success, type },
}) => ({
  body,
  businessType: item,
  industries: items[1] ? items[1] : [],
  isFetchingBusinessType: isFetchingItem,
  isFetchingIndustries: isFetching,
  isUpdatingBusinessType: isUpdating,
  success: type === types.UPDATE_BUSINESS_TYPE && success,
});

export default connect(
  mapStateToProps,
  {
    ...allIndustriesActions,
    ...businessTypesActions,
  }
)(EditBusinessType);

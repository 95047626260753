import { createResource } from 'redux-rest-resource';
import { HEADERS as headers, INDUSTRIES_URL } from '../../Constants';
import { transformFetchResponse as transformResponse } from '../../Helpers';

export const { types, actions, reducers } = createResource({
  name: 'industry',
  pluralName: 'industries',
  url: `${INDUSTRIES_URL}/:id`,
  headers,
  actions: {
    createMany: {
      gerundName: 'creatingMany',
      isArray: true,
      method: 'POST',
      url: `${INDUSTRIES_URL}/many`,
    },
    fetch: {
      transformResponse,
    },
  },
});

import React from 'react';
import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import moment from 'moment';
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import CheckCircle from '../../../Components/Table/Styled/StatusCheck';
import Table from '../../../Components/Table';
import { actions as sentCampaignsActions } from '../../../Redux/Api/Schedules';
import {
  API_DATE_FORMAT,
  CLIENT_SENT_CAMPAIGNS_TABLE_TITLES,
} from '../../../Constants';
import {
  CLIENT_SENT_CAMPAIGNS_FILTERS_INPUTS,
} from './config';
import { FILTERS_FORM as form } from '../../../Components/Filters/config';
import { getPage, parseApiDate, parseApiTime } from '../../../Helpers';

class ClientSentCampaigns extends React.Component {
  state = {
    filters: {},
  };

  fetchSentCampaignsFromDateFrom = date => {
    const { filters } = this.state;

    const momentDate = moment(date, API_DATE_FORMAT);

    if (momentDate._isValid) {
      filters.from =  date;
    } else {
      delete filters.from;
    }

    this.setState({ filters }, () => {
      this.fetchSchedules();
    });
  };

  fetchSentCampaignsFromDateTo = date => {
    const { filters } = this.state;

    const momentDate = moment(date, API_DATE_FORMAT);

    if (momentDate._isValid) {
      filters.to =  date;
    } else {
      delete filters.to;
    }

    this.setState({ filters }, () => {
      this.fetchSchedules();
    });
  };

  fetchSchedules = newPage => {
    const { filters } = this.state;
    const { location } = this.props;
    const page = getPage(newPage, location);

    this.props.fetchSchedules({}, {
      query: { page, client: true, ...filters },
    });
  };

  initFilters = () => {
    this.props.reset(form);
    this.props.change(form, 'from_date', undefined);
    this.props.change(form, 'to_date', undefined);
  };

  loading = () => {
    const {
      isFetchingSentCampaigns,
    } = this.props;

    return isFetchingSentCampaigns;
  };

  parseSentCampaignData = ({
    campaign_message,
    queued,
    sent,
    send_date: date,
    send_time: time,
  }) => [
    {
      text: campaign_message && campaign_message.campaign && campaign_message.campaign.name,
    },
    {
      text: campaign_message
      && campaign_message.campaign
      && campaign_message.campaign.group
      && campaign_message.campaign.group.name,
    },
    {
      noWrap: true,
      text: parseApiDate(date),
    },
    {
      noWrap: true,
      text: parseApiTime(time),
    },
    {
      text: queued || sent ? <CheckCircle /> : <RemoveCircle color="error" />,
    },
  ];

  componentDidMount() {
    this.fetchSchedules();
    this.initFilters();
  }

  render() {
    const { sentCampaigns, pagination } = this.props;

    return (
      <Table
        data={sentCampaigns}
        dataParser={this.parseSentCampaignData}
        filtersProps={{
          afterChange: {
            from_date: this.fetchSentCampaignsFromDateFrom,
            to_date: this.fetchSentCampaignsFromDateTo,
          },
          inputs: CLIENT_SENT_CAMPAIGNS_FILTERS_INPUTS,
          loading: this.loading(),
        }}
        handleChangePage={this.fetchSchedules}
        loading={this.loading()}
        pagination={pagination}
        titles={CLIENT_SENT_CAMPAIGNS_TABLE_TITLES}
      />
    );
  }
}

const mapStateToProps = ({
  schedules: {
    isFetching,
    items: [success, data, pagination],
  },
}) => ({
  pagination,
  success,
  sentCampaigns: success ? data : [],
  isFetchingSentCampaigns: isFetching,
});

export default connect(
  mapStateToProps,
  {
    ...sentCampaignsActions,
    change,
    reset,
  }
)(ClientSentCampaigns);

import React from 'react';
import Option from './Option';
import SmallWhiteInfomailLogo from '../../../Logos/Infomail/SmallWhite';
import { APP_SHORT_NAME as title } from '../../../../Constants';

export default ({ path }) => (
  <div className="navbar-logo">
    <Option
      option={{
        path,
        title,
        image: SmallWhiteInfomailLogo,
      }}
    />
  </div>
);

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { COLORS } from '../../../../Constants';

export default withStyles({
  root: {
    borderTop: props => props.topBorder ? `1px solid ${COLORS.black1}` : 'none',
    marginTop: props => props.topBorder ? '14px' : 0,
    paddingBottom: 25,
    paddingTop: props => props.topBorder ? '30px !important' : 0,
  },
})(Grid);

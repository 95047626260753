import styled from 'styled-components';
import { COLORS } from '../../../../../Constants';

export default styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  padding: 10px 10px;
  width: 100%;

  * {
    color: ${props => props.selected ? 'white !important' : COLORS.mediumBlue};
  }
`;

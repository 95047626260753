import styled from 'styled-components';

export default styled.h2`
  font-size: 1.5rem;
  display: block;
  color: #fff;
  margin-top: .6rem;
  font-weight: 400;
  line-height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

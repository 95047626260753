import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import ResponsiveForm from '../../Form';
import { validate } from '../../../../Helpers';
import {
  SHORT_CODE_FORM as form,
  SHORT_CODE_VALIDATIONS,
  EDIT_INPUTS,
} from './config';

let EditShortCodeForm = ({ handleSubmit, loading = false, ...props }) => (
  <ResponsiveForm
    {...props}
    inputs={EDIT_INPUTS}
    loading={loading}
    onSubmit={handleSubmit}
  />
);

EditShortCodeForm = reduxForm({
  form,
  validate: validate(SHORT_CODE_VALIDATIONS),
})(EditShortCodeForm);

const mapStateToProps = ({
  shortCodes: {
    item,
  },
}) => ({
  initialValues: item ? item.data : {},
});

export default connect(
  mapStateToProps
)(EditShortCodeForm);

import axios from 'axios';
import * as types from './types';

const throwError = error => ({
  body: error.response.data,
});

// Action Creators
const uploadFilePending = () => ({
  type: types.UPLOAD_FILE_PENDING,
});

const uploadFileSuccess = response => ({
  type: types.UPLOAD_FILE_SUCCESS,
  response,
});

const uploadFileError = () => ({
  type: types.UPLOAD_FILE_ERROR,
});

export const uploadFileReset = () => ({
  type: types.UPLOAD_FILE_RESET,
});

export const uploadFile = (
  url,
  data,
  options = {},
) => dispatch => {
  dispatch(uploadFilePending());
  const formData = new FormData();

  if (typeof data === 'object' || !data) {
    Object.keys(data).forEach(key => {
      formData.append(key, data[key]);
    });
  }

  return axios.post(url, formData, options)
    .then(res => {
      if(res.error) {
        dispatch(uploadFileError());
        throw(res.error);
      }
      dispatch(uploadFileSuccess(res));
      return res;
    })
    .catch(error => {
      dispatch(uploadFileError());
      throw throwError(error);
    });
};

import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import LoginForm from '../Forms/Login';
import Title from './Styled/Title';
import trans from '../../Translations/translation.service';
import { types } from '../../Redux/Api/Users';
import { actions as authActions } from '../../Redux/Ducks/Auth';

class Form extends React.Component {
  render() {
    const { body, logInFailed } = this.props;

    return (
      <Card>
        <CardContent>
          <Title>
            {trans('login.form.title')}
          </Title>
          {
            logInFailed &&
            <Typography color="error" component="p" paragraph>
              {body.data && body.data.message}
            </Typography>
          }
          <LoginForm
            loading={this.props.isLoggingInUser}
            onSubmit={this.props.loginUser}
          />
        </CardContent>
      </Card>
    );
  }
}

const mapStateToProps = ({
  users: { isLoggingIn },
  reduxRestResponse: { body, success, type },
}) => ({
  body,
  isLoggingInUser: isLoggingIn,
  logInFailed: type === types.LOGIN_USER && !success,
});

export default connect(
  mapStateToProps,
  {
    ...authActions,
  }
)(Form);

/** Forms components messages (ES)  */
export default {
  actions: {
    apply: 'Aplicar',
    cancel: 'Cancelar',
    clear: 'Limpiar',
    export: 'Exportar',
    save: 'Guardar',
    schedule: 'Programar',
    send: 'Enviar',
  },
  fields: {
    admin: {
      accounts: {
        clientUsername: 'Nombre de Usuario',
        clientPassword: 'Contraseña',
        clientPasswordConfirm: 'Confirmar Contraseña',
        accountType: 'Tipo',
        status: 'Estatus',
        channelId: 'Canal',
        clientPersonFirstName: 'Nombre',
        clientPersonLastName: 'Apellido',
        clientPersonIdNumber: 'Cédula',
        clientPersonAltIdNumber: 'RIF',
        clientPersonGender: 'Género',
        clientPersonBirthDate: 'Fecha de Nacimiento',
        companyName: 'Nombre',
        companyBusinessName: 'Razón Social',
        companyIdNumber: 'RIF',
        companyBusinessTypeId: 'Tipo de Negocio',
        companyEmail: 'Email',
        companyPhone: 'Teléfono',
        companyAddress: 'Dirección',
        startDate: 'Fecha de Inicio',
        dueDate: 'Fecha de Corte',
        prepaid: 'Tipoe de suscripción',
        prepaidOn: 'Prepago',
        prepaidOff: 'Pospago',
        shortCodeId: 'Número Corto',
        subscriptionDetails: 'Detalles del Plan',
        subscriptionUnlimited: 'Ilimitado',
        subscriptionUnlimitedOff: 'No',
        subscriptionUnlimitedOn: 'Sí',
        subscription: 'Suscripición',
        subscriptionPrice: 'Costo',
        subscriptionCredits: 'Créditos',
        subscriptionTotal: 'Total',
        subscriptionUsername: 'Usuario de Integradora',
        subscriptionPassword: 'Contraseña de Integradora',
        applyCredits: 'Aplicar Créditos',
        shortCodes: 'Números Cortos',
      },
      activitiesFilters: {
        from: 'Desde',
        to: 'Hata',
        account: 'Cuenta',
        user: 'Usuario',
        type: 'Tipo',
      },
      addCredits: {
        account: 'Cuenta',
        integrator: 'Integradora',
        currentCredits: 'Créditos Actuales',
        credits: 'Agregar Créditos',
      },
      banks: {
        addBank: 'Agregar Banco',
        removeBank: 'Eliminar Banco',
        name: 'Nombre',
      },
      businessTypes: {
        addBusinessType: 'Agregar Tipo de Negocio',
        removeBusinessType: 'Eliminar Tipo de Negocio',
        name: 'Nombre',
        industry: 'Industria',
      },
      campaignsFilters: {
        from: 'Desde',
        to: 'Hasta',
        account: 'Cuenta',
      },
      dashboardFilters: {
        from: 'Desde',
        to: 'Hasta',
        account: 'Cuenta',
        shortCode: 'Número Corto',
        keyword: 'Palabra Clave',
        campaign: 'Campaña',
        api: 'De API',
        carrier: 'Operadora',
        integrator: 'Integradora',
      },
      industries: {
        addIndustry: 'Agregar Industria',
        removeIndustry: 'Eliminar Industria',
        name: 'Nombre',
      },
      integrators: {
        name: 'Nombre',
        code: 'Código',
      },
      keywords: {
        word: 'Palabra',
        shortCode: 'Número Corto',
        account: 'Cuenta',
        replies: 'Respuestas',
        addReply: 'Agregar Respuesta',
        removeReply: 'Eliminar Respuesta',
        replyUrl: 'De API externa',
        replyUrlOff: 'No',
        replyUrlOn: 'Sí',
        replyFrom: 'Desde',
        replyTo: 'Hasta',
        replyContentUrl: 'URL',
        replyContent: 'Contenido',
      },
      keywordsFilters: {
        account: 'Cuenta',
        keyword: 'Palabra',
        shortCode: 'Número Corto',
      },
      news: {
        title: 'Título',
        from: 'Desde',
        to: 'Hasta',
        content: 'Contenido',
      },
      payments: {
        account: 'Cuenta',
        month: 'Mes',
        year: 'Año',
        transactionDate: 'Fecha Del Pago',
        transactionType: 'Tipo De Pago',
        bank: 'Banco',
        transactionId: 'Número De Referencia',
        transactionFile: 'Comprobante',
      },
      paymentsFilters: {
        from: 'Desde',
        to: 'Hata',
        account: 'Cuenta',
        month: 'Mes',
        year: 'Año',
      },
      receivedMessages: {
        export: {
          from: 'Desde',
          to: 'Hasta',
        },
      },
      removeCredits: {
        account: 'Cuenta',
        integrator: 'Integradora',
        currentCredits: 'Créditos Actuales',
        credits: 'Eliminar Créditos',
      },
      reportsFilters: {
        from: 'Desde',
        to: 'Hasta',
      },
      shortCodes: {
        addShortCode: 'Agregar Número Corto',
        removeShortCode: 'Eliminar Número Corto',
        code: 'Número',
      },
    },
    client: {
      activitiesFilters: {
        from: 'Desde',
        to: 'Hata',
        user: 'Usuario',
        type: 'Tipo',
      },
      campaigns: {
        name: 'Name',
        group: 'Grupo',
        file: 'Contactos',
        fileOff: 'Manualmente',
        fileOn: 'De un Archivo',
        contactsFile: 'Archivo de Contactos',
        fileFields: 'Campos Obligatorios',
        fileFieldsDesc: 'Estos campos deben estar presentes dentro del archivo. Por favor indica su nombre correspondiente en el mismo. De no encontrarse estos campos en el archivo los contactos no serán importados.',
        field: 'Dato {{ pos }}',
        firstNameField: 'Nombre',
        lastNameField: 'Apellido',
        emailField: 'Email',
        phoneField: 'Teléfono',
        contacts: 'Contactos',
        addContact: 'Agregar Contacto',
        removeContact: 'Eliminar Contacto',
      },
      campaignsFilters: {
        from: 'Desde',
        to: 'Hasta',
      },
      groups: {
        addGroup: 'Agregar Grupo',
        removeGroup: 'Eliminar Grupo',
        name: 'Nombre',
      },
      keywordsFilters: {
        keyword: 'Palabra',
        shortCode: 'Número Corto',
      },
      myAccount: {
        companyBusinessName: 'Razón Social',
        companyIdNumber: 'RIF',
        companyPhone: 'Teléfono',
        companyEmail: 'Email',
        companyAddress: 'Dirección',
        username: 'Nombre de Usuario',
        password: 'Nueva Contraseña',
        passwordConfirm: 'Confirmar Contraseña',
        startDate: 'Fecha de Inicio',
        dueDate: 'Fecha de Corte',
        channel: 'Canal',
        accountType: 'Tipo',
        subscriptionDetails: 'Detalles del Plan',
        subscription: 'Suscripción',
        subscriptionPrice: 'Costo',
        subscriptionCredits: 'Créditos',
        subscriptionTotal: 'Total',
      },
      newMessage: {
        group: 'Grupo',
        campaign: 'Campaña',
        integrator: 'Integradora',
        contactCount: 'Contactos',
        movistarContactCount: 'Movistar',
        digitelContactCount: 'Digitel',
        movilnetContactCount: 'Movilnet',
        content: 'Contenido',
        messageCount: 'Mensajes',
        totalMessages: 'Total de Mensajes a Enviar',
        credits: 'Créditos',
        schedule: 'Programar',
        addSchedule: 'Agregar Fecha',
        sendDate: 'Fecha',
        sendTime: 'Hora',
        removeSchedule: 'Eliminar Fecha',
      },
      payments: {
        month: 'Mes',
        year: 'Año',
        transactionDate: 'Fecha de Pago',
        transactionType: 'Tipo de Pago',
        bank: 'Banco',
        transactionId: 'Número de Referencia',
        transactionFile: 'Archivo',
      },
      paymentsFilters: {
        from: 'Desde',
        to: 'Hata',
        month: 'Mes',
        year: 'Año',
      },
      receivedMessages: {
        export: {
          from: 'Desde',
          to: 'Hasta',
        },
      },
      testMessage: {
        integrator: 'Integradora',
        receipent: 'Destinatario',
      },
      users: {
        username: 'Nombre de Usuario',
        password: 'Contraseña',
        passwordConfirm: 'Confirmar Contraseña',
        status: 'Estatus',
        personFirstName: 'Nombre',
        personLastName: 'Apellido',
        personIdNumber: 'Cédula',
        personAltIdNumber: 'RIF',
        personGender: 'Género',
        personBirthDate: 'Fecha de Nacimiento',
        permissions: 'Permisos',
      },
    },
  },
  labels: {
    filePicker: 'Arroja un archivo aquí o haz click',
  },
  tabs: {
    admin: {
      accounts: {
        changePassword: 'Cambiar Contraseña',
        company: 'Compañía',
        credits: 'Créditos',
        general: 'General',
        permissions: 'Permisos',
        personal: 'Personal',
        subscription: 'Suscripción',
      },
    },
    client: {
      myAccount: {
        company: 'Información de la Empresa',
        settings: 'Ajustes',
        subscription: 'Suscripción',
      },
      users: {
        changePassword: 'Cambiar Contraseña',
        general: 'General',
        personal: 'Personal',
        permissions: 'Permisos',
      },
    },
  },
};

import React from 'react';
import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import Table from '../../../Components/Table';
import trans from '../../../Translations/translation.service';
import { actions as allAccountsActions } from '../../../Redux/Api/AllAccounts';
import { actions as allKeywordsActions } from '../../../Redux/Api/AllKeywords';
import { actions as allShortCodesActions } from '../../../Redux/Api/AllShortCodes';
import { actions as fileDownloadActions } from '../../../Redux/Ducks/FileDownload';
import { actions as keywordsActions } from '../../../Redux/Api/Keywords';
import { actions as navBarActions } from '../../../Redux/Ducks/Navbar';
import {
  ALL_SELECT_OPTION_F,
  ALL_SELECT_OPTION_M,
  ALL_SELECT_OPTION_VALUE as ALL,
  DEFAULT_TABLE_ACTIONS,
  HEADERS as headers,
  KEYWORDS_TABLE_TITLES,
  KEYWORDS_URL,
  SUCCESS_CODE,
} from '../../../Constants';
import { PRIVATE_PATHS } from '../../config';
import {
  ADMIN_KEYWORDS_FILTERS_INPUTS,
} from './config';
import { FILTERS_FORM as form } from '../../../Components/Filters/config';
import { getPage } from '../../../Helpers';

class AdminKeywords extends React.Component {
  state = {
    account: ALL,
    shortCode: ALL,
    filters: {},
  };

  deleteKeyword = id => (
    this.props.deleteKeyword(id)
      .then(({ code }) => code === SUCCESS_CODE && this.fetchKeywords())
  );

  fetchAllKeywords = () => {
    const { account, shortCode } = this.state;

    if (account === ALL && shortCode === ALL) {
      this.props.fetchAllKeywords();
    } else if (account === ALL) {
      this.props.fetchAllKeywords({}, { query: { short_code: shortCode } });
    } else if (shortCode === ALL) {
      this.props.fetchAllKeywords({}, { query: { account } });
    } else {
      this.props.fetchAllKeywords({}, { query: { account, short_code: shortCode } });
    }
  };

  fetchAllShortCodes = () => {
    const { account } = this.state;

    if (account === ALL) {
      this.props.fetchAllShortCodes();
    } else {
      this.props.fetchAllShortCodes({}, { query: { account } });
    }
  };

  fetchAllKeywordsFromAccount = account => {
    // TODO: clean short code filter also
    const { filters } = this.state;

    if (account === ALL) {
      delete filters.account;
    } else {
      filters.account =  account;
    }

    delete filters.keyword;
    delete filters.short_code;
    this.props.change(form, 'keyword_id', ALL);
    this.props.change(form, 'short_code_id', ALL);

    this.setState({ account, filters },  () => {
      this.fetchAllKeywords();
      this.fetchAllShortCodes();
      this.fetchKeywords();
    });
  };

  fetchAllKeywordsFromShortCode = shortCode => {
    const { filters } = this.state;

    if (shortCode === ALL) {
      delete filters.short_code;
    } else {
      filters.short_code =  shortCode;
    }

    delete filters.keyword;
    this.props.change(form, 'keyword_id', ALL);

    this.setState({ filters, shortCode }, () => {
      this.fetchAllKeywords();
      this.fetchKeywords();
    });
  };

  fetchKeywords = newPage => {
    const { filters } = this.state;
    const { location } = this.props;
    const page = getPage(newPage, location);

    this.props.fetchKeywords({}, { query: { page, ...filters } });
  };

  fetchKeywordsFromKeyword = keyword => {
    const { filters } = this.state;

    if (keyword === ALL) {
      delete filters.keyword;
    } else {
      filters.keyword =  keyword;
    }

    this.setState({ filters }, () => {
      this.fetchKeywords();
    });
  };

  handleSearch = (search)  => {
    console.log('search', search);
    const { filters } = this.state;

    filters.word = search;

    this.setState({ filters }, () => {
      this.fetchKeywords();
    });
  };

  initFilters = () => {
    this.props.reset(form);
    this.props.change(form, 'account_id', ALL);
    this.props.change(form, 'keyword_id', ALL);
    this.props.change(form, 'short_code_id', ALL);
  };

  loading = () => {
    const {
      isDeletingKeyword,
      isDownloadingFile,
      isFetchingAllKeywords,
      isFetchingAccount,
      isFetchingKeywords,
      isFetchingShortCodes,
    } = this.props;
    return isDeletingKeyword
      || isDownloadingFile
      || isFetchingAllKeywords
      || isFetchingAccount
      || isFetchingKeywords
      || isFetchingShortCodes;
  };

  parseKeywordData = ({
    word,
    account: { company_name: companyName },
    short_code: { code },
  }) => [
    {
      text: word,
    },
    {
      text: code,
    },
    {
      text: companyName,
    },
  ];

  parseAccounts = () => {
    const accounts = this.props.accounts.map(({ id: value, company_name: text }) => ({
      text, value,
    }));

    accounts.unshift(ALL_SELECT_OPTION_F);

    return accounts;
  };

  parseKeywords = () => {
    const keywords = this.props.allKeywords.map(({ id: value, word: text }) => ({
      text, value,
    }));

    keywords.unshift(ALL_SELECT_OPTION_F);

    return keywords;
  };

  parseShortCodes = () => {
    const shortCodes = this.props.shortCodes.map(({ id: value, code: text }) => ({
      text, value,
    }));

    shortCodes.unshift(ALL_SELECT_OPTION_M);

    return shortCodes;
  };

  export = () => {
    this.props.downloadFile(`${KEYWORDS_URL}/export`, { headers }, 'keywords.xlsx');
  };

  setMenuActions = () => {
    this.props.setHeaderMenuAction('export', this.export);
  };

  componentDidMount() {
    this.fetchKeywords();
    this.props.setOnSearch(this.handleSearch);
    this.props.fetchAllAccounts();
    this.fetchAllKeywords();
    this.fetchAllShortCodes();
    this.initFilters();
    this.setMenuActions();
  }

  render() {
    const { keywords, history, pagination } = this.props;
    const accounts = this.parseAccounts();
    const allKeywords = this.parseKeywords();
    const shortCodes = this.parseShortCodes();
    const type = trans('resources.singular.keyword');

    return (
      <Table
        actions={DEFAULT_TABLE_ACTIONS}
        actionButtonNames={{
          delete: ({ word }) => word,
        }}
        data={keywords}
        dataParser={this.parseKeywordData}
        deleteWarningMessage="deleteOpSingF"
        editPath={PRIVATE_PATHS.adminEditKeyword.noParamsPath}
        filtersProps={{
          afterChange: {
            account_id: this.fetchAllKeywordsFromAccount,
            short_code_id: this.fetchAllKeywordsFromShortCode,
            keyword_id: this.fetchKeywordsFromKeyword,
          },
          data: {
            account_id: accounts,
            keyword_id: allKeywords,
            short_code_id: shortCodes,
          },
          inputs: ADMIN_KEYWORDS_FILTERS_INPUTS,
          loading: this.loading(),
        }}
        handleChangePage={this.fetchKeywords}
        history={history}
        loading={this.loading()}
        onDeleteAcceptClick={this.deleteKeyword}
        pagination={pagination}
        titles={KEYWORDS_TABLE_TITLES}
        type={type}
      />
    );
  }
}

const mapStateToProps = ({
  allAccounts: {
    isFetching: isFetchingAccounts,
    items: accounts,
  },
  allKeywords: {
    isFetching: isFetchingAllKeywords,
    items: keywords,
  },
  allShortCodes: {
    isFetching: isFetchingShortCodes,
    items: shortCodes,
  },
  fileDownload: { pending },
  keywords: {
    isDeleting,
    isFetching,
    items: [success, data, pagination],
  },
}) => ({
  isFetchingAccounts,
  isFetchingAllKeywords,
  isFetchingShortCodes,
  pagination,
  success,
  accounts: accounts[1] ? accounts[1] : [],
  allKeywords: keywords[1] ? keywords[1] : [],
  shortCodes: shortCodes[1] ? shortCodes[1] : [],
  keywords: success ? data : [],
  isDeletingKeyword: isDeleting,
  isDownloadingFile: pending,
  isFetchingKeywords: isFetching,
});

export default connect(
  mapStateToProps,
  {
    change,
    reset,
    ...allAccountsActions,
    ...allKeywordsActions,
    ...allShortCodesActions,
    ...fileDownloadActions,
    ...keywordsActions,
    ...navBarActions,
  }
)(AdminKeywords);

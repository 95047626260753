import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Wrapper from './Styled/Wrapper';
import trans from '../../Translations/translation.service';

export default props => (
  <Wrapper {...props}>
    <CircularProgress color="primary" size={50} thickness={5} />
    <span>{trans('default.loading.pleaseWait')}</span>
    <span>{trans('default.loading.dontRefresh')}</span>
  </Wrapper>
);

import Close from '@material-ui/icons/Close';
import trans from '../../../../Translations/translation.service';

export const ADD_ACTIONS = [
  {
    color: 'secondary',
    handler: 'backToList',
    label: trans('modals.actions.backToList'),
    type: 'button',
  },
  {
    color: 'primary',
    label: trans('forms.actions.save'),
    type: 'submit',
  },
];
export const EDIT_ACTIONS = [
  {
    color: 'secondary',
    handler: 'backToList',
    label: trans('modals.actions.backToList'),
    type: 'button',
  },
  {
    color: 'primary',
    label: trans('forms.actions.save'),
    type: 'submit',
  },
];

export const DEFAULT_ADD_VALUES = {
  businessTypes: [],
};

export const DEFAULT_VALUES = {
  name: '',
  industry_id: null,
};

export const INPUTS = [
  {
    fieldType: 'array',
    name: 'businessTypes',
    inputs: [
      {
        clickableIcon: true,
        id: 'name',
        icon: Close,
        iconAlign: 'right',
        inputProps: {
          maxLength: 255,
        },
        label: trans('forms.fields.admin.businessTypes.name'),
        name: 'name',
        remove: true,
        required: true,
        sizes: {
          xs: 6,
        },
      },
      {
        id: 'industry',
        label: trans('forms.fields.admin.businessTypes.industry'),
        name: 'industry_id',
        required: true,
        sizes: {
          xs: 6,
        },
        type: 'select',
      },
    ],
    size: {
      xs: 12,
      sm: 6,
    },
  },
];

export const EDIT_INPUTS = [
  {
    id: 'name',
    inputProps: {
      maxLength: 255,
    },
    label: trans('forms.fields.admin.businessTypes.name'),
    name: 'name',
    required: true,
    sizes: {
      xs: 12,
      sm: 6,
      md: 4,
    },
  },
  {
    id: 'industry',
    label: trans('forms.fields.admin.businessTypes.industry'),
    name: 'industry_id',
    required: true,
    sizes: {
      xs: 12,
      sm: 6,
      md: 4,
    },
    type: 'select',
  },
];

export const BUSINESS_TYPE_FORM = 'businessType';
export const BUSINESS_TYPES_FORM = 'businessTypes';

export const BUSINESS_TYPES_VALIDATIONS = [
  {
    array: true,
    field: 'businessTypes',
    validations: [
      {
        field: 'name',
        message: ['default.forms.validations.isRequired'],
        method: 'isEmpty',
        validWhen: false,
      },
      {
        field: 'industry_id',
        message: ['default.forms.validations.isRequired'],
        method: 'isEmpty',
        validWhen: false,
      },
    ],
  },
];

export const BUSINESS_TYPE_VALIDATIONS = [
  {
    field: 'name',
    message: ['default.forms.validations.isRequired'],
    method: 'isEmpty',
    validWhen: false,
  },
  {
    field: 'industry_id',
    message: ['default.forms.validations.isRequired'],
    method: 'isEmpty',
    validWhen: false,
  },
];

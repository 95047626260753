import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import StyledMenuOption from './Styled/StyledMenuOption';
import OptionName from './Styled/StyledOptionName';

class MenuOption extends React.Component {
  handleClick = () => {
    const { headerMenuActions, option: { name } } = this.props;

    const callback = headerMenuActions[name];

    if (callback && typeof callback === 'function') {
      callback();
    }
  };

  render() {
    const { option: { icon: Icon, path, text } } = this.props;

    let option = (
      <div onClick={this.handleClick}>
        <Icon />
        <OptionName>{text}</OptionName>
      </div>
    );

    if (path) {
      option = (
        <Link to={path}>
          {option}
        </Link>
      );
    }

    return (
      <StyledMenuOption>
        {option}
      </StyledMenuOption>
    );
  }
}

const mapStateToProps = ({
  navbar: { headerMenuActions },
}) => ({
  headerMenuActions,
});

export default connect(mapStateToProps)(MenuOption);

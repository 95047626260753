import React from 'react';
import { connect } from 'react-redux';
import AddPaymentForm from '../../../../Components/Forms/Admin/Payments/AddPayment';
import ModalsWrappedForm from '../../../../Components/Forms/ModalsWrappedForm';
import trans from '../../../../Translations/translation.service';
import { actions as allAccountsActions } from '../../../../Redux/Api/AllAccounts';
import { actions as allBanksActions } from '../../../../Redux/Api/AllBanks';
import { actions as paymentsActions, types } from '../../../../Redux/Api/Payments';
import { actions as fileUploadActions } from '../../../../Redux/Ducks/FileUpload';
import { PRIVATE_PATHS } from '../../../config';
import { PAYMENTS_URL, MALE_CODE, JSON_HEADERS as headers } from '../../../../Constants';
import { ADD_ACTIONS } from '../../../../Components/Forms/Admin/Payments/config';
import { catchApiItemErrors } from '../../../../Helpers';

class AddPayment extends React.Component {
  state = {
    createdId: null,
  };

  createPayment = ({ transaction_src_file, ...data }) => {
    const { createdId } = this.state;

    if (!createdId) {
      return this.props.createPayment(data)
        .then(async res => {
          const { id } = res.body.data;

          this.setState({ createdId: id });

          if (transaction_src_file) {
            await this.uploadTransactionFile(id, transaction_src_file);
          }
        })
        .catch(
          catchApiItemErrors,
        );
    } else {
      return this.props.updatePayment({ id: createdId, ...data })
        .then(async res => {
          if (transaction_src_file) {
            await this.uploadTransactionFile(createdId, transaction_src_file);
          }
        })
        .catch(
          catchApiItemErrors,
        );
    }
  };

  loading = () => {
    const {
      isCreatingPayment,
      isFetchingAccounts,
      isFetchingBanks,
      isUpdatingPayment,
      isUploadingFile,
    } = this.props;
    return isCreatingPayment
      || isFetchingAccounts
      || isFetchingBanks
      || isUpdatingPayment
      || isUploadingFile;
  };

  openSuccessModal = () => {
    const { fileUploadSuccess, success } = this.props;

    return success || fileUploadSuccess;
  };

  parseAccounts = () => (
    this.props.accounts.map(({ id: value, company_name: text }) => ({
      text, value,
    }))
  );

  parseBanks = () => (
    this.props.banks.map(({ id: value, name: text }) => ({
      text, value,
    }))
  );

  uploadTransactionFile = (id, transaction_src_file) => (
    this.props.uploadFile(
      `${PAYMENTS_URL}/${id}`,
      {
        transaction_src_file,
        transaction_src_file_name: transaction_src_file.name,
      },
      {
        headers,
      },
    )
  );

  componentDidMount() {
    this.props.fetchAllAccounts();
    this.props.fetchAllBanks();
    this.props.uploadFileReset();
  }

  render() {
    const { history } = this.props;
    const accounts = this.parseAccounts();
    const banks = this.parseBanks();
    const subject = trans('resources.singular.payment');

    return (
      <ModalsWrappedForm
        gender={MALE_CODE}
        history={history}
        loading={this.loading()}
        open={this.openSuccessModal()}
        path={PRIVATE_PATHS.adminPayments.path}
        subject={subject}
        successMessage="successOpSingM"
        type="add"
      >
        <AddPaymentForm
          actions={ADD_ACTIONS}
          data={{
            account_id: accounts,
            bank_id: banks,
          }}
          history={history}
          onSubmit={this.createPayment}
          path={PRIVATE_PATHS.adminPayments.path}
        />
      </ModalsWrappedForm>
    );
  }
}

const mapStateToProps = ({
  allAccounts: {
    isFetching: isFetchingAccounts,
    items: accounts,
  },
  allBanks: {
    isFetching: isFetchingBanks,
    items: banks,
  },
  payments: { isCreating, isUpdating },
  fileUpload: { pending, response, success: fileUploadSuccess },
  reduxRestResponse: { body, success, type },
  ...state
}) => ({
  body,
  isFetchingAccounts,
  isFetchingBanks,
  response,
  fileUploadSuccess,
  accounts: accounts[1] ? accounts[1] : [],
  banks: banks[1] ? banks[1] : [],
  isCreatingPayment: isCreating,
  isUpdatingPayment: isUpdating,
  isUploadingFile: pending,
  success: (
    type === types.CREATE_PAYMENT || type === types.UPDATE_PAYMENT
  ) && success,
});

export default connect(
  mapStateToProps,
  {
    ...allAccountsActions,
    ...allBanksActions,
    ...paymentsActions,
    ...fileUploadActions,
  }
)(AddPayment);

import React from 'react';
import { connect } from 'react-redux';
import WarningModal from '../../../Components/Modals/Warning';
import Table from '../../../Components/Table';
import trans from '../../../Translations/translation.service';
import { actions as allContactsActions, types } from '../../../Redux/Api/AllContacts';
import { actions as contactsActions } from '../../../Redux/Api/Contacts';
import { actions as navBarActions } from '../../../Redux/Ducks/Navbar';
import {
  API_CARRIERS,
  CONTACTS_TABLE_TITLES,
  DELETE_TABLE_ACTIONS,
  SUCCESS_CODE,
} from '../../../Constants';
import { getPage } from '../../../Helpers';

class ClientContacts extends React.Component {
  state = {
    openDeleteAllModal: false,
  };

  deleteAll = () => {
    this.toggleDeleteAllModal();
    this.props.deleteAllContacts({})
      .then(({ code }) => code === SUCCESS_CODE && this.fetchContacts(1));
  };

  deleteContact = id => (
    this.props.deleteContact(id)
      .then(({ code }) => code === SUCCESS_CODE && this.fetchContacts())
  );

  fetchContacts = newPage => {
    const { location } = this.props;
    const page = getPage(newPage, location);

    this.props.fetchContacts({}, { query: { page, client: true } });
  };

  loading = () => {
    const {
      isDeletingContact,
      isDeletingContacts,
      isFetchingContacts,
    } = this.props;

    return isDeletingContact
      || isDeletingContacts
      || isFetchingContacts;
  };

  parseContactData = ({
    first_name,
    last_name,
    email,
    phone,
    carrier,
  }) => [
    {
      text: first_name,
    },
    {
      text: last_name,
    },
    {
      text: email,
    },
    {
      text: phone,
    },
    {
      text: API_CARRIERS[carrier] ? trans(`default.carriers.${API_CARRIERS[carrier]}`) : '',
    },
  ];

  setMenuActions = () => {
    this.props.setHeaderMenuAction('deleteAll', this.showDeleteAllModal);
  };

  showDeleteAllModal = () => {
    this.toggleDeleteAllModal();
  };

  toggleDeleteAllModal = () => {
    const { openDeleteAllModal } = this.state;

    this.setState({ openDeleteAllModal: !openDeleteAllModal });
  };

  componentDidMount() {
    this.setMenuActions();
    this.fetchContacts();
  }

  render() {
    const { contacts, history, pagination } = this.props;
    const type = trans('resources.singular.contact');
    const typePlural = trans('resources.plural.contacts');

    return (
      <>
        <Table
          //actions={DELETE_TABLE_ACTIONS}
          //actionButtonNames={{
            //delete: ({ first_name, last_name }) => `${first_name} ${last_name}`,
          //}}
          data={contacts}
          dataParser={this.parseContactData}
          //deleteWarningMessage="deleteOpSingM"
          handleChangePage={this.fetchContacts}
          history={history}
          loading={this.loading()}
          //onDeleteAcceptClick={this.deleteContact}
          pagination={pagination}
          titles={CONTACTS_TABLE_TITLES}
          type={type}
        />
        <WarningModal
          onAcceptClick={this.deleteAll}
          onCancelClick={this.toggleDeleteAllModal}
          open={this.state.openDeleteAllModal}
          warningText={trans('modals.messages.deleteAllM', { type: typePlural })}
        />
      </>
    );
  }
}

const mapStateToProps = ({
  allContacts: {
    isDeletingMany: isDeletingContacts,
  },
  auth: { user },
  contacts: {
    isDeleting,
    isFetching,
    items: [success, data, pagination],
  },
  reduxRestResponse: { body, success: responseSuccess, type },
}) => ({
  isDeletingContacts,
  pagination,
  success,
  user,
  contacts: success ? data : [],
  deleteAllSuccess: type === types.DELETE_ALL_CONTACTS && responseSuccess,
  isDeletingContact: isDeleting,
  isFetchingContacts: isFetching,
});

export default connect(
  mapStateToProps,
  {
    ...allContactsActions,
    ...contactsActions,
    ...navBarActions,
  }
)(ClientContacts);

import React from 'react';
import { connect } from 'react-redux';
import EditUserForm from '../../../../Components/Forms/Client/Users/EditUser';
import ModalsWrappedForm from '../../../../Components/Forms/ModalsWrappedForm';
import trans from '../../../../Translations/translation.service';
import { actions as allPermissionsActions } from '../../../../Redux/Api/AllPermissions';
import { actions as usersActions, types } from '../../../../Redux/Api/Users';
import { PRIVATE_PATHS } from '../../../config';
import {
  USERS_STATUSES_LIST,
  MALE_CODE,
  GENDERS_LIST,
} from '../../../../Constants';
import {
  USER_PARSE_CONFIG,
  EDIT_ACTIONS,
} from '../../../../Components/Forms/Client/Users/config';
import {
  catchApiItemErrors,
  parseDataForApi,
} from '../../../../Helpers';

class EditUser extends React.Component {
  getUser = () => {
    const { id } = this.props.match.params;

    this.props.getUser(id);
  };

  updateUser = user => (
    this.props.updateUser(
      parseDataForApi(user, USER_PARSE_CONFIG),
    ).catch(
      catchApiItemErrors,
    )
  );

  loading = () => {
    const {
      isFetchingUser,
      isFetchingPermissions,
      isUpdatingUser,
    } = this.props;
    return isFetchingUser
      || isFetchingPermissions
      || isUpdatingUser;
  };

  parsePermissions = () => (
    this.props.permissions.map(({ id: value, name: label }) => ({
      label, value,
    }))
  );

  componentDidMount() {
    this.props.fetchAllPermissions();
    this.getUser();
  }

  render() {
    const { user, history, success } = this.props;
    const permissions = this.parsePermissions();
    const subject = trans('resources.singular.user');

    return (
      <ModalsWrappedForm
        gender={MALE_CODE}
        history={history}
        loading={this.loading()}
        open={success}
        path={PRIVATE_PATHS.clientUsers.path}
        subject={subject}
        successMessage="successOpSingM"
        type="edit"
      >
        {
          user &&
          <EditUserForm
            actions={EDIT_ACTIONS}
            data={{
              user: {
                status: USERS_STATUSES_LIST,
                'permissions._ids': permissions,
                'person.gender': GENDERS_LIST,
              },
            }}
            history={history}
            onSubmit={this.updateUser}
            path={PRIVATE_PATHS.clientUsers.path}
          />
        }
      </ModalsWrappedForm>
    );
  }
}

const mapStateToProps = ({
  allPermissions: {
    isFetching: isFetchingPermissions,
    items: permissions,
  },
  users: { isFetchingItem, isUpdating, item },
  reduxRestResponse: { body, success, type },
  ...state
}) => ({
  body,
  user: item,
  isFetchingPermissions,
  isFetchingUser: isFetchingItem,
  isUpdatingUser: isUpdating,
  permissions: permissions[1] ? permissions[1] : [],
  success: type === types.UPDATE_USER && success,
});

export default connect(
  mapStateToProps,
  {
    ...allPermissionsActions,
    ...usersActions,
  }
)(EditUser);

import React from 'react';
import { makeStyles } from '@material-ui/styles';
import MenuOption from './MenuOption';
import Menu from './Styled/StyledMenu';

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

export default ({ options }) => {
  const classes = useStyles();
  return (
    <Menu className={classes.root}>
      {
        options.map(option => (
          <MenuOption option={option}/>
        ))
      }
    </Menu>
  );
};

/** Resources names (EN)  */
export default {
  plural: {
    accounts: 'Accounts',
    banks: 'Banks',
    businessTypes: 'Business Types',
    campaigns: 'Campaigns',
    contacts: 'Contacts',
    credits: 'Credits',
    groups: 'Groups',
    industries: 'Industries',
    integrators: 'Integrators',
    keywords: 'Keywords',
    messages: 'Messages',
    news: 'News',
    payments: 'Payments',
    shortCodes: 'Short Codes',
    users: 'Users',
  },
  singular: {
    account: 'Account',
    bank: 'Bank',
    businessType: 'Business Type',
    campaign: 'Campaign',
    contact: 'Contact',
    group: 'Group',
    industry: 'Industry',
    integrator: 'Integrator',
    keyword: 'Keyword',
    message: 'Message',
    new: 'New',
    payment: 'Payment',
    shortCode: 'Short Code',
    user: 'User',
  },
};

import React from 'react';
import { connect } from 'react-redux';
import { change, formValueSelector } from 'redux-form';
import MyAccountForm from '../../../../Components/Forms/Client/Accounts/MyAccount';
import ModalsWrappedForm from '../../../../Components/Forms/ModalsWrappedForm';
import trans from '../../../../Translations/translation.service';
import { actions as allChannelsActions } from '../../../../Redux/Api/AllChannels';
import { actions as reduxRestResponseActions } from '../../../../Redux/Ducks/ReduxRestResponse';
import { actions as usersActions, types } from '../../../../Redux/Api/Users';
import { PRIVATE_PATHS } from '../../../config';
import {
  FEMALE_CODE,
} from '../../../../Constants';
import {
  MY_ACCOUNT_FORM as form,
  UPDATE_ACCOUNT_ACTIONS,
} from '../../../../Components/Forms/Client/Accounts/config';
import {
  catchApiItemErrors,
  parseDataForApi,
} from '../../../../Helpers';

class MyAccount extends React.Component {
  updateUser = user => {
    const { id, password, username } = user;

    return this.props.updateUser({ id, password, username }).catch(
      catchApiItemErrors,
    );
  };

  loading = () => {
    const {
      isFetchingAccount,
      isFetchingChannels,
      isFetchingUser,
      isUpdatingUser,
    } = this.props;
    return isFetchingAccount
      || isFetchingChannels
      || isFetchingUser
      || isUpdatingUser;
  };

  componentDidMount() {
    this.props.fetchAllChannels();
  }

  render() {
    const { account, history, success, user } = this.props;
    const subject = trans('resources.singular.account');

    return (
      <ModalsWrappedForm
        acceptText={trans('modals.actions.accept')}
        gender={FEMALE_CODE}
        history={history}
        loading={this.loading()}
        onAcceptClick={this.props.reset}
        open={success}
        subject={subject}
        successMessage="successOpSingF"
        type="edit"
      >
        {
          account && user &&
          <MyAccountForm
            actions={UPDATE_ACCOUNT_ACTIONS}
            history={history}
            onSubmit={this.updateUser}
            path={PRIVATE_PATHS.myAccount.path}
          />
        }
      </ModalsWrappedForm>
    );
  }
}

const selector = formValueSelector(form);

const mapStateToProps = ({
  accounts: { isFetchingItem: isFetchingAccount, item: account },
  allChannels: {
    isFetching: isFetchingChannels,
  },
  reduxRestResponse: { body, success, type },
  users: {
    isFetchingItem, isUpdating, item: user,
  },
  ...state
}) => ({
  account,
  body,
  isFetchingAccount,
  isFetchingChannels,
  user,
  isFetchingUser: isFetchingItem,
  isUpdatingUser: isUpdating,
  subscriptions: selector(state, 'account.subscriptions'),
  success: type === types.UPDATE_USER && success,
});

export default connect(
  mapStateToProps,
  {
    change,
    ...allChannelsActions,
    ...reduxRestResponseActions,
    ...usersActions,
  }
)(MyAccount);

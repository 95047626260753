import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import { withStyles } from '@material-ui/core/styles';

export default withStyles({
  expanded: {
    margin: '0 !important',
  },
  root: {
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
  },
})(ExpansionPanel);
